import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import Drexel_Vertical_stacked_Lockup_HEX from '../../content/images/Drexel_Vertical_stacked_Lockup_HEX.png';
import httpUtil from '../../utils/HttpUtil';
import logo_myownmed from '../../content/images/logo_myownmed.png';
import NavBar from './../../components/NavBar.js'
export default class ResetPin extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            pin: '',
            pinconfirm: '',
            have4digits: true,
            samePin: true,
            showIndicator: false,
            regSuccess: false,
        };
    }
    componentDidMount() {

    }
    //TODO:需要继续修改alert
    next() {
        if (this.state.pin.length === 0) {
            alert(
                //Translation.lang().Missing,
                this.translate("w_Please_enter_digit_PIN_")
            )
        }
        else if (this.state.pin.length < 4) {
            alert(
                //Translation.lang().w_setPin,
                this.translate("w_must_contain_4_digits")
            )
        }
        else if (this.state.pin !== this.state.pinconfirm) {
            alert(

                //Translation.lang().w_setPin,
                this.translate("w_pin_must_match")
            )
        }
        else {

            this.setState({ showIndicator: true });
            let email = this.props.location.state.email;
            httpUtil.post('api/Participant/UpdatePin', { pin: this.state.pin },
                (responseData) => {
                    this.setState({ showIndicator: false });
                    if (responseData && responseData.status === 'Success') {
                        //Profile.savePin(this.state.pin);
                       // alert(
                         //   this.translate("w_You_successfully_Reset_your_digits_PIN_")
                            //Translation.lang().w_congratulations,
                        //     Translation.lang().w_You_successfully_Reset_your_digits_PIN_,
                        //     [
                        //         {
                        //             text: this.translate("w_ok"), onPress: () => {
                        //                 //MomEnv.back();
                                        
                        //             }
                        //         },
                        //     ]
                        // );
                        //);
                        alert(this.translate("w_You_successfully_Reset_your_digits_PIN_"));
                        this._signIn({ email: email, pin: this.state.pin });
                    } else {
                        alert(
                            //Translation.lang().w_reset_pin,
                            this.translate("w_Reset_PIN_Failed__try_again_later")
                        )
                    }
                }
            );
        }
        //}
    }

    _validPin(pin) {
        this.setState({
            have4digits: pin.length >= 4,
            samePin: pin === this.state.pinconfirm
        });
    }

    _validPinconfirm(pinconfirm) {
        this.setState({ samePin: pinconfirm === this.state.pin });
    }

    _scrollToEnd() {
        this._scroll && this._scroll.scrollTo({ x: 0, y: 160, animated: true });
    }
    getProfile() {
        httpUtil.get('/api/Profile/GetMine',
            (profileData) => {
                if (profileData.id && profileData.status === 1) {
                    localStorage.setItem('profile', JSON.stringify(profileData));
                    localStorage.setItem("need_alert_survey","1");
                    this.props.history.push('/trackmain');
                }
            }
        );
    }
    _signIn(param) {
        this.setState({ showIndicator: true });
        httpUtil.post('Token', `grant_type=password&username=${param.email}&password=${param.pin}`,
            (result) => {
                this.setState({ showIndicator: false });
                if (result && result.access_token) {
                    let token = result.access_token;
                    localStorage.setItem('token', token);
                    this.getProfile();

                }
            }
        );
    }

    render() {
        let { pin, pinconfirm } = this.state;
        return (
            <div className="conatiner" style={{ justifyContent: 'flex-start' }}>
                <NavBar
                    title={this.translate('w_Reset_4_digit_PIN')}
                    backurl={'/'}
                    history={this.props.history}
                />
                <div style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    alignSelf: 'stretch',
                    marginTop: 10,
                    marginBottom: 5
                }}>
                    <p style={{ fontSize: 18,fontWeight:800 }}>{this.translate("w_Reset_your_4digit_Pin")}</p>
                    <p style={{ fontSize: 16 }}>{this.translate("w_Reset_your_4digit_Pin_to_login_to_the_app")}</p>
                </div>
                <input
                    className="input1"
                    type='password'
                    maxLength={4}
                    style={{ marginTop: 20, }}
                    placeholder={this.translate("w_new_4_digit_pin")}
                    value={pin}
                    onChange={(e) => {
                        this.setState({ pin: e.target.value });
                        this._validPin(e.target.value.replace(/\D/g, ''))
                    }}
                />
                <input
                    className="input1"
                    type='password'
                    style={{ marginTop: 20, }}
                    maxLength={4}
                    placeholder={this.translate("w_confirm_4_digit_pin")}
                    value={pinconfirm}
                    onChange={(e) => {
                        this.setState({ pinconfirm: e.target.value });
                        this._validPinconfirm(e.target.value.replace(/\D/g, ''));
                    }}
                />
                {
                    pin !== '' &&
                    <div style={{ alignSelf: 'stretch', marginBottom: 10 ,marginLeft:20}}>
                        <p style={this.state.have4digits ? styles.txtOK : styles.txtErr}>
                            {this.state.have4digits ? '√' : 'x'} {this.translate("w_must_contain_4_digits")}
                        </p>
                        <p style={this.state.samePin ? styles.txtOK : styles.txtErr}>
                            {this.state.samePin ? '√' : 'x'} {this.translate("w_pin_must_match")}
                        </p>
                    </div>
                }
                <button onClick={this.next.bind(this)} className="button">
                    {this.translate('w_done')}
                </button>
            </div>
        );
    }

}
const styles = {
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',

    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5,
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    txtErr: {
        color: '#f00',
        marginTop: 3,
        fontSize: 14
    },
    txtOK: {
        color: '#00b359',
        marginTop: 3,
        fontSize: 14
    }
};