import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import Container from '../../components/Container.js';
import HttpUtil from '../../utils/HttpUtil.js';
import Loading from './../../components/Loading.js';
import moment from 'moment';
import i18n from 'i18next';
const reg = new RegExp("^[A-Za-z0-9]+([._\\-]*[A-Za-z0-9])*@([A-Za-z0-9]+[-a-z0-9]*[A-Za-z0-9]+.){1,63}[A-Za-z0-9]+$");

export default class Email extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            valid: true,
            emailexist: false,
            showIndicator: false,
            rmodel: {},
            validemail: true,
            hasRequested: false,
            loading:false
        };
    }
    
    componentDidMount(){
        //避免浏览器通过返回键回到这个页面以后，点击next没有反应
        this.setState({hasRequested:false});
    }
    
    translate(key) {
        return i18n.t(key);
    }

    emailtextchange(text) {
        text = text.trim();
        let valid = reg.test(text);
        this.setState({ email: text, valid: valid });
        if (valid) {
            let emailtext1 = text.split('@');
            if (emailtext1[1] != "drexel.edu") {
                this.setState({ validemail: false });
            }
            else {
                this.setState({ validemail: true });
            }
            this.checkemail(text);
        } else {
            if (this.state.emailexist) {
                this.setState({ emailexist: false });
            }
        }
    }

    checkemail(email) {
        let _this = this;
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(
            () => {
                HttpUtil.post('/api/Participant/VerifyEmail', {email: email},
                  (responseData) => {
                    if (responseData && responseData.status == 'Success') {
                        _this.setState({ emailexist: responseData.data == '1' && responseData.model && responseData.model.isRegisteredByAppUser });
                    }
                  }
                );
            },
            1200
        );
    }

    sendVerifyAuthenticationEmail(model) {
        this.props.history.push('/authenticationEmail',{email: this.state.email, rmodel: model});
    }

    next() {
        let { email, validemail, hasRequested } = this.state;
        console.log(hasRequested);
        if (hasRequested) {
            return;
        }
        this.setState({ hasRequested: true });
        email = email.trim();
        if (email == '') {
            alert(this.translate('Email_address_is_required'))
            this.setState({ hasRequested: false });
            //Alert.alert(Translation.lang().Missing, Translation.lang().Email_address_is_required);
        } else {
            if (reg.test(email) && validemail) {
                this.setState({ showIndicator: true });
                HttpUtil.post('/api/Participant/VerifyEmail', {email: email},
                (responseData) => {
                    this.setState({ showIndicator: false });
                    if (responseData && responseData.status == 'Success') {
                        if (responseData.data == '1' && responseData.model) {
                            if ((responseData.model.fAvalidated == null && responseData.model.clientConsent == null) || (responseData.model.fAvalidated != null && responseData.model.fAvalidated == false && responseData.model.clientConsent != null && responseData.model.clientConsent == false)) {
                                this.sendVerifyAuthenticationEmail();
                            }
                            else if (responseData.model.isRegisteredByAppUser) {
                                this.props.history.push('/',{ email: email });
                            }
                            else {
                                this.props.history.push('/nameAndMobileNumber',{ email: email, rmodel: responseData.model});
                            }
                        }
                        else {
                            let model = responseData.model || {};
                            this.sendVerifyAuthenticationEmail(model);
                        }
                    } else {
                        this.setState({ hasRequested: false });
                        alert(this.translate('Email_check_failed'));
                        //Alert.alert(Translation.lang().Warning, Translation.lang().Email_check_failed);
                    }
                });
            } else {
                alert(this.translate('Email_address_must_be_valid_email'))
                //Alert.alert(Translation.lang().w_Warning, Translation.lang().Email_address_must_be_valid_email);
            }
        }
    }

    render() {
        var {email,loading} = this.state;
        return (
            <div style={styles.container2}>
                <div style={styles.content} offset={150}>
                    <p style={{fontWeight:600}}>
                        {this.translate('Please_provide_your_email_address')}
                    </p>
                    <div style={{width:'100%'}}>
                            <input
                                type="p"
                                style={{ marginBottom: 5, marginTop: 5, padding: 0,width:'100%',marginLeft:0 }}
                                placeholder={this.translate('Your_email_address')}
                                value={email}
                                onChange={(e) => {
                                    this.emailtextchange(e.target.value);
                                }}
                                className="input"
                            />
                    </div>
                    {
                        this.state.email == null || this.state.email.length == 0 &&
                        <p style={{color: '#f00', pAlign: 'left', marginTop: 15,width:'100%' }}>
                            {this.translate('x_Email_address_is_required')}
                        </p>
                    }
                    {
                        !this.state.valid && this.state.email != null && this.state.email.length > 0 &&
                        <p style={{ color: '#f00', pAlign: 'left', marginTop: 15 }}>
                            {this.translate('x_Email_address_must_be')}
                        </p>
                    }
                    {
                        this.state.valid && this.state.email != null && this.state.email.length > 0 && !this.state.validemail
                        &&
                        <p style={{ color: '#f00', pAlign: 'left', marginTop: 15 }}>
                            {this.translate('w_must_enter_Drexel_University_email_address')}
                        </p>
                    }
                    {
                        this.state.emailexist &&
                        <p style={{color: '#f00', pAlign: 'left', marginTop: 15}}>
                            {this.translate('x_Email_address_already_exists')}
                        </p>
                    }
                </div>

                <div style={{ alignSelf: 'stretch', marginTop: 10,position:'absolute', bottom: 0,marginBottom:20}}>
                    <div style={{ alignSelf: 'stretch', flexDirection: 'row', 
                    alignItems: 'center', marginLeft: 20,marginRight:20,wordBreak:"break-word",fontSize:12 }}>
                    <p style={{fontSize:14}}>
                        <span>
                            {this.translate('By_providing_your_email')}
                        </span>
                        <a onClick={() => {
                            this.props.history.push("/home/more/settings/termsofUse");
                        }} style={{ color: 'green',cursor:'pointer' }}>
                            {this.translate('w_termOfUse')}
                        </a>
                        <span>
                            {this.translate('_and')}
                        </span>
                        <a  onClick={() => {
                            this.props.history.push("/home/more/settings/privacy");
                        }} style={{ color: 'green',cursor:'pointer'}}>
                            {this.translate('w_privacyPolicy')}
                        </a>
                        <span>
                            {this.translate('for_the_application')}
                        </span>
                    </p>
                    </div>
                    <div style={{ alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center', marginLeft: 20}}>

                    </div>
                    <button className="button" 
                    style={{marginTop:0 ,width:'90%',justifyContent: 'center',
                            display: 'flex',alignItems: 'center'}}
                        underlayColor={'blue'}
                        onClick={()=>{
                            this.next();
                        }}>
                        <p>{this.translate('w_next')}</p>
                    </button>
                </div>
                {this.state.loading && <Loading />}
            </div>
        );
    }
}
const styles = {
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    container2:{
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#fff',
        position:'relative'
    },
    navContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        height: 60,
        paddingLeft: 12,
        paddingRight: 12,
    },
    content: {
        marginLeft: 20,
        marginTop: 40,
        marginRight: 20,
        alignSelf: 'stretch',
    }
}