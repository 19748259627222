import React from 'react';

import BaseComponent from '../../components/BaseComponent.js';
import moment from 'moment';
import arrow_right from '../../content/images/arrow_right.png';

const globalStyles = require('../../utils/GlobalStyles');
export default class CalendarList extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  _renderRow(rowData, rowID) {
    var separator = <div style={styles.separator} />;

    if (rowID === 0) {
      separator = null;
    }

    let statusStyle = (rowData.status == 10 || rowData.status == 30) ? { backgroundColor: '#13B456' } : { backgroundColor: '#13B456' }

    return (
      <div key={rowID}>
        {separator}
        <div onClick={() => this.props.pressRow(rowData)}>
          <div style={styles.row}>
            <div style={{ ...styles.rowLeft, flex: 1, paddingLeft: 6 }}>
              <div style={styles.leftContent}>
                <span style={{...globalStyles.normalFont, ...globalStyles.boldFont, ...styles.appointType}}>
                  {rowData.appointmentTypeName}
                </span>
                <span style={{...globalStyles.normalFont, ...styles.dateTime }}>
                  {rowData.location || ''}
                </span>
                <span style={{...globalStyles.normalFont, ...globalStyles.boldFont, ...styles.dateTime}}>
                  {moment.utc(rowData.startDateTime).local().format('h:mm A')}
                </span>
              </div>
            </div>
            <div style={{...styles.status, ...statusStyle}}>
              <span style={{...globalStyles.normalFont, ...styles.statusText}}>
                {rowData.statusDisplay}
              </span>
            </div>
            <div style={{...styles.rowRight, marginLeft: 20 }}>
              <img style={{ tintColor: '#999' }}  src={arrow_right} alt="Select" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.dataItems.length > 0) {
      return this.props.dataItems.map(this._renderRow.bind(this));
    }
    return (
      <div />
    );
  }
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 6,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  rowLeft: {
    marginLeft: 10
  },
  rowRight: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10
  },
  month: {
  },
  feeling: {
    marginRight: 10,
  },
  separator: {
    height: 0.5,
    marginLeft: 8,
    marginRight: 8,
    backgroundColor: '#CCC',
  },
  status: {
    padding: 3,
    backgroundColor: '#13B456',
    alignItems: 'center',
    borderRadius: 4,
  },
  statusText: {
    color: 'white',
    marginLeft: 3,
    marginRight: 3
  },
  dateTime: {
    //fontSize: 14,
    //color: '#999',
    marginRight: 10,
  },
  appointType: {
    //color: '#666',
    //fontSize: 14,
    marginBottom: 3
  },
  staffName: {
    marginBottom: 3
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateAndStatus: {
    alignItems: 'center',
    flexDirection: 'row'
  }
};