import React, { Component, PureComponent, Fragment } from 'react';
import BaseComponent from '../../../components/BaseComponent';
import NavBar from '../../../components/NavBar.js';
import arrow_right from '../../../content/images/arrow_right.png';
import addTestResultIcon from '../../../content/images/icon_AddPlusCircle.png';
import introductionAlbum from '../../../content/images/photo/introductionAlbum.png';
import introductionPhoto from '../../../content/images/photo/introductionPhoto.png';
import i18n from 'i18next';
import Loading from '../../../components/Loading';
import common from '../../../Common';
import httpUtil from '../../../utils/HttpUtil';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Row, Col } from 'antd';
import CommonUtil from './../../../utils/CommonUtil';
import AddPhotoAndImage from './AddPhotoAndImage';
import Profile from './../../../store/ProfileStorage';
const CustomDatePickerInput = ({ value, onClick }) => (
  <button
    className="custom_date_picker_input"
    style={styles.datePickerButton}
    onClick={onClick}
  >
    {value}
  </button>
);

export default class AddTestResult extends BaseComponent {
  constructor(props) {
    super(props);
    let editLabResult = this.props.location.state;
    var photo = '';
    var labResultAttachments = [];
    var DateResults = new Date();
    var resourceOther = '';
    var labTypeID = '';
    var Location = '';
    var labResultQualitativeID = '';
    var labResultID = 0;
    var LabType = '';
    if (editLabResult) {
      if (
        editLabResult.labResultAttachments &&
        editLabResult.labResultAttachments.length > 0
      ) {
        if (editLabResult.labResultAttachments[0].fileBase64) {
          photo = editLabResult.labResultAttachments[0].fileBase64;
        } else {
          photo = editLabResult.labResultAttachments[0].file.fileBits;
        }

        labResultAttachments = editLabResult.labResultAttachments;
      }
      Location = editLabResult.resourceOther;
      resourceOther = editLabResult.resourceOther;
      labTypeID = editLabResult.labTypeID;
      LabType = editLabResult.labTypeID;
      labResultQualitativeID = editLabResult.labResultQualitativeID;

      var year = editLabResult.dateResults.substring(0, 4);
      var month = editLabResult.dateResults.substring(5, 7);
      var day = editLabResult.dateResults.substring(8, 10);

      DateResults = new Date(year, month - 1, day);
      labResultID = editLabResult.labResultID;
    }
    var labResultAttachments = [];
    this.state = {
      LabTypes: [],
      LabResultQual: [],
      labResultID: labResultID,
      DateResults: moment(DateResults).format('YYYY-MM-DD'),
      LocationTests: {},
      results: [],
      LabTypeOtionsLoaded: false,
      LatResultQualOtionsLoaded: false,
      LabType: LabType,
      Location: Location,
      result: labResultQualitativeID,
      image: photo,
      showIndicator: false,
      labResultAttachments: labResultAttachments,
      labResultNotes:'',
      item: {
        labTypeID: labTypeID,
        dateResults: labResultQualitativeID,
        resourceOther: resourceOther,
        labResultQualitativeID: labResultQualitativeID,
      },
    };
  }

  componentDidMount() {
    this._loadLabTypes();
    this._loadLabResultQual();
}

  _loadLabTypes() {
    httpUtil.get(
      '/api/TestResults/GetLabTypes',
      (responseData) => {
        this.setState({
          LabTypes: responseData ? responseData : [],
          LabTypeOtionsLoaded: true,
        });
      },
      'GET'
    );
  }

  _loadLabResultQual() {
    httpUtil.get(
      '/api/TestResults/QueryLabResultQualitatives',
      (responseData) => {
        this.setState({
          LabResultQual: responseData ? responseData : [],
          LabResultQualOtionsLoaded: true,
        });
      },
      'GET'
    );
  }

  _goBack() {
    this.props.history.push('/home/more/testresults');
  }

  _saveTestResult() {
    let testResult = {};
    testResult.labResultID = this.state.labResultID;
    testResult.labTypeID = this.state.LabType;
    testResult.labResultQualitativeID = this.state.result;
    testResult.resourceOther = this.state.Location;
    testResult.labGroupID = this.state.LabType == 1063 ? 14 : 15;
    testResult.dateResults = moment(this.state.DateResults).format(
      'YYYY-MM-DD'
    );
    if (this.state.labResultAttachments.length > 0) {
      testResult.labResultAttachments = this.state.labResultAttachments;
    }
    let url = '';
    
    if (testResult.labResultID == 0) {
      url = '/api/TestResults/SaveEntry';
    } else {
      url = '/api/TestResults/UpdateEntry';
    }
    this.setState({ showIndicator: true });
    httpUtil.post(url, testResult, (responseData) => {
      if (responseData) {
        this.setState({ showIndicator: false });
        this._goBack();
      }
    });
  }

  _updateLabResultAttachments(labResultAttachments) {
    if (labResultAttachments && labResultAttachments.length > 0) {
      this.setState({
        labResultAttachments: labResultAttachments,
        image: labResultAttachments[0].file.fileBits,
      });
    }
  }

  render() {
    let {
      image,
      labResultAttachments,
      showIndicator,
      item,
      DateResults,
      LocationTests,
      result,
      LabType,
      Location,
      LabTypes,
      LabTypeOtionsLoaded,
      LabResultQual,
      LabResultQualOtionsLoaded,
    } = this.state;
    
    const labTypeOptions = LabTypes.map((o) => (
      <option key={o.id} value={o.id}>
        {o.name}
      </option>
    ));
    labTypeOptions.unshift(
      <option key="0" value="" disabled selected>
        {this.translate('w_Select_TestResult_Type')}
      </option>
    );

    let labResultQualOptions;
    
    if(LabResultQual){
      labResultQualOptions = LabResultQual.map((o) => (
        <option key={o.labResultQualitativeID} value={o.labResultQualitativeID}>
          {o.mappedName}
        </option>
      ));
      labResultQualOptions.unshift(
        <option key="0" value="" disabled selected>
          {this.translate('w_Select_Your_Result')}
        </option>
      );
    }
    
    let DateNow = new Date();
    let DateSixmonthsago = DateNow.setMonth(DateNow.getMonth() - 6);
    let ableSave = LabType && DateResults && result && Location;
    return (
      <div className="conatiner" style={{ justifyContent: 'flex-start' }}>
        <NavBar
          title={this.translate('w_add_Test_Results')}
          backurl={'/home/more/testresults'}
          history={this.props.history}
        />
        <div
          className="conatiner"
          style={{
            overflowY: 'auto',
            width: '100%',
            overflowX: 'hidden',
            justifyContent: 'end',
          }}
        >
          <div style={styles.divContent}>
            <label style={styles.labelText}>
              {this.translate('w_TestResult_Type')}
            </label>
            {LabTypeOtionsLoaded && (
              <select
              id="selectTestResultType"
              value={this.state.LabType}
              style={styles.selectPickers}
              onChange={(item) => {
                var value =
                  item.target.options[item.target.selectedIndex].value;
                if (value !== this.state.LabType) {
                  this.setState({ LabType: value });
                }
              }}
             >
                {labTypeOptions}
             </select>
           )}
            
          </div>
          <div style={styles.divContent}>
            <label style={styles.labelText}>
              {this.translate('w_Test_Date')}
            </label>
            <div
              key="t6"
              className="date_picker_container"
              style={{ minHeight: 48, justifyContent: 'start', marginLeft: 5 }}
            >
              <DatePicker
                showPopperArrow={false}
                dateFormat="MM/dd/yyyy"
                minDate={DateSixmonthsago}
                maxDate={new Date()}
                selected={
                  DateResults && DateResults != ''
                    ? moment(DateResults, 'YYYY-MM-DD').toDate()
                    : new Date()
                }
                onChange={(date) => {
                  this.setState({
                    DateResults: moment(date).format('YYYY-MM-DD'),
                  });
                }}
                customInput={<CustomDatePickerInput />}
              />
            </div>
          </div>
          <div style={styles.divContent}>
            <label style={styles.labelText}>
              {this.translate('w_location_of_the_test')}
            </label>
            <input
              className="input1"
              type="text"
              style={styles.input1}
              value={Location || ''}
              onChange={(e) => this.setState({ Location: e.target.value })}
            />
          </div>
          <div style={styles.divContent}>
            <label style={styles.labelText}>
              {this.translate('w_what_were_your_results')}
            </label>
            { LabResultQualOtionsLoaded && (
            <select
              value={this.state.result}
              style={styles.selectPickers}
              onChange={(item) => {
                var value = item.target.options[item.target.selectedIndex].value;
                if (value !== this.state.result) {
                  Profile.getLabResultNotes(value).then((v)=> {
                    this.setState({labResultNotes: v });
                  });
                  this.setState({ result: value });
                }
              }}
            >             
              {labResultQualOptions}
            </select>
            )}
          </div> 
          <div style={styles.divContent}>
            <label style={styles.labelText}>
              {this.translate('w_Add_a_picture_of_your_results')}
            </label>
            <AddPhotoAndImage
              image={image}
              labResultAttachments={labResultAttachments}
              updateLabResultAttachments={this._updateLabResultAttachments.bind(
                this
              )}
            ></AddPhotoAndImage>
          </div>
          <div style={styles.divContent}>
            <button
              className="button"
              key="selectDate"
              style={{
                marginTop: 20,
                marginBottom: 20,
                backgroundColor: ableSave ? '#123b6d' : '#CCCC',
                width: '90%',
                cursor: ableSave ? 'pointer' : 'no-drop',
              }}
              onClick={() => {
                if (ableSave) {
                  this._saveTestResult();
                }
              }}
            >
              {this.translate('w_save')}
            </button>
          </div>
        </div>

        {showIndicator && <Loading />}
      </div>
    );
  }
}

var styles = {
  cameraBtn: {
    margin: '0px 20px 20px',
    width: 44,
    height: 34,
    border: 0,
    float: 'left',
    background: 'white',
    backgroundImage: 'url(' + introductionPhoto + ')',
  },
  uploadBtn: {
    margin: '0px 20px 20px',
    width: 35,
    height: 34,
    border: 0,
    float: 'left',
    background: 'white',
    backgroundImage: 'url(' + introductionAlbum + ')',
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },

  divContent: {
    width: '100%',
    display: 'block',
  },
  input1: {
    marginTop: 2,
    width: '95%',
    marginLeft: 10,
    textAlign: 'start',
    fontSize: 18,
  },
  selectPickers: {
    fontSize: 18,
    width: '91%',
    height: 40,
    paddingRight:'5%',
    marginLeft: 10,
    borderWidth: '0px 0px 2px',
    borderColor: '#ddd',
  },

  datePicker: {
    marginTop: 0,
  },

  datePickerButton: {
    textAlign: 'start',
    padding: 10,
    borderColor: '#999',
    borderWidth: 0,
    borderStyle: 'solid',
    minWidth: 250,
    borderRadius: 0,
    height: 48,
    fontSize: 18,
  },

  datePickerText: {
    color: '#000000',
    textAlign: 'left',
  },

  picker: {
    justifyContent: 'space-around',
    flex: 1,
    width: '85%',
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: 'rgb(238,238,238)',
  },

  labelText: {
    width: '100',
    display: 'block',
    margin: 10,
    fontSize: 18,
  },

  TextInputview: {
    marginTop: 10,
    width: '85%',
    height: 45,
    marginLeft: 15,
    marginRight: 15,
    textAlign: 'left',
  },
};
