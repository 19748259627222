import common from '../Common';
var React = require('react');
var TopicSvr = {
	openTopic:function(topic){
        let uri = common.BASE_URL + 'WebApi/Media/WikiWithFont/' + topic.id;
        let link = '';
        if(topic.subject){
            let href_startindex = topic.subject.indexOf('href="');
            if (href_startindex > 0) {
                let href_endindex = topic.subject.indexOf('"', href_startindex + 6);
                if (href_endindex > href_startindex + 6) {
                    link = JSON.stringify(topic.subject.substring(href_startindex + 6, href_endindex));
                    link = link.replace('"', '').replace('"', '');
                }
            }
        } 
        window.open(link);
	},	
};

export default TopicSvr;
