import common from '../Common';
import httpUtils from '../utils/HttpUtil';
var React = require('react');
const QUERYLABRESULTQUALITATIVES = 'api/TestResults/QueryLabResultQualitatives';
var LabResultSvr = {
	post:function(url,data,callback){
		httpUtils.post(url, data, (responseData) => {
			if(callback){
				callback(responseData);
			}
		});
	},

	put:function(url,data,callback){
		httpUtils.put(url, data, (responseData) => {
			if(callback){
				callback(responseData);
			}
		});
	},

	get:function(url,callback){
		httpUtils.get(url,(responseData) => {
			if(callback){
				callback(responseData);
			}
		});
	},

	getLabResultNotes:function(callback){
		this.post(QUERYLABRESULTQUALITATIVES,{},callback);
	},
	
	
};

export default LabResultSvr;