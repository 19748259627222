import React, { Component } from 'react';
import BaseComponent from '../../components/BaseComponent';
import common from '../../Common';
import httpUtil from '../../utils/HttpUtil';
import i18n from 'i18next';
import CommonUtil from '../../utils/CommonUtil';
var emailing = false;

export default class Consent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: 'Dutch',
      email: this.props.email,
    };
  }
  
  componentDidMount(){
    localStorage.setItem('pageWithoutToken',true);
  }

  next() {
      let data = this.props.location.state.data;
      let data2Pass = { email: data.email, pin: data.pin, firstName: data.firstName , lastName: data.lastName, phone: data.phoneNumber || '', token: data.token,
             employeeID: data.employeeID, fAvalidated: data.fAvalidated || false, clientConsent: true, password: data.password || '', pin: data.pin || ''
      };
      //console.log('consent data');
      //console.log(pdata);
      if(common.isTurnToSSOLogin()){        
         this.props.history.push('/Questionnaire', data2Pass); 
       }
       else{
         this.props.history.push('/nameAndMobileNumber', {clientConsent: true, email: data.email, fAvalidated:data.fAvalidated, rmodel:data.rmodel }); 
       }      
      // 
  }

  render() {
    let uri = common.BASE_URL + "Privacy/en/MyOwnMedHealthTrackerApp.htm";
    return (
      <div className="conatiner">
        <div style={{ backgroundColor: '#f9f9fa', flex: 1 ,width:'100%'}}>
          <iframe
            title="resg"
            src={uri}
            style={{ width: '100%',height: CommonUtil.clientHeight() - 80}}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            scrolling="auto"
          />
          <button className="button" style={{width:'88%',marginBottom: 20, marginTop: 0, alignSelf: 'center' }}
              onClick={this.next.bind(this)}
            >
             {this.translate('w_I_agree')}
          </button>
        </div>
      </div>
    );
  }
}

const styles = {
  termTitle: {
    fontSize: 20,
    textAlign: 'center',
    color: 'black',
  },
  termContent: {
    color: 'black',
    marginLeft: 25,
    marginRight: 25
  },
  termView: {

  },
  scrollView: {
    backgroundColor: 'rgb(238,238,238)',
  },
};
