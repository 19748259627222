import React, { Component,PureComponent, Fragment } from 'react';
import BaseComponent from '../../../components/BaseComponent';
import NavBar from '../../../components/NavBar.js'
import arrow_right from '../../../content/images/arrow_right.png';
import addTestResultIcon from '../../../content/images/icon_AddPlusCircle.png';
import introductionAlbum from '../../../content/images/photo/introductionAlbum.png';
import introductionPhoto from '../../../content/images/photo/introductionPhoto.png';
import i18n from 'i18next';
import Loading from '../../../components/Loading';
import common from '../../../Common';
import httpUtil from '../../../utils/HttpUtil';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Row, Col } from 'antd'
import CommonUtil from './../../../utils/CommonUtil';

//视频流
let trackStrem;

export default class AddPhotoAndImage extends BaseComponent {
    constructor(props) {
        var labResultAttachments = [];
        var image = '';
        super(props);
        if(this.props.image){
          image = 'data:image/jpeg;base64,' + this.props.image;
        }
        if(this.props.labResultAttachments){
          labResultAttachments = this.props.labResultAttachments;
        }
        this.state = {
            image:image,
            camState: 1,
            labResultAttachments:labResultAttachments,
          };
          this._imgfilechange = this._imgfilechange.bind(this);
          this._changeState = this._changeState.bind(this);
          this._getImgSize = this._getImgSize.bind(this);         
    }

    componentWillReceiveProps(nextProps){
        var {image,labResultAttachments} = nextProps;
        if(image != '' && labResultAttachments != null && labResultAttachments.length > 0){
          this.setState({image:'data:image/jpeg;base64,' + image,labResultAttachments:labResultAttachments})
        }
    }

    componentWillUnmount() {
      this._closeCamera()
    }

    _imgfilechange(){
      var file = {};
      if(document.getElementsByName('img_file')){
        file = document.getElementsByName('img_file')["0"].files["0"];
        if(file){
          var fileSize = file.size/1024;
          if(fileSize > 5120){
            alert(this.translate('w_Image_Too_Big'));
            return;
          }
        }
      }
      var reader = new FileReader();
      var that  = this;
      reader.readAsDataURL(document.getElementsByName('img_file')["0"].files["0"])
      reader.onload = function(e) {
        var labResultAttachment = {};      
        labResultAttachment.created = moment(new Date()).format("YYYY-MM-DD");
        labResultAttachment.fileName = file.name;
        labResultAttachment.fileType = file.type;
        labResultAttachment.fileSize = file.size;
        labResultAttachment.file = {};
        var dataStr = e.target.result;
        dataStr = dataStr.replace(/data:.*;base64,/,'');
        labResultAttachment.file.fileBits = dataStr;
        var labResultAttachments = [];
        labResultAttachments.push(labResultAttachment);
        that.setState({camState:1,image:e.target.result,labResultAttachments:labResultAttachments});
        that.props.updateLabResultAttachments(labResultAttachments);
    }
      
    }

    // 状态切换
    _changeState = (state) => {
      switch (state) {
          case 1:
              this.setState({ camState: 1 })
              break;
          case 2:
              this.setState({ camState: 2 })
              this._openCamera()
              break;
          case 3:
              this.setState({ camState: 3 })
              this._catchPhoto()
              this._closeCamera()
              break;
        }
    }

    // 关闭摄像头
    _closeCamera = () => {
          console.log(`close camera...`)
          if (this.refs.video) this.refs.video.pause();
          if (trackStrem) trackStrem.getTracks()[0].stop();
    }

    // 打开摄像头
    _openCamera = () => {
      console.log(`open camera...`)
      const video = this.refs.video
      const videoObj = {
          video: {
              width: 320,
              height: 240
          }
      }
      const errBack = function (error) {
          console.log(error.message);
          console.log("Video capture error: ", error.message + " " + error.code);
      }

      // 判断媒体对象是否有权限
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

          navigator.mediaDevices.getUserMedia(videoObj).then(function (stream) {
              console.log(`video`, video)
              video.srcObject = trackStrem = stream
              video.play()
          }).catch(function (err) {
              errBack(err)
          })
      }
      else window.alert('打开摄像头失败')
      
    }

    // 保存图片
    _catchPhoto = () => {
      const { onChange } = this.props;
      const canvas = this.refs.canvas
      const video = this.refs.video
      let context = canvas.getContext("2d")
      context.drawImage(video, 0, 0, 320, 240);
      // 从画布上获取照片数据
      let imgData = canvas.toDataURL(`image/jpeg`);
      // 对外派发图片数据
      if (onChange) onChange(imgData);

      var labResultAttachment = {};      
      labResultAttachment.created = moment(new Date()).format("YYYY-MM-DD");
      labResultAttachment.fileName = 'newFile.jpg';
      labResultAttachment.fileType = `image/jpeg`;
      
      labResultAttachment.file = {};
      var dataStr = imgData;    
      labResultAttachment.file.fileBits = dataStr.replace(/data:.*;base64,/,'');
      var filesize = this._getImgSize(dataStr);
      labResultAttachment.fileSize = filesize;
      var labResultAttachments = [];
      labResultAttachments.push(labResultAttachment);
      this.setState({labResultAttachments:labResultAttachments});
      this.props.updateLabResultAttachments(labResultAttachments);
    }

    _getImgSize(str) {      
      var strLength = str.length;
      return parseInt(strLength - (strLength / 8) * 2); 
    }

    

    render() {
        let {camState,image} = this.state;

        return (
                <div style={{alignSelf:'flex-start'}}>
                  <Row>                                                  
                    <Col span={24} style={{ padding: 12, textAlign: 'center' }}>
                     <Button style={styles.cameraBtn} 
                        onClick={()=>{
                          if(CommonUtil.isPC()){
                            if(camState === 2){
                              this._changeState(3)
                            }else{
                              this._changeState(2)
                            } 
                          }else{
                            document.getElementById('upload_input').click();
                          }                            
                        }} 
                        type="primary"></Button>
                      <Button style={styles.uploadBtn} 
                        onClick={()=>{
                            document.getElementById('upload_input').click();                     
                        }} 
                        type="primary"></Button>
                    </Col>
                    <input style={{display:'none'}} name="img_file" id="upload_input" type="file" className="upload_input" onChange={this._imgfilechange}  accept="image/*"/>
                    <Col span={24} style={{ padding: 12, textAlign: 'center' }}>
                    {image &&
                      <img style={{ width: 320, height: 240, display: camState === 1 ? 'block' : 'none' }} src={image} />}
                      <video ref="video" width="320" height="240" autoPlay style={{ display: camState === 1 ? 'none' : 'block' }}></video>
                      <canvas ref="canvas" width="320" height="240" style={{ display: 'none' }}></canvas>
                    </Col>
                  </Row>
                </div>

        );
    }
}    

var styles = {
  cameraBtn:{
    margin:'0px 20px 20px',
    width:44,
    height:34,
    border:0,
    float:'left',
    background: 'white',
    backgroundImage:'url('+introductionPhoto+')'
  },
  uploadBtn:{
    margin:'0px 20px 20px',
    width:35,
    height:34,
    border:0,
    float:'left',
    background: 'white',
    backgroundImage:'url('+introductionAlbum+')'
  }
}
