import React, { Component } from 'react';
import CommonUtil from '../utils/CommonUtil';

export default class ModalContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#00000066',
                    zIndex: 999
                }}>
                <div
                    style={{
                        padding: 30,
                        margin: 20,
                        borderRadius: 5,
                        backgroundColor: '#ffffff',
                        maxWidth: CommonUtil.clientWidth() - 120
                    }}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}
