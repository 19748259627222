import React, { Component } from 'react';
import i18n from 'i18next';

export default class BaseComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        let token = localStorage.getItem('token');
        let pageWithoutToken = localStorage.getItem('pageWithoutToken');
        if (!token) {
            if(pageWithoutToken){
                localStorage.removeItem("pageWithoutToken");
            }else{
                this.props.history.push("/");
            }
        }
    }

    translate(key) {
        return i18n.t(key);
    }

    render() {
        return (
            <div></div>
        );
    }
}
