import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import TabBarContainer from '../../components/TabBarContainer';
import httpUtil from '../../utils/HttpUtil';
import ReactDOM from 'react-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';
import journay_logo from '../../content/images/track-journay.png';
import star_logo from '../../content/images/star.png';
import common from '../../Common';
import NavBar from '../../components/NavBar';
export default class Survey2 extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            completed: false,
            loaded: false
        };
    }


    componentDidMount() {
        //     this.loadData();
        window.addEventListener("message", this.handlerIframeClick.bind(this));
    }

    componentWillMount() {
        window.removeEventListener("message", function () {
        });
    }

    handlerIframeClick(e) {
        //alert('11');
        //.this.props.history.goBack();
        let { loaded, completed } = this.state;
        if (JSON.parse(e.data).message === "Loaded" && loaded === false) {
            this.setState({ loaded: true });
        }
        if (JSON.parse(e.data).message === "Done" && completed === false) {
            this.props.history.replace("/survey");
            this.setState({ completed: true });
        }
}

render() {
    let { loaded } = this.state;
    let uri = common.BASE_URL + `WebApi/ACSurveyView/TakeAnAssessment?source=webapp&assessmentOID=${this.props.location.state.assessmentOID}&token=${localStorage.getItem('token')}`;
    if (this.props.location.state.type === 3) {
        uri = common.BASE_URL + `WebApi/ACSurveyView/TakeAnAssessment2?source=webapp&assessmentOID=${this.props.location.state.assessmentOID}&token=${localStorage.getItem('token')}`;
    }
    return (
        <div className="conatiner" style={{ display: "block" }}>
            <div style={{ width: '100%', overflow: 'auto', justifyContent: 'flex-start', border: 0 }}>
                <NavBar title={this.translate("w_Surveys")} backurl='/home'  history={this.props.history} />
                {!loaded &&
                    <div className="conatiner" style={{ alignSelf: 'stretch', flex: 1, backgroundColor: '#fff', padding: 30 }}>
                        <p style={
                            { fontSize: 16, alignSelf: 'stretch', textAlign: 'center', marginTop: 20 }
                        }>
                            {this.translate("w_Loading_Please_wait")}
                        </p>
                    </div>
                }
                <iframe
                    title="resg"
                    src={uri}
                    //srcDoc={common}
                    style={{ width: '100%', border: '0px', height: '1100px' }}
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    scrolling="auto"
                />

            </div>
        </div>
    );
}
}
