export default {
  isPC() {
    let userAgentInfo = navigator.userAgent;
    let agents = [
      'Android',
      'iPhone',
      'SymbianOS',
      'Windows Phone',
      'iPad',
      'iPod',
    ];
    let flag = true;
    for (var v = 0; v < agents.length; v++) {
      if (userAgentInfo.indexOf(agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  },

  clientWidth() {
    return this.isPC() ? 480 : document.body.clientWidth;
  },

  clientHeight() {
    return this.isPC() ? 800 : document.body.clientHeight;
  },

  clientZoom() {
    return this.isPC() ? '72%' : '100%';
  },

  toTicks(date) {
    return (date.getTime() * 1000 * 1000) / 100;
  },

  isBlank: function (str) {
    return !str || /^\s*$/.test(str);
  },
  isEmpty: function (str) {
    return !str || 0 === str.length;
  },

  isEmail: function (str) {
    return !str || /^.+@.+\..+$/.test(str);
  },
};