import React from "react";
import BaseComponent from "../../components/BaseComponent";
import NavBar from "../../components/NavBar.js";
import httpUtil from "../../utils/HttpUtil";
import common from "../../Common";
import "../../content/style/form.css";
import { Alert } from "antd";

export default class UpdateUserAccount extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      mobilePhone: "",
      groupType: "",
      profileType: "",
      studentType: "",
      athleticTeamId: "",
      groupTypeHtmls: [],
      profileTypeHtmls: [],
      studentTypeHtmls: [],
      AthleticTeamsHtmls: [],

      ProfileTypeOptions: false,
      StudentTypesOptions: false,
      GroupTypeOptions: false,
      AthleticTeamsOptions: false,
      GetProfile: false,
    };
    this.getProfile();
    this.getGroupTypes();
    this.getStudentTypes();
    this.getAthleteTypes();
    this._goBack = this._goBack.bind(this);
  }

  componentDidMount() {}

  getGroupTypes() {
    httpUtil.get("/api/GroupTypes/GetGroupTypes", (responseData) => {
      if (responseData) {
        this._setGroupTypeHtmls(responseData);
      }
    });
  }

  getStudentTypes() {
    httpUtil.get("/api/StudentType/GetStudentTypes", (responseData) => {
      if (responseData) {
        this._setStudentTypeHtmls(responseData);
      }
    });
  }

  getAthleteTypes() {
    httpUtil.get("/api/AthleticTeams/GetAthleticTeams", (responseData) => {
      if (responseData) {
        this._setAthleticTeamHtmls(responseData);
      }
    });
  }

  getProfileTypes() {
    let htmls = [];
    htmls.push(
      <option value="" disabled selected>
        {this.translate("w_select_profile_type")}
      </option>
    );
    htmls.push(
      <option value="1">{this.translate("Healthcare_Worker")}</option>
    );
    htmls.push(<option value="2">{this.translate("Drexel_Student")}</option>);
    htmls.push(<option value="3">{this.translate("Faculty")}</option>);
    htmls.push(<option value="4">{this.translate("Employee")}</option>);
    this.setState({ profileTypeHtmls: htmls, ProfileTypeOptions: true });
  }

  _setGroupTypeHtmls(groupTypes) {
    let htmls = [];
    htmls.push(
      <option key="0" value="" selected>
        {this.translate("w_Select_Group_Type")}
      </option>
    );

    let keyVal = 0;
    groupTypes.map((item) => {
      keyVal = keyVal + 1;
      htmls.push(
        <option key={keyVal} value={item.id}>
          {item.name}
        </option>
      );
    });

    this.setState({ groupTypeHtmls: htmls, GroupTypeOptions: true });
    this.getProfileTypes();
  }

  _setStudentTypeHtmls(studentTypes) {
    let htmls = [];
    let keyVal = 0;
    htmls.push(
      <option value="" disabled selected>
        {this.translate("w_Select_Student_Type")}
      </option>
    );
    studentTypes.map((item) => {
      keyVal = keyVal + 1;
      htmls.push(
        <option key={keyVal} value={item.value}>
          {item.typeName}
        </option>
      );
    });

    this.setState({ studentTypeHtmls: htmls, StudentTypesOptions: true });
  }

  _setAthleticTeamHtmls(AthleticTeams) {
    let htmls = [];
    let keyVal = 0;
    htmls.push(
      <option value="" disabled selected>
        {this.translate("w_Select_AthleticTeam_Type")}
      </option>
    );
    AthleticTeams.map((item) => {
      keyVal = keyVal + 1;
      htmls.push(
        <option key={keyVal} value={item.teamId}>
          {item.name}
        </option>
      );
    });
    //console.log(htmls);
    this.setState({ AthleticTeamsHtmls: htmls, AthleticTeamsOptions: true });
  }

  getProfile() {
    httpUtil.get("/api/Profile/GetMine", (profileData) => {
      //console.log(profileData);
      if (profileData.id && profileData.status === 1) {
        localStorage.setItem("profile", JSON.stringify(profileData));
        var firstName = profileData.firstName;
        var lastName = profileData.lastName;
        var email = profileData.email;
        var mobilePhone = profileData.mobilePhone || "";

        if (mobilePhone != null && mobilePhone.length > 0) {
          mobilePhone = mobilePhone.replace(/\D/g, "");
          if (mobilePhone.length > 0 && mobilePhone.length < 4) {
            mobilePhone = "(" + mobilePhone;
          } else if (mobilePhone.length >= 4 && mobilePhone.length < 7) {
            mobilePhone =
              "(" +
              mobilePhone.substring(0, 3) +
              ") " +
              mobilePhone.substring(3);
          } else if (mobilePhone.length >= 7) {
            mobilePhone =
              "(" +
              mobilePhone.substring(0, 3) +
              ") " +
              mobilePhone.substring(3, 6) +
              "-" +
              mobilePhone.substring(6);
          }
        }
        this.setState({
          firstName: firstName,
          lastName: lastName,
          email: email,
          mobilePhone: mobilePhone,
          groupType: profileData.groupType,
          profileType: profileData.profileType,
          studentType: profileData.studentType,
          athleticTeamId: profileData.athleticTeamId,
          GetProfile: true,
        });
      }
    });
  }

  _goBack() {
    window.history.back();
  }

  _save() {
    let params = {};
    params.firstName = this.state.firstName;
    params.lastName = this.state.lastName;
    params.email = this.state.email;
    params.mobilePhone = this.state.mobilePhone.replace(/\D/g, "");
    params.groupType = this.state.groupType;
    params.profileType = this.state.profileType;
    params.studentType = this.state.studentType;
    params.AthleticTeamId = this.state.athleticTeamId;

    //TODO:alert
    httpUtil.put("/api/Profile/UpdateUserAccount", params, (responseData) => {
      console.log(responseData.status);
      if (responseData && responseData.status == "Success") {
        var profile = JSON.parse(localStorage.getItem("profile"));
        profile.firstName = params.firstName;
        profile.lastName = params.lastName;
        profile.email = params.email;
        profile.mobilePhone = params.mobilePhone;
        localStorage.setItem("profile", JSON.stringify(profile));
        alert(this.translate("w_success_Updated_account"));
        this._goBack();
        /*Alert.alert(Translation.lang().w_Updated_User_Account, Translation.lang().w_success_Updated_account,
                        [
                            {
                                text: Translation.lang().w_ok, onPress: () => {
                                    MomEnv.back();
                                }
                            }
                        ]);*/
      } else {
        if (
          responseData &&
          responseData.status == "Failure" &&
          responseData.data == "-1"
        ) {
          //Alert.alert(Translation.lang().w_Unable_to_Update_Account, Translation.lang().w_The_email_address_provided_already_exists_different_email_address_);
          alert(
            this.translate(
              "w_The_email_address_provided_already_exists_different_email_address_"
            )
          );
        } else {
          alert(this.translate("w_Failed_Update_"));
          //Alert.alert(Translation.lang().w_failed, Translation.lang().w_Failed_Update_);
        }
      }
    });
  }

  componentDidMount() {}

  _phoneChange(text) {
    text = text.replace(/\D/g, "");
    if (text.length > 0 && text.length < 4) {
      text = "(" + text;
    } else if (text.length >= 4 && text.length < 7) {
      text = "(" + text.substring(0, 3) + ") " + text.substring(3);
    } else if (text.length >= 7) {
      text =
        "(" +
        text.substring(0, 3) +
        ") " +
        text.substring(3, 6) +
        "-" +
        text.substring(6);
    }
    this.setState({ mobilePhone: text });
  }

  _emailtextchange(text) {
    this.setState({ email: text });
  }

  render() {
    let {
      pin,
      pinconfirm,
      GetProfile,
      ProfileTypeOptions,
      GroupTypeOptions,
      AthleticTeamsOptions,
      StudentTypesOptions,
    } = this.state;
    let isStudent = this.state.profileType == 2;
    let isAthlete = this.state.studentType == 1;

    return (
      <div className="conatiner" style={{ justifyContent: "flex-start" }}>
        <NavBar
          title={this.translate("w_Update_User_Account")}
          history={this.props.history}
        />
        {/*<label className="title">
                    {this.translate("w_firstName")}
                </label>
                <input
                    className="input1"
                    type='text'
                    style={{ marginTop: 2, }}
                    placeholder={this.translate("w_Enter_your_ﬁrst_name")}
                    value={this.state.firstName || ''}
                    onChange={(e) => this.setState({ firstName: e.target.value })}
                />
                <label className="title">
                    {this.translate("w_lastName")}
                </label>
                <input
                    className="input1"
                    type='text'
                    style={{ marginTop: 2, }}
                    placeholder={this.translate("w_Enter_your_last_name")}
                    value={this.state.lastName || ''}
                    onChange={(e) => this.setState({ lastName: e.target.value })}
                />
                <label className="title">
                    {this.translate("w_Email__")}
                </label>
                <input
                    className="input1"
                    type='text'
                    style={{ marginTop: 2, }}
                    placeholder={this.translate("w_Enter_your_email")}
                    value={this.state.email}
                    onChange={(e) => this._emailtextchange(e.target.value)}
                />
                <label className="title">
                    {this.translate("w_Phone_Number_")}
                </label>
                <input
                    className="input1"
                    type='text'
                    maxLength={14}
                    style={{ marginBottom: 5, marginTop: 2 }}
                    placeholder={'xxx-xxx-xxxx'}
                    value={this.state.mobilePhone || ''}
                    onChange={(e) => this._phoneChange(e.target.value)}
                />*/}
        <label className="title">{this.translate("w_Group_Type")}</label>
        {GetProfile && GroupTypeOptions && (
          <div className="input1">
            <select
              id="selectGroupType"
              value={this.state.groupType == null ? 0 : this.state.groupType}
              style={styles.inputSelect}
              onChange={(item) => {
                var value =
                  item.target.options[item.target.selectedIndex].value;
                if (value !== this.state.groupType) {
                  this.setState({ groupType: value });
                }
              }}
            >
              {this.state.groupTypeHtmls}
            </select>
          </div>
        )}
        <label className="title">{this.translate("w_Profile_Type")}</label>
        {GetProfile && ProfileTypeOptions && (
          <div className="input1">
            <select
              id="selectProfileType"
              value={
                this.state.profileType == null ? -1 : this.state.profileType
              }
              style={styles.inputSelect}
              onChange={(item) => {
                var value =
                  item.target.options[item.target.selectedIndex].value;
                if (value !== this.state.profileType) {
                  this.setState({ profileType: value });
                }
              }}
            >
              {this.state.profileTypeHtmls}
            </select>
          </div>
        )}
        {
          // this.state.profileType & this.state.profileType == 2
          isStudent ? (
            <div style={{ width: "100%" }}>
              <label className="title">
                {this.translate("w_student_Type")}
              </label>
              {GetProfile && StudentTypesOptions && (
                <div className="input1">
                  <select
                    id="selectstudentType"
                    value={
                      this.state.studentType == null
                        ? -1
                        : this.state.studentType
                    }
                    style={styles.inputSelect}
                    onChange={(item) => {
                      var value =
                        item.target.options[item.target.selectedIndex].value;
                      if (value !== this.state.studentType) {
                        this.setState({ studentType: value });
                      }
                    }}
                  >
                    {this.state.studentTypeHtmls}
                  </select>
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )
        }
        {
          // this.state.profileType & this.state.profileType == 2 & this.state.studentType && this.state.studentType == 1
          isStudent && isAthlete ? (
            <div style={{ width: "100%" }}>
              <label className="title">{this.translate("athletic_team")}</label>
              {GetProfile && AthleticTeamsOptions && (
                <div className="input1">
                  <select
                    id="selecathleticteam"
                    value={
                      this.state.athleticTeamId == null
                        ? -1
                        : this.state.athleticTeamId
                    }
                    style={styles.inputSelect}
                    onChange={(item) => {
                      var value =
                        item.target.options[item.target.selectedIndex].value;
                      if (value !== this.state.athleticTeamId) {
                        this.setState({ athleticTeamId: value });
                      }
                    }}
                  >
                    {this.state.AthleticTeamsHtmls}
                  </select>
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )
        }
        <div className="button2_div">
          <button
            onClick={this._goBack.bind(this)}
            className="button2 button2_1"
          >
            {this.translate("w_cancel")}
          </button>
          <button onClick={this._save.bind(this)} className="button2 button2_2">
            {this.translate("w_save")}
          </button>
        </div>
      </div>
    );
  }
}
const styles = {
  inputSelect: {
    backgroundcolor: "#eeeeee",
    alignself: "stretch",
    marginbottom: "10px",
    marginright: "10px",
    margintop: "10px",
    height: "48px",
    width: "100%",
    borderWidth: "1px",
    borderradius: "3px",
    fontsize: "16px",
    textalign: "center",
  },
};
