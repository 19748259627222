module.exports = {
  normalFont: {
    fontFamily: 'SourceSansPro-Regular',
    backgroundColor: 'transparent',
    fontSize: 16,
  },
  boldFont: {
    fontFamily: 'SourceSansPro-Bold',
    fontSize: 18,
    backgroundColor: 'transparent',
    color: '#000000',
  },
  mButton: {
    backgroundColor: '#123B6D',
    alignSelf: 'center',
    height: 46,
    borderRadius: 4,
    shadowRadius: 0,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowColor: '#123B6D',
  },
  mButtonText: {
    color: '#FFF',
    alignSelf: 'center',
    marginTop: 6,
    lineHeight: 26,
    height: 30,
  },
  button2: {
    marginTop: 20,
    marginBottom: 0,
    backgroundColor: '#123b6d',
  },
};
