import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import TabBarContainer from '../../components/TabBarContainer';
import httpUtil from '../../utils/HttpUtil';
import ReactDOM from 'react-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';
import journay_logo from '../../content/images/track-journay.png';
import star_logo from '../../content/images/star.png';
//import { DatePicker } from 'antd';
//import 'antd/dist/antd.css';
import CommonUtils from './../../utils/CommonUtil'
import common from '../../Common';
import NavBar from '../../components/NavBar';
import ModalContainer from '../../components/ModalContainer';
import Loading from '../../components/Loading';
import i18n from 'i18next';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const CustomDatePickerInput = ({ value, onClick }) => (
    <button className="custom_date_picker_input" onClick={onClick}>
        {value}
    </button>
);

export default class SurveyDetial extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            survey: this.props.location.state.survey,
            readonly: this.props.location.state.readonly,
            questions: [],
            currentQuestionIndex: this.props.location.state.readonly ? 0 : -1,
            need_alert_survey: '',
            save_progress: '',
            loading: false,
            questionIndex: ''
        };
    }
    componentDidMount() {
        // console.log(this.state.survey);
        this._reload();
    }

    _reload() {
        let { survey } = this.state;
        this.setState({ loaded: false, loading: true, questions: [] });
        //console.log(survey);
        httpUtil.get('api/Quiz/GetQuizParticipantQuestions?quizID=' + survey.quizId + '&quizParticipantID=' + survey.quizzParticipantId,
            (responseData) => {
                if (responseData) {
                    this.setState({ loaded: true, loading: false, questions: responseData });
                    // if (survey.progress !== "0%") {
                    //     this._beginVaild(0);
                    // }
                    this.setState({ currentQuestionIndex: 0 });
                }
            }
        );

    }

    _Confirmation_done(){
        let { questions, currentQuestionIndex } = this.state;
        let question = questions[currentQuestionIndex];
        if (currentQuestionIndex === questions.length - 1 && question.quizAnswers.length > 0) {
            this._save();
        }
    }

    _Confirmation_save(progress) {
        this.setState({ need_alert_survey: 1, save_progress: progress });
            //alert(

            //Translation.lang().w_confirmation,
            //   this.translate("w_Are_sure_not_complete_the_survey"),
            // [{text:Translation.lang().w_cancel, onClick: () => { } },
            // {text:Translation.lang().w_confirm, onClick: () => this._Incompleedsave(progress)},
            // ]
            // );
    }

    _Incompleedsave(progress) {
        let { questions, survey } = this.state;
        let answers = [];
        questions.map((question) => {
            question.quizAnswers.map((answer) => {
                answers.push({
                    answerValue: answer.answerValue,
                    quizPossibleAnswerID: answer.quizPossibleAnswerID,
                    quizQuestionID: question.quizQuestionID,
                    quizParticipantId: survey.quizzParticipantId,
                });
            });
        });
        this.setState({ loaded: false, loading: true });
        httpUtil.post('api/Quiz/SaveIncompletedQuizAnswers', { answers: answers, percentage: progress / 100 },
            (responseData) => {
                if (responseData) {
                    this.setState({ need_alert_survey: 2, loading: false });
                    //this.props.location.state.onSaveAnswer();
                    //alert(
                    //Translation.lang().w_confirmation,
                    //  this.translate("w_Your_answers_have_been_saved_"),
                    //   [
                    //     {text:Translation.lang().w_ok, onClick: () => this.props.navigator.pop()},
                    //   ]
                    //);
                    //this.setState({loaded:true,readonly:true});
                }
            }
        );
    }
    _save() {
        let { questions, survey } = this.state;
        let answers = [];
        questions.map((question) => {
            question.quizAnswers.map((answer) => {
                answers.push({
                    answerValue: answer.answerValue,
                    quizPossibleAnswerID: answer.quizPossibleAnswerID,
                    quizQuestionID: question.quizQuestionID,
                    quizParticipantId: survey.quizzParticipantId,
                });
            });
        });
        this.setState({ loaded: false, loading: true });
        httpUtil.post('api/Quiz/SaveQuizAnswers', { answers: answers },
            (responseData) => {
                if (responseData) {
                    //this.props.location.state.onSaveAnswer();
                    this.setState({ need_alert_survey: 3, loading: false });
                    // alert(
                    //Translation.lang().w_confirmation,
                    // this.translate("w_Thank_completing_survey_"),
                    // [
                    //   {text:Translation.lang().w_ok, onClick: () => this.props.navigator.pop()},
                    // ]
                    //);
                    //this.setState({loaded:true,readonly:true});
                }
            }
        );
    }

    _begin() {
        let { questions, currentQuestionIndex, readonly, survey } = this.state;
        if (readonly) {
            this.setState({ currentQuestionIndex: 0 });
        } else {
            if (survey.progress == "0%") {
                this.setState({ currentQuestionIndex: 0 });
            } else {
                let beginIndex = 0;
                this._beginVaild(beginIndex);
            }
        }
    }
    _beginVaild(beginIndex) {
        let { questions, survey,loaded } = this.state;
        let question = questions[beginIndex];
        if(beginIndex > questions.length-1){
            this.setState({currentQuestionIndex:0});
          }
          else{
            if((question.quizAnswers && question.quizAnswers.length > 0 && (question.quizAnswers[0].quizPossibleAnswerID!=null || question.quizAnswers[0].answerValue != "" )) || question.quizQuestionTypeID==8 || question.quizQuestionTypeID==9 || question.quizQuestionTypeID==10){
            beginIndex = beginIndex + 1;
            this._beginVaild(beginIndex);
            return false;
        }
        else {
            if (question.quizTriggers && question.quizTriggers.length > 0) {
                let existedPossibleAnswerID = false;
                question.quizTriggers.map((trigger) => {
                    let quizPossibleAnswerSeqID = trigger.quizPossibleAnswerSeqID;
                    questions.map((tempQ) => {
                        tempQ.quizAnswers.map((tempA) => {
                            tempQ.quizPossibleAnswers.map((tempP) => {
                                if (tempA.quizPossibleAnswerID == tempP.quizPossibleAnswerID && tempP.quizPossibleAnswerSeqID == quizPossibleAnswerSeqID) {
                                    existedPossibleAnswerID = true;
                                }
                            });
                        });
                    });
                });
                if (existedPossibleAnswerID) {
                    beginIndex = beginIndex;
                }
                else {
                    beginIndex = beginIndex + 1;
                    this._beginVaild(beginIndex);
                    return false;
                }
            }
            this.setState({ currentQuestionIndex: beginIndex });
            return beginIndex;
        }
    }
    }
    _prev(questionIndex) {
        let { questions, currentQuestionIndex } = this.state;
        let prevQuestionIndex = questionIndex - 1;
        if (prevQuestionIndex > 0) {
            let prevQuestion = questions[prevQuestionIndex];
            if (prevQuestion && prevQuestion.skiped) {
                //prevQuestionIndex = prevQuestionIndex - 1;
                this._prev(prevQuestionIndex);
                return false;
            }
        }
        this.setState({ currentQuestionIndex: prevQuestionIndex });
    }

    _next(questionIndex) {
        let { questions, currentQuestionIndex } = this.state;
        let nextQuestionIndex = questionIndex + 1;
        if (questions.length > nextQuestionIndex) {
          let nextQuestion = questions[nextQuestionIndex];
          if (nextQuestion.quizTriggers && nextQuestion.quizTriggers.length > 0) {
            let existedPossibleAnswerID = false;
            nextQuestion.quizTriggers.map((trigger) => {
              let quizPossibleAnswerSeqID = trigger.quizPossibleAnswerSeqID;
              questions.map((tempQ) => {
                tempQ.quizAnswers.map((tempA) => {
                  tempQ.quizPossibleAnswers.map((tempP) => {
                    if (tempA.quizPossibleAnswerID == tempP.quizPossibleAnswerID && tempP.quizPossibleAnswerSeqID == quizPossibleAnswerSeqID) {
                      existedPossibleAnswerID = true;
                    }
                  });
                });
              });
            });
            if (existedPossibleAnswerID) {
              nextQuestion.skiped = false;
            } else {
              nextQuestion.skiped = true;
              this._next(nextQuestionIndex);
              return false;
            }
    
            //let trigger = nextQuestion.quizTriggers[0];
            //if(trigger){
            //    let quizPossibleAnswerID = trigger.quizPossibleAnswerID;
            //    let existedPossibleAnswerID = false;
            //    questions.map((tempQ)=>{
            //       tempQ.quizAnswers.map((tempA)=>{
            //          if(tempA.quizPossibleAnswerID == quizPossibleAnswerID){
            //             existedPossibleAnswerID = true;
            //          }
            //       });
            //    });
            //    if(existedPossibleAnswerID){
            //       nextQuestion.skiped = false;
            //    }else{
            //       nextQuestion.skiped = true;
            //        //nextQuestionIndex = nextQuestionIndex + 1;
            //       this._next(nextQuestionIndex);
            //       return false;
            //    }
            //}
          }
          if (nextQuestionIndex >= questions.length) {
            this._save();
          } else {
            this.setState({ currentQuestionIndex: nextQuestionIndex });
          }
        } else {
          this._save();
        }
    }

    _next_end(questionIndex) {
        let { questions, currentQuestionIndex } = this.state;
        let nextQuestionIndex = questionIndex + 1;
        if (questions.length > nextQuestionIndex) {
            let nextQuestion = questions[nextQuestionIndex];
            if (nextQuestion.quizTriggers && nextQuestion.quizTriggers.length > 0) {
                let existedPossibleAnswerID = false;
                nextQuestion.quizTriggers.map((trigger) => {
                    let quizPossibleAnswerSeqID = trigger.quizPossibleAnswerSeqID;
                    questions.map((tempQ) => {
                        tempQ.quizAnswers.map((tempA) => {
                            tempQ.quizPossibleAnswers.map((tempP) => {
                                if (tempA.quizPossibleAnswerID == tempP.quizPossibleAnswerID && tempP.quizPossibleAnswerSeqID == quizPossibleAnswerSeqID) {
                                    existedPossibleAnswerID = true;
                                }
                            });
                        });
                    });
                });
                if (existedPossibleAnswerID) {
                    nextQuestion.skiped = false;
                } else {
                    nextQuestion.skiped = true;
                    this._next(nextQuestionIndex);
                    return false;
                }
            }
            if (nextQuestionIndex >= questions.length) {
                this._save();
            } else {
                this.setState({ currentQuestionIndex: nextQuestionIndex });
            }
        } else {
            this._save();
        }
    }

    //   async showTimePicker(stateKey, options, question) {
    //     try {
    //       const { action, minute, hour } = await TimePickerAndroid.open(options);
    //       if (action === TimePickerAndroid.timeSetAction) {
    //         this._updateQuestionAnswer(question, moment(new Date(1, 1, 1, hour, minute)).format('hh:mm A'));
    //       }
    //     } catch ({ code, message }) {
    //     }
    //   }

    //   async showDatePicker(options, question) {
    //     try {
    //       const { action, year, month, day } = await DatePickerAndroid.open(options);
    //       if (action !== DatePickerAndroid.dismissedAction) {
    //         this._updateQuestionAnswer(question, moment(new Date(year, month, day)).format('MM-DD-YYYY'));
    //       }
    //     } catch ({ code, message }) {
    //     }
    //   }

    _updateQuestionAnswer(question, answer) {
        //console.warn(question.quizQuestionID);
        let { questions } = this.state;
        questions.map((item) => {
          if (question.quizQuestionID == item.quizQuestionID) {
            if (answer != '' || answer == 0) {
              item.quizAnswers = [{ answerValue: answer }];
            } else {
              item.quizAnswers = [];
            }
          }
        });
        this.setState({ questions: questions });
    }

    _updateQuestionAnswerForSelect(question, possibleAnswerId, isNext) {
        let { questions } = this.state;
        questions.map((item) => {
            if (question.quizQuestionID === item.quizQuestionID) {
                if (question.quizQuestionTypeID === 4 || question.quizQuestionTypeID === 1) {
                    item.quizAnswers = [{ quizPossibleAnswerID: possibleAnswerId }];
                } else if (question.quizQuestionTypeID === 5) {
                    let existIndex = -1;
                    item.quizAnswers.map((answer, index) => {
                        if (answer.quizPossibleAnswerID === possibleAnswerId) {
                            existIndex = index;
                        }
                    });
                    if (existIndex !== -1) {
                        item.quizAnswers.splice(existIndex, 1);
                    } else {
                        item.quizAnswers.push({ quizPossibleAnswerID: possibleAnswerId, answerValue: true });
                        if (isNext) {
                            //this._next(this.state.currentQuestionIndex);
                        }
                    }
                }
            }
        });
        this.setState({ questions: questions });
    }

    _renderSystems(question, possibleAnswerIds, quizQuestions, isENLocal, htmls) {
        let isNext = false;
        let TrackItems = common.TrackItems();
        for (var i = 0, l = quizQuestions.length; i < l; i += 3) {

            let isSelected1 = false;
            let isSelected2 = false;
            let isSelected3 = false;
            let quizQuestion1 = quizQuestions[i];
            let quizQuestion2 = quizQuestions[i + 1];
            let quizQuestion3 = quizQuestions[i + 2];
            possibleAnswerIds.map((id) => {
                if (quizQuestions[i].quizPossibleAnswerID === id) {
                    isSelected1 = true;
                }
                if (quizQuestions[i + 1].quizPossibleAnswerID === id) {
                    isSelected2 = true;
                }
                if (quizQuestions[i + 2].quizPossibleAnswerID === id) {
                    isSelected3 = true;
                }
            });
            htmls.push(
                <div key={i}
                    style={{
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        marginLeft: 30,
                        marginRight: 30,
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                    <div key={`t5_102_${i}`}
                        //underlayColor="#717171"
                        style={{
                            marginBottom: 8,
                            borderRadius: 10,
                            borderWidth: 2,
                            borderStyle: 'solid',
                            borderColor: isSelected1 ? '#35B502' : '#eeeeee',

                        }}
                        onClick={() => { this._updateQuestionAnswerForSelect(question, quizQuestion1.quizPossibleAnswerID, quizQuestion1.quizPossibleAnswerSeqID === 111 ? true : false) }}
                    >
                        <div style={styles.imageContent}>
                            <img
                                resizemode={'center'}
                                style={{
                                    marginTop: 4,
                                    height: 40,
                                }}
                                src={TrackItems[i].logo}
                                alt=""
                            />
                            {
                                isSelected1 &&
                                <img
                                    style={styles.selectedSymptom}
                                    src={require('../../content/images/overallIconStatusWell.png')}
                                />
                            }
                            <p style={styles.symptomsFontSize}>
                                {isENLocal ? quizQuestions[i].answerValue : quizQuestions[i].answerValueES}
                            </p>
                        </div>
                    </div>
                    <div key={`t5_102_${i + 1}`}
                        //underlayColor="#717171"
                        style={{
                            marginBottom: 8,
                            borderRadius: 10,
                            borderWidth: 2,
                            borderStyle: 'solid',
                            borderColor: isSelected2 ? '#35B502' : '#eeeeee',

                        }}
                        onClick={() => { this._updateQuestionAnswerForSelect(question, quizQuestion2.quizPossibleAnswerID, quizQuestion2.quizPossibleAnswerSeqID == 111 ? true : false) }}
                    >
                        <div style={styles.imageContent}>
                            <img
                                resizemode={'center'}
                                style={{
                                    marginTop: 4,
                                    height: 40,
                                }}
                                src={TrackItems[i + 1].logo}
                            />
                            {
                                isSelected2 &&
                                <img
                                    style={styles.selectedSymptom}
                                    src={require('../../content/images/overallIconStatusWell.png')}
                                />
                            }
                            <p style={styles.symptomsFontSize}>
                                {isENLocal ? quizQuestions[i + 1].answerValue : quizQuestions[i + 1].answerValueES}
                            </p>
                        </div>
                    </div>
                    <div key={`t5_102_${i + 2}`}
                        //underlayColor="#717171"
                        style={{
                            marginBottom: 8,
                            borderRadius: 10,
                            borderWidth: 2,
                            borderStyle: 'solid',
                            borderColor: isSelected3 ? '#35B502' : '#eeeeee',

                        }}
                        onClick={() => { this._updateQuestionAnswerForSelect(question, quizQuestion3.quizPossibleAnswerID, quizQuestion3.quizPossibleAnswerSeqID === 111 ? true : false) }}
                    >
                        <div style={styles.imageContent}>
                            <img
                                resizemode={'center'}
                                style={{
                                    marginTop: 4,
                                    height: 40,
                                }}
                                src={TrackItems[i + 2].logo}
                            />
                            {
                                isSelected3 &&
                                <img
                                    style={styles.selectedSymptom}
                                    src={require('../../content/images/overallIconStatusWell.png')}
                                />
                            }
                            <p style={styles.symptomsFontSize}>
                                {isENLocal ? quizQuestions[i + 2].answerValue : quizQuestions[i + 2].answerValueES}
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

    }

    _renderQuestion(question, readonly, isENLocal) {
        let htmls = [];
        if (question) {
            if (question.questionSeqID === 103 && !readonly) {
                let answer = '';
                if (question.quizAnswers && question.quizAnswers.length > 0) {
                    answer = question.quizAnswers[0].answerValue;
                }
                htmls.push(
                    <div style={{
                        alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'
                    }}>
                        <div style={styles.feverView}>
                            <div style={styles.option_row} onClick={() => { this._updateQuestionAnswer(question, "") }}>
                                <div style={answer != "N/A" && answer != null ? styles.selectedContainer : styles.unSelectContainer}>
                                    {answer != "N/A" && answer != null && <div style={{ color: '#fff' }}>{'√'}</div>}
                                </div>
                                <input
                                    className="input"
                                    key={"t3"}
                                    //style={{ width: 280 }}
                                    keyboardtype='numeric'
                                    value={answer != 'N/A' ? answer : ""}
                                    maxLength={5}
                                    autoFocus={true}
                                    onChange={(e) => {
                                        e.target.value = e.target.value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
                                        this._updateQuestionAnswer(question, e.target.value)
                                    }}
                                />
                    ℉
                    </div>
                        </div>
                        <div style={styles.feverView}>
                            <div style={styles.option_row} onClick={() => { this._updateQuestionAnswer(question, "N/A") }}>
                                <div style={answer == "N/A" ? styles.selectedContainer : styles.unSelectContainer}>
                                    {answer == "N/A" && <div style={{ color: '#fff' }}>{'√'}</div>}

                                </div>
                                <p style={{ margin: 20 }}>N/A</p>
                            </div>
                        </div>
                    </div>);
            } else 
            if (question.quizQuestionTypeID === 1) {
                //var answers = [{label: 'No', value: 0 },{label: 'Yes', value: 1 }];
                //let answer = -1;
                //if(question.quizAnswers && question.quizAnswers.length>0){
                //    answer = (question.quizAnswers[0].answerValue=='1'|| question.quizAnswers[0].answerValue==1)?1:0;
                //}
                var answers = [];
                let initial = -1;
                let possibleAnswerId = -1;
                if (question.quizAnswers && question.quizAnswers.length > 0) {
                  possibleAnswerId = question.quizAnswers[0].quizPossibleAnswerID;
                }
                question.quizPossibleAnswers.map((item, index) => {
                  if (possibleAnswerId == item.quizPossibleAnswerID) {
                    initial = index;
                  }
                  answers.push({ label: item.answerValue, value: item.quizPossibleAnswerID });
                });
                //TODO:doing
                answers.map((item, index) => {
                    //alert(possibleAnswerId);
                    //alert(item.value);
                    htmls.push(
                       <div style={{ display: 'inline', justifyContent: 'center', alignItems: 'center',margin:20}} key={`rn_${index}`}>
                            <input
                                className="input"
                                type="radio"
                                style={{margin:10,height:14}}
                                defaultChecked={initial == index}
                                //checked={possibleAnswerId == item.value}
                                name={`t1${question.quizQuestionID}`}
                                key={`t1${question.quizQuestionID}`}
                                //formHorizontal={true}
                                disabled={readonly}
                                value={item.value}
                                // radio_props={answers}
                                // initial={initial}
                                onChange={(e) => {
                                    this._updateQuestionAnswerForSelect(question, e.target.value, false)
                                }}
                            />{item.label}
                        </div>
                    );
                });

            } else if (question.quizQuestionTypeID === 2) {
                let answer = '';
                if (question.quizAnswers && question.quizAnswers.length > 0) {
                    answer = question.quizAnswers[0].answerValue;
                }
                if (readonly) {
                    htmls.push(<div style={{ //width: width - 70,
                        //width: 100,
                        height: 46, borderRadius: 4, backgroundColor: 'rgb(238,238,238)', alignItems: 'center', justifyContent: 'center'
                    }} key='t2'>
                        <p style={{ fontSize: 16 }}>{answer}</p>
                    </div>);
                } else {
                    htmls.push(
                        <div style={{
                            alignItems: 'center', justifyContent: 'center', display: 'flex'
                        }}>
                            <input
                                className="input"
                                key={"t2"}
                                //style={[globalStyles.normalFont, globalStyles.input]}
                                keyboardtype='text'
                                value={answer}
                                maxLength={question.maxLength}
                                autoFocus={true}
                                onChange={(e) => { this._updateQuestionAnswer(question, e.target.value) }}
                            />
                        </div>
                    );
                }
            } else if (question.quizQuestionTypeID === 6) {
                let answer = '';
                if (question.quizAnswers && question.quizAnswers.length > 0) {
                    answer = question.quizAnswers[0].answerValue;
                }
                if (readonly) {
                    htmls.push(<div style={{
                        //width: width - 70,
                        width: 100,
                        height: 46, borderRadius: 4, backgroundColor: 'rgb(238,238,238)', alignItems: 'center', justifyContent: 'center'
                    }} key='t7'>
                        <p style={{ fontSize: 14 }}>{answer}</p>
                    </div>);
                } else {
                    htmls.push(
                        <div key='t6' className="date_picker_container">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="MM-dd-yyyy"
                                maxDate={question.questionSeqID === 16 ? new Date() : null}
                                selected={answer && answer != '' ? moment(answer, 'MM-DD-YYYY').toDate() : new Date()}
                                onChange={(date) => {
                                    this._updateQuestionAnswer(question, moment(date).format('MM-DD-YYYY'))
                                }}
                                customInput={<CustomDatePickerInput />}
                            />
                        </div>
                    );
                }
            } else if (question.quizQuestionTypeID === 7) {
                let answer = '';
                if (question.quizAnswers && question.quizAnswers.length > 0) {
                    answer = question.quizAnswers[0].answerValue;
                }
                if (readonly) {
                    htmls.push(<div style={{
                        //width: width - 70,
                        width: 150, height: 46, borderRadius: 4, backgroundColor: 'rgb(238,238,238)', alignItems: 'center', justifyContent: 'center'
                    }} key='t7'>
                        <p style={{ fontSize: 14 }}>{answer}</p>
                    </div>);
                } else {
                    htmls.push(
                        <div key="t7" className="date_picker_container">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="hh:mm a"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                selected={answer && answer != '' ? moment(answer, 'hh:mm A').toDate() : new Date()}
                                onChange={(date) => {
                                    this._updateQuestionAnswer(question, moment(date).format('hh:mm A'))
                                }}
                                customInput={<CustomDatePickerInput />}
                            />
                        </div>
                    );
                }
            } else if (question.quizQuestionTypeID === 3) {
                let answer = '';
                if (question.quizAnswers && question.quizAnswers.length > 0) {
                    answer = question.quizAnswers[0].answerValue;
                }
                if (readonly) {
                    htmls.push(<div style={{
                        // width: width - 70,
                        //width: 150,
                        height: 46, borderRadius: 4, backgroundColor: 'rgb(238,238,238)', alignItems: 'center', justifyContent: 'center'
                    }} key='t3'>
                        <p style={{ fontSize: 14 }}>{answer}</p>
                    </div>);
                } else {
                    htmls.push(
                        <div style={{
                            alignItems: 'center', justifyContent: 'center', display: 'flex'
                        }}>
                            <input
                                className="input"
                                key={"t3"}
                                //style={[globalStyles.normalFont, globalStyles.input]}
                                keyboardtype='numeric'
                                value={answer}
                                maxLength={question.maxLength}
                                autoFocus={true}
                                onChange={(e) => { this._updateQuestionAnswer(question, e.target.value) }}
                            />
                        </div>);

                }
            } else if (question.quizQuestionTypeID === 4) {
                let possibleAnswerId;
                if (question.quizAnswers && question.quizAnswers.length > 0) {
                    possibleAnswerId = question.quizAnswers[0].quizPossibleAnswerID;
                }
                question.quizPossibleAnswers.map((item, index) => {
                    let isSelected = possibleAnswerId === item.quizPossibleAnswerID;
                    if (readonly) {
                        htmls.push(<div key={`t4_${index}`} style={{
                            //width: width - 30,
                            alignItems: 'flex-start', margin: 5, padding: 10, borderRadius: 5,
                            backgroundColor: isSelected ? '#0088cbbb' : '#eee'
                        }}>
                            <p style={{ color: isSelected ? '#fff' : '#000', fontSize: 14 }}>
                                {isENLocal ? item.answerValue : item.answerValueES}
                            </p>
                        </div>);
                    } else {
                        htmls.push(<div key={`t4_${index}`} onClick={() => this._updateQuestionAnswerForSelect(question, item.quizPossibleAnswerID, false)}>
                            <div style={{
                                //width: width - 30,
                                alignItems: 'flex-start', margin: 5, padding: 10, borderRadius: 5,
                                backgroundColor: isSelected ? '#0088cbbb' : '#eee'
                            }}>
                                <p style={{ color: isSelected ? '#fff' : '#000' }}>
                                    {isENLocal ? item.answerValue : item.answerValueES}
                                </p>
                            </div>
                        </div>);
                    }
                });
            } else if (question.quizQuestionTypeID === 5) {
                let possibleAnswerIds = [];
                let quizQuestions = [];
                if (question.quizAnswers && question.quizAnswers.length > 0) {
                    question.quizAnswers.map((item) => {
                        possibleAnswerIds.push(item.quizPossibleAnswerID);
                    });
                }

                question.quizPossibleAnswers.map((item, index) => {
                    let isSelected = false;
                    possibleAnswerIds.map((id) => {
                        if (item.quizPossibleAnswerID === id) {
                            isSelected = true;
                        }
                    });
                    if (readonly) {
                        htmls.push(<div key={`t5_${index}`} style={{
                            //width: width - 30,
                            alignItems: 'center', margin: 5, paddingBottom: 10, paddingTop: 10, borderRadius: 5,
                            backgroundColor: isSelected ? '#0088cbbb' : '#eee'
                        }}>
                            <p style={{ color: isSelected ? '#fff' : '#000', fontSize: 14 }}>
                                {isENLocal ? item.answerValue : item.answerValueES}
                            </p>
                        </div>);
                    } else {
                        if (item.quizQuestionSeqID === 102) {
                            quizQuestions.push(item);
                        }
                        else {
                            htmls.push(<div key={`t5_${index}`} onClick={() => this._updateQuestionAnswerForSelect(question, item.quizPossibleAnswerID, false)}>
                                <div style={{
                                    //width: width - 30,
                                    alignItems: 'center', margin: 5, paddingBottom: 10, paddingTop: 10, borderRadius: 5,
                                    backgroundColor: isSelected ? '#0088cbbb' : '#eee'
                                }}>
                                    <p style={{ color: isSelected ? '#fff' : '#000', fontSize: 14 }}>
                                        {isENLocal ? item.answerValue : item.answerValueES}
                                    </p>
                                </div>
                            </div>);
                        }
                    }
                });
                if (quizQuestions != null) {
                    this._renderSystems(question, possibleAnswerIds, quizQuestions, isENLocal, htmls);
                }
            }
        }
        return (<div style={{ alignItems: 'center', marginTop: 10, justifyContent: 'center',textAlign:'center',marginLeft:30,marginRight:30  }}>{htmls}</div>);
    }

    render() {
        let { loaded, loading, readonly, survey, questions, currentQuestionIndex } = this.state;
        let isENLocal = i18n.language  == 'en';
        //let isENLocal = true;
        let displayNum = '';
        let questionTilte = '';
        let question;
        let totalNeedAnswerQuestionNum = 0;
        let currentNeedAnswerQuestionIndex = 0;
        let progress = 0;
        let beginVisible = currentQuestionIndex < 0;
        let nextVisible = readonly ? (questions.length - 1 > currentQuestionIndex && currentQuestionIndex >= 0) : (questions.length > currentQuestionIndex && currentQuestionIndex >= 0);
        let prevVisible = currentQuestionIndex > 0;
        let saveVisible = false;
        let nextEnable = false;
        if (currentQuestionIndex < 0) {
            //displayNum = isENLocal?survey.title:survey.titleES;
            //questionTilte = isENLocal?survey.description:survey.descriptionES;
          } else {
            question = questions[currentQuestionIndex];
            if (question) {
              console.log(question);
              displayNum = question.quizQuestionNumberDisplay;
              questionTilte = isENLocal ? question.questionText : question.questionTextES;
              nextEnable = question.quizAnswers.length > 0 || question.quizQuestionTypeID == 8 || question.quizQuestionTypeID == 9 || question.quizQuestionTypeID == 10;
              if(nextEnable){
                if(question.quizAnswers.length > 0){           
                  if((question.quizQuestionTypeID == 2 || question.quizQuestionTypeID == 3 || question.quizQuestionTypeID == 6 || question.quizQuestionTypeID == 7)
                    && question.quizAnswers.length > 0 && question.quizAnswers[0].answerValue != null && question.quizAnswers[0].answerValue != ""){
                      nextEnable = true;
                  }else if((question.quizQuestionTypeID == 1 || question.quizQuestionTypeID == 4 || question.quizQuestionTypeID == 5)
                  && question.quizAnswers.length > 0 && question.quizAnswers[0].quizPossibleAnswerID != null && question.quizAnswers[0].quizPossibleAnswerID != ""){
                    nextEnable = true;
                  }else{
                    nextEnable = false;
                  }
                }
              }
            }
        }
        questions.map((item, index) => {
            if (item.quizQuestionTypeID !== 8 && item.quizQuestionTypeID !== 9 && item.quizQuestionTypeID !== 10) {
                totalNeedAnswerQuestionNum = totalNeedAnswerQuestionNum + 1;
                if (item.quizAnswers.length > 0 || item.skiped === true) {
                    currentNeedAnswerQuestionIndex = currentNeedAnswerQuestionIndex + 1;
                }
            }
        });
        if (totalNeedAnswerQuestionNum > 0) {
            progress = 100 * currentNeedAnswerQuestionIndex / totalNeedAnswerQuestionNum;
            saveVisible = progress > 0 && !readonly && currentQuestionIndex > 0 ? true : false;
        }

        let progressBolderColor = readonly ? '#ccc' : '#00B050';
        let progressBackgroundColor = readonly ? 'transparent' : '#00B050';
        let progressText = readonly ? this.translate("w_Results") : `You are ${progress.toFixed(0)}% complete`;

        return (
            //<TabBarContainer tab={'home'} history={this.props.history}>
            <div className="conatiner" style={{ display: "block" }}>
                <div style={{ width: '100%', overflow: 'auto', justifyContent: 'flex-start', border: 0 ,height:'100%',
                position:CommonUtils.isPC()?'relative':'static'}}>
                    <NavBar title={this.translate("w_Surveys")} history={this.props.history} />
                    {
                        loaded
                        ?
                        (<div style={{ alignSelf: 'stretch', flex: 1, backgroundColor: '#ffffff' }}>
                            <div style={{ alignSelf: 'stretch', alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, borderColor: '#0088cb88', height: 120 }}>
                                <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 10, display: 'flex',textAlign:'center'  }}>
                                    <p style={{ fontSize: 18 }}>{isENLocal ? survey.title : survey.titleES}</p>
                                </div>
                                <div style={{
                                    margin: 10,
                                    borderWidth: 1,
                                    borderColor: progressBolderColor,
                                    alignItems: 'center',
                                    height: 28,
                                    borderStyle: 'solid',
                                    display: 'flex',
                                    position:'relative'
                                }}>
                                    <div style={{
                                        width: `${(0.01 * progress * 100)}%`,
                                        height: '100%',
                                        backgroundColor: progressBackgroundColor
                                    }} />
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        left: 0,
                                        right: 0
                                    }}>
                                        <p style={{ backgroundColor: 'transparent', textAlign: 'center' }}>{progressText}</p>
                                    </div>

                                </div>
                                {/*<p style={{marginTop:10,marginBottom:5}}>{displayNum}</p>*/}
                            </div>
                            <div style={{ alignSelf: 'stretch', flex: 1, alignItems: 'center' }}>
                                <div style={{ alignSelf: 'stretch', flex: 1, alignItems: 'center' }}>
                                    <p style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'center', margin: 10 }}>{questionTilte}</p>
                                    <div style={{ flex: 1, alignSelf: 'stretch',overflow:'auto',height:CommonUtils.clientHeight()-350}} >
                                        {this._renderQuestion(question, readonly, isENLocal)}
                                    </div>
                                </div>
                                    <div style={{ flexDirection: 'row',position:CommonUtils.isPC()?'absolute':'fixed',
                                    bottom:'20px',left:0,right:0}}>
                                        <div style={{
                                            flex: 1,
                                            flexDirection: 'row',
                                            alignSelf: 'stretch',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            margin: 20,
                                            display: 'flex'
                                        }}>

                                            {
                                                prevVisible
                                                    ?
                                                    (
                                                        <div
                                                            onClick={this._prev.bind(this, currentQuestionIndex)}
                                                            style={{
                                                                flex: 1,
                                                                marginRight: 20,
                                                                overflow: 'hidden',
                                                                height: prevVisible ? 36 : 0,
                                                                justifyContent: 'center',
                                                                display: 'flex',
                                                                textAlign: 'center'
                                                            }}>
                                                            <div style={{
                                                                backgroundColor: '#123B6D',
                                                                padding: 5,
                                                                width: 90,
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: 5,
                                                                color: '#fff',
                                                                fontSize: 16
                                                            }}>
                                                                {this.translate("w_Prev")}
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    (<div></div>)
                                            }
                                            {
                                                beginVisible
                                                    ?
                                                    (
                                                        <div
                                                            onClick={this._begin.bind(this)}
                                                            style={{
                                                                flex: 1,
                                                                overflow: 'hidden',
                                                                height: beginVisible ? 36 : 0,
                                                                justifyContent: 'center',
                                                                display: 'flex',
                                                                textAlign: 'center'
                                                            }}>
                                                            <div style={{
                                                                backgroundColor: '#123B6D',
                                                                padding: 5,
                                                                width: 90,
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: 5,
                                                                color: '#fff',
                                                                fontSize: 16
                                                            }}>
                                                                {this.translate("w_Begin")}
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    (<div></div>)
                                            }
                                            {/*
                                                saveVisible
                                                    ?
                                                    (<div
                                                        onClick={this._Confirmation_save.bind(this, progress)}
                                                        style={{
                                                            flex: 1,
                                                            overflow: 'hidden',
                                                            marginRight: 20,
                                                            height: nextVisible ? 36 : 0,
                                                            justifyContent: 'center',
                                                            display: 'flex',
                                                            textAlign: 'center'
                                                        }}>
                                                        <div style={{
                                                            backgroundColor: '#123B6D',
                                                            padding: 5,
                                                            width: 90,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: 5,
                                                            color: '#fff',
                                                            fontSize: 16
                                                        }}>
                                                            {this.translate("w_save")}
                                                        </div>
                                                    </div>)
                                                    :
                                                    (<div></div>)
                                                    */}
                                            {
                                                nextVisible && currentQuestionIndex !== questions.length - 1
                                                    ?
                                                    (<div
                                                        onClick={nextEnable ? this._next.bind(this, currentQuestionIndex) : null}
                                                        style={{
                                                            flex: 1,
                                                            overflow: 'hidden',
                                                            height: nextVisible ? 36 : 0,
                                                            justifyContent: 'center',
                                                            display: 'flex',
                                                            textAlign: 'center'
                                                        }}>
                                                        <div style={{
                                                            backgroundColor: nextEnable ? '#123B6D' : '#ccc',
                                                            padding: 5,
                                                            width: 90,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: 5,
                                                            color: '#fff',
                                                            fontSize: 16,
                                                        }}>
                                                            {currentQuestionIndex === questions.length - 1 ? this.translate("w_save") : this.translate("w_next")}
                                                        </div>
                                                    </div>)
                                                    :
                                                    ( saveVisible
                                                        ?
                                                        (<div
                                                            onClick={this._Confirmation_done.bind(this)}
                                                            style={{
                                                                flex: 1,
                                                                overflow: 'hidden',
                                                                marginRight: 20,
                                                                height: nextVisible ? 36 : 0,
                                                                justifyContent: 'center',
                                                                display: 'flex',
                                                                textAlign: 'center'
                                                            }}>
                                                            <div style={{
                                                                backgroundColor: '#123B6D',
                                                                padding: 5,
                                                                width: 90,
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: 5,
                                                                color: '#fff',
                                                                fontSize: 16
                                                            }}>
                                                                {this.translate("w_save")}
                                                            </div>
                                                        </div>)
                                                        :
                                                        (<div></div>))
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                            )
                            :
                            (<div style={{ alignSelf: 'stretch', flex: 1, backgroundColor: '#fff' }}></div>)
                    }
                    {
                        this.state.need_alert_survey == "1" &&
                        <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_confirmation')}
                            </div>
                            <div className="alert_content">
                                {this.translate('w_Your_answers_have_been_saved_')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    this.setState({ need_alert_survey: 0 });
                                }}
                            >
                                {this.translate('w_cancel')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    //localStorage.removeItem("need_alert_survey");
                                    this._Incompleedsave(progress);
                                    this.setState({ need_alert_survey: 0 });
                                }}
                            >
                                {this.translate('w_confirm')}
                            </div>
                        </ModalContainer>
                    }
                    {
                        this.state.need_alert_survey == "2" &&
                        <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_confirmation')}
                            </div>
                            <div className="alert_content">
                                {this.translate('w_Your_answers_have_been_saved_')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    //localStorage.removeItem("need_alert_survey");
                                    this.props.history.goBack();
                                    this.setState({ need_alert_survey: 0 });
                                }}
                            >
                                {this.translate('w_ok')}
                            </div>
                        </ModalContainer>
                    }
                    {
                        this.state.need_alert_survey == "3" &&
                        <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_confirmation')}
                            </div>
                            <div className="alert_content">
                                {this.translate('w_Thank_completing_survey_')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    //localStorage.removeItem("need_alert_survey");
                                    if (
                                      this.props.location.state
                                        .continueToScheduleAppt
                                    ) {
                                      this.props.history.replace(
                                        '/home/calendar/schedule',
                                        {
                                          selectedDate: this.props.location
                                            .state.selectedDate,
                                          quizParticipantID:
                                            survey.quizzParticipantId,
                                        }
                                      );
                                    } else {
                                        this.props.history.goBack();
                                    }
                                    this.setState({ need_alert_survey: 0 });
                                }}
                            >
                                {this.translate('w_ok')}
                            </div>
                        </ModalContainer>
                    }
                    {
                        this.state.need_alert_survey == "4" &&
                        <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_Invalid_Temperature')}
                            </div>
                            <div className="alert_content">
                                {this.translate('w_Please_Temperature_value')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    this.setState({ need_alert_survey: 0 });
                                }}
                            >
                                {this.translate('w_ok')}
                            </div>
                        </ModalContainer>
                    }
                    {
                        this.state.need_alert_survey == "5" &&
                        <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_reocard_systems_title')}
                            </div>
                            <div className="alert_content">
                                {this.translate('w_reocard_systems_content')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    this._next_end(this.state.questionIndex);
                                    this.setState({ need_alert_survey: 0 });
                                }}
                            >
                                {this.translate('w_ok')}
                            </div>
                        </ModalContainer>
                    }
                                        {
                        this.state.need_alert_survey == "6" &&
                        <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_InvalidAnswer')}
                            </div>
                            <div className="alert_content">
                                {this.translate('w_Please_enter_a_value_1_to_100')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    //this._next_end(this.state.questionIndex);
                                    this.setState({ need_alert_survey: 0 });
                                }}
                            >
                                {this.translate('w_ok')}
                            </div>
                        </ModalContainer>
                    }
                </div>
                {loading && <Loading />}
            </div>

            //</TabBarContainer>
        );
    }
}
const styles = {
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',

    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5,
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    txtErr: {
        color: '#f00',
        marginTop: 3,
        fontSize: 14
    },
    txtOK: {
        color: '#00b359',
        marginTop: 3,
        fontSize: 14
    },
    ptext: {
        color: '#fff',
        textAlign: 'end'
    },
    selectedSymptom: {
        flexDirection: 'row',
        justifyContent: 'center',
        left: 50,
        position: 'absolute',
        width: 25,
        height: 25,
        borderRadius: 8,
        borderWidth: 0,
        borderColor: 'transparent',
        //borderColor: '#ffffff',
    },
    imageContent: {
        width: 80,
        height: 80,
        padding: 0,
        backgroundColor: '#ffffff',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        textAlign: 'center',
        position: 'relative'
    },
    symptomsFontSize: {
        wordWrap: 'break-word',
        marginTop: 1,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 12,
        color: "#717171"
    },
    option_row: {
        flexDirection: 'row',
        margin: 15,
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex'
    },
    unSelectContainer: {
        width: 24,
        height: 24,
        backgroundColor: '#fff',
        borderRadius: 1,
        borderColor: '#e0e0e0',
        borderWidth: 2,
        display: 'flex',
        borderStyle: 'solid'
    },
    selectedContainer: {
        width: 24,
        height: 24,
        backgroundColor: '#123B6D',
        borderRadius: 1,
        borderColor: '#123B6D',
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        borderStyle: 'solid'
    },
    feverView: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginLeft: 60,
        display: 'flex'
    }
};
