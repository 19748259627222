import React from "react";
import BaseComponent from "../../components/BaseComponent";
import TabBarContainer from "../../components/TabBarContainer";
import symtopmchecker from "../../content/images/Drexel_Vertical_stacked_Lockup_HEX.png";
import arrow_right from "../../content/images/arrow_right.png";
import icon_setting from "../../content/images/more/more_icon_setting.png";
import icon_resources from "../../content/images/more/more_icon_resources_blue.png";
import icon_testresult from "../../content/images/more/more_icon_textresult.png";
import icon_vaccinerecord from "../../content/images/more/more_icon_vaccinerecord.png";
import icon_surveys from "../../content/images/more/more_icon_survey.png";
import icon_question from "../../content/images/more/more_icon_question.png";
import Powered_By_logo from "../../content/images/Powered_By_logo.png";
import ScrollView from "../../components/ScrollView";
import Loading from "../../components/Loading";
import CommonUtil from "../../utils/CommonUtil";
import httpUtil from "../../utils/HttpUtil";
import Common from "../../Common";
export default class More extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      suveryCount: localStorage.getItem("survey_count"),
    };
  }

  componentDidMount() {
    httpUtil.get("/api/Profile/GetAppStatus", (appStatus) => {
      if (appStatus && appStatus.hasOwnProperty("numNewMsg")) {
        localStorage.setItem(
          "survey_count",
          JSON.stringify(appStatus.numNewSurvey)
        );
        this.setState({
          suveryCount: localStorage.getItem("survey_count"),
          wikiCount: localStorage.getItem("wiki_count"),
        });
      }
    });
  }

  signOutClick() {
    //localStorage.removeItem('token');
    //localStorage.removeItem('profile');
    // localStorage.removeItem('wiki_count');
    // localStorage.removeItem('msg_count');
    // localStorage.removeItem('survey_count');
    // localStorage.removeItem('FeaturedArticles');
    // localStorage.removeItem('LastGetFeaturedArticlesTime');
    // localStorage.removeItem('TopicBoards');
    // localStorage.removeItem('LastGetTopicBoardsTime');
    //don't clear "MOM_APP_FIRSTLANUCH"
    //localStorage.removeItem('viewSummary',false);

    //this.props.history.push("/");
    //TODO for more test
    // httpUtil.get('/api/Participant/LogOff',
    //   (response)=> {
    // console.log('LogOff');
    // console.log(response);
    // if(response){
    // if(response.status == 'Success'){
    // let MOM_APP_FIRSTLANUCH = localStorage.getItem('MOM_APP_FIRSTLANUCH');
    // localStorage.clear();
    // localStorage.setItem("MOM_APP_FIRSTLANUCH", MOM_APP_FIRSTLANUCH);
    // this.props.history.push("/");
    // this.redirectToSSO();
    // }
    // }
    //   });
    let MOM_APP_FIRSTLANUCH = localStorage.getItem("MOM_APP_FIRSTLANUCH");
    localStorage.clear();
    localStorage.setItem("MOM_APP_FIRSTLANUCH", MOM_APP_FIRSTLANUCH);
    this.redirectToSSO();
  }
  redirectToSSO() {
    let logoutUrl = "";
    if (Common.getEnvironment() != "production") {
      logoutUrl =
        "https://shib.irttest.drexel.edu/cas/confirmLogout?appName=MyOwnMed";
    } else {
      logoutUrl =
        "https://connect.drexel.edu/cas/confirmLogout?appName=MyOwnMed";
    }
    window.location.href = logoutUrl;
    //https://connect.drexel.edu/idp/profile/cas/logout?execution=e1s1
    //history.push('/')
  }

  _itemClick(item) {
    if (item == "resources") {
      this.props.history.push("/home/resources");
    } else {
      this.props.history.push("/home/more/" + item);
    }
  }

  render() {
    return (
      <TabBarContainer tab={"more"} history={this.props.history}>
        <div
          style={{
            alignSelf: "stretch",
            flex: "1 1 0%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ScrollView
            style={{
              flex: 1,
              alignSelf: "stretch",
              width: CommonUtil.clientWidth(),
              height: CommonUtil.clientHeight() - 210,
            }}
          >
            <div
              style={{
                backgroundColor: "#123b6d",
                textAlign: "-webkit-center",
                width: "100%",
                height: "31%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "60px",
                  width: "120px",
                  height: "120px",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  margin: "0 auto",
                  marginTop: 20,
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                <img
                  style={{
                    margin: "15%",
                    width: "85px",
                    height: "85px",
                    resizeMode: "contain",
                  }}
                  src={symtopmchecker}
                  alt=""
                />
              </div>
              <p
                style={{
                  fontSize: 27,
                  color: "#fff",
                  marginBottom: 20,
                  alignSelf: "center",
                  lineHeight: 0,
                  textAlign: "center",
                }}
              >
                {this.translate("w_Health_Checker")}
              </p>
            </div>
            <div
              className="fill"
              style={{
                justifyContent: "flex-start",
                backgroundColor: "#ffffff",
                padding: 10,
              }}
            >
              <div
                className="row pointer bottomBorder"
                style={styles.row}
                onClick={this._itemClick.bind(this, "surveys")}
              >
                <img src={icon_surveys} style={{ width: 45 }} />
                <div
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginLeft: 10,
                    display: "flex",
                    alignItems: "center",
                    fontSize: 17,
                  }}
                >
                  {this.translate("w_Surveys")}
                </div>
                {this.state.suveryCount && this.state.suveryCount > 0 && (
                  <div style={styles.count}>{this.state.suveryCount}</div>
                )}
                <img
                  src={arrow_right}
                  style={{ width: 8, height: 16, marginTop: 8 }}
                />
              </div>
              <div
                className="row pointer bottomBorder"
                style={styles.row}
                onClick={this._itemClick.bind(this, "testresults")}
              >
                <img src={icon_testresult} style={{ width: 45 }} />
                <div
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginLeft: 10,
                    display: "flex",
                    alignItems: "center",
                    fontSize: 17,
                  }}
                >
                  {this.translate("w_Lab_Result")}
                </div>
                <img
                  src={arrow_right}
                  style={{ width: 8, height: 16, marginTop: 8 }}
                />
              </div>
              <div
                className="row pointer bottomBorder"
                style={styles.row}
                onClick={this._itemClick.bind(this, "vaccinerecords")}
              >
                <img
                  src={icon_vaccinerecord}
                  style={{ height: 30, marginLeft: 7.5, marginRight: 7.5 }}
                />
                <div
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginLeft: 10,
                    display: "flex",
                    alignItems: "center",
                    fontSize: 17,
                  }}
                >
                  {this.translate("w_Vaccine_Records")}
                </div>
                <img
                  src={arrow_right}
                  style={{ width: 8, height: 16, marginTop: 8 }}
                />
              </div>
              <div
                className="row pointer bottomBorder"
                style={styles.row}
                onClick={this._itemClick.bind(this, "resources")}
              >
                <img
                  src={icon_resources}
                  style={{ width: 23, paddingLeft: 10 }}
                />
                <div
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginLeft: 22,
                    display: "flex",
                    alignItems: "center",
                    fontSize: 17,
                  }}
                >
                  {this.translate("w_resources")}
                </div>
                {/*this.state.wikiCount && this.state.wikiCount > 0 && <div style={styles.count}>{this.state.wikiCount}</div>*/}

                <img
                  src={arrow_right}
                  style={{ width: 8, height: 16, marginTop: 8 }}
                />
              </div>
              <div
                className="row pointer bottomBorder"
                style={styles.row}
                onClick={this._itemClick.bind(this, "settings")}
              >
                <img src={icon_setting} style={{ width: 45 }} />
                <div
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginLeft: 10,
                    display: "flex",
                    alignItems: "center",
                    fontSize: 17,
                  }}
                >
                  {this.translate("w_settings")}
                </div>
                <img
                  src={arrow_right}
                  style={{ width: 8, height: 16, marginTop: 8 }}
                />
              </div>
              {
                <button
                  onClick={this.signOutClick.bind(this)}
                  className="button"
                >
                  {this.translate("w_signout")}
                </button>
              }
              <div
                style={{
                  fontSize: 20,
                  color: "#000",
                  marginTop: 5,
                  marginBottom: 1,
                  alignSelf: "center",
                  textAlign: "center",
                  lineHeight: 0,
                }}
              >
                {this.translate("w_more_footer_text")}
              </div>
              <div
                style={{
                  flex: 1,
                  alightSelft: "center",
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                <img
                  src={Powered_By_logo}
                  style={styles.powerbylogo}
                  resizemode={"contain"}
                  alt=""
                />
                <div style={styles.powerbylogText}>
                  {this.translate("w_mom_version")}
                  {Common.VERSION}
                </div>
              </div>
            </div>
          </ScrollView>
          {this.state.loading && <Loading />}
        </div>
      </TabBarContainer>
    );
  }
}

var styles = {
  row: {
    alignSelf: "stretch",
    padding: 8,
    display: "flex",
    maxHeight: 30,
  },
  count: {
    display: "flex",
    position: "relative",
    marginBottom: "-12px",
    right: "16px",
    top: "7px",
    zIndex: "999",
    backgroundColor: "red",
    width: "16px",
    height: "16px",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10px",
    color: "#ffffff",
  },
  powerbylogo: {
    width: 100,
    margin: 10,
    tintColor: "#123B6D",
  },
  powerbylogText: {
    fontSize: 14,
    fontWeight: 800,
    textAlign: "center",
    color: "#000000",
    flex: 1,
  },
};
