import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import httpUtil from '../../utils/HttpUtil';
import logo_myownmed from '../../content/images/logo_myownmed.png';
import Loading from '../../components/Loading';
import ModalContainer from './../../components/ModalContainer';
import LabResultSvr from '../../service/LabResultSvr';
import Profile from '../../store/ProfileStorage';
export default class Pin extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            pin: '',
            pinconfirm: '',
            have4digits: true,
            samePin: true,
            regSuccess: false,
            loading: false,
            successRegisterAlertShow:false
        };
    }
    componentDidMount() {

    }
    //TODO:需要继续修改alert
    next() {
        var locationState = this.props.location.state;
        let confirmcp = locationState.rmodel && locationState.rmodel.chlids && locationState.rmodel.chlids.length > 0;
        let param = {
            firstName: locationState.firstName ? locationState.firstName : locationState.rmodel.firstName,
            lastName: locationState.lastName ? locationState.lastName :  locationState.rmodel.lastName,
            phoneNumber: locationState.phone ? locationState.phone : locationState.rmodel.phoneNumber,
            // gender: '',
            // dateOfBirth: moment.utc(`${register_birth_y}-${register_birth_m}-${register_birth_d}`),
            email: locationState.email ? locationState.email : locationState.rmodel.email,
            zipCode: locationState.zipcode ? locationState.zipcode : locationState.rmodel.zipcode,
            have36MonthChildren: locationState.had36monthchildren,
            password: locationState.password,
            pin: this.state.pin,
            enableTouchID: false,
            //chlids: locationState.childs,
            isPregnant: locationState.pregnant,
            locationId: locationState.locationId, //|| this.state.locationId,
            positionType: locationState.positionType ? locationState.positionType : locationState.rmodel.positionType,
            studentType : locationState.studentType ? locationState.studentType : locationState.rmodel.studentType,
            athleticType: locationState.athleticType ? locationState.athleticType : locationState.rmodel.athleticType,
            age: locationState.age ? locationState.age : locationState.rmodel.age,
            //internationalTravelinPast14D: locationState.internationalTravelinPast14D,
            healthCondition: locationState.healthCondition,
            hadContactConfirmedCase: locationState.hadContactConfirmedCase,
            fAvalidated:locationState.fAvalidated ? locationState.fAvalidated : locationState.rmodel.fAvalidated,
            clientConsent: this.props.location.state.clientConsent
        };
        if (this.state.pin.length == 0) {
            alert(
                //Translation.lang().Missing,
                this.translate("w_Please_enter_digit_PIN_")
            )
        }
        else if (this.state.pin.length < 4) {
            alert(
                //Translation.lang().w_setPin,
                this.translate("w_must_contain_4_digits")
            )
        }
        else if (this.state.pin !== this.state.pinconfirm) {
            alert(

                //Translation.lang().w_setPin,
                this.translate("w_pin_must_match")
            )
        }
        else {
            //dismissKeyboard();
            // MomEnv.pushPage(Platform.OS == 'ios' ? Touch : Photo, { pin: this.state.pin, registerParams: this.props.registerParams });
            // if (Platform.OS == 'ios') {
            //     MomEnv.pushPage(Touch, { pin: this.state.pin, registerParams: this.props.registerParams });
            // }
            // else {
            this.setState({ loading: true });
            param.pin = this.state.pin;
            //param.platform = 'ANDROID';
            //param.version = MomEnv.appVersion();
            httpUtil.post('/api/Participant/Register', param,
                (responseData) => {
                    if (responseData && responseData.status == 'Success') {
                        var profile = JSON.parse(localStorage.getItem('profile'));
                        this._signIn(param);
                        this.setState({loading:false});          
                        alert(
    
                            //Translation.lang().w_congratulations,
                            this.translate("w_successfully_created_account"),
                            // [
                            //     {
                            //         text: this.translate("w_ok"), onPress: () => {
                            //             this._signIn(param);
                            //         }
                            //     },
                            // ]

                        );
                    } else {
                        this.setState({loading:false});
                        alert(
                            //Translation.lang().w_failed, 
                            this.translate("w_Failed_Register"));
                    }
                }
            );
        }
        //}
    }

    _validPin(pin) {
        this.setState({
            have4digits: pin.length >= 4,
            samePin: pin == this.state.pinconfirm
        });
    }

    _validPinconfirm(pinconfirm) {
        this.setState({ samePin: pinconfirm == this.state.pin });
    }

    _scrollToEnd() {
        this._scroll && this._scroll.scrollTo({ x: 0, y: 160, animated: true });
    }

    _signIn(param) {
        this.setState({ loading: true });
        httpUtil.post('Token', `grant_type=password&username=${param.email}&password=${param.pin}`,
            (result) => {
                this.setState({ loading: false });
                if (result && result.access_token) {
                    let token = result.access_token;
                    localStorage.setItem('token', token);
                    this.getProfile();
                }
            }
        );
    }
    getProfile() {
        httpUtil.get('/api/Profile/GetMine',
            (profileData) => {
                if (profileData.id && profileData.status == 1) {
                    LabResultSvr.getLabResultNotes(Profile.saveLabResultNotes);
                    localStorage.setItem('profile', JSON.stringify(profileData));
                    localStorage.setItem("need_alert_survey", "1");
                    this.props.history.push('/trackmain');
                }
            }
        );
    }

    render() {
        let { pin, pinconfirm,successRegisterAlertShow } = this.state;
        return (
            <div className="conatiner" style={{ justifyContent: 'flex-start' }}>
                <div style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    alignSelf: 'stretch',
                    marginTop: 10,
                    marginBottom: 5
                }}>
                    <p style={{ fontSize: 18 }}>{this.translate("w_set_4_digit_pin")}</p>
                    <p style={{ fontSize: 16 }}>{this.translate("w_set_4_digit_pin_tip")}</p>
                </div>
                <input
                    className="input1"
                    type='password'
                    maxLength={4}
                    style={{ marginTop: 20, }}
                    placeholder={this.translate("w_new_4_digit_pin")}
                    value={pin}
                    onChange={(e) => {
                        this.setState({ pin: e.target.value });
                        this._validPin(e.target.value.replace(/\D/g, ''))
                    }}
                />
                <input
                    className="input1"
                    type='password'
                    style={{ marginTop: 20, }}
                    maxLength={4}
                    placeholder={this.translate("w_confirm_4_digit_pin")}
                    value={pinconfirm}
                    onChange={(e) => {
                        this.setState({ pinconfirm: e.target.value });
                        this._validPinconfirm(e.target.value.replace(/\D/g, ''));
                    }}
                />
                {
                    pin !== '' &&
                    <div style={{ alignSelf: 'stretch', marginBottom: 10,paddingLeft:20 }}>
                        <p style={this.state.have4digits ? styles.txtOK : styles.txtErr}>
                            {this.state.have4digits ? '√' : 'x'} {this.translate("w_must_contain_4_digits")}
                        </p>
                        <p style={this.state.samePin ? styles.txtOK : styles.txtErr}>
                            {this.state.samePin ? '√' : 'x'} {this.translate("w_pin_must_match")}
                        </p>
                    </div>
                }
                {/* <img style={{ margin: 30 }} src={logo_myownmed} alt="" /> */}
                <div style={{
                    alignSelf: 'stretch',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginBottom: 10,
                    marginTop: 50,
                    display: 'flex',
                    justifyContent: 'flex-start'

                }}>
                    <p style={{ fontSize: 14 }}>
                        {this.translate("w_by_creating_your_account")}
                    </p>
                    &nbsp;
                    <p
                        onClick={() => {
                            localStorage.setItem("need_alert_survey", "1");
                            localStorage.setItem("pageWithoutToken",true);
                            this.props.history.push('/home/more/settings/termsofUse');
                        }}
                        style={{ fontSize: 14, color: '#00b359',cursor:'pointer' }}>
                        {this.translate("w_termOfUse")}
                    </p>
                    &nbsp;
                    <p style={{ fontSize: 14 }}>
                        {this.translate("w_and")}
                    </p>
                    &nbsp;

                    <p onClick={() => {
                        localStorage.setItem("need_alert_survey", "1");
                        localStorage.setItem("pageWithoutToken",true);
                        this.props.history.push('/home/more/settings/privacy');
                    }}
                        style={{ fontSize: 14, color: '#00b359',cursor:'pointer' }}>
                        {this.translate("w_privacyPolicy")}
                    </p>
                    &nbsp;

                    <p style={{ fontSize: 14 }}>
                        {this.translate("w_for_the_application")}
                    </p>
                </div>
                <button onClick={this.next.bind(this)} className="button">
                    {this.translate('w_done')}
                </button>
                {successRegisterAlertShow && 
                    <ModalContainer>
                        <div className="alert_title">
                            {this.translate('w_congratulations')}
                        </div>
                        <div className="alert_content">
                            {this.translate('w_successfully_created_account')}
                        </div>
                        <div
                            className="alert_button"
                            onClick={() => {
                                this.setState({successRegisterAlertShow:false});
                            }}
                        >
                            {this.translate('w_ok')}
                        </div>
                    </ModalContainer>
                }
                {
                    this.state.loading && <Loading />
                }
            </div>
        );
    }

}
const styles = {
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',

    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5,
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    txtErr: {
        color: '#f00',
        marginTop: 3,
        fontSize: 14
    },
    txtOK: {
        color: '#00b359',
        marginTop: 3,
        fontSize: 14
    }
};