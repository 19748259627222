import httpUtil from "./HttpUtil";
import moment from 'moment';

export default {
    GetFeaturedArticles(callback) {
        let lastGetFeaturedArticlesTime = localStorage.getItem('LastGetFeaturedArticlesTime');
        if (!lastGetFeaturedArticlesTime || (moment().unix() - lastGetFeaturedArticlesTime) > 1800) {
            //cache 30 mins
            httpUtil.get('/api/Wiki/GetFeaturedArticles',
                (responseData) => {
                    if (responseData) {
                        localStorage.setItem('FeaturedArticles', JSON.stringify(responseData));
                        localStorage.setItem('LastGetFeaturedArticlesTime', moment().unix());
                        callback(responseData);
                    }
                    else {
                        callback([]);
                    }
                });
        }
        else {
            callback(JSON.parse(localStorage.getItem('FeaturedArticles')));
        }
    },

    GetTopicBoards(callback) {
        let lastGetTopicBoardsTime = localStorage.getItem('LastGetTopicBoardsTime');
        if (!lastGetTopicBoardsTime || (moment().unix() - lastGetTopicBoardsTime) > 1800) {
            //cache 30 mins
            httpUtil.get('/api/Wiki/GetTopicBoards',
                (responseData) => {
                    if (responseData) {
                        localStorage.setItem('TopicBoards', JSON.stringify(responseData));
                        localStorage.setItem('LastGetTopicBoardsTime', moment().unix());
                        callback(responseData);
                    }
                    else {
                        callback([]);
                    }
                });
        }
        else {
            callback(JSON.parse(localStorage.getItem('TopicBoards')));
        }
    }
}