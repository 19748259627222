import React, { Component } from 'react';
import { Link } from "react-router-dom";
import arrow_left_white from "../content/images/arrow_left_white.png"

export default class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  back() {
    if(this.props.backurl){
      this.props.history.replace(this.props.backurl);
    }else{
      this.props.history.goBack();
    }    
  }

  render() {
    //<Link to={this.props.backurl} style={styles.backImg}>{'<'}</Link>
    return (
      <div style={styles.backContainer}>
        <div className='pointer' style={styles.backImg} onClick={this.back.bind(this)}>
          <img src={arrow_left_white} style={{ width: 9, height: 14 }} resizemode={'contain'} />
        </div>
        <div style={styles.backTxt}>{this.props.title}</div>
        <div style={{ width: 50, height: 40 }}>{this.props.right}</div>
      </div>
    );
  }
}

const styles = {
  backContainer: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    color: '#fff',
    display: 'flex',
    backgroundColor: "#123b6d"
  },
  backImg: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: 21,
    padding: 18,
  },
  backTxt: {
    textDecoration: 'none',
    fontSize: 18,
    display: 'flex',
    alignItems: "center",
  },
};