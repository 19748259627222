import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import TabBarContainer from '../../components/TabBarContainer';
import Drexel_Vertical_stacked_Lockup_HEX from '../../content/images/Drexel_Vertical_stacked_Lockup_HEX.png';
import httpUtil from '../../utils/HttpUtil';
import logo_myownmed from '../../content/images/logo_myownmed.png';
import ScrollView from '../../components/ScrollView';
import Loading from '../../components/Loading';
import CommonUtil from '../../utils/CommonUtil';
export default class register extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            access_code: '',
            access_code_ok: true,
            access_code_verify_ok: false,
            register_f_name: '',
            register_l_name: '',
            register_email: '',
            register_birth_y: '',
            register_birth_m: '',
            register_birth_d: '',
            register_gender: 'M',
            register_phone: '',
            showIndicator: false,
            BirthYear: '',
            loading: false
        };
    }
    componentDidMount() {

    }
    ////TODO:需要继续修改alert
    _verifyAccessCode() {
        //PWRHSE
        let { access_code, BirthYear } = this.state;
        if (access_code === '') {
            //alert(this.translate("w_Missing_Info"), this.translate("w_Participant_ID_required_"))
            alert(this.translate("w_Participant_ID_required_"))

        } else if (BirthYear === '') {
            //alert(this.translate("w_Missing_Info"), this.translate("w_BirthYear_required_"))
            alert(this.translate("w_BirthYear_required_"))
        }
        else {
            this.setState({ showIndicator: true, loading: true });
            httpUtil.put('api/Participant/VerifyParticipantID?ParticipantID=' + access_code + '&BirthYear=' + BirthYear, null,
                (responseData) => {
                    if (responseData && responseData.status === 'Success') {
                        this.setState({ showIndicator: false, access_code_ok: true, access_code_verify_ok: true, loading: false });
                    } else {
                        // Alert.alert('Participant ID', 'Please contact the study team at Parker Institute to issue you a Participant ID.')
                        this.setState({ showIndicator: false, access_code_ok: false, access_code_verify_ok: false, access_code: '', BirthYear: '', loading: false });
                    }
                }
            );
        }
    }


    _register() {
        let { register_f_name, register_l_name, register_email, register_birth_m, register_birth_d, register_birth_y, register_gender, register_phone, access_code } = this.state;
        if (register_f_name === '') {
            alert(
                //this.translate("w_Warning"), 
                this.translate("w_First_Name_is_required_"))
        }
        else if (register_l_name === '') {
            alert(
                //this.translate("w_Warning"), 
                this.translate("w_Last_Name_is_required_"))
        }
        else if (register_email === '') {
            alert(
                //this.translate("w_Warning"), 
                this.translate("w_Email_is_required_"))
        } else if (register_phone === '') {
            //alert(this.translate("w_Warning"), 
            this.translate("w_Phone_number_is_required_")
        }
        else if (register_phone.length !== 14) {
            alert(
                //this.translate("w_Warning"), 
                this.translate("w_Phone_number_invalid_"))
        }
        else {
            this.setState({ showIndicator: true });

            httpUtil.put('api/Participant/VerifyEmail?email=' + register_email, null,
                (responseData) => {
                    this.setState({ showIndicator: false });
                    if (responseData && responseData.status === 'Success') {
                        let param = {
                            firstName: register_f_name,
                            lastName: register_l_name,
                            email: register_email,
                            phone: register_phone.replace(/\D/g, ''),
                            patientID: access_code,
                            //registerEnd:1 = moible app, 2 = web app                            
                            registerEnd: 2,
                        };
                        this.props.history.push('/pin', { registerParams: param });
                        //MomEnv.pushPage(Pin, { registerParams: param });

                    } else {
                        alert(this.translate("w_Unable_to_Register"), this.translate("w_Email_address_exists_Please_enter_different_page_"),
                            [
                                {
                                    text: this.translate("w_ok"), onPress: () => {
                                    }
                                },
                            ]
                        );
                    }
                },
            );
        }
    }
    emailtextchange(text) {
        this.setState({ register_email: text });
    }
    _renderRegisterDetail() {
        let { register_f_name, register_l_name, register_email, register_birth_m, register_birth_d, register_birth_y, register_gender, register_phone } = this.state;
        return (
            <div style={{height:CommonUtil.clientHeight()}}>
                <div className="conatiner" style={styles.borderContainer}>
                    <div style={{
                        color: '#666',
                        fontSize: 16,
                        textAlign: 'center',
                        marginTop: 15
                    }}>
                        {this.translate("w_Please_provide_email_address_app_registration_1")}
                    </div>
                    <p style={
                        {
                            color: '#666',
                            fontSize: 14,
                            alignSelf: 'flex-start',
                            marginLeft: 25,
                            marginTop: 5
                        }}>
                        {this.translate("w_firstName")}
                    </p>
                    <input
                        className="input1"
                        type='text'
                        style={{ marginTop: 2, }}
                        placeholder={this.translate("w_Enter_your_ﬁrst_name")}
                        value={register_f_name}
                        onChange={(e) => this.setState({ register_f_name: e.target.value })}
                    />
                    <p style={
                        {
                            color: '#666',
                            fontSize: 14,
                            alignSelf: 'flex-start',
                            marginLeft: 25,
                            marginTop: 2
                        }}>
                        {this.translate("w_lastName")}
                    </p>
                    <input
                        className="input1"
                        type='text'
                        style={{ marginTop: 2, }}
                        placeholder={this.translate("w_Enter_your_last_name")}
                        value={register_l_name}
                        onChange={(e) => this.setState({ register_l_name: e.target.value })}
                    />
                    <p style={
                        {
                            color: '#666',
                            fontSize: 14,
                            alignSelf: 'flex-start',
                            marginLeft: 25,
                            marginTop: 2
                        }}>
                        {this.translate("w_Email__")}
                    </p>
                    <input
                        className="input1"
                        type='text'
                        style={{ marginTop: 2, }}
                        placeholder={this.translate("w_Enter_your_email")}
                        value={register_email}
                        onChange={(e) => this.emailtextchange(e.target.value)}
                    />
                    <p style={
                        {
                            color: '#666',
                            fontSize: 14,
                            alignSelf: 'flex-start',
                            marginLeft: 25,
                            marginTop: 2
                        }}>
                        {this.translate("w_Phone_Number_")}
                    </p>
                    <input
                        className="input1"
                        type='text'
                        //style={[globalStyles.normalFont, globalStyles.input, styles.input, { marginTop: 3, height: 40 }]}
                        maxLength={14}
                        style={{ marginBottom: 5, marginTop: 2 }}
                        placeholder={'xxx-xxx-xxxx'}
                        value={register_phone}
                        onChange={(e) => this._phoneChange(e.target.value)}
                    />

                    {/* <div style={{ alignSelf: 'stretch' }}> */}

                    {/* <div className="button"
                            onClick={() => this._register.bind(this)}
                        >
                            <p style={{}}>{this.translate("w_Sign_Me_Up_")}</p>
                        </div> */}
                    <button onClick={this._register.bind(this)} style={styles.button}>
                        {this.translate('w_Sign_Me_Up_')}
                    </button>
                    <button onClick={() => this.props.history.push("/")} style={styles.button}>
                        {this.translate('w_cancel')}
                    </button>
                    {/* <div className="button"
                            onClick={() => { this.props.history.push("/") }}
                        >
                            <p >{this.translate("w_cancel")}</p>
                        </div> */}

                    {/* </div> */}

                    <div
                        style={{ marginTop: 1 }}
                        onClick={() => { this.props.history.push("/") }}
                    >
                        <div style={{ textAlign: 'center', marginTop: 1, fontSize: 16, display: 'flex', flexWrap: 'wrap' }}>
                            <p>
                                {this.translate("w_Already_Have_An_Account_")}
                            </p>&nbsp;
                            <p style={{ color: '#4A90E2' }}>
                                {this.translate("w_signin")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    _phoneChange(text) {
        text = text.replace(/\D/g, '');
        if (text.length > 0 && text.length < 4) {
            text = '(' + text;
        }
        else if (text.length >= 4 && text.length < 7) {
            text = '(' + text.substring(0, 3) + ') ' + text.substring(3);
        }
        else if (text.length >= 7) {
            text = '(' + text.substring(0, 3) + ') ' + text.substring(3, 6) + '-' + text.substring(6);
        }
        this.setState({ register_phone: text })
    }
    _renderRegisterAccessCode() {
        let { access_code, access_code_ok, BirthYear } = this.state;
        return (
            <div className="conatiner" style={styles.borderContainer}>
                <div style={{ textAlign: 'center', marginTop: 10, fontSize: 18, }}>
                    {this.translate("w_Please_Participant_ID_your_account_1")}
                </div>
                <div style={styles.box}>
                    <input
                        type="text"
                        style={access_code_ok ? { marginBottom: 5, marginTop: 5, } : { marginBottom: 5, marginTop: 5, borderWidth: 1, borderColor: '#f00', borderStyle: 'solid' }}
                        placeholder={access_code_ok ? this.translate('w_ParticipantID') : this.translate('w_Please_Try_Again')}
                        value={access_code}
                        onChange={(e) => {
                            this.setState({ access_code: e.target.value });
                        }}
                        className="input1"
                    />
                </div>
                <input
                    type="text"
                    style={{ marginBottom: 5, marginTop: 5 }}
                    placeholder={this.translate('w_BirthYear')}

                    value={BirthYear}
                    onChange={(e) => {
                        this.setState({ BirthYear: e.target.value });
                    }}
                    className="input1"
                />

                <button onClick={this._verifyAccessCode.bind(this)} style={styles.button}>
                    {this.translate('w_Register')}
                </button>
                <button onClick={() => this.props.history.push("/")} style={styles.button}>
                    {this.translate('w_Already_Registered_Sign_In')}
                </button>

                <div style={{ textAlign: 'center', marginTop: 10, fontSize: 18, display: 'flex', flexWrap: 'wrap' }} >
                    <p style={{ textAlign: 'center', marginTop: 10, fontSize: 18, }}>{this.translate('w_Don_have_a')}</p>
                    &nbsp;<p onClick={() => alert(this.translate('w_Please_study_team_to_issue_ParticipantID'))} style={{ textAlign: 'center', marginTop: 10, fontSize: 18, color: '#4A90E2' }}>
                        {this.translate('w_Participant_ID_')}</p>
                </div>
            </div>)
    }
    render() {
        let { access_code, access_code_ok, BirthYear } = this.state;
        return (
            <div className="conatiner">
                {
                    this.state.access_code_verify_ok ? <div style={{ margin: 30 }}>&nbsp;</div> : <img style={{ margin: 30,height:120 }} src={Drexel_Vertical_stacked_Lockup_HEX} alt="" />
                }
                {
                    this.state.access_code_verify_ok ? this._renderRegisterDetail() : this._renderRegisterAccessCode()
                }

                {/*<div>
                    <img style={{ margin: 10 }} src={logo_myownmed} alt="" />
                </div>*/}

                {
                    this.state.loading && <Loading />
                }
            </div>
        );


    }
}
const styles = {
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',

    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5,
        marginBottom: 50,
    },
    borderContainer1: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    button: {
        backgroundColor: "#123b6d",
        alignSelf: "stretch",
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
        marginBottom: 10,
        color: "#fff",
        height: 48,
        border: 0,
        borderRadius: 3,
        fontSize: 18
    }
};