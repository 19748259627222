import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import TabBarContainer from '../../components/TabBarContainer';
import httpUtil from '../../utils/HttpUtil';
import ReactDOM from 'react-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';
import journay_logo from '../../content/images/track-journay.png';
import star_logo from '../../content/images/star.png';
import Loading from '../../components/Loading';
import CacheUtil from '../../utils/CacheUtil';
import ModalContainer from '../../components/ModalContainer';
import HttpUtil from '../../utils/HttpUtil';
import ScrollView from '../../components/ScrollView';
import TrkHowIsToday from './trackcompment/TrkHowIsToday';
import common from './../../Common';
import TrkCovidExposure from './trackcompment/TrkCovidExposure';
import TrkSelfCertification from './trackcompment/TrkSelfCertification';
import CommonUtil from '../../utils/CommonUtil';
export default class TrackView extends BaseComponent {

  constructor(props) {
    super(props);
    var showSummary = false;
    this.state = {
      picking: false,
      lastTrk: null,
      loading: false,
      howIsToday: -1,
      showSummary:this.props.location.state && this.props.location.state.viewSummary,
      haveTracked: false,
      featuredArticles: [],
      showNoSymptoms: false,
      showSelfCertification: false,
      internationalTravelinPast14D: null,
      WhereTravelled: '',
      contactwithCOVID: null,
      base64img: null,
      showDone: null,
      bShowCheckInPopUp:false,
      need_alert_survey:null,
      quizAlertShow:false
    };
    this.onShowSummary = this.onShowSummary.bind(this);
  }

  componentDidMount() {
    // this.NotificationListener = RCTDeviceEventEmitter.addListener('Notification_ACTION', (value) => {
    //   if (value.action == 'action.checkin') {
    //     this.onStartPicker();
    //   }
    // });
    // this.ChangeProfileListener = RCTDeviceEventEmitter.addListener('change_profile_event', (value) => {
    //   this.getLastTrk();
    // });
    // GoogleAnalyticsManager.trackScreenView('Track');
    this.getLastTrk();
    this.getFeaturedArticles();
    this.exitTracking();
    this._alertQuiz();
    //this.setState({ showSummary: true});
    //this.onShowSummary(true);
  }

  componentWillUnmount() {
    // this.NotificationListener && this.NotificationListener.remove();
    // this.ChangeProfileListener && this.ChangeProfileListener.remove();
  }
  
  componentWillReceiveProps(newProps) {    
    //this.onShowSummary(true);
  }

  getLastTrk() {
    let{bShowCheckInPopUp} = this.state;
    // Profile.getAutoCheckIn().then((autoCheckIn) => {
    //var picking = autoCheckIn || false;
    var picking = false;
    let profile = JSON.parse(localStorage.getItem('profile'));
    httpUtil.get('/api/TrkRSV/GetLatestTrkRSV3?profileId=' + profile.id,
    (responseData) => {
      if (responseData) {
        if (responseData.status == 1) {        
          picking = false;
          responseData.isToday = true;
          //responseData.isToday = (responseData.day == moment(new Date()).format('YYYY-MM-DDT00:00:00'));
          this.setState({ showSummary: true,lastTrk: responseData, showIndicator: false, picking: picking, haveTracked: true, howIsToday: responseData.howIsToday,loading:false });
          localStorage.setItem('viewSummary',true);
        }
        else {
          this.setState({ showIndicator: false, picking: picking, haveTracked: false, howIsToday: false,loading:false });
        }
      } else {
        this.setState({ showIndicator: false, picking: picking, haveTracked: false, howIsToday: false ,loading:false});
      }
    });
  }

  getFeaturedArticles() {
    // CacheUtil.getFeaturedArticles((responseData) => {
    //   this.setState({ featuredArticles: responseData });
    // });
    // //pre load topics
    // CacheUtil.getTopicBoards((responseData) => { });
  }

  resetTrackItems() {
      common.TrackItems().map((item) => {
        item.show = false;
        item.selected = false;
        item.feverValue = '';
      });
  }

  GetBase64img() {
    // HttpUtil.get('api/QRCode/GetQRCode?ProfileId=' + Profile.ItemValue("id"), null,
    //   (responseData) => {
    //     if (responseData) {
    //       console.log(responseData);
    //       if (responseData.status == "Success") {
    //         let QRCode = JSON.parse(responseData.data);
    //         console.log(QRCode);
    //         this.setState({ base64img: QRCode.QRCodeBase64String, qrDateTimeStart: QRCode.DateTimeStart, qrDateTimeExpired: QRCode.DateTimeExpired });

    //       }
    //     }
    //   }, 'GET'
    // );
  }

  onFinishTracking(howIsToday, TrackItems) {
    let { contactwithCOVID, internationalTravelinPast14D } = this.state;
    this.setState({ showIndicator: true });
    let profile = JSON.parse(localStorage.getItem('profile'));
    let profileType = profile.profileType;
    //let profileType = Profile.profileType();
    let symptomPath = '';
    let riskLevel = '';
    let troubleBreath = false;
    let shortnessOfBreath = false;
    let cough = false;
    let fever = false;
    let lossOfTaste = false;
    let lossOfSmell = false;
    let redEyes = false;
    let vomiting = false;
    let bodyAches = false;
    let selectedCount = 0;
    let params = {};
    params.day = moment(new Date()).format('YYYY-MM-DD');
    params.utcDay = moment(new Date()).format('YYYY-MM-DD');
    params.isToday = true;
    params.profileId = profile.id;
    // params.profileId = Profile.ItemValue("id");
    params.howIsToday = howIsToday;
    params.eligibleForRSV = false;

    if (params.howIsToday != 1) {
      TrackItems.map((item) => {
        if (item.selected == true) {
          selectedCount = selectedCount + 1;
          params[item.key] = true;
          if (item.key == 'fever') {
            fever = item.selected;
            if (item.feverValue) {
              params.feverValue = item.feverValue;
            }
          }
          else if (item.key == 'troubleBreadthing') {
            shortnessOfBreath = item.selected;
            troubleBreath = item.selected;
            params.eligibleForRSV = item.selected;
          }
          else if (item.key == 'cough') {
            cough = item.selected;
          }
          else if (item.key == 'lossofTaste') {
            lossOfTaste = item.selected;
          }
          else if (item.key == 'lossofSmell') {
            lossOfSmell = item.selected;
          }
          else if (item.key == 'redorWateryEyes') {
            redEyes = item.selected;
          }
          else if (item.key == 'bodyAches') {
            bodyAches = item.selected;
          }
          else if (item.key == 'vomiting') {
            vomiting = item.selected;
          }

        }
      });

      // if (profileType == 1 && !shortnessOfBreath) {
      //   symptomPath = 'A1';
      //   riskLevel = 'RED';
      // }      
      // else if (profileType != 1 && !shortnessOfBreath && cough && fever) {
      //   symptomPath = 'B';
      //   riskLevel = 'RED';
      // }
      // else 
      if (profileType != 2 && (fever || (lossOfSmell && lossOfTaste) || cough || bodyAches || vomiting || shortnessOfBreath)) {
        symptomPath = 'C';
        riskLevel = 'COVID';
      }
      // else if ((profileType == 3 || profileType == 4) && !shortnessOfBreath && (fever || (lossOfSmell && lossOfTaste) || redEyes || bodyAches)) {
      //   symptomPath = 'C';
      //   riskLevel = 'YELLOW';
      // }
      // else if (profileType != 1 && !shortnessOfBreath) {
      //   symptomPath = 'D';
      //   riskLevel = 'GREEN';
      // }
      else if (profileType != 2 && (!fever && !(lossOfSmell && lossOfTaste) && !cough && !bodyAches && !vomiting && !shortnessOfBreath)) {
        symptomPath = 'D';
        riskLevel = 'Any';
      }
      else if (profileType == 2 && (fever || (lossOfSmell && lossOfTaste) || cough || bodyAches || vomiting || shortnessOfBreath)) {
        symptomPath = 'E';
        riskLevel = 'COVID';
      }
      else if (profileType == 2 && (!fever && !(lossOfSmell && lossOfTaste) && !cough && !bodyAches && !vomiting && !shortnessOfBreath)) {
        symptomPath = 'F';
        riskLevel = 'Any';
      }
      // else if (profileType == 2 && shortnessOfBreath) {
      //   symptomPath = 'G';
      //   riskLevel = 'GREEN';
      // }
    }
    else {
      if (profileType == 1) {
        // symptomPath = 'A2';
        // riskLevel = 'YELLOW';
        symptomPath = 'A';
        riskLevel = 'None';
      }
      else {
        // symptomPath = 'E';
        // riskLevel = 'GREEN';
        symptomPath = 'B';
        riskLevel = 'None';
      }
    }
    //console.warn(symptomPath)
    //Alert.alert('Warning', '1')
    params.symptomPath = symptomPath;
    params.riskLevel = riskLevel;
    params.contactwithCOVID = contactwithCOVID;
    params.internationalTravelinPast14D = internationalTravelinPast14D;
    this.setState({ loading: true });
    httpUtil.post('/api/TrkRSV/SaveTrkRSV2', params,
      (responseData) => {
        
        if (responseData && responseData.status == 'Success') {
          this.resetTrackItems();
          this.setState({ lastTrk: params, tracking: false, loading: false });
          if (params.howIsToday != 1) {
            this.props.history.push('/trackcomplete',{
              eligible: params.eligibleForRSV, 
              showOnset: true,
              troubleBreath: troubleBreath,
              shortnessOfBreath: shortnessOfBreath,
              fever: fever,
              cough: cough,
              lossOfTaste: lossOfTaste,
              lossOfSmell: lossOfSmell,
              redEyes: redEyes,
              bodyAches: bodyAches,
              symptomPath: symptomPath,
              riskLevel: riskLevel,
              need_alert_survey:'',
              onShowSummary: this.onShowSummary()
            });
            
            // MomEnv.pushPage(Complete, {
            //   eligible: params.eligibleForRSV,
            // showOnset: true,
            // troubleBreath: troubleBreath,
            // shortnessOfBreath: shortnessOfBreath,
            // fever: fever,
            // cough: cough,
            // lossOfTaste: lossOfTaste,
            // lossOfSmell: lossOfSmell,
            // redEyes: redEyes,
            // bodyAches: bodyAches,
            // symptomPath: symptomPath,
            // riskLevel: riskLevel,
            //onShowSummary: this.onShowSummary.bind(this),
            // });
          } else {
            this.getLastTrk();
          }
        } else {
          // Alert.alert(Translation.lang().w_failed, Translation.lang().Save_Failed);
        }
      }
    );
  }

  exitTracking() {
    this.resetTrackItems();
    this.setState({ picking: false });
  }

  onSelectHowToday(howIsToday, picking, useLastSymptom) {
    this.resetTrackItems();

    if (useLastSymptom && this.state.lastTrk) {

      let lastTrk = this.state.lastTrk;
      let TrackItems = common.TrackItems();
      TrackItems.map((item) => {
        if (item.key == 'fever') {
          item.selected = lastTrk.fever;
          item.feverValue = lastTrk.feverValue;
        } else if (item.key == 'longPausesBetweenBreaths') {
          item.selected = lastTrk.longPausesBetweenBreaths;
        } else if (item.key == 'runningorStuffyNose') {
          item.selected = lastTrk.runningorStuffyNose;
        } else if (item.key == 'cough') {
          item.selected = lastTrk.cough;
        } else if (item.key == 'fatigue') {
          item.selected = lastTrk.fatigue;
        } else if (item.key == 'nothimselforherself') {
          item.selected = lastTrk.nothimselforherself;
        } else if (item.key == 'breathingDifforNoise') {
          item.selected = lastTrk.breathingDifforNoise;
        } else if (item.key == 'troubleBreadthing') {
          item.selected = lastTrk.troubleBreadthing;
        } else if (item.key == 'feedingDiff') {
          item.selected = lastTrk.feedingDiff;
        } else if (item.key == 'dehydration') {
          item.selected = lastTrk.dehydration;
        } else if (item.key == 'earacheandDrainage') {
          item.selected = lastTrk.earacheandDrainage;
        } else if (item.key == 'soreThroat') {
          item.selected = lastTrk.soreThroat;
        } else if (item.key == 'skinColorTurnsBlueorPurple') {
          item.selected = lastTrk.skinColorTurnsBlueorPurple;
        } else if (item.key == 'lossofTaste') {
          item.selected = lastTrk.lossofTaste;
        } else if (item.key == 'lossofSmell') {
          item.selected = lastTrk.lossofSmell;
        } else if (item.key == 'redorWateryEyes') {
          item.selected = lastTrk.redorWateryEyes;
        } else if (item.key == 'headache') {
          item.selected = lastTrk.headache;
        } else if (item.key == 'bodyAches') {
          item.selected = lastTrk.bodyAches;
        } else if (item.key == 'vomiting') {
          item.selected = lastTrk.vomiting;
        } else if (item.key == 'diarrhea') {
          item.selected = lastTrk.diarrhea;
        }

      });
    }
    //this.setState({ howIsToday: howIsToday, picking: picking });
    if (howIsToday == 1) {
      this.onFinishTracking(1);
      this.setState({ howIsToday: howIsToday });
    }
    else {
      this.setState({ howIsToday: howIsToday, picking: picking, showSummary: false });
      localStorage.setItem('viewSummary',false);
    }
  }

  onShowSummary(bSummary, cshowNoSymptoms, dshowSelfCertification,bShowCheckInPopUp,ifAlertConfrimCheckIn) {
    bShowCheckInPopUp = bShowCheckInPopUp || false;
    localStorage.setItem('viewSummary',bSummary);
    this.setState({ showSummary: bSummary, showNoSymptoms: cshowNoSymptoms, showSelfCertification: dshowSelfCertification ,bShowCheckInPopUp:bShowCheckInPopUp});
    if (bSummary) {
      this.getLastTrk();
    }
    if(ifAlertConfrimCheckIn){
      this.alertChange(1);
    }
  }

  _alertQuiz() {
    if(localStorage.getItem('survey_count') > 0 && localStorage.getItem("need_alert_survey") == 1){
      this.setState({quizAlertShow:true});
      localStorage.setItem("need_alert_survey",0);
    }
    
    // Profile.getProfile().then((data) => {
    //   if (data.quizCount && data.quizCount > 0) {
    //     Alert.alert(Translation.lang().w_Please_tell_us_more_, Translation.lang().w_need_fillout_survey,
    //       [
    //         {
    //           text: Translation.lang().w_ok, onPress: () => {
    //             //MomEnv.saveStep('home', 'Survey')
    //             MomEnv.pushPage(Survey);
    //           }
    //         },
    //         //{ text: Translation.lang().w_no, onPress: () => { } },
    //       ]);
    //   }
    // });
  }

  onStartPicker() {
    this.resetTrackItems();
    this.setState({ picking: true });
  }

  viewHistory() {
    this.props.history.push('/trackhistory');
    //MomEnv.pushPage(History)
    // MomEnv.saveStep('home', 'History');
    //MomEnv.pushPage(History, {onShowSummary: this.onShowSummary});
  }

  CreateBase64img() {
    // MomEnv.callApi('api/QRCode/CreateQRCode', {
    //   profileId: Profile.ItemValue("id"),
    // },
    //   (responseData) => {
    //     if (responseData) {
    //       if (responseData.status == "Success") {
    //         this.setState({ base64img: responseData.data });

    //       }
    //     }
    //   }
    // );
  }

  viewSurvey() {
    // MomEnv.saveStep('home', 'Survey');
  }
  onshowNoSymptoms(showSymptoms) {
    this.setState({ showNoSymptoms: showSymptoms });
  }

  onshowSelfCertification(showSelfCertification, showSymptoms, showDone) {
    this.setState({ showSelfCertification: showSelfCertification, showNoSymptoms: showSymptoms, showDone: showDone });
  }

  onSetNoSymptomsQuestion(internationalTravelinPast14D, contactwithCOVID, WhereTravelled,callback) {
    this.setState({ internationalTravelinPast14D: internationalTravelinPast14D, contactwithCOVID: contactwithCOVID, WhereTravelled: WhereTravelled },()=>{
      if(callback){
        callback();
      }
    });
  }

  alertChange(i){
    localStorage.setItem('need_alert_survey',i);
    this.setState({need_alert_survey:i},()=>{
      this.setState({need_alert_survey:null})
    })
  }

  render() {

    let { picking, lastTrk, haveTracked, featuredArticles, howIsToday, showSummary, showNoSymptoms, showSelfCertification, showDone,need_alert_survey,quizAlertShow } = this.state;
    return (
      <TabBarContainer tab={'home'} 
        history={this.props.history}
        need_alert_survey={need_alert_survey}>
        {/* <div ></div> */}
        <div className="fill">
          {/* <TrkHeader profileNewMsgs={this.props.profileNewMsgs} wikiCount={this.props.wikiCount} /> */}
          {/* {
          !picking &&
          <Image
            style={{ width: width, height: 125, position: 'absolute', bottom: Platform.OS === 'ios' ? 40 : 0, }}
            resizeMode={'cover'}
            source={require('../../../assets/crocus/wave_bg.png')} />
        } */}
          <ScrollView style={styles.content} >
            {
              !picking
              // && lastTrk && lastTrk.howIsToday == 3 && lastTrk.isToday 
              && !showNoSymptoms && !showSelfCertification &&
              <TrkHowIsToday
                history={this.props.history}
                lastTrk={lastTrk}
                haveTracked={haveTracked}
                howIsToday={howIsToday}
                showSummary={showSummary}
                featuredArticles={featuredArticles}
                onSelectHowToday={this.onSelectHowToday.bind(this)}
                onShowSummary={this.onShowSummary.bind(this)}
                viewHistory={this.viewHistory.bind(this)}
                viewSurvey={this.viewSurvey.bind(this)}
                onFinishTracking={this.onFinishTracking.bind(this, 3)}
                exit={this.exitTracking.bind(this)}
                onshowNoSymptoms={this.onshowNoSymptoms.bind(this)}
                onshowSelfCertification={this.onshowSelfCertification.bind(this)}
              />
            }
            {
            showNoSymptoms &&
            !showSelfCertification &&
            <TrkCovidExposure
              lastTrk={lastTrk}
              haveTracked={haveTracked}
              howIsToday={howIsToday}
              showSummary={showSummary}
              featuredArticles={featuredArticles}
              onSelectHowToday={this.onSelectHowToday.bind(this)}
              onShowSummary={this.onShowSummary.bind(this)}
              viewHistory={this.viewHistory.bind(this)}
              viewSurvey={this.viewSurvey.bind(this)}
              onFinishTracking={this.onFinishTracking.bind(this, 3)}
              exit={this.exitTracking.bind(this)}
              onshowNoSymptoms={this.onshowNoSymptoms.bind(this)}
              onSetNoSymptomsQuestion={this.onSetNoSymptomsQuestion.bind(this)}
              onshowSelfCertification={this.onshowSelfCertification.bind(this)}
            />
          }
          {
            !showNoSymptoms &&
            showSelfCertification &&
            <TrkSelfCertification
              lastTrk={lastTrk}
              haveTracked={haveTracked}
              howIsToday={howIsToday}
              showSummary={showSummary}
              featuredArticles={featuredArticles}
              showDone={showDone}
              onSelectHowToday={this.onSelectHowToday.bind(this)}
              onShowSummary={this.onShowSummary.bind(this)}
              viewHistory={this.viewHistory.bind(this)}
              viewSurvey={this.viewSurvey.bind(this)}
              onFinishTracking={this.onFinishTracking.bind(this, 3)}
              exit={this.exitTracking.bind(this)}
              onshowNoSymptoms={this.onshowNoSymptoms.bind(this)}
              onshowSelfCertification={this.onshowSelfCertification.bind(this)}
            />
          }
            {/* {
            !picking && !(lastTrk && lastTrk.howIsToday == 1 && lastTrk.isToday) &&
            <TrkTodayAllGood
              onStartPicker={this.onStartPicker.bind(this)}
              viewHistory={this.viewHistory.bind(this)} />
          } */}
            {/* {
            picking &&
            <TrkPickerSymptoms
              onFinishTracking={this.onFinishTracking.bind(this, 3)}
              exit={this.exitTracking.bind(this)} />
          } */}
              {quizAlertShow && 
                    <ModalContainer>
                        <div className="alert_title">
                            {this.translate('w_Please_tell_us_more_')}
                        </div>
                        <div className="alert_content">
                            {this.translate('w_need_fillout_survey')}
                        </div>
                        <div
                            className="alert_button"
                            onClick={() => {
                                this.setState({quizAlertShow:false});
                                this.props.history.push("/survey");
                            }}
                        >
                            {this.translate('w_ok')}
                        </div>
                    </ModalContainer>
                }
          </ScrollView>
          {this.state.loading && <Loading />}
          {/* {
          this.state.showIndicator &&
          <ActivityIndicator
            animating={true}
            style={globalStyles.overlay}
            size="large"
          />
        } */}
          {/* <KeyboardSpacer /> */}
          {/* <InputAccessory extHeight={-20} /> */}
          {/* {
          !(this.props.first && this.props.helpStep < 4) && (profiles == null || profiles.length == 0) &&
          <View
            style={{
              position: 'absolute',
              backgroundColor: '#000000bb',
              top: 60,
              left: 0,
              width: width,
              height: height - 60,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <View style={{
              margin: 40,
              marginBottom: 120,
              backgroundColor: '#fff',
              padding: 15,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 10
            }}>
              <Text style={[globalStyles.boldFont, { margin: 10, textAlign: 'center' }]}>
                {'Add Child Profile'}
              </Text>
              <Text style={[globalStyles.normalFont, { margin: 10, textAlign: 'center' }]}>
                {"Add a new child profile when you are ready to track your child's symptoms.\n\n Go to Resources to learn more information about RSV."}
              </Text>
            </View>
          </View>
        } */}
        </div>
      </TabBarContainer>
    );
  }
}


const styles = {
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    // marginTop: Platform.OS == 'ios' ? (height > 800 ? 20 : 0) : 0,
  },
  content: {
    flex: 1,
    alignSelf: 'stretch',
    width: CommonUtil.clientWidth(),
    height: CommonUtil.clientHeight() - 210
  }
};