import React from "react";
import BaseComponent from "../../../components/BaseComponent";
import NavBar from "../../../components/NavBar.js";
import introductionAlbum from "../../../content/images/photo/introductionAlbum.png";
import introductionPhoto from "../../../content/images/photo/introductionPhoto.png";
import Loading from "../../../components/Loading";
import httpUtil from "../../../utils/HttpUtil";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddPhotoAndImage from "./AddPhotoAndImage";
import ModalContainer from '../../../components/ModalContainer';

const CustomDatePickerInput = ({ value, onClick }) => (
  <button
    className="custom_date_picker_input"
    style={styles.datePickerButton}
    onClick={onClick}
  >
    {value}
  </button>
);

export default class AddVaccineRecord extends BaseComponent {
  constructor(props) {
    super(props);
    let editVaccineRecord = this.props.location.state;
    var photo = "";
    var attachment;
    var vaccineDate = new Date();
    var lotNumber = "";
    var vaccineTypeId = "";
    var vaccineTypeLabel = "";
    var id = 0;
    console.log(editVaccineRecord);
    if (editVaccineRecord) {
      if (editVaccineRecord.attachment) {
        if (editVaccineRecord.attachment.fileBase64) {
          photo = editVaccineRecord.attachment.fileBase64;
        } else {
          photo = editVaccineRecord.attachment.file.fileBits;
        }

        attachment = editVaccineRecord.attachment;
      }
      lotNumber = editVaccineRecord.lotNumber;
      vaccineTypeId = editVaccineRecord.vaccineTypeId;
      vaccineTypeLabel = editVaccineRecord.vaccineTypeLabel;

      var year = editVaccineRecord.vaccineDate.substring(0, 4);
      var month = editVaccineRecord.vaccineDate.substring(5, 7);
      var day = editVaccineRecord.vaccineDate.substring(8, 10);

      vaccineDate = new Date(year, month - 1, day);
      id = editVaccineRecord.id;
    }

    this.state = {
      VaccineTypes: [],
      id: id,
      vaccineDate: moment(vaccineDate).format("YYYY-MM-DD"),
      results: [],
      optionsLoaded: false,
      vaccineTypeId: vaccineTypeId,
      vaccineTypeLabel: vaccineTypeLabel,
      lotNumber: lotNumber,
      image: photo,
      showIndicator: false,
      attachment: attachment,
      bSave:0,
    };
  }

  componentDidMount() {
    this._loadVaccineRecords(!this.props.vaccineRecord);
  }

  _loadVaccineRecords(isNewRecord) {
    httpUtil.get(
      "/api/VaccineRecords/QueryVaccineTypes" +
        (isNewRecord ? "Available" : ""),
      (responseData) => {
        this.setState({
          VaccineTypes: responseData ? responseData : [],
          optionsLoaded: true,
        });
      },
      "GET"
    );
  }

  _goBack() {
    this.props.history.push("/home/more/vaccinerecords");
  }

  _saveVaccineRecord() {
    let vaccineRecord = {};
    vaccineRecord.id = this.state.id;
    vaccineRecord.vaccineTypeId = this.state.vaccineTypeId;
    vaccineRecord.lotNumber = this.state.lotNumber;
    vaccineRecord.vaccineDate = moment(this.state.vaccineDate).format(
      "YYYY-MM-DD"
    );
    if (this.state.attachment) {
      vaccineRecord.attachment = this.state.attachment;
    }
    let url = "";
    if (vaccineRecord.id === 0) {
      url = "/api/VaccineRecords/SaveEntry";
    } else {
      url = "/api/VaccineRecords/UpdateEntry";
    }
    console.log(vaccineRecord);
    this.setState({ showIndicator: true });
    httpUtil.post(url, vaccineRecord, (responseData) => {
      if (responseData) {  
        this.setState({ bSave: 1 });
        this.setState({ showIndicator: false });
        // this._goBack();
      }
      else  
      {
        this.setState({ bSave: 0 });
      }
    });
  }

  _updateVaccineRecordAttachments(attachments) {
    if (attachments && attachments.length > 0) {
      this.setState({
        attachment: attachments[0],
        image: attachments[0].file.fileBits,
      });
    }
  }

  render() {
    let {
      id,
      image,
      attachment,
      showIndicator,
      vaccineDate,
      vaccineTypeId,
      vaccineTypeLabel,
      lotNumber,
      VaccineTypes,
      optionsLoaded,
    } = this.state;
    let DateNow = new Date();
    let DateSixmonthsago = new Date(2020, 2, 1); //DateNow.setMonth(DateNow.getMonth() - 6);
    let ableSave = vaccineTypeId && vaccineDate;

    const isEdit = id > 0;
    const pageTitle = this.translate(
      isEdit ? "w_modify_Vaccine_Record" : "w_add_Vaccine_Record"
    );
    const vaccineTypeOptions = VaccineTypes.map((o) => (
      <option key={o.id} value={o.id}>
        {o.label}
      </option>
    ));
    vaccineTypeOptions.unshift(
      <option key="0" value="">
        --select--
      </option>
    );
    return (
      <div className="conatiner" style={{ justifyContent: "flex-start" }}>
        <NavBar
          title={pageTitle}
          backurl={"/home/more/vaccinerecords"}
          history={this.props.history}
        />
        <div
          className="conatiner"
          style={{
            overflowY: "auto",
            width: "100%",
            overflowX: "hidden",
            justifyContent: "end",
          }}
        >
          <div style={styles.divContent}>
            <label style={styles.labelText}>
              {this.translate("w_Vaccine_Type")}
            </label>
            {optionsLoaded && !isEdit && (
              <select
                value={vaccineTypeId}
                style={styles.selectPickers}
                onChange={(item) => {
                  var value =
                    item.target.options[item.target.selectedIndex].value;
                  if (value !== vaccineTypeId) {
                    this.setState({ vaccineTypeId: value });
                  }
                }}
              >
                {vaccineTypeOptions}
              </select>
            )}
            {isEdit && (
              <span style={styles.readonlyText}>{vaccineTypeLabel}</span>
            )}
          </div>
          <div style={styles.divContent}>
            <label style={styles.labelText}>
              {this.translate("w_Vaccine_Date")}
            </label>
            <div
              key="t6"
              className="date_picker_container"
              style={{
                minHeight: 48,
                justifyContent: "start",
                marginLeft: 5,
                fontSize: 18,
              }}
            >
              <DatePicker
                showPopperArrow={false}
                dateFormat="MM/dd/yyyy"
                minDate={DateSixmonthsago}
                maxDate={new Date()}
                selected={
                  vaccineDate && vaccineDate !== ""
                    ? moment(vaccineDate, "YYYY-MM-DD").toDate()
                    : new Date()
                }
                onChange={(date) => {
                  this.setState({
                    vaccineDate: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                customInput={<CustomDatePickerInput />}
              />
            </div>
          </div>
          <div style={styles.divContent}>
            <label style={styles.labelText}>
              {this.translate("w_vaccine_lot_number")}
            </label>
            <input
              className="input1"
              type="text"
              style={{
                marginTop: 2,
                width: "95%",
                marginLeft: 10,
                textAlign: "start",
                fontSize: 18,
              }}
              value={lotNumber || ""}
              onChange={(e) => this.setState({ lotNumber: e.target.value })}
            />
          </div>
          <div style={styles.divContent}>
            <label style={styles.labelText}>
              {this.translate("w_Add_a_picture_of_your_record")}
            </label>
            <AddPhotoAndImage
              image={image}
              labResultAttachments={attachment ? [attachment] : []}
              updateLabResultAttachments={this._updateVaccineRecordAttachments.bind(
                this
              )}
            ></AddPhotoAndImage>
          </div>
          <div style={styles.divContent}>
            <button
              className="button"
              key="selectDate"
              style={{
                marginTop: 20,
                marginBottom: 20,
                backgroundColor: ableSave ? "#123b6d" : "#CCCC",
                width: "90%",
                cursor: ableSave ? "pointer" : "no-drop",
              }}
              onClick={() => {
                if (ableSave) {
                  this._saveVaccineRecord();
                }
              }}
            >
              {this.translate("w_save")}
            </button>
          </div>
        </div>

        {showIndicator && <Loading />} 
                {
                    this.state.bSave == "1" &&
                    <ModalContainer>
                      <div className="alert_title">
                          {this.translate('VaccineUploadTitle')}
                      </div>
                      <div className="alert_content">
                          {this.translate('VaccineUploadContent')}
                      </div>
                      <div className="alert_button" onClick={() => {
                                this._goBack();
                                //this.setState({ need_alert_survey: 2 });
                            }}>
                          {this.translate('w_ok')}
                      </div> 
                   </ModalContainer>
                }
      </div>
    );
  }
}

var styles = {
  cameraBtn: {
    margin: "0px 20px 20px",
    width: 44,
    height: 34,
    border: 0,
    float: "left",
    background: "white",
    backgroundImage: "url(" + introductionPhoto + ")",
  },
  uploadBtn: {
    margin: "0px 20px 20px",
    width: 35,
    height: 34,
    border: 0,
    float: "left",
    background: "white",
    backgroundImage: "url(" + introductionAlbum + ")",
  },
  container: {
    flex: 1,
    alignItems: "center",
  },

  divContent: {
    width: "100%",
    display: "block",
  },

  selectPickers: {
    fontSize: 18,
    height: 40,
    width: "91%",
    marginLeft: 10,
    borderWidth: "0px 0px 2px",
    borderColor: "#ddd",
  },

  datePicker: {
    marginTop: 0,
  },

  datePickerButton: {
    textAlign: "start",
    padding: 10,
    borderColor: "#999",
    borderWidth: 0,
    borderStyle: "solid",
    minWidth: 250,
    borderRadius: 0,
    height: 48,
  },

  datePickerText: {
    color: "#000000",
    textAlign: "left",
  },

  picker: {
    justifyContent: "space-around",
    flex: 1,
    width: "85%",
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "rgb(238,238,238)",
  },

  labelText: {
    width: "100",
    display: "block",
    margin: 10,
    fontSize: 18,
  },

  readonlyText: {
    width: "100",
    display: "block",
    margin: 10,
    fontSize: 18,
    backgroundColor: "#eeeeee",
    border: 0,
    borderRadius: 3,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 3,
    paddingRight: 3,
  },

  TextInputview: {
    marginTop: 10,
    width: "85%",
    height: 45,
    marginLeft: 15,
    marginRight: 15,
    textAlign: "left",
  },
};
