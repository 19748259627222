import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import NavBar from '../../components/NavBar';
import checked from '../../content/images/more/checked.png';
import i18n from 'i18next';
import httpUtil from '../../utils/HttpUtil';

export default class SettingLanguage extends BaseComponent {
    constructor(props) {
        let checkLanguage = i18n.language;
        super(props);
        this.state = {
            checkLanguage: checkLanguage
        };
    }

    checkLanguageEnglish() {
        this.setState({
            checkLanguage: 'en'
        });
    }

    checkLanguageDutch() {
        this.setState({
            checkLanguage: 'es'
        });
    }

    saveAndContinue() {
        /*i18n.changeLanguage(this.state.checkLanguage);
        localStorage.setItem("cultureName",this.state.checkLanguage);
        let language = this.state.checkLanguage == 'en' ? 'English' : 'Spanish';
        httpUtil.post('/api/Profile/UpdateCulture', { culture: language },
            (responseData) => {
                if (responseData.status == 'Success') {
                    window.history.back();
                }
            }
        );*/
        window.history.back();
    }

    render() {
        return (
            <div className="fill">
                <NavBar title={this.translate('w_language')} history={this.props.history} />
                <div
                    style={{
                        alignSelf: 'stretch',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 20
                    }}
                    onClick={this.checkLanguageEnglish.bind(this)}
                >

                    <div style={{ marginLeft: 10, fontSize: 18 }}>
                        {this.translate('w_english')}
                    </div>
                    {this.state.checkLanguage == 'en' ?
                        <img src={checked} style={{ width: 24, height: 24, marginRight: 10 }} resizemode={'contain'} />
                        : ''
                    }
                </div>
                <button className="button"
                    onClick={this.saveAndContinue.bind(this)}>{this.translate('w_save')}</button>
            </div>
        );
    }
}
