import React, { Component } from 'react';
import httpUtil from '../../utils/HttpUtil.js';
import moment from 'moment';
import Loading from './../../components/Loading.js';
import TabBarContainer from '../../components/TabBarContainer.js';
import Radio from './../../components/Radio.js';
import i18n from 'i18next';
var emailing = false;

const childcounts = [0, 1, 2, 3, 4];
const months = [
    { name: 'January', display: 'Jan', value: 1 },
    { name: 'February', display: 'Feb', value: 2 },
    { name: 'March', display: 'Mar', value: 3 },
    { name: 'April', display: 'Apr', value: 4 },
    { name: 'May', display: 'May', value: 5 },
    { name: 'June', display: 'Jun', value: 6 },
    { name: 'July', display: 'Jul', value: 7 },
    { name: 'August', display: 'Aug', value: 8 },
    { name: 'September', display: 'Sep', value: 9 },
    { name: 'October', display: 'Oct', value: 10 },
    { name: 'November', display: 'Nov', value: 11 },
    { name: 'December', display: 'Dec', value: 12 }
];
//const years = [2019, 2018, 2017, 2016];
const terms = ['Yes', 'No'];
const hepatitiOptions = ['Yes', 'No'];
const travels = ['Yes, I am willing to travel to this site.', 'No thanks, I am not willing to travel to this site.'];


export default class Questionnaire extends Component {


    constructor(props) {
        super(props);
        let currentYear = moment().year();
        let years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
        this.state = {
            loading: false,
            zipcode: '',
            zipcodeErr: '',
            had36monthchildren: -1,
            hepatitis: -1,
            hadimmunocompromised: -1,
            birthdate: null,
            showchildcounts: false,
            showmonths: false,
            showyears: false,
            showHepatitis: false,
            chlidcount: -1,
            childs: [],
            studentTypeItems: [],
            athleteTypeItems: [],
            cIndex: -1,
            qualified: true,
            locationId: null,
            locationName: '',
            locationAddress: '',
            locationCity: '',
            locationState: '',
            locationZipCode: '',
            verifyZipCodeOk: false,
            showTravels: false,
            travelOk: false,
            selectTravel: '',
            willQuit: false,
            years: years,
            positionType: null,
            studentType: null,
            athleticType: null,
            age: '',
            internationalTravelinPast14D: null,
            healthCondition: '',
            houseHoldMembersConditions: '',
            hadContactConfirmedCase: false,    
            hasClicked: false, 
            hasLoadedStudentTypeItems: false,
            hasLoadedAthleteTypeItems: false,
        };

        let data = this.props.location.state;
        console.log('Data in Questionnaire');
        console.log(data)
    }

    
    translate(key) {
        return i18n.t(key);
    }

    componentDidMount(){
        this.getStudentTypes();
        this.getAthleteTypes();
        localStorage.setItem('pageWithoutToken',true);
    }

    componentWillMount() {
        //this.getStudentTypes();
        //this.getAthleteTypes();
    }

    getStudentTypes() {
        //this.setState({loading:true});
        httpUtil.get('/api/StudentType/GetStudentTypes',
            (responseData) => {
                if (responseData) {
                    this.setState({ studentTypeItems: responseData, hasLoadedStudentTypeItems: true});
                }
            });
    }

    getAthleteTypes() {
        //this.setState({loading:true});
        httpUtil.get('/api/AthleticTeams/GetAthleticTeams',
            (responseData) => {
                //console.log('getAthleteTypes');
                //console.log(responseData);
                if (responseData) {
                    this.setState({ athleteTypeItems: responseData, hasLoadedAthleteTypeItems: true});
                }
            });
    }
 
    next(qualified) {
        if (qualified) {          
           let { zipcode,
                had36monthchildren,
                hepatitis,
                hadimmunocompromised,
                birthdate,
                childs,
                chlidcount,
                age,
                positionType,
                studentType,
                athleticType,
                healthCondition,
                internationalTravelinPast14D,
                hadContactConfirmedCase,
                hasClicked,
            } = this.state;
            if(hasClicked){
                return;
            }  
            this.setState({hasClicked:true});
            var locationState = this.props.location.state;
            let rmodel = this.props.location.state.rmodel;
            let qualifiedChilds = [];
            if (rmodel == null || rmodel.chlids == null) {
                qualifiedChilds.push(
                    {
                        name: locationState.firstName,
                    }
                );
                rmodel = { chlids: qualifiedChilds };
            }
            
            httpUtil.post('/api/PreScreener/UpdatePreScreener', {
                Email: locationState.email,
                ContactFirstName: locationState.firstName || '',
                ContactLastName: locationState.lastName || '',
                ContactPhone: locationState.phone ? locationState.phone.replace(/\D/g, ''):'',
                positionType: positionType || null,
                studentType: studentType || null,
                athleticType: athleticType || null,
                age: age || null,
                healthCondition: healthCondition || null,
                hadContactConfirmedCase: hadContactConfirmedCase == 1,
            },
                (responseData) => {
                    if (responseData && responseData.status == 'Success') {
                    } else {
                    }
                }
            );

            let param = {
                email: locationState.email, //? locationState.email : locationState.rmodel.email,
                //had36monthchildren: locationState.had36monthchildren,
                //hepatitis: locationState.hepatitis,
                //hadimmunocompromised: locationState.hadimmunocompromised,
                //chlidcount: qualifiedChilds.length,
                //chlids: qualifiedChilds,//locationState.childs,
                //locationId: locationState.locationId,
                //locationName: locationState.locationName,
                //locationAddress: locationState.locationAddress,
                zipCode: zipcode, //? this.state.zipcode : locationState.rmodel.zipCode,
                firstName: locationState.firstName, //? locationState.firstName : locationState.rmodel.firstName,
                lastName: locationState.lastName, //? locationState.lastName :  locationState.rmodel.lastName,
                phone: locationState.phone ? locationState.phone.replace(/\D/g, '') : '',//locationState.rmodel.phoneNumber.replace(/\D/g, ''),
                positionType: positionType || null, //? this.state.positionType : locationState.rmodel.positionType,
                studentType : studentType || null, //? this.state.studentType : locationState.rmodel.studentType,
                athleticType: athleticType || null, //? this.state.athleticType : locationState.rmodel.athleticType,
                age: age || null, //? this.state.age : locationState.rmodel.age,
                healthCondition: healthCondition,
                hadContactConfirmedCase: hadContactConfirmedCase == 1,
                fAvalidated:locationState.fAvalidated || false, //? locationState.fAvalidated : locationState.rmodel.fAvalidated,
                clientConsent: locationState.clientConsent
            };
            console.log('register param');
            console.log(param);
            httpUtil.post('/api/Participant/Register', param,
            (responseData) => {
                //console.log('register response');
                //console.log(responseData);
                this.autoLogin(param);
                if (responseData && responseData.status == 'Success') {
                     //var profile = JSON.parse(localStorage.getItem('profile'));
                     console.log('register success')
                     this.autoLogin(param);
                     this.setState({loading:false});                                                          
                } else {
                      this.setState({loading:false});
                       alert(
                       //Translation.lang().w_failed, 
                       this.translate("w_Failed_Register"));
                }
             });
            // this.props.history.push('/pin',
                // {
                    // rmodel: rmodel,
                    // email: locationState.email, //? locationState.email : locationState.rmodel.email,
                    // had36monthchildren: locationState.had36monthchildren,
                    // hepatitis: locationState.hepatitis,
                    // hadimmunocompromised: locationState.hadimmunocompromised,
                    // chlidcount: qualifiedChilds.length,
                    // childs: qualifiedChilds,//locationState.childs,
                    // locationId: locationState.locationId,
                    // locationName: locationState.locationName,
                    // locationAddress: locationState.locationAddress,
                    // zipcode: zipcode, //? this.state.zipcode : locationState.rmodel.zipCode,
                    // firstName: locationState.firstName, //? locationState.firstName : locationState.rmodel.firstName,
                    // lastName: locationState.lastName, //? locationState.lastName :  locationState.rmodel.lastName,
                    // phone: locationState.phone ? locationState.phone.replace(/\D/g, '') : '',//locationState.rmodel.phoneNumber.replace(/\D/g, ''),
                    // positionType: positionType || null, //? this.state.positionType : locationState.rmodel.positionType,
                    // studentType : studentType || null, //? this.state.studentType : locationState.rmodel.studentType,
                    // athleticType: athleticType || null, //? this.state.athleticType : locationState.rmodel.athleticType,
                    // age: age || null, //? this.state.age : locationState.rmodel.age,
                    // healthCondition: healthCondition,
                    // hadContactConfirmedCase: hadContactConfirmedCase == 1,
                    // fAvalidated:locationState.fAvalidated ? locationState.fAvalidated : locationState.rmodel.fAvalidated,
                    // clientConsent: locationState.clientConsent
                // });
        }
        else {
            this.setState({ qualified: false });
        }
    }

    autoLogin(data){
        var profile = JSON.parse(localStorage.getItem('profile'));
        if (!profile) {
          profile = {};
        }
        var email = data.email;
        profile.email = email;   
        localStorage.setItem('profile', JSON.stringify(profile));
        this.getAppStatus(() => {
        this.getProfile();  
        });
    }

    getProfile() {
      httpUtil.get('/api/Profile/GetMine',
      (result) => {
          if (result && result.id) {
            httpUtil.get('/api/TrkRSV/GetLatestTrkRSV3?profileId=' + result.id,
              (responseData) => {
                //result.password =  localStorage.getItem("pin");                  
                var latestTrkRSV3 = {};
                var viewSummary = false;
                if (responseData) {
                  if (responseData.status == 1) {                     
                    latestTrkRSV3.latestTrkRSV3Status = 1;
                    latestTrkRSV3.responseData = responseData;
                    viewSummary = true;
                    localStorage.setItem('viewSummary',true);
                  }
                }
                result.latestTrkRSV3 = latestTrkRSV3;
                localStorage.setItem('profile', JSON.stringify(result));
                if (result.cultureName) {
                  if (result.cultureName == 'en' || result.cultureName == 'en-US') {
                      i18n.changeLanguage('en');
                      localStorage.setItem("cultureName", "en");
                  }
                  else if (result.cultureName == 'nl' || result.cultureName == 'nl-NL') {
                      i18n.changeLanguage('es');
                      localStorage.setItem("cultureName", "es");
                  }
                }
                if (result.isTempPassword == false) {
                    localStorage.setItem("need_alert_survey", "1");
                    this.props.history.push('/trackmain', { viewSummary: viewSummary });
                }
                else {
                    this.props.history.push('/trackmain', { viewSummary: viewSummary });
                }
            });               
          } else {
              localStorage.removeItem('token');
              this.setState({ loading: false });
          }
      });
    }

    getAppStatus(callback) {
        httpUtil.get('/api/Profile/GetAppStatus', (appStatus) => {
          if (appStatus && appStatus.hasOwnProperty('numNewMsg')) {
            localStorage.setItem('msg_count', JSON.stringify(appStatus.numNewMsg));
            localStorage.setItem(
              'survey_count',
              JSON.stringify(appStatus.numNewSurvey)
            );
            localStorage.setItem(
              'wiki_count',
              JSON.stringify(appStatus.numNewWiki)
            );
          }
          callback && callback();
        }, this);
      }

    selectchlidcount(count) {
        let childs = [];
        for (var i = 0; i < count; i++) {
            childs.push({ month: '', year: '', term: '', monthsage: -1, term: '', hepatitis: '', agree: false });
        }
        this.setState({ childs: childs, chlidcount: count, qualified: count > 0 });
    }

    selectmonth(month) {
        let { cIndex, childs } = this.state;
        childs[cIndex].month = month.value;
        if (childs[cIndex].year) {
            let bornDate = moment().set('year', childs[cIndex].year).set('month', childs[cIndex].month - 1);
            childs[cIndex].monthsage = moment().diff(bornDate, 'months');
        }
        this.setState({ childs: childs });
    }

    selectyear(year) {
        let { cIndex, childs } = this.state;
        childs[cIndex].year = year;
        if (childs[cIndex].month) {
            let bornDate = moment().set('year', childs[cIndex].year).set('month', childs[cIndex].month - 1);
            childs[cIndex].monthsage = moment().diff(bornDate, 'months');
        }
        this.setState({ childs: childs });
    }

    selectterm(term) {
        let { cIndex, childs } = this.state;
        childs[cIndex].term = term;
        this.setState({ childs: childs });
    }

    selecthepatitis(hepatitis) {
        let { cIndex, childs } = this.state;
        childs[cIndex].hepatitis = hepatitis;
        this.setState({ childs: childs });
    }

    selecttravel(travel) {
        if (travel.indexOf('Yes') == 0) {
            this.setState({ travelOk: true, selectTravel: travel });
        } else {
            this.setState({ qualified: false, selectTravel: travel });
        }
    }

    onAgeChange(p) {
        let age = p.target.value.toString().replace(/\D/g, '');
        this.setState({ age: age });
    }

    onZipCodeChange(p) {
        let zipCode = p.target.value.toString().replace(/\D/g, '');
        this.setState({ zipcode: zipCode });
        this.timer && clearTimeout(this.timer);
        if (zipCode && zipCode.length == 5) {
        }
    }

    _dropdownRenderRow(rowData, rowID, highlighted) {
        let evenRow = rowID % 2;
        return (
            <button underlayColor='cornflowerblue'>
                <div style={{ height: 32, width: '90%', marginLeft: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: evenRow ? '#eee' : '#eee' }}>
                    <p style={highlighted && { color: '#008cd3' }}>
                        {`${rowData.name}`}
                    </p>
                </div>
            </button>
        );
    }

    renderChilds() {
        let { chlidcount, childs } = this.state;
        let htmls = [];
        if (chlidcount > 0) {
            htmls.push(
                <p key='chlid_instruct' style={{ marginTop: 25, marginBottom: -10 }}>
                    {'You can apply for more than one child to join the CROCuS study. Please answer the following questions for each child you would like to be considered for the study.'}
                </p>
            );
            let nowDate = moment();
            for (var i = 0; i < chlidcount; i++) {
                let cIndex = i;
                let child = childs[i];
                let month = child && child.month && child.month > 0 ? months[child.month - 1].display : 'Month';
                let monthStyle = child && child.month && child.month > 0 ? [styles.selectText] : [styles.unselectText];
                let year = child && child.year && child.year > 0 ? child.year : 'Year';
                let yearStyle = child && child.year && child.year > 0 ? [styles.selectText] : [styles.unselectText];
                let term = child && child.term && child.term != '' ? child.term : 'Select answer';
                let termStyle = child && child.term && child.term != '' ? [styles.selectText] : [styles.unselectText];

                let hepatitis = child && child.hepatitis && child.hepatitis != '' ? child.hepatitis : 'Select answer';
                let hepatitisStyle = child && child.hepatitis && child.hepatitis != '' ? [styles.selectText] : [styles.unselectText];

                let agree = child.agree;
                let monthsage = child.monthsage;
                let showFullTerms = monthsage >= 0 && monthsage <= 3;
                let showHepatitis = monthsage >= 0 && monthsage <= 33;
                let showAgree = monthsage >= 0 && monthsage <= 33 && child.hepatitis == 'No';

                htmls.push(
                    <div key={'C_' + i} style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}>
                        <p style={ { color: 'black', alignSelf: 'flex-start' }}>
                            {`When was Child ${i + 1} born?`}
                        </p>
                        <div style={{ alignSelf: 'stretch', flexDirection: 'row', marginTop: 5, alignItems: 'center' }}>
                            <button style={{ flex: 1 }}
                                onClick={() => {
                                    this.setState({
                                        cIndex: cIndex,
                                        showmonths: true,
                                        showchildcounts: false,
                                        showterms: false,
                                        showyears: false,
                                        showHepatitis: false
                                    })
                                }}>
                                <div style={styles.selectContainer, { flex: 1 }}>
                                    <p style={monthStyle}>
                                        {month}
                                    </p>
                                </div>
                            </button>
                            <div style={{ width: 30 }} />
                            <button style={{ flex: 1 }}
                                onClick={() => {
                                    this.setState({
                                        cIndex: cIndex,
                                        showmonths: false,
                                        showchildcounts: false,
                                        showterms: false,
                                        showyears: true,
                                        showHepatitis: false
                                    })
                                }}>
                                <div style={styles.selectContainer, { flex: 1 }}>
                                    <p style={yearStyle}>
                                        {year}
                                    </p>
                                </div>
                            </button>
                        </div>
                        {
                            showFullTerms &&
                            <div style={{ alignSelf: 'stretch' }}>
                                <p style={{ color:'black', alignSelf: 'flex-start', marginTop: 25 }}>
                                    {`Was Child ${i + 1} born at full-term (at or after 37 weeks of pregnancy)?`}
                                </p>
                                <button style={{ alignSelf: 'stretch' }}
                                    onClick={() => {
                                        this.setState({
                                            cIndex: cIndex,
                                            showmonths: false,
                                            showchildcounts: false,
                                            showterms: true,
                                            showyears: false,
                                            showHepatitis: false
                                        })
                                    }}>
                                    <div style={styles.selectContainer, { flex: 1 }}>
                                        <p style={termStyle}>
                                            {term}
                                        </p>
                                    </div>
                                </button>
                            </div>
                        }

                        {
                            showHepatitis &&
                            <div style={{ alignSelf: 'stretch' }}>
                                <p style={ { color: 'black', alignSelf: 'flex-start', marginTop: 25 }}>
                                    {`Does Child ${i + 1} have hepatitis B or C infection?`}
                                </p>
                                <button style={{ alignSelf: 'stretch' }}
                                    onClick={() => {
                                        this.setState({
                                            cIndex: cIndex,
                                            showmonths: false,
                                            showchildcounts: false,
                                            showterms: false,
                                            showyears: false,
                                            showHepatitis: true
                                        })
                                    }}>
                                    <div style={styles.selectContainer, { flex: 1 }}>
                                        <p style={hepatitisStyle}>
                                            {hepatitis}
                                        </p>
                                    </div>
                                </button>
                            </div>
                        }
                    </div>
                );
            }
        }
        return htmls;
    }

    renderStudentTypeChoose(positionType) {
        if (positionType == 2) {
            var studentType = this.state.studentType;
            var htmls = [];
            var studentTypeItems = this.state.studentTypeItems;
            if (studentTypeItems) {
                studentTypeItems.map((item, index) => {
                    htmls.push(
                        <label style={styles.option_row} key={index}
                            onClick={() => {
                            this.setState({ studentType: item.$id, athleticType: (item.$id == 1 ? this.state.athleticType : null) }) 
                        }}>
                            <Radio styleType={2} checked={ studentType == item.$id } type="radio" name="studentType" 
                                defaultChecked={studentType == item.$id}  />
                        {/*<input style={styles.input_size} type="radio" name="studentType"
                        defaultChecked={studentType == item.$id} 
                        onClick={() => {
                            this.setState({ studentType: item.$id, athleticType: (item.$id == 1 ? this.state.athleticType : null) }) 
                       }}/>    */}                      
                        <p style={styles.option_text}>
                            {item.typeName}
                        </p>
                    </label>
                    );
                });
            }
            return (
                <div style={{ marginTop: 25,width:'100%',display:'block' }}>
                    <p style={ styles.question_text}>
                        {this.translate("What_type_of_student_are_you")}
                    </p>
                    {htmls}
                </div>);
        } else {
            return (<div></div>)
        }


    }

    renderAthleteTeamChoose(studentType) {
        //console.log('studentType');
        //console.log(studentType);
        //=== can not work
        if (studentType == 1) {
            var athleticType = this.state.athleticType;
            var htmls = [];
            var athleteTypeItems = this.state.athleteTypeItems;
            //console.log(athleteTypeItems);
            var selectText = this.translate("Select_a_team");
            if (athleticType) {
                athleteTypeItems.map((item) => {
                    if (athleticType == item.teamId) {
                        selectText = item.name;
                    }
                })
            }
            var pickItems = [];
            htmls.push(<option disabled selected value>{selectText}</option>);
            athleteTypeItems.map((item) => {
                pickItems.push({
                    label: item.name,
                    value: item.teamId
                });
                htmls.push(                   
                    <option style={styles.option_text} value={item.teamId}>
                        {item.name}
                    </option>
                );
            })

            return (
                <div style={{ marginTop: 25,width:'100%',display:'block' }}>
                    <p style={ styles.question_text}>
                        {this.translate("Select_your_athletic_team")}
                    </p>
                    <div style={{ alignSelf: 'stretch', height:25,flex: 3,paddingBottom:20}}>
                        <select
                            className={"input1"}
                            style={{ flex: 1,width:'100%',margin:0}}
                            dropdownStyle={{ marginTop: 4, marginLeft: -10 }}
                            defaultIndex={-1}
                            options={athleteTypeItems}
                            onChange={
                                (item) => {
                                    var value = item.target.options[item.target.selectedIndex].value;
                                    if (value !== this.state.athleticType) {
                                        this.setState({ athleticType: value });
                                    }
                            }}>
                            {htmls}
                        </select>
                    </div>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }

    render() {
        let {
            zipcode,
            had36monthchildren,
            hepatitis,
            hadimmunocompromised,
            birthdate,
            chlidcount,
            childs,
            qualified,
            verifyZipCodeOk,
            locationName,
            locationAddress,
            locationCity,
            locationState,
            locationZipCode,
            showTravels,
            travelOk,
            selectTravel,
            willQuit,
            positionType,
            studentType,
            athleticType,
            healthCondition,
            age,
            internationalTravelinPast14D,
            //anyChronicMedConditions,
            //isAnyoneSickinHoursehold,
            //houseHoldMembersConditions,
            hadContactConfirmedCase
        } = this.state;
        let zipCodeCompleted = zipcode != null && zipcode.length == 5 && verifyZipCodeOk;
        let childCompleted = chlidcount >= 0;
        let hasChildQualified = false;

        if (chlidcount > 0) {
            childs.map((item) => {
                childCompleted = childCompleted
                    && item.month != null && item.month != ''
                    && item.year != null && item.year != ''
                    &&
                    (
                        item.monthsage > 33
                        || (item.monthsage > 3 && item.monthsage <= 33 && item.hepatitis != '')
                        || (item.monthsage >= 0 && item.monthsage <= 3 && item.term != '' && item.hepatitis != '')
                        || item.monthsage < 0
                    );
                hasChildQualified = hasChildQualified || (item.monthsage >= 0 && item.monthsage <= 33 && item.hepatitis == 'No');
            });
        }
        //let hepatitisCompleted = hepatitis >= 0;
        //let completed = hadimmunocompromised >= 0;
        let completed = childCompleted;



        return (
            <div className="fill">  
                <div ref='kh' style={styles.content} offset={150}>
                    <div style={{alignItems:'flex-start',width:'100%'}}>
                        <p style={styles.question_text}>
                            {this.translate("Please_answer_the_following_")}
                        </p>
                    </div>
                    <div style={{ marginTop: 10,width:'100%' }}>
                        <p style={{ marginTop: 25,width:'100%',display:'block',fontWeight:'800' }}>
                            {this.translate("What_is_your_age")}
                        </p>
                        <input
                            key={"a"}
                            className={"input1"}
                            secureTextEntry={false}
                            autoFocus={false}
                            maxLength={3}
                            autoCorrect={false}
                            autoCapitalize="none"
                            clearButtonMode="while-editing"
                            style={{margin:0,marginBottom:5,width:'100%'}} 
                            placeholder={this.translate("Enter_your_age")}
                            placeholderTextColor="rgb(158,158,158)"
                            value={age}
                            defaultValue = {age}
                            returnKeyType={'done'}
                            onChange={this.onAgeChange.bind(this)}
                        />
                        <p style={{ marginTop: 25,width:'100%',display:'block',fontWeight:'800' }}>
                            {this.translate("What_is_your_zip_code")}
                        </p>
                        <input
                            key={"e"}
                            className={"input1"}
                            secureTextEntry={false}
                            autoFocus={false}
                            maxLength={5}
                            autoCorrect={false}
                            autoCapitalize="none"
                            clearButtonMode="while-editing"
                            style={{margin:0,marginBottom:5,width:'100%'}} 
                            placeholder={this.translate("Enter_Zip_code")}
                            placeholderTextColor="rgb(158,158,158)"
                            value={zipcode}
                            defaultValue = {zipcode}
                            returnKeyType={'done'}
                            onChange={this.onZipCodeChange.bind(this)}
                        />
                    </div>
                    <div style={{ marginTop: 25,width:'100%',display:'block' }}>
                        <p style={ styles.question_text}>
                            {this.translate("You_are")}
                        </p>
                        <label style={styles.option_row} for="positionType1"
                            onClick={() => { 
                                this.setState({ positionType: 1, studentType: null, athleticType: null });
                            }}>
                            <Radio styleType={2}  type="radio"  name="positionType" checked={positionType == 1} />                            
                            <p style={styles.option_text}>
                                {this.translate("Healthcare_Worker")}
                            </p>
                        </label>
                        <label style={styles.option_row} for="positionType2"
                            onClick={() => { 
                                this.setState({ positionType: 2, studentType: null, athleticType: null });
                            }}                        >
                            <Radio styleType={2}  name="positionType" checked={positionType == 2} />                            
                            <p style={styles.option_text}>
                                {this.translate("Drexel_Student")}
                            </p>
                        </label>    
                        <label style={styles.option_row} for="positionType3"
                             onClick={() => { 
                                this.setState({ positionType: 3, studentType: null, athleticType: null });
                            }}                        >
                            <Radio styleType={2}   name="positionType" checked={positionType == 3}/>                           
                            <p style={styles.option_text}>
                                {this.translate("Faculty")}
                            </p>
                        </label>
                        <label style={styles.option_row} for="positionType4"
                            onClick={() => { 
                                this.setState({ positionType: 4, studentType: null, athleticType: null });
                            }}>
                            <Radio styleType={2}  name="positionType" checked={positionType == 4} />                            
                            <p style={styles.option_text}>
                                {this.translate("Employee")}
                            </p>
                        </label> 
                    </div>
                    {this.state.hasLoadedStudentTypeItems && this.renderStudentTypeChoose(positionType)}
                    {this.state.hasLoadedAthleteTypeItems && this.renderAthleteTeamChoose(studentType)}

                    <div style={{ height: 20 }} />
                    {

                        zipcode !== '' &&
                        positionType !== null &&
                        age != '' &&
                        ((positionType != 2) || (studentType !== null && studentType != 1) || (athleticType !== null)) &&

                        <button className="button" style={{width:'85%',marginBottom: 20, alignSelf: 'center' }}
                            onClick={this.next.bind(this)}
                        >
                            {this.translate("w_next")}
                        </button>
                    }

                </div>
                {this.state.loading && <Loading />}
            </div>
        );
    }
}


var styles ={
    input_size:{
        zoom:1.5,
        margin:0,
        height:20
    },
    question_text:{
        color:'black',
        margin:2,
        fontWeight:'bold'
    },
    option_text:{
        display:'inline',
        marginLeft: 10,
        color:'black',
        margin:5
    },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    navContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        height: 60,
        paddingLeft: 12,
        paddingRight: 12,
        //marginTop: Platform.OS == 'ios' ? 20 : 0,
    },
    content: {
        flex: 1,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 15,
        alignSelf: 'stretch',
        justifyContent:'flex-start'
    },
    selectItem: {
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        height: 46,
        borderRadius: 4,
        backgroundColor: 'rgb(238,238,238)',
        marginTop: 5,
    },
    hadSelectItem: {
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        height: 46,
        borderRadius: 4,
        backgroundColor: '#FFC000',
        marginTop: 5,
    },
    selectContainer: {
        backgroundColor: '#eee',
        height: 44,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3
    },
    unselectText: {
        color: '#9E9E9E',
    },
    selectText: {
        color:'black'
    },
    option_row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        display:'flex'
    },
    radio: {
        width: 24,
        height: 24,
        borderRadius: 12,
        borderWidth: 2,
        borderColor: '#e0e0e0',
        alignItems: 'center',
        justifyContent: 'center'
    },
    checked_view: {
        width: 10,
        height: 10,
        borderRadius: 5,
    },
    unSelectContainer: {
        width: 24,
        height: 24,
        backgroundColor: '#fff',
        borderRadius: 1,
        borderColor: '#e0e0e0',
        borderWidth: 2,
    },
    selectedContainer: {
        width: 24,
        height: 24,
        backgroundColor: 'black',
        borderRadius: 1,
        borderColor: 'black',
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',

    },
};