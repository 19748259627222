import React, { Alert } from 'react';
import BaseComponent from '../../components/BaseComponent.js';
import HttpUtil from './../../utils/HttpUtil.js';
import Container from '../../components/Container.js';
import NavBar from './../../components/NavBar.js';
import validUtil from '../../utils/CommonUtil';
import ShareCal from './ShareCal';
import moment from 'moment';

const formStyles = require('./../../utils/FormStyles');
const globalColor = require('./../../utils/Color');
const globalStyles = require('../../utils/GlobalStyles');

export default class AppointmentDetails extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      appointment: this.props.location.state,
      reason: '',
      cancelPending: false,
      showShare: false,
    };
    this._goBack = this._goBack.bind(this);

    this._cancelAppointment = this._cancelAppointment.bind(this);
    this._confirmCancel = this._confirmCancel.bind(this);
    this.confirmAppointment = this._confirmAppointment.bind(this);
  }

  _goBack() {
    this.props.history.goBack();
  }

  _appointmentLocked() {
    let appointment = this.state.appointment;
    var afterTomorrow = new Date();
    afterTomorrow.setDate(afterTomorrow.getDate() + 2);

    if (afterTomorrow > new Date(appointment.startDateTime)) {
      Alert.alert(
        this.translate('w_confirmation'),
        this.translate('w_appointmentLocked')
      );
      return true;
    }

    return false;
  }

  _confirmAppointment() {
    Alert.alert(
      this.translate('w_confirmation'),
      this.translate('w_confrimApt_content'),
      [
        { text: this.translate('w_cancel'), onPress: () => {} },
        {
          text: this.translate('w_yes'),
          onPress: () => {
            HttpUtil.post(
              'api/Appointment/Confirm',
              {
                id: this.state.appointment.id,
              },
              () => {
                // if (this.props.afterChange) {
                //   this.props.afterChange();
                // }
                this._goBack();
              }
            );
          },
        },
      ]
    );

    return;
  }

  _cancelAppointment() {
    // if (this._appointmentLocked()) {
    //   return;
    // }

    this.setState({ cancelPending: true });
  }

  _confirmCancel() {
    if (validUtil.isBlank(this.state.reason)) {
      alert(this.translate('w_reasonRequired'));
      return;
    }

    HttpUtil.post(
      '/api/Appointment/cancel',
      {
        id: this.state.appointment.id,
        reasons: this.state.reason,
      },
      () => {
        // if (this.props.afterChange) {
        //   this.props.afterChange();
        // }

        this._goBack();
      }
    );
  }

  render() {
    let { appointment } = this.state;
    var isCancelAppointmentBtnShow =
      moment.utc(appointment.startDateTime) - moment.utc(new Date()) > 0;

    return this.state.showShare ? (
      <ShareCal
        history={this.props.history}
        appointmentId={appointment.id}
        closePopup={() => {
          this.setState({ showShare: false });
        }}
      />
    ) : (
      <Container style={styles.container}>
        <NavBar
          title={this.translate('w_appointment')}
          backurl={'/home/calendar'}
          history={this.props.history}
        />

        {!this.state.cancelPending ? (
          <div style={styles.fieldList}>
            <div
              style={{
                ...formStyles.fieldContainer,
                ...formStyles.horizontalContainer,
                ...styles.formItemContainerStyle,
              }}
            >
              <span
                style={{
                  ...globalStyles.normalFont,
                  ...formStyles.fieldText,
                  ...styles.fieldLabel,
                }}
              >
                {this.translate('w_appointmentType')}
              </span>
              <div
                style={{
                  ...formStyles.horizontalContainer,
                  ...formStyles.fieldContent,
                }}
              >
                <span
                  style={{
                    ...globalStyles.normalFont,
                    ...formStyles.fieldValue,
                    ...styles.fieldValue,
                  }}
                >
                  {appointment.appointmentTypeName}
                </span>
              </div>
            </div>
            <div
              style={{
                ...formStyles.fieldContainer,
                ...formStyles.horizontalContainer,
                ...styles.formItemContainerStyle,
              }}
            >
              <span
                style={{
                  ...globalStyles.normalFont,
                  ...formStyles.fieldText,
                  ...styles.fieldLabel,
                }}
              >
                {this.translate('w_appointmentDate')}
              </span>
              <div
                style={{
                  ...formStyles.fieldContent,
                }}
              >
                <span
                  style={{
                    ...globalStyles.normalFont,
                    ...formStyles.fieldValue,
                    ...styles.fieldValue,
                  }}
                >
                  {moment.utc(appointment.startDateTime).local().format('lll')}
                </span>
              </div>
            </div>
            <div
              style={{
                ...formStyles.fieldContainer,
                ...formStyles.horizontalContainer,
                ...styles.formItemContainerStyle,
              }}
            >
              <span
                style={{
                  ...globalStyles.normalFont,
                  ...formStyles.fieldText,
                  ...styles.fieldLabel,
                }}
              >
                {this.translate('w_location')}
              </span>
              <div
                style={{
                  ...formStyles.fieldContent,
                }}
              >
                <span
                  style={{
                    ...globalStyles.normalFont,
                    ...formStyles.fieldValue,
                    ...styles.fieldValue,
                  }}
                >
                  {appointment.location || ''}
                </span>
              </div>
            </div>
            {/*<View style={{formStyles.fieldContainer,
                formStyles.horizontalContainer,styles.formItemContainerStyle}}>
                <Text style={formStyles.fieldText}>{this.translate("w_medicalStaff}</Text>
                <View style={{formStyles.horizontalContainer, formStyles.fieldContent}}>
                  <Text style={formStyles.fieldValue}>{appointment.medicalStaffName}</Text>
                </View>
              </View>*/}
            <div style={formStyles.fieldContainer}>
              <div style={{ marginTop: 10, alignSelf: 'stretch' }}>
                <span
                  style={{
                    ...globalStyles.normalFont,
                    ...formStyles.fieldText,
                    ...styles.fieldLabel,
                  }}
                >
                  {this.translate('w_note')}
                </span>
                <div
                  style={{
                    ...formStyles.horizontalContainer,
                    ...{
                      marginTop: 5,
                      marginBottom: 10,
                      paddingBottom: 10,
                      borderBottom: '1px solid #ccc',
                    },
                  }}
                >
                  <span
                    style={{
                      ...formStyles.fieldValue,
                      ...{
                        minHeight: '4em',
                        display: 'block',
                        padding: '10px 20px',
                        flex: 1,
                      },
                    }}
                  >
                    {(appointment.participantNotes == null
                      ? ''
                      : appointment.participantNotes) +
                      '\n' +
                      (appointment.notes == null ? '' : appointment.notes)}
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <button
                className="button"
                style={{
                  ...globalStyles.button2,
                  ...styles.button,
                  ...{
                    backgroundColor: globalColor.buttoUunderlayColor,
                  },
                }}
                onClick={() => {
                  this.setState({ showShare: true });
                }}
              >
                <span
                  style={{
                    ...globalStyles.normalFont,
                    ...globalStyles.buttonTextBold,
                  }}
                >
                  {this.translate('w_shareappointment')}
                </span>
              </button>

              {isCancelAppointmentBtnShow && (
                <button
                  className="button"
                  style={{
                    ...globalStyles.button2,
                    ...styles.button,
                    ...{
                      backgroundColor: globalColor.buttoUunderlayColor,
                    },
                  }}
                  onClick={this._cancelAppointment}
                >
                  <span
                    style={{
                      ...globalStyles.normalFont,
                      ...globalStyles.buttonTextBold,
                    }}
                  >
                    {this.translate('w_Cancelappointment')}
                  </span>
                </button>
              )}
            </div>
          </div>
        ) : (
          <div style={{ ...styles.fieldList }}>
            <div style={formStyles.fieldContainer}>
              <div style={{ marginTop: 10, alignItems: 'stretch' }}>
                <span
                  style={{
                    ...globalStyles.normalFont,
                    ...formStyles.fieldText,
                    ...styles.fieldLabel,
                  }}
                >
                  {this.translate('w_reason')}
                </span>
                <div
                  style={{
                    ...formStyles.horizontalContainer,
                    marginTop: 5,
                    padding: '10px 20px',
                    marginBottom: 10,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <textarea
                    maxLength="50"
                    style={{
                      ...globalStyles.normalFont,
                      minHeight: '4em',
                    }}
                    placeholder={this.translate('w_enterHere')}
                    onChange={(e) => this.setState({ reason: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <button
                className="button"
                style={{
                  ...globalStyles.button2,
                  marginTop: 30,
                  backgroundColor: '#123b6d',
                }}
                onClick={this._confirmCancel}
              >
                {this.translate('w_ok')}
              </button>
              <button
                className="button"
                style={{
                  ...globalStyles.button2,
                  marginTop: 30,
                  backgroundColor: '#123b6d',
                }}
                onClick={() => {
                  this.setState({ cancelPending: false });
                }}
              >
                {this.translate('w_cancel')}
              </button>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'center',
  },
  fieldList: {
    flex: 1,
    alignSelf: 'stretch',
    // backgroundColor: '#f9f9f9',
  },
  formItemContainerStyle: {
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
  },
  fieldLabel: {
    fontSize: 18,
    marginLeft: 20,
  },
  fieldValue: {
    fontSize: 18,
    marginRight: 20,
    fontWeight: 'bold',
  },
  button: {
    color: 'white',
  },
};
