import axios from "axios";
import comom from ".././Common";

export default {
  post(api, data, callback) {
    let token = localStorage.getItem("token");
    axios
      .post(api, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (res) {
        console.log(res);
        callback && callback(res.data);
      })
      .catch(function (error) {
        console.log(error);
        handleWhenReturnErrors(error.response, callback);
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          //callback && callback(error.response.data);
          handleWhenReturnErrors(error.response, callback);
        } else {
          console.log("Error", error.message);
          callback && callback(null);
        }
      });
  },

  put(api, data, callback) {
    let token = localStorage.getItem("token");
    axios
      .put(api, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (res) {
        //console.log(res.data);
        callback && callback(res.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          //callback && callback(error.response.data);
          handleWhenReturnErrors(error.response, callback);
        } else {
          console.log("Error", error.message);
          callback && callback(null);
        }
      });
  },

  get(api, callback, callbackFailure) {
    let token = localStorage.getItem("token");
    //console.log('http with get method');
    //console.log(token);
    axios
      .get(api, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (res) {
        //console.log(res);
        callback && callback(res.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
          //callback && callback(error.response.data);
          //callbackFailure && callbackFailure(error.response);
          handleWhenReturnErrors(error.response, callback, callbackFailure);
        } else {
          console.log("Error", error.message);
          callback && callback(null);
        }
      });
  },
};

function handleWhenReturnErrors(response, callback, callbackFailure) {
  if (response.status === "401" || response.status === 401) {
    localStorage.removeItem("token");
    console.log("log out when status is 401");
    if (comom.isTurnToSSOLogin()) {
      window.location.href = comom.getSSOUrl();
      //pageThis.props.history.push("/SSOWeb");
    } else {
      //alert(10000);
      //pageThis.props.history.push("/");
    }
  } else {
    callback && callback(response.data);
    callbackFailure && callbackFailure(response);
  }
}
