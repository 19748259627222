import React, { Component } from 'react';
import loading from '../content/images/loading.gif'

export default class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div style={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 999
            }}>
                <div
                    style={{
                        padding: 30,
                        borderRadius: 5,
                        backgroundColor: '#00000099'
                    }}
                >
                    <img src={loading} style={{ width: 30, height: 33 }} resizemode={'contain'} />
                </div>
            </div>
        );
    }
}
