export const backgroundColor = '#123B6D';
export const mainColor = '#123B6D';
export const greenColor = '#00b359';
export const textColor = '#000000';
export const tipColor = '#666666';
export const purple = '#9400D3';
export const disableColor = '#999999';
export const buttoUunderlayColor = '#123B6D';
export const linkColor = '#3C8ACA';
export const splitlineColor = '#EEEEEE';
export const btnBackColor = '#123B6D';
export const navyColor = '#0070c0';
export const white = '#fff';
export const resourceRed = '#c00000';
export const resourcebule = '#4472c4';
//export const levelColors = [ "#FFFFFF","#16B157", "#CEDD4C","#F8A82A", "#CC4B4C", "#FF2222",];
export const levelColors = [
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
];
