import React from 'react';
import BaseComponent from '../../../components/BaseComponent';
import TabBarContainer from '../../../components/TabBarContainer';
import httpUtil from '../../../utils/HttpUtil';
import ReactDOM from 'react-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';
import journay_logo from '../../../content/images/track-journay.png';
import star_logo from '../../../content/images/star.png';

import Loading from '../../../components/Loading';
import CacheUtil from '../../../utils/CacheUtil';
import ModalContainer from '../../../components/ModalContainer';
import ScrollView from '../../../components/ScrollView';
import CommonUtil from './../../../utils/CommonUtil';
import Common from '../../../Common';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Calendar from 'react-calendar';
import NavBar from '../../../components/NavBar';
import 'react-calendar/dist/Calendar.css';
export default class History extends BaseComponent {
    constructor(props) {
        super(props);
        let startOfDay = new Date();
        //startOfDay.setHours(0, 0, 0, 0);

        this.state = {
            currentMonth: new Date(),
            selectedDate: startOfDay,
            eventDates: [],
            historys: [],
            trkRsvs: [],
            showIndicator: false,
            lastShowIndex: -1,
            date: new Date(),
            loading: false
        };

        this._onDateSelected = this._onDateSelected.bind(this);
        this._onCurrentMonth = this._onCurrentMonth.bind(this);
    }

    componentDidMount() {
        // this.ChangeProfileListener = RCTDeviceEventEmitter.addListener('change_profile_event', (value) => {
        //     this._refreshData();
        // });
        this._refreshData();
        // this.refs.scrollView.scrollTo({ x: 0, y: 20, animated: true });
    }

    componentWillUnmount() {
        // this.ChangeProfileListener && this.ChangeProfileListener.remove();
    }

    _refreshData() {
        let cDate = moment(this.state.selectedDate).format('YYYY-MM-DD');
        let profile = JSON.parse(localStorage.getItem('profile'));
        this.setState({ loading: true });
        httpUtil.post('api/TrkRSV/History3', { profileId: profile.id },
            (responseData) => {
                this.setState({ loading: false });
                if (responseData) {
                    let eventDates = [];
                    let trkRsvs = [];
                    responseData.map((value) => {
                        //let date = moment.utc(value.day).local().format('YYYY-MM-DD');
                        let date = moment(value.day).format('YYYY-MM-DD');
                        eventDates.push(date);
                        if (cDate == date) {
                            trkRsvs = value.models;
                        }
                    });
                    this.setState({ eventDates: eventDates, historys: responseData, trkRsvs: trkRsvs });
                }
            }
        );
    }

    _onDateSelected(dateString) {
        let { historys } = this.state;
        let trkRsvs = [];
        let selectedDate = new Date(dateString);
        let cDate = moment(selectedDate).format('YYYY-MM-DD');
        historys.map((item) => {
            let date = moment(item.day).format('YYYY-MM-DD');
            if (cDate == date) {
                trkRsvs = item.models;
            }
        });
        this.setState({ selectedDate: selectedDate, trkRsvs: trkRsvs });
    }
    _onDateChange(dateString) {
        let { historys } = this.state;
        let trkRsvs = [];
        let selectedDate = new Date(dateString);
        let cDate = moment(selectedDate).format('YYYY-MM-DD');
        historys.map((item) => {
            let date = moment(item.day).format('YYYY-MM-DD');
            if (cDate == date) {
                trkRsvs = item.models;
            }
        });
        this.setState({ date: selectedDate, trkRsvs: trkRsvs,selectedDate:selectedDate });
        // this.setState({ date: date });
    }
    _onCurrentMonth(dateString) {
        //console.warn(dateString);
    }
    _goBack() {
        //this.props.navigator.pop();
        this.props.history.goBack();
        //MomEnv.saveStep('home', null, { viewSummary: true });
        //MomEnv.pushPage(TrackView); this.props.onShowSummary && this.props.onShowSummary(true);      
    }

    render() {
        let { trkRsvs, selectedDate, eventDates } = this.state;
        let profile = JSON.parse(localStorage.getItem('profile'));
        let name = profile.name;
        let title = (name ? (name + "'s") : '') + this.translate("History");

        let trkHtmls = [];
        let hadFever = false;
        let TrackItems = Common.TrackItems();
        if (trkRsvs && trkRsvs.length > 0) {
            trkRsvs.map((trkRsv, tIndex) => {
                let tmpHtmls = [];
                if (trkRsv.howIsToday == 1) {
                    tmpHtmls.push(
                        <div key={'v_' + tIndex} style={{ alignSelf: 'flex-start', margin: 15 }}>
                            {this.translate("No_Symptoms")}
                        </div>
                    );
                } else {
                    {/* if (trkRsv.symptomOnset != null && trkRsv.symptomOnset > 0) {
                        tmpHtmls.push(
                            <Text key={'t_symptom'} style={[globalStyles.normalFont, { color: '#666' }]}>
                                <Text style={[globalStyles.normalFont, { color: '#000' }]}>
                                    {'Symptom Onset:  '}
                                </Text>
                                {common.SymptomOnsets[trkRsv.symptomOnset - 1]}
                            </Text>
                        ); */
                    }
                    if (trkRsv.whenFirstNoticed != null) {
                        tmpHtmls.push(
                            <div key={'t_symptom'} style={{ color: '#666', display: 'flex' }}>
                                <div style={{ color: '#000' }}>
                                    {'Symptom Onset:  '}
                                </div>
                                {moment(trkRsv.whenFirstNoticed).format('D MMMM YYYY')}
                            </div>
                        );
                    }
                    TrackItems.map((item, index) => {
                        if (trkRsv[item.key]) {
                            tmpHtmls.push(
                                <div
                                    key={'v_' + index}
                                    style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 0, backgroundColor: '#FFF', marginTop: 4, marginBottom: 4, borderRadius: 5, display: 'flex' }}>
                                    <img
                                        style={{ width: 44, height: 44 }}
                                        src={item.logo}
                                        resizeMode={'contain'} />
                                    <div style={{ marginLeft: 5, backgroundColor: '#FFF' }}>
                                        {item.name}
                                    </div>
                                    {/* {
                                        item.instructiona &&
                                        <TouchableOpacity
                                            style={{ padding: 12 }}
                                            onPress={() => {
                                                item.show = !item.show;
                                                this.setState({ update: item.show, lastShowIndex: tIndex });
                                            }}
                                        >
                                            <Image
                                                style={{ height: 12, width: 12, marginLeft: 20 }}
                                                source={item.show && this.state.lastShowIndex == tIndex ? require('../../../assets/crocus/up.png') : require('../../../assets/crocus/down.png')}
                                                resizeMode={'contain'} />
                                        </TouchableOpacity>
                                    } */}
                                    <div style={{ flex: 1 }} />

                                </div>
                            );
                            if (item.key == 'fever') {
                                hadFever = true;
                                if (trkRsv.feverValue != null && trkRsv.feverValue > 0) {
                                    tmpHtmls.push(
                                        <div key='f_v' style={{ alignSelf: 'flex-start', marginLeft: 48, marginTop: -8 }}>
                                            {`${trkRsv.feverValue.toString()} °F`}
                                        </div>
                                    );
                                }
                            }
                            // if (item.instructiona && item.show && this.state.lastShowIndex == tIndex) {
                            //     tmpHtmls.push(
                            //         <View key={'in_' + index} style={{ alignSelf: 'stretch', backgroundColor: '#f6f6f6', padding: 8, borderRadius: 15 }}>
                            //             <Text style={[globalStyles.normalFont, globalStyles.fontSize14]}>
                            //                 <Text style={[globalStyles.boldFont, globalStyles.fontSize14, { color: '#888' }]}>{item.name + ' '}</Text>{item.instructiona}
                            //             </Text>
                            //         </View>
                            //     );
                            // }
                        }
                    });
                }

                trkHtmls.push(
                    <div key={'trkrsv_' + tIndex} style={{ alignSelf: 'stretch' }}>
                        <div style={{ backgroundColor: '#fff', borderRadius: 5, marginTop: 8, marginBottom: 0 }}>
                            <div >
                                {moment.utc(trkRsv.created).local().format('hh:mm A')}
                            </div>
                        </div>
                        {tmpHtmls}
                    </div>
                );
            });
        }
        const tileClassName = ({ date, view }) => {
            let getDate = moment(date).format("YYYY-MM-DD");
            if (eventDates && eventDates.length > 0 && eventDates.some((item) => { return item === getDate })) {
                return "selDate";
            }
            return null;
        }


        return (
            <div className="conatiner">
                <NavBar title={this.translate("History")} backurl='/trackmain' history={this.props.history} />

                <ScrollView style={styles.content}>

                    <Calendar
                        ref="calendar"
                        locale="en"
                        maxDate={new Date()}
                        tileClassName={tileClassName}
                        calendarType='US'
                        // onClickDay={this.state.eventDates}
                        onChange={(date) => {
                            this._onDateChange(date)
                        }}
                        value={this.state.date}
                    />

                    {
                        trkRsvs && trkRsvs.length > 0 &&
                        <div style={{ alignSelf: 'stretch', height: 44, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', marginTop: 10, display: 'flex' }}>
                            <div style={{ color: '#888', marginLeft: 10, marginRight: 10 }}>
                                {moment(selectedDate).format('DD MMMM YYYY')}
                            </div>
                            {
                                hadFever &&
                                <div onClick={() => {
                                    this.props.history.push('/feverhistory', {});
                                    //MomEnv.pushPage(FerveHistory);
                                    // MomEnv.saveStep('home', 'FerveHistory');
                                }}>
                                    <div style={{ color: '#3C8ACA', fontSize: 16, textDecorationLine: 'underline' }}>
                                        {this.translate("View_Fever_Chart")}
                                    </div>
                                    {/* <Image
                                        style={{ width: 32, height: 32, marginBottom: 3 }}
                                        source={require('../../../assets/crocus/icon/FeverChart_3x.png')}
                                        resizeMode={'contain'} /> */}
                                </div>
                            }
                        </div>

                    }
                    <div style={styles.content}>
                        {trkHtmls}
                    </div>
                </ScrollView>
                {this.state.loading && <Loading />}
            </div>
        );
    }
}

const styles = {
    container: {
        alignSelf: 'stretch',
        flex: 1,
        //justifyContent: 'flex-start',
        //alignItems: 'center',
        backgroundColor: '#fff',
        //marginTop: Platform.OS == 'ios' && height > 800 ? 20 : 0,
    },
    navContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        height: 44,
        paddingLeft: 12,
        paddingRight: 12,
        borderBottomWidth: 1,
        backgroundColor: '#fbfbfb',
    },
    content: {
        flex: 1,
        flexDirection: 'column',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 0,
        alignSelf: 'stretch',
        justifyContent: 'flex-start',
        paddingBottom: 40,
    }
};