import httpUtils from '../utils/HttpUtil'
import common from '../Common';
import LabResultSvr from './../service/LabResultSvr';
var React = require('react');
var _profile = {};
var _editingProfile = {};
var _loginProfile = {};

var storageUtil = {
};

storageUtil.getItem = function(key) {
	var store = localStorage.getItem(key);
  	if (store) {
    	try {
      		store = JSON.parse(store);
    	} catch (e) {
      		store = undefined;
      		this.remove(key);
    	}
	} 
	var promise = new Promise((resolve, reject)=>{
		resolve(store);     
	});
 	return promise;
};

storageUtil.setItem = function(key, value) {
  	localStorage.setItem(key, JSON.stringify(value));
	var promise = new Promise((resolve, reject)=>{
		resolve();     
	});
 	return promise;
};

storageUtil.removeItem = function(key) {
  	localStorage.removeItem(key);
};
	
const LabResultNotesKeyPrefix = 'NotesForLabResultQualitativeID-';

var ProfileStorage = {
	
	post:function(url,data,callback){
		httpUtils.post(url, data, (responseData) => {
			callback(responseData);
		});
	},

	put:function(url,data,callback){
		httpUtils.put(url, data, (responseData) => {
			callback(responseData);
		});
	},

	get:function(url,callback){
		httpUtils.get(url,(responseData) => {
			callback(responseData);
		});
	},

	saveToken: function (token) {
		return storageUtil.setItem('MOM_APP_TOKEN', JSON.stringify(token)).then
			(() => {
				return storageUtil.setItem('MOM_APP_ACCESS_TOKEN', token.access_token);
			})
	},

	saveProfile: function (value) {
		_profile = value;
		return storageUtil.setItem('MOM_APP_PROFILE', JSON.stringify(value));
	},

	//TODO
	//replace this with loadProfile
	getProfile: function () {
		return storageUtil.getItem('MOM_APP_PROFILE').then(function (value) {
			return JSON.parse(value);
		});
	},

	loadProfile: function () {
		return storageUtil.getItem('MOM_APP_PROFILE').then(function (value) {
			_profile = JSON.parse(value);
			return _profile;
		});
	},

	saveEditingProfile: function (value) {
		_editingProfile = value;
		return storageUtil.setItem('MOM_APP_EDITING_PROFILE', JSON.stringify(value));
	},

	loadEditingProfile: function () {
		return storageUtil.getItem('MOM_APP_EDITING_PROFILE').then(function (value) {
			_editingProfile = JSON.parse(value);
			return _editingProfile;
		});
	},

	ItemValue: function (itemName) {
		return _editingProfile[itemName];
	},

	profileType: function () {
		return _editingProfile['profileType'];
	},

	saveLoginProfile: function (value) {
		_loginProfile = value;
		return storageUtil.setItem('MOM_APP_LOGIN_PROFILE', JSON.stringify(value));
	},

	loadLoginProfile: function () {
		return storageUtil.getItem('MOM_APP_LOGIN_PROFILE').then(function (value) {
			_loginProfile = JSON.parse(value);
			return _editingProfile;
		});
	},

	LoginProfileItemValue: function (itemName) {
		return _loginProfile[itemName];
	},

	LoginProfile: function () {
		return _loginProfile;
	},


	SubProfiles: function () {
		return _profile.childs;
	},

	/*updatePassword: async function (value, _callback) {
		this.savePassword(value.password);
		MomEnv.callApi('api/Profile/UpdatePassword', value,
			() => {
				if (_callback) _callback();
			}
			, "PUT"
		);
	},*/

	updatePin: async function (value, _callback) {
		storageUtil.setItem('MOM_APP_PIN', JSON.stringify(value.pin)).done();
		//this.updateProfile({ stage: 3 });
		if (_callback) _callback();
	},

	savePin: function (value) {
		storageUtil.setItem('MOM_APP_PIN', JSON.stringify(value)).done();
	},

	getPin: function () {
		return storageUtil.getItem('MOM_APP_PIN').then(function (value) {
			return JSON.parse(value);
		});
	},

	saveUserName: function (value) {
		storageUtil.setItem('MOM_APP_USERNAME', JSON.stringify(value)).done();
	},

	getUserName: function () {
		return storageUtil.getItem('MOM_APP_USERNAME').then(function (value) {
			return JSON.parse(value);
		});
	},

	savePassword: function (value) {
		storageUtil.setItem('MOM_APP_PASSWORD', JSON.stringify(value)).done();
	},

	getPassword: function () {
		return storageUtil.getItem('MOM_APP_PASSWORD').then(function (value) {
			return JSON.parse(value);
		});
	},

	logout: function () {
		storageUtil.clear();
	},

	saveLabResultNotes:function(responseData){
		if(responseData && responseData.length > 0){
			for(var i = 0;i < responseData.length; i++){
				var labResultQualitatives = responseData[i];
				//alert(labResultQualitatives.description);
				if(labResultQualitatives.labResultQualitativeID == 3){					
					storageUtil.setItem('Positive_Text', JSON.stringify(labResultQualitatives.description));
					//storageUtil.setItem('Positive_Text', labResultQualitatives.description).done();
				}else if(labResultQualitatives.labResultQualitativeID == 4){
					storageUtil.setItem('Negative_Text', JSON.stringify(labResultQualitatives.description));
					//storageUtil.setItem('Negative_Text', labResultQualitatives.description).done();
				}
				storageUtil.setItem(getLabResultNotesKey(labResultQualitatives.labResultQualitativeID), JSON.stringify(labResultQualitatives.description));			
				//storageUtil.setItem(getLabResultNotesKey(labResultQualitatives.labResultQualitativeID), labResultQualitatives.description).done();
			}
		}	
	},

	getLabResultNotes(labResultQualitativeID) {
		return storageUtil.getItem(getLabResultNotesKey(labResultQualitativeID)).then(function (value) {
			return JSON.parse(value);
		});
	},

	getPositiveText: function () {
		return storageUtil.getItem('Positive_Text').then(function (value) {
			return JSON.parse(value);
		});
	},

	getNegativeText: function () {
		return storageUtil.getItem('Negative_Text').then(function (value) {
			return JSON.parse(value);
		});
	},

	/*updateSecurityQuestions: async function (value, _callback) {
		MomEnv.callApi('api/Profile/UpdateSecurityQuestions',
			{
				answer1: value.answer1,
				answer2: value.answer2,
				question1: value.question1,
				question2: value.question2
			},
			() => {
				if (_callback) _callback();
			}
			, "PUT"
		);
	},

	updateName: function (value, _callback) {
		MomEnv.callApi('api/Profile/UpdateName', value,
			() => {
				this.updateProfile(value);
				if (_callback) _callback();
			}
			, "PUT"
		);
	},

	updateDOB: function (value, _callback) {
		MomEnv.callApi('api/Profile/UpdateDateOrBirth', value,
			() => {
				this.updateProfile(value);
				if (_callback) _callback();
			}
			, "PUT"
		);
	},

	updateGender: function (value, _callback) {
		MomEnv.callApi('api/Profile/UpdateGender', value,
			() => {
				this.updateProfile(value);
				if (_callback) _callback();
			}
			, "PUT"
		);
	},

	updatePhoto: function (value, _callback) {
		MomEnv.callApi('api/Profile/UpdatePhoto', value,
			(response) => {
				this.updateProfile({ avatarBase64: response.data });
				if (_callback) _callback();
			}
			, "PUT"
		);
	},

	updateNickName: function (value, _callback) {
		MomEnv.callApi('api/Profile/UpdateNickName', value,
			() => {
				this.updateProfile(value);
				if (_callback) _callback();
			}
			, "PUT"
		);
		MomEnv.callApi('api/Profile/GetSurveysCount', null,
			(appStatus) => {
				MomEnv.setNotification(300, appStatus.numNewSurvey);
			},
			"GET"
		);
	},

	acceptTerm: function () {
		if (MomEnv.isDebug) {
			console.log('accept term');
		};

		MomEnv.callApi('api/Profile/Consent', {
			"termAccepted": true
		},
			() => {
				this.updateProfile({ consented: true });
			}
			, "PUT"
		);
	},

	update: function (key, value) {
		return storageUtil.getItem(key).then((item) => {
			item = Object.assign({}, JSON.parse(item), value);
			return storageUtil.setItem(key, JSON.stringify(item));
		});
	},

	updateProfile: function (value) {
		let key = 'MOM_APP_PROFILE';
		item = Object.assign({}, _profile, value);
		_profile = item;
		return storageUtil.setItem(key, JSON.stringify(item));
	},*/

	saveFirstLogin: function (value) {
		storageUtil.setItem('MOM_APP_FIRSTLOGIN', JSON.stringify(value)).done();
	},

	getFirstLogin: function () {
		return storageUtil.getItem('MOM_APP_FIRSTLOGIN').then(function (value) {
			return JSON.parse(value);
		});
	},

	saveEnableTouchID: function (value) {
		storageUtil.setItem('MOM_APP_ENABLETOUCHID', JSON.stringify(value)).done();
	},

	getEnableTouchID: function () {
		return storageUtil.getItem('MOM_APP_ENABLETOUCHID').then(function (value) {
			return JSON.parse(value);
		});
	},

	saveAutoCheckIn: function (value) {
		storageUtil.setItem('MOM_APP_AUTOCHECKIN', JSON.stringify(value)).done();
	},

	getAutoCheckIn: function () {
		return storageUtil.getItem('MOM_APP_AUTOCHECKIN').then(function (value) {
			return JSON.parse(value);
		});
	},

	saveDeviceToken: function (value) {
		storageUtil.setItem('MOM_DEVICE_TOKEN', JSON.stringify(value)).done();
	},

	getDeviceToken: function () {
		return storageUtil.getItem('MOM_DEVICE_TOKEN').then(function (value) {
			return JSON.parse(value);
		});
	},
};

function getLabResultNotesKey(labResultQualitativeID) {
	return LabResultNotesKeyPrefix + labResultQualitativeID;
}
export default ProfileStorage;
