import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import Drexel_Vertical_stacked_Lockup_HEX from '../../content/images/Drexel_Vertical_stacked_Lockup_HEX.png';
import httpUtil from '../../utils/HttpUtil';
import logo_myownmed from '../../content/images/logo_myownmed.png';
import ModalContainer from './../../components/ModalContainer';

const reg = new RegExp("^[A-Za-z0-9]+([._\\-]*[a-z0-9])*@([A-Za-z0-9]+[-a-z0-9]*[A-Za-z0-9]){1,63}.[a-z0-9]+$");
export default class ProblemsSigningIn extends BaseComponent {
    constructor(props) {
        let email = '';
        let profile = JSON.parse(localStorage.getItem('profile'));
        if (profile) {
            email = profile.email;
        }
        super(props);
        this.state = {
            email: email,
            emailFormatOk: email && reg.test(email),
            phone: '',
            type: 1,
            showIndicator: false,
            successSentPinShow:false
        };
    }
    componentDidMount() {

    }

    emailtextchange(text) {
        this.setState({ email: text, emailFormatOk: reg.test(text) });
    }

    _phoneChange(text) {
        text = text.replace(/\D/g, '');
        if (text.length > 0 && text.length < 4) {
            text = '(' + text;
        }
        else if (text.length >= 4 && text.length < 7) {
            text = '(' + text.substring(0, 3) + ') ' + text.substring(3);
        }
        else if (text.length >= 7) {
            text = '(' + text.substring(0, 3) + ') ' + text.substring(3, 6) + '-' + text.substring(6);
        }
        this.setState({ phone: text })
    }
    //TODO:需要继续修改alert
    next() {
        let { email, phone, type } = this.state;
        phone = phone.replace(/\D/g, '')
        if (type === 1 && email === '') {
            alert(
                //Translation.lang().w_Missing,
                this.translate("w_Email_is_required_"));
        }
        else if (type === 2 && phone === '') {
            alert(
                //Translation.lang().w_Missing,
                this.translate("w_Phone_number_is_required_"));
        }
        else if (type === 2 && phone.length !== 10) {
            alert(
                //Translation.lang().w_Check_Phone_Number,
                this.translate("w_Please_enter_10_digit_phone_number_text_"));
        }
        else {
            this.setState({ showIndicator: true });
            httpUtil.post('api/Participant/ResetPin', { email: email, type: type, phonenumber: phone, oriEmail: this.props.email },
                (responseData) => {
                    this.setState({ showIndicator: false });
                    if (responseData && responseData.status === 'Success') {
                        if (responseData.data === '1') {
                            //alert(
                                //Translation.lang().w_Temporary_PIN,
                                //this.translate("w_Your_temporary_PIN_been_sent"),
                                // [
                                //     {
                                //         text: Translation.lang().w_ok, onPress: () => {
                                //             if (type == 2 || email != this.props.email) {
                                //                 Profile.removeProfile().then(() => {
                                //                     MomEnv.saveStep('login');
                                //                 }).done();
                                //             } else {
                                //                 MomEnv.back();
                                //             }
                                //         }
                                //     },
                                // ]
                                
                            //);
                            this.setState({successSentPinShow: true})
                        }
                        else if (responseData.data === '0') {
                            if (type === 1) {
                                alert(
                                    //Translation.lang().Email_Does_Not_Exist,
                                    this.translate("w_Email_does_not_exist_Please_check_email_")
                                    //     , [
                                    //     { text: Translation.lang().w_ok, onPress: () => { } }
                                    // ]
                                );
                            } else {
                                alert(
                                    //Translation.lang().w_Check_Phone_Number, 
                                    this.translate("Phone_number_please_provide"));
                            }
                        }
                        else if (responseData.data === '-1') {
                            if (type === 1) {
                                //Alert.
                                alert(
                                    //Translation.lang().Email_Does_Not_Exist, 
                                    this.translate("Email_address_new_account")
                                    // , [
                                    //     { text: Translation.lang().w_cancel, onPress: () => { } },
                                    //     { text: Translation.lang().Create, onPress: () => { MomEnv.pushPage(Email); } },
                                    // ]
                                );
                            } else {
                                //Alert.
                                alert(
                                    //Translation.lang().SMS_Service_Unavailable, 
                                    this.translate("Unfortunately_the_SMS_service"));
                            }
                        }
                        else if (responseData.data === '-1') {
                            if (type === 1) {
                                alert(
                                    //Translation.lang().Service_Unavailable,
                                    this.translate("Unfortunately_Please_try_again"));
                            } else {
                                alert(
                                    //Translation.lang().SMS_Service_Unavailable,
                                    this.translate("Unfortunately_the_SMS_service"));
                            }
                        }
                        else if (responseData.data === '-2') {
                            if (type === 2) {
                                alert(//Translation.lang().Check_Phone_Number,
                                    this.translate("Phone_number_please_provide"));
                            }
                        }
                        else if (responseData.data === '-3') {
                            if (type === 1) {
                                alert(
                                    //Translation.lang().Invalid_Email, 
                                    this.translate("Email_is_please_check"));
                            }
                        }
                    } else {
                        if (type === 1) {
                            alert(//Translation.lang().Service_Unavailable,
                                this.translate("Unfortunately_Please_try_again"));
                        } else {
                            alert(//Translation.lang().SMS_Service_Unavailable,
                                this.translate("Unfortunately_the_SMS_service"));
                        }
                    }
                }
            );
        }
    }


    render() {
        let { email, phone, type, emailFormatOk, successSentPinShow} = this.state;
        let sendable = (type === 1 && emailFormatOk) || phone.length === 14;
        return (
            <div className="conatiner" style={{ justifyContent: 'flex-start',width:'100%',marginTop:50}}>
                <div style={{width:'75%'}}>
                <div style={{
                    alignSelf: 'stretch',
                    marginTop: -10,
                    marginBottom: 5
                }}>
                    <p style={{ fontSize: 18 }}>{this.translate("Problems_signing_in")}</p>
                    <p style={{ fontSize: 12,color:'#808080'}}>{this.translate("You_can_SMS_receive")}</p>
                </div>
                <div style={styles.wrapper}>
                    <p style={{ fontSize: 12, color: '#f00' }}>{this.translate("Once_sign_in_temporary_PIN")}</p>
                    {type === 1 &&
                        <input
                            className="input1"
                            type='text'
                            style={{ marginTop: 2, }}
                            placeholder={this.translate("w_Your_email_address")}
                            value={email}
                            onChange={(e) =>{
                                this.emailtextchange(e.target.value);
                            }}
                        />
                    }
                    {type === 2 &&
                        <input
                            className="input1"
                            type='text'
                            style={{ marginTop: 2, }}
                            placeholder={"xxx-xxx-xxxx"}
                            value={phone}
                            onChange={(e) =>{
                                this._phoneChange(e.target.value);
                            }}
                        />
                    }
                    <div style={{ alignSelf: 'stretch', flexDirection: 'row', display: 'flex', justifyContent: 'space-around', margin: 20 }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <div onClick={() => { this.setState({ type: 1 }) }} style={{ width: 20, height: 20, borderRadius: 10, borderColor: '#bbb', borderWidth: 1, borderStyle: 'solid', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    type === 1 &&
                                    <div style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: '#bbb', margin: 4 }} />
                                }
                            </div>&nbsp;{this.translate("w_email")}

                        </div>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <div onClick={() => { this.setState({ type: 2 }) }} style={{ width: 20, height: 20, borderRadius: 10, borderStyle: 'solid', borderColor: '#bbb', borderWidth: 1, justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    type === 2 &&
                                    <div style={{ width: 12, height: 12, borderRadius: 6, margin: 4, backgroundColor: '#bbb' }} />
                                }
                            </div>
                            &nbsp;
                            {this.translate("w_SMS")}
                        </div>
                    </div>
                    {
                        type === 2 &&
                        <div style={{ alignSelf: 'stretch', marginTop: 30, alignItems: 'center', justifyContent: 'center', color: '#f00', fontSize: 12 }}>
                            {this.translate("Message_data_rates")}
                        </div>
                    }
                    <div> </div>

                </div>
                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 100 }}>
                    <button onClick={() => this.props.history.goBack()} className="button" style={styles.notSendbutton}>
                        {this.translate('w_cancel')}
                    </button>
                    <button onClick={this.next.bind(this)} className="button" style={{ width: 150 }}>
                        {this.translate('w_Send')}
                    </button>
                </div>
                </div>
                {successSentPinShow && 
                    <ModalContainer>
                        <div className="alert_title">
                            {this.translate('w_Temporary_PIN')}
                        </div>
                        <div className="alert_content">
                            {this.translate('w_Your_temporary_PIN_been_sent')}
                        </div>
                        <div
                            className="alert_button"
                            onClick={() => {
                                this.setState({successSentPinShow:false});
                                this.props.history.goBack()
                            }}
                        >
                            {this.translate('w_ok')}
                        </div>
                    </ModalContainer>
                }

            </div>    
        );
    }

}
const styles = {
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',

    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5,
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    txtErr: {
        color: '#f00',
        marginTop: 3,
        fontSize: 14
    },
    txtOK: {
        color: '#00b359',
        marginTop: 3,
        fontSize: 14
    },
    wrapper: {
        alignSelf: 'stretch',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#eee',
        borderWidth: 1,
        borderRadius: 3,
        padding: 15,
        paddingTop: 20,
        marginTop: 20,
        borderStyle: "solid",
        display: "flex",
        flexDirection: "column"
    },
    Sendbutton: {
        width: 150,

    },
    notSendbutton: {
        width: 150,
        color: '#999',
        backgroundColor: 'rgb(238,238,238)',
    }
};