import React from 'react';
import BaseComponent from './BaseComponent';
import "../content/style/tabbar.css";
import track_logo from '../content/images/tab-track.png';
import resources_logo from '../content/images/tab-resources.png';
import calendar_logo from '../content/images/tab-calendar.png';
import more_logo from '../content/images/tab-more.png';
import track_logo_off from '../content/images/tab-track-off.png';
import resources_logo_off from '../content/images/tab-resources-off.png';
import more_logo_off from '../content/images/tab-more-off.png';
import tabTelephone from '../content/images/tabTelephone.png';
import tabMessage from '../content/images/tabMessage.png';
import CommonUtils from './../utils/CommonUtil';
import httpUtil from '../utils/HttpUtil';
import ModalContainer from './ModalContainer';
import Loading from './Loading';
export default class TabBarContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            suveryCount: localStorage.getItem('survey_count'),
            wikiCount: localStorage.getItem('wiki_count'),
            messageCount : localStorage.getItem('msg_count'),
            need_alert_survey: '',
            moreCount :  '',//parseInt(localStorage.getItem('survey_count') ?? "0") + parseInt (localStorage.getItem('wiki_count') ?? "0"),
            loading: false
        };
    }

    componentDidMount() {
        httpUtil.get('/api/Profile/GetAppStatus',
            (appStatus) => {
                if (appStatus && appStatus.hasOwnProperty("numNewMsg")) {
                    localStorage.setItem('msg_count', JSON.stringify(appStatus.numNewMsg));
                    localStorage.setItem('survey_count', JSON.stringify(appStatus.numNewSurvey));
                    localStorage.setItem('wiki_count', JSON.stringify(appStatus.numNewWiki));
                    this.setState({ suveryCount: localStorage.getItem('survey_count'), wikiCount: localStorage.getItem('wiki_count') });
                    if (localStorage.getItem('survey_count') == 0) {
                        localStorage.setItem('need_alert_survey', 0);
                    }
                }
            });
    }

    componentWillReceiveProps(){
        if(this.props.need_alert_survey && this.props.need_alert_survey !== this.state.need_alert_survey){
            this.setState({need_alert_survey: this.props.need_alert_survey});
        }
    }
    
    tabbarItemClick(item) {
        if (item === 'home') {
            this.props.history.replace("/trackmain", { viewSummary: localStorage.getItem('viewSummary')});    
        } else if (item === 'calendar') {
            this.props.history.replace('/home/calendar');
        } else if (item === 'resources') {
            this.props.history.replace("/home/resources");
        } else if (item === 'more') {
            this.props.history.replace("/home/more");
        } else if (item === 'checkIn') {
            this._FirstConfirmCheckIn(); 
        } else if (item == 'messages'){ 
            this.props.history.replace("/home/messages");
        }
    }
    _FirstConfirmCheckIn() {
        // Alert.alert(Translation.lang().w_Confirm_Check_In_title, Translation.lang().w_Confirm_Check_In_Content, [
        //     {
        //         text: Translation.lang().w_Confirm_Check_In_yes, onPress: () => {
        //             this._CheckInV2();
        //         }
        //     },
        //     {
        //         text: Translation.lang().w_Confirm_Check_In_no, onPress: () => {
        //             this.props.history.replace("/home");
        //         }
        //     },

        // ])
        this.setState({ need_alert_survey: 1 });
    }
    _CheckInV2() {
        this.setState({ loading: true });
        let profile = JSON.parse(localStorage.getItem('profile'));
        httpUtil.post('/api/Participant/CheckEffectiveSelfCertification', {
            profileId: profile.id, CheckDate: new Date()//MomEnv.toUtcDayString(new Date())
        },
            (responseData) => {
                this.setState({ loading: false });
                if (responseData && responseData.data == '1') {
                    this.setState({ need_alert_survey: 2 });
                    // Alert.alert(Translation.lang().w_View_Self_Certification_title, Translation.lang().w_View_Self_Certification_content, [
                    //     {
                    //         text: Translation.lang().w_ok, onPress: () => {
                    //             MomEnv.saveStep('home', null, { viewSummary: true });
                    //         }
                    //     },
                    // ]);
                } else if (responseData && responseData.data == '2') {
                    this.setState({ need_alert_survey: 3 });
                    // Alert.alert(Translation.lang().w_Unable_to_checkin_title, Translation.lang().w_Unable_to_checkin_content, [
                    //     {
                    //         text: Translation.lang().w_ok, onPress: () => {
                    //             MomEnv.saveStep('home', null);
                    //         }
                    //     },
                    // ]);
                }
                else {
                    this.setState({ need_alert_survey: 4 });
                    // Alert.alert(Translation.lang().w_View_Your_Symptoms_title, Translation.lang().w_View_Your_Symptoms_content, [
                    //     {
                    //         text: Translation.lang().w_ok, onPress: () => {
                    //             MomEnv.saveStep('home', null);
                    //         }
                    //     },
                    // ]);
                }

            }
        );
    }
    render() {
        let { tab } = this.props;
        return (
            <div className="conatiner">
                <div className="conatiner">
                    {this.props.children}
                </div>
                <div style={{
                    alignSelf: "stretch",
                    backgroundColor: '#f6f6f6',
                    height: 52,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    position:CommonUtils.isPC()?'static':'fixed',
                    bottom:0,
                    left:0,
                    right:0
                }}>
                    <div
                        className={tab === 'home' ? 'tabbar active' : 'tabbar'}
                        onClick={this.tabbarItemClick.bind(this, 'home')}
                    >
                        {/*
                            this.state.suveryCount && this.state.suveryCount > 0 && <div className="count">{this.state.suveryCount}</div>
                        */}
                        <img
                            src={tab === 'home' ? track_logo : track_logo_off}
                            resizemode={'contain'}
                            style={styles.logo}
                        />
                        <div className={tab === 'home' ? 'title active' : 'title'}>
                            {this.translate('w_symptoms')}
                        </div>

                    </div>
                    <div
                        className={tab === 'checkIn' ? 'tabbar active' : 'tabbar'}
                        onClick={this.tabbarItemClick.bind(this, 'checkIn')}
                    >
                        {/*
                            this.state.suveryCount && this.state.suveryCount > 0 && <div className="count">{this.state.suveryCount}</div>
                        */}
                        <img
                            src={tab === 'checkIn' ? tabTelephone : tabTelephone}
                            resizemode={'contain'}
                            style={styles.logo}
                        />
                        <div className={tab === 'checkIn' ? 'title active' : 'title'}>
                            {this.translate('Check_In')}
                        </div>

                    </div>                    
                    <div
                        className={tab === 'messages' ? 'tabbar active' : 'tabbar'}
                        onClick={this.tabbarItemClick.bind(this, 'messages')}
                    >
                        {
                            this.state.messageCount && this.state.messageCount > 0 && <div className="count">{this.state.messageCount}</div>
                        }
                        <img
                            src={tab === 'messages' ? tabMessage : tabMessage}
                            resizemode={'contain'}
                            style={styles.logo}
                        />
                        <div className={tab === 'messages' ? 'title active' : 'title'}>
                            {this.translate('w_message')}
                        </div>

                    </div>
                    <div
                        className={tab === 'calendar' ? 'tabbar active' : 'tabbar'}
                        onClick={this.tabbarItemClick.bind(this, 'calendar')}
                    >
                        <img
                            src={tab === 'calendar' ? calendar_logo : calendar_logo}
                            resizemode={'contain'}
                            style={styles.logo}
                        />
                        <div className={tab === 'calendar' ? 'title active' : 'title'}>
                            {this.translate('Calendar')}
                        </div>
                    </div>
                    {/* <div
                        className={tab === 'resources' ? 'tabbar active' : 'tabbar'}
                        onClick={this.tabbarItemClick.bind(this, 'resources')}
                    >
                        {
                            this.state.wikiCount && this.state.wikiCount > 0 && <div className="count">{this.state.wikiCount}</div>
                        }
                        <img
                            src={tab === 'resources' ? resources_logo : resources_logo_off}
                            resizemode={'contain'}
                            style={styles.logo}
                        />
                        <div className={tab === 'resources' ? 'title active' : 'title'}>
                            {this.translate('Resources')}
                        </div>
                    </div> */}
                    <div
                        className={tab === 'more' ? 'tabbar active' : 'tabbar'}
                        onClick={this.tabbarItemClick.bind(this, 'more')}
                    >
                    {
                        this.state.moreCount && this.state.moreCount > 0 && <div className="count">{this.state.moreCount}</div>
                    }
                        <img
                            src={tab === 'more' ? more_logo : more_logo_off}
                            resizemode={'contain'}
                            style={styles.logo}
                        />
                        <div className={tab === 'more' ? 'title active' : 'title'}>
                            {this.translate('More')}
                        </div>
                    </div>
                </div>
                {this.state.loading && <Loading />}
                {
                    this.state.need_alert_survey == "1" &&
                    <ModalContainer>
                        <div className="alert_title">
                            {this.translate('w_Confirm_Check_In_title')}
                        </div>
                        <div className="alert_content">
                            {this.translate('w_Confirm_Check_In_Content')}
                        </div>
                        <div
                            className="alert_button"
                            onClick={() => {
                                this._CheckInV2();
                                //this.setState({ need_alert_survey: 2 });
                            }}
                        >
                            {this.translate('w_Confirm_Check_In_yes')}
                        </div>
                        <div
                            className="alert_button"
                            onClick={() => {
                                this.setState({ need_alert_survey: 0 });
                                this.props.history.replace("/trackmain", { viewSummary: true });
                            }}
                        >
                            {this.translate('w_Confirm_Check_In_no')}
                        </div>
                    </ModalContainer>
                }
                {
                    this.state.need_alert_survey == "2" &&
                    <ModalContainer>
                        <div className="alert_title">
                            {this.translate('w_View_Self_Certification_title')}
                        </div>
                        <div className="alert_content">
                            {this.translate('w_View_Self_Certification_content')}
                        </div>
                        <div
                            className="alert_button"
                            onClick={() => {
                                this.setState({ need_alert_survey: 0 });
                                this.props.history.replace("/trackmain");
                            }}
                        >
                            {this.translate('w_ok')}
                        </div>
                    </ModalContainer>
                }
                {
                    this.state.need_alert_survey == "3" &&
                    <ModalContainer>
                        <div className="alert_title">
                            {this.translate('w_Unable_to_checkin_title')}
                        </div>
                        <div className="alert_content">
                            {this.translate('w_Unable_to_checkin_content')}
                        </div>
                        <div
                            className="alert_button"
                            onClick={() => {
                                this.setState({ need_alert_survey: 0 });
                                this.props.history.replace("/trackmain");
                            }}
                        >
                            {this.translate('w_ok')}
                        </div>
                    </ModalContainer>
                }
                {
                    this.state.need_alert_survey == "4" &&
                    <ModalContainer>
                        <div className="alert_title">
                            {this.translate('w_View_Your_Symptoms_title')}
                        </div>
                        <div className="alert_content">
                            {this.translate('w_View_Your_Symptoms_content')}
                        </div>
                        <div
                            className="alert_button"
                            onClick={() => {
                                this.setState({ need_alert_survey: 0 });
                                this.props.history.replace("/trackmain");
                            }}
                        >
                            {this.translate('w_ok')}
                        </div>
                    </ModalContainer>
                }
            </div>
        );
    }
}

const styles = {
    logo: {
        height: 21,
        width: 21,
        margin: 0,
        marginBottom: 2
    }
};
