import React, { Component }  from 'react';
import Container from './../../components/Container.js';
import TabBarContainer from '../../components/TabBarContainer.js';
import httpUtil from '../../utils/HttpUtil.js';
import ScrollView from '../../components/ScrollView.js';
import Loading from './../../components/Loading.js';
import Questionnaire from '../account/Questionnaire.js';
import ModalContainer from '../../components/ModalContainer.js';
import i18n from 'i18next';
const reg = new RegExp("^[A-Za-z0-9]*[A-Za-z][A-Za-z0-9 ]*[A-Za-z][A-Za-z0-9 ]*");
export default class NameAndMobileNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            register_f_name: '',
            register_l_name: '',
            register_email: '',
            register_phone: '',
            loading: false,
            serviceErrAlert:false
        };
    }

    componentDidMount() {
        if(this.props.location.state){
            this.setState({ register_email: this.props.location.state.email });
        }      
    }

    translate(key) {
        return i18n.t(key);
    }

    next() {
        console.log(this.props);
        httpUtil.post('api/PreScreener/UpdatePreScreener',{
            email: this.state.register_email,
            ContactFirstName: this.state.register_f_name,
            ContactLastName: this.state.register_l_name,
            ContactPhone: this.state.register_phone.replace(/\D/g, ''),
            fAvalidated: this.props.location.state.fAvalidated ? this.props.location.state.fAvalidated : this.props.location.state.rmodel.fAvalidated,
            clientConsent: this.props.location.state.clientConsent
        },
        (responseData) => {
            if (responseData && responseData.status == 'Success') {
            }
            else {
                this.setState({serviceErrAlert:true});
            }
        });
        this.props.history.push('/Questionnaire',
            {
                rmodel: this.props.location.state.rmodel ,
                email: this.props.location.state.email ? this.props.location.state.email : this.props.location.state.rmodel.email,
                zipcode: this.props.location.state.zipcode? this.props.location.state.zipcode : this.props.location.state.rmodel.zipCode,
                had36monthchildren: this.props.location.state.had36monthchildren,
                hepatitis: this.props.location.state.hepatitis,
                hadimmunocompromised: this.props.location.state.hadimmunocompromised,
                chlidcount: this.props.location.state.chlidcount,
                childs: this.props.location.state.childs,
                locationId: this.props.location.state.locationId,
                locationName: this.props.location.state.locationName,
                locationAddress: this.props.location.state.locationAddress,
                age: this.props.location.state.age ? this.props.location.state.age: this.props.location.state.rmodel.age,
                firstName: this.state.register_f_name ? this.state.register_f_name : this.props.location.state.rmodel.firstName,
                lastName: this.state.register_l_name ? this.state.register_l_name :  this.props.location.state.rmodel.lastName,
                phone: this.state.register_phone ? this.state.register_phone.replace(/\D/g, '') : this.props.location.state.rmodel.phoneNumber.replace(/\D/g, ''),
                fAvalidated:this.props.location.state.fAvalidated ? this.props.location.state.fAvalidated : this.props.location.state.rmodel.fAvalidated,
                clientConsent: this.props.location.state.clientConsent
            });
    }

    _phoneChange(text) {
        text = text.replace(/\D/g, '');
        if (text.length > 0 && text.length < 4) {
            text = '(' + text;
        }
        else if (text.length >= 4 && text.length < 7) {
            text = '(' + text.substring(0,3) + ') ' + text.substring(3);
        }
        else if (text.length >= 7) {
            text = '(' + text.substring(0,3) + ') ' + text.substring(3, 6) + '-' + text.substring(6);
        }
        this.setState({ register_phone: text })
    }
    
    render() {
        let { register_f_name, register_l_name, register_email, register_phone,loading,serviceErrAlert } = this.state;
        return (
            <div className="conatiner" style={styles.borderContainer}>
                <p style={
                   {
                       color: '#666',
                       fontSize: 14,
                       alignSelf: 'flex-start',
                       marginLeft: 25,
                       marginTop: 5,
                       wordBreak:'break-word',
                       fontWeight:800
                   }}>
                   {this.translate('Thank_you_for_taking_the')}
                </p>
                <div style={{marginBottom:20,wordBreak:'break-word'}}>
                    <span style={{
                       color: '#666',
                       fontSize: 14,
                       alignSelf: 'flex-start',
                       marginLeft: 25,
                       marginTop: 5
                    }}>
                        {this.translate('By_providing_this_information')}
                    </span>
                    <br/>
                    <span style={{ flexDirection: 'row', alignSelf: 'stretch' }}>
                        <span style={{
                            color: '#666',
                            fontSize: 14,
                            alignSelf: 'flex-start',
                            marginLeft: 25,
                            marginTop: 5
                        }}>
                            {this.translate('MyOwnMed')}
                        </span>
                        <a onClick={() => {
                            this.props.history.push("/home/more/settings/termsofUse");
                        }} style={{ color: 'green',cursor:'pointer' }}>
                            {this.translate('w_termOfUse')}
                        </a>
                        <span>
                            {this.translate('_and')}
                        </span>
                        <a  onClick={() => {
                            this.props.history.push("/home/more/settings/privacy");
                        }} style={{ color: 'green',cursor:'pointer'}}>
                            {this.translate('Privacy_Policy_')}
                        </a>
                    </span>
                    <br/>
                    <p style={{
                       color: '#666',
                       fontSize: 14,
                       alignSelf: 'flex-start',
                       margin:0,
                       marginRight:25,
                       marginLeft: 25,
                       wordBreak:'break-word',
                       
                    }}>
                        {this.translate('This_app_will_only_collect')}
                    </p>
                </div>
               <p style={
                   {
                       color: '#666',
                       fontSize: 14,
                       alignSelf: 'flex-start',
                       marginLeft: 25,
                       marginTop: 5,
                       fontWeight:800
                   }}>
                   {this.translate('w_firstName')}
               </p>
               <input
                   className="input1"
                   type='text'
                   style={{ marginTop: 2,marginBottom:0 }}
                   placeholder={this.translate('w_Enter_your_ﬁrst_name')}
                   value={register_f_name}
                   onChange={(e) => this.setState({ register_f_name: e.target.value })}
               />
                    {
                        this.state.register_f_name.length == 0 &&
                        <p style={styles.errText}>
                            {this.translate('x_First_name_is_required')}
                        </p>
                    }
                    {
                        this.state.register_f_name.length > 0 && !reg.test(this.state.register_f_name) &&
                        <p style={styles.errText}>
                            {this.translate('x_First_name_required_characters')}
                        </p>
                    }
               <p style={
                   {
                       color: '#666',
                       fontSize: 14,
                       alignSelf: 'flex-start',
                       marginLeft: 25,
                       marginTop: 2,
                       fontWeight:800
                   }}>
                   {this.translate('w_lastName')}
               </p>
               <input
                   className="input1"
                   type='text'
                   style={{ marginTop: 2, }}
                   placeholder={this.translate('w_Enter_your_last_name')}
                   value={register_l_name}
                   onChange={(e) => this.setState({ register_l_name: e.target.value })}
               />
                    {
                        this.state.register_l_name.length == 0 &&
                        <p style={styles.errText}>
                            {this.translate('x_Last_name_is_required')}
                        </p>
                    }
                    {
                        this.state.register_l_name.length > 0 && !reg.test(this.state.register_l_name) &&
                        <p style={styles.errText}>
                            {this.translate('x_Last_name_requires_characters')}
                        </p>
                    }
               <p style={
                   {
                       color: '#666',
                       fontSize: 14,
                       alignSelf: 'flex-start',
                       marginLeft: 25,
                       marginTop: 2,
                       fontWeight:800
                   }}>
                   {this.translate('w_Phone_Number_')}
               </p>
               <input
                   className="input1"
                   type='text'
                   maxLength={14}
                   style={{ marginBottom: 5, marginTop: 2 }}
                   placeholder={'xxx-xxx-xxxx'}
                   value={register_phone}
                   onChange={(e) => this._phoneChange(e.target.value)}
               />
                    {
                        this.state.register_phone.length == 0 &&
                        <p style={styles.errText}>
                            {this.translate('x_Mobile_number_is_required')}
                        </p>
                    }
                    {
                        this.state.register_phone != null && this.state.register_phone.length > 0 && this.state.register_phone.length < 14 &&
                        <p style={styles.errText}>
                            {this.translate('x_Mobile_number_must')}
                        </p>
                    }
               {register_phone && register_l_name && register_f_name && 
               register_f_name.length > 0 && reg.test(this.state.register_f_name) &&
               register_l_name.length > 0 && reg.test(this.state.register_l_name) &&
               register_phone.length == 14 &&
               <button onClick={this.next.bind(this)} style={styles.button}>
                   {this.translate('w_next')}
               </button>}
               {serviceErrAlert && <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_Warning')}
                            </div>
                            <div className="alert_content">
                                {this.translate('Service_error_please')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    this.setState({serviceErrAlert:false});
                                }}
                            >
                                {this.translate('w_ok')}
                            </div>
                        </ModalContainer>}
               {this.state.loading && <Loading />}    
            </div>
        );
    }
}

const styles = {
    errText:{
        marginTop: 10,
        color:'#f00',
        alignSelf:'flex-start',
        marginLeft:25
    },
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',
    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5,
        width:'100%',
        height:'100%',
        justifyContent: 'flex-start',
        paddingTop:20
    },
    borderContainer1: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    button: {
        backgroundColor: "#123b6d",
        alignSelf: "stretch",
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
        marginBottom: 10,
        color: "#fff",
        height: 48,
        border: 0,
        borderRadius: 3,
        fontSize: 18
    }
};