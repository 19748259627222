import React, { Component } from 'react';

export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {token: null};
  }

  componentDidMount() {     
  }

  render() {
    return (
       <div style={styles.container}>
         {this.props.children}
       </div>
    );
  }
}

const styles = {
  container:{    
      alignSelf:"stretch",
      flex:1,
  },
};