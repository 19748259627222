import React, { Component } from 'react';
//import MomEnv from '../../config/Environment';
//import LoginSvr from '../../../Service/LoginSvr';
//import CollectInformation from './CollectInformation';
import HttpUtil from '../../utils/HttpUtil.js';
import Loading from './../../components/Loading';
import ModalContainer from './../../components/ModalContainer.js';
import i18n from 'i18next';
import commonUtils from './../../utils/CommonUtil.js'
const globalColor = require('./../../utils/Color');

export default class AuthenticationEmail extends Component {
    constructor(props) {
        super(props);
        var email = '';
        if(this.props.location.state && this.props.location.state.email){
            email = this.props.location.state.email;
        }
        this.state = {
            email: email,
            code1: '',
            code2: '',
            code3: '',
            code4: '',
            code5: '',
            code6: '',
            codeConfirm: false,
            loading: false,
            showCodeErrorAlert:false,
            showResendAlert:false
        };
        this.VerifyAuthenticationemail = this.VerifyAuthenticationemail.bind(this);
        this.ResendEmail = this.ResendEmail.bind(this);
    }

    translate(key) {
        return i18n.t(key);
    }

    componentDidMount(){
        this.SendVerifyAuthenticationEmail();
    }

    next() {
        let { email, code1, code2, code3, code4, code5, code6 } = this.state;
        if (email != this.props.location.state.email) {
            alert(this.translate('w_Email_not_match'));
        } else {
            HttpUtil.post('/api/Participant/VerifyPasscode',  { email: email, pin: `${code1}${code2}${code3}${code4}${code5}${code6}` },
            (responseData) => {
                if (responseData && responseData.status == 'Success') {                       
                    this.props.history.push('/Consent', { email: email,fAvalidated:true, 
                        rmodel: this.props.location.state.rmodel });
                }else{
                    this.setState({showCodeErrorAlert : true});
                }
            });
        }
    }

    _oncodeTextChange(text, index) {
        text = text.toString().replace(/\D/g, '');
        if (index == 1) {
            if (text != '') {
                this.refs.code2.focus();
            }
            this.setState({ code1: text.toString() });
        } else if (index == 2) {
            if (text != '') {
                this.refs.code3.focus();
            }
            this.setState({ code2: text.toString() });
        } else if (index == 3) {
            if (text != '') {
                this.refs.code4.focus();
            }
            this.setState({ code3: text.toString() });
        } else if (index == 4) {
            if (text != '') {
                this.refs.code5.focus();
            }
            this.setState({ code4: text.toString() });
        } else if (index == 5) {
            if (text != '') {
                this.refs.code6.focus();
            }
            this.setState({ code5: text.toString() });
        } else if (index == 6) {
            if (text != '') {
                this.refs.code6.blur();
            }
            this.setState({ code6: text.toString(), codeConfirm: true });
        }

    }

    SendVerifyAuthenticationEmail(){
        let { email } = this.state;
        this.VerifyAuthenticationemail(email);
    }

    VerifyAuthenticationemail(email,callback){
        HttpUtil.post('/api/Participant/VerifyAuthenticationemail', { email: email },
        (responseData) => {
            if (responseData && responseData.status == 'Success') {
                if(callback){
                    callback();
                }                  
            }
        });
    }

    ResendEmail() {
        let { email } = this.state;
        var that = this;
        var callback = function(){
            that.setState({showResendAlert:true});
        }
        this.VerifyAuthenticationemail(email,callback);
    }

    _oncodeKeyPress(e, index) {
        if (e.nativeEvent.key == 'Backspace') {
            if (index == 6) {
                this.refs.code5.focus();
            } else if (index == 5) {
                this.refs.code4.focus();
            } else if (index == 4) {
                this.refs.code3.focus();
            } else if (index == 3) {
                this.refs.code2.focus();
            } else if (index == 2) {
                this.refs.code1.focus();
            }
        }
    }

    render() {
        let { code1, code2, code3, code4, code5, code6, codeConfirm,loading,showCodeErrorAlert,showResendAlert } = this.state;

        let codeConfirm1 = code1 && code2 && code3 && code4 && code5 && code6;
        return (
            <div style={styles.container2}>
                    <div style={styles.content}>
                        <div style={styles.normalFont, styles.boldFont, { color: globalColor.textColor, marginTop: 20,width:'100%',fontWeight:'800' }}>
                            {this.translate('w_Two_factor_authentication')}
                        </div>
                        <div style={styles.normalFont, { color: globalColor.textColor,  marginTop: 20,marginBottom:40,width:'100%' }}>
                            {this.translate('w_anthentication_text1')}
                        </div>
                        <span style={styles.normalFont, { color: globalColor.textColor,  marginTop: 20 ,width:'100%'}}>
                            {this.translate('w_anthentication_text2')}
                        </span>

                        <div style={styles.normalFont, styles.boldFont, { color: globalColor.textColor, marginTop: 20,width:'100%',fontWeight:'800' }}>
                            {this.translate('w_Enter_Code')}
                        </div>
                        <div style={{ alignSelf: 'stretch', marginTop: 15, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                            <input
                                type={"password"}
                                key={'code1'}
                                ref={'code1'}
                                maxLength={1}
                                password={true}
                                secureTextEntry={false}
                                style={styles.normalFont, styles.code}
                                value={this.state.code1}
                                onChange={(e) => this._oncodeTextChange(e.target.value, 1)}
                                onKeyDown={(e) => this._oncodeKeyPress(e, 1)}
                                onFocus={() => { this.setState({ code1: '' }); }}
                                underlineColorAndroid={'#000'}
                            />
                            <input
                                type={"password"}
                                key={'code2'}
                                ref={'code2'}
                                maxLength={1}
                                password={true}
                                secureTextEntry={false}
                                style={styles.normalFont,styles.code}
                                value={this.state.code2}
                                onChange={(e) => this._oncodeTextChange(e.target.value, 2)}
                                onKeyDown={(e) => this._oncodeKeyPress(e, 2)}
                                onFocus={() => { this.setState({ code2: '' }); }}
                            />
                            <input
                                type={"password"}
                                key={'code3'}
                                ref={'code3'}
                                maxLength={1}
                                password={true}
                                secureTextEntry={false}
                                style={styles.normalFont,styles.code}
                                value={this.state.code3}
                                onChange={(e) => this._oncodeTextChange(e.target.value, 3)}
                                onKeyDown={(e) => this._oncodeKeyPress(e, 3)}
                                onFocus={() => { this.setState({ code3: '' }); }}
                            />
                            <input
                                type={"password"}
                                key={'code4'}
                                ref={'code4'}
                                maxLength={1}
                                password={true}
                                secureTextEntry={false}
                                style={styles.normalFont,styles.code}
                                value={this.state.code4}
                                onChange={(e) => this._oncodeTextChange(e.target.value, 4)}
                                onKeyDown={(e) => this._oncodeKeyPress(e, 4)}
                                onFocus={() => { this.setState({ code4: '' }); }}
                            />
                            <input
                                type={"password"}
                                key={'code5'}
                                ref={'code5'}
                                maxLength={1}
                                password={true}
                                secureTextEntry={false}
                                style={styles.normalFont,styles.code}
                                value={this.state.code5}
                                onChange={(e) => this._oncodeTextChange(e.target.value, 5)}
                                onKeyDown={(e) => this._oncodeKeyPress(e, 5)}
                                onFocus={() => { this.setState({ code5: '' }); }}
                            />
                            <input
                                type={"password"}
                                key={'code6'}
                                ref={'code6'}
                                maxLength={1}
                                password={true}
                                secureTextEntry={false}
                                style={styles.normalFont, styles.code}
                                value={this.state.code6}
                                onChange={(e) => this._oncodeTextChange(e.target.value, 6)}
                                onKeyDown={(e) => this._oncodeKeyPress(e, 6)}
                                onFocus={() => { this.setState({ code6: '' }); }}
                            />
                        </div>
                        <button className="button" style={{width:'85%', margin: 20, marginTop: 25, backgroundColor: codeConfirm1 ? globalColor.buttoUunderlayColor : '#d9d9d9' }}
                            //underlayColor={globalColor.buttoUunderlayColor}
                            onClick={() => {
                                if (codeConfirm1) {
                                    this.next()
                                }
                            }
                            }
                        >
                            <span style={styles.normalFont}>
                                {this.translate('w_next')}
                            </span>
                        </button>
                        <div style={{ textAlign:'center',alignSelf: 'stretch', marginTop: 30, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={styles.normalFont, { color: '#000' }}>
                                {this.translate('w_Didnt_get_the_passcode')}
                            </span>
                            <a style={styles.normalFont, { color: '#3e93cf',cursor:'pointer'}} onClick={() => { this.ResendEmail() }}>
                                    {this.translate('w_Resend')}
                            </a>
                        </div>
                        {showCodeErrorAlert && <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_Invalid_Passcode_title')}
                            </div>
                            <div className="alert_content">
                                {this.translate('w_Invalid_Passcode_content')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    this.setState({ code1: "", code2: "", code3: "", code4: "", code5: "", code6: "", showCodeErrorAlert:false});
                                }}
                            >
                                {this.translate('w_Invalid_Passcode_left')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    this.props.history.goBack();
                                }}
                            >
                                {this.translate('w_Invalid_Passcode_right')}
                            </div>
                        </ModalContainer>}
                        {showResendAlert && <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_New_Passcode_title')}
                            </div>
                            <div className="alert_content">
                                {this.translate('w_New_Passcode_content')}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    this.setState({showResendAlert:false});
                                }}
                            >
                                {this.translate('w_ok')}
                            </div>
                        </ModalContainer>}
                </div>
                {this.state.loading && <Loading />}
            </div>
        );
    }
}

const styles = {
    normalFont: {
        fontFamily: 'SourceSansPro-Regular',
        backgroundColor: 'transparent',
        fontSize: 16,
    },    
    boldFont: {
        fontFamily: 'SourceSansPro-Bold',
        fontSize: 15,
        backgroundColor: 'transparent',
        color: '#000000'
    },
    container2:{
        flex: 1,
        justifyContent: 'start',
        alignItems: 'center',
        backgroundColor: '#fff',
        alignSelf: 'stretch',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        alignSelf: 'stretch'
    },
    navContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        height: 60,
        paddingLeft: 12,
        paddingRight: 12,
    },
    content: {
        flex: 1,
        flexDirection: 'column',
        marginLeft: 7,
        marginTop: 15,
        alignSelf: 'stretch',
        justifyContent: 'flex-start',
        wordBreak:'break-word',
    },
    code: {
        alignSelf: 'center',
        textAlign: 'center',
        width: commonUtils.clientWidth()*0.08,
        height: commonUtils.clientWidth()*0.08,
        borderRadius: 4,
        margin:commonUtils.clientWidth()*0.03,
        backgroundColor: 'rgb(238,238,238)',
    }
};