import React, { Component} from 'react';
import BaseComponent from '../../../components/BaseComponent';
import NavBar from '../../../components/NavBar.js'
import Pencil_White from '../../../content/images/Pencil_White.png';
import addTestResultIcon from '../../../content/images/icon_AddPlusCircle.png';
import i18n from 'i18next';
import Loading from '../../../components/Loading';
import common from '../../../Common';
import httpUtil from '../../../utils/HttpUtil';
import moment from 'moment';
import DatePicker from "react-datepicker";
import AddPhotoAndImage from "./AddPhotoAndImage";
import "react-datepicker/dist/react-datepicker.css";
import arrow_left_white from "./../../../content/images/arrow_left_white.png";
import Profile from './../../../store/ProfileStorage'
import { AutoComplete } from 'antd';

const CustomDatePickerInput = ({ value, onClick }) => (
    <button className="custom_date_picker_input" style={styles.datePickerButton} onClick={onClick}>
        {value}
    </button>
);

export default class TestResultDetail extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            LabTypes: [],
            DateResults: new Date(),
            LocationTests: {},
            results: [],
            optionsLoaded: false,
            LabType: '',
            Location: '',
            result: '',
            showIndicator: false,
            labResult:{},
            photo:'',
            labResultAttachments:[],
            item: {
              labTypeID: '',
              dateResults: new Date(),
              resourceOther: '',
              labResultQualitativeID: ''
            },
            labResultNotes: '',
            firstName: '',
            lastName: '',
            DateOfBirth:''
        };
        this._goEditResult = this._goEditResult.bind(this);
    }

    componentDidMount() {
        this._reload(); 
    }

    _updateLabResultAttachments(labResultAttachments){
        var labResultModel = {};
        labResultModel.LabResultId = this.props.location.state.labResultID;
        labResultModel.LabResultAttachments = labResultAttachments;
        this.setState({ showIndicator: true},()=>{
            httpUtil.post('/api/TestResults/UpdateLabResultAttachment', labResultModel,
            (responseData) => {
                alert(this.translate('You_successfully_reset_ResultAttachment'));
                if(labResultAttachments != null && labResultAttachments.length > 0){
                  this.setState({labResultAttachments:labResultAttachments,photo:labResultAttachments[0].file.fileBits,showIndicator: false})
                }      
            });
        });
    }

    _reload() {
        this.setState({ showIndicator: true,labResult:{}},()=>{
            httpUtil.get('/api/TestResults/GetLabResultDetailByLabResultId?LabResultId=' + this.props.location.state.labResultID,
            (responseData) => {
                if (responseData) {
                    var photo = '';
                    var labResultAttachments = [];
                    if(responseData.labResultAttachments && responseData.labResultAttachments.length > 0){                       
                        if(responseData.labResultAttachments[0].fileBase64){
                            photo = responseData.labResultAttachments[0].fileBase64;
                        }else{
                            photo = responseData.labResultAttachments[0].fileBits;
                        }
                        labResultAttachments = responseData.labResultAttachments;       
                    }
                    //this.setState({labResultNotes: responseData.qualitativeText ?? ""});
                    /* if(!responseData.selfReported){
                        Profile.getLabResultNotes(responseData.labResultQualitativeID).then((v)=> {
                          //alert('Lab Result Details LabNote ' + v);
                          this.setState({labResultNotes: v });
                        });
                    }else{
                        this.setState({labResultNotes: responseData.qualitativeText});
                    } */
                    this.getProfile();
                    this.setState({ showIndicator: false, labResult: responseData, photo:photo,labResultAttachments:labResultAttachments});
                }else{
                    alert('This Lab Result is Delete');
                }
            }
            );
        });

    }

    _goBack() {
        this.props.history.push("/home/more/testresults");
    }

    _goEditResult(){
        var editLabResult = {};
        editLabResult = this.state.labResult;
        editLabResult.labResultAttachments = this.state.labResultAttachments;
        this.props.history.push("/home/more/addtestresult",editLabResult);
    }
    
    getProfile() {
        httpUtil.get('/api/Profile/GetMine',
            (profileData) => {
                if (profileData.id && profileData.status === 1) {
                    localStorage.setItem('profile', JSON.stringify(profileData));
                    var firstName = profileData.firstName;
                    var lastName = profileData.lastName;
                    var DateOfBirth = profileData.dateOfBirth;
                    this.setState({
                        firstName: firstName,
                        lastName: lastName, 
                        DateOfBirth:DateOfBirth
                    })
                }
            }
        );
    }
    
    render() {
        let {showIndicator,item, labResult, LocationTests, result, LabType, Location} = this.state;
        
        let description = "";
        if(labResult.qualitativeText){
            description = labResult.qualitativeText.split(/     /).map(s => (<p>{s}</p>));
        }
        
        let resultTypeHtml = [];
        resultTypeHtml.push(
            <option value="{labResult.labTypeID}" selected>{labResult.labTypeName}</option>               
        );
        return (
            <div className="conatiner" style={{ justifyContent: 'flex-start' }}>
                <NavBar title={this.translate("w_Lab_Result")} backurl='/home/more/testresults' history={this.props.history} 
                right={ false?<img onClick={this._goEditResult} src={Pencil_White} style={{paddingTop:20 }} resizemode={'contain'} />:<div></div>}/>
                    <div className="conatiner" style={{ overflowY:'auto',width:'100%',overflowX:'hidden',justifyContent: 'end'}}>                       
                        <div style={styles.divContent}>
                        <div style={styles.divContent2}>
                            <div>
                            <label style={styles.labelText}>{this.translate('w_firstName')}</label>
                            <input
                                disabled="disabled"
                                className="input1"
                                type='text'
                                style={styles.input1}
                                value={this.state.firstName}
                            />
                            </div>
                            <div>
                            <label style={styles.labelText}>{this.translate('w_lastName')}</label>    
                            <input
                                disabled="disabled"
                                className="input1"
                                type='text'
                                style={styles.input1}
                                value={this.state.lastName}
                            /> 
                            </div>
                        </div>
                        </div>
                        <div style={styles.divContent}> 
                            <label style={styles.labelText}>{this.translate('w_date_of_birth')}</label> 
                        
                            <input
                                disabled="disabled"
                                className="input1"
                                type='text'
                                style={{ fontSize:18, marginTop: 2,width:'63%',marginLeft:10,textAlign:'start',paddingLeft: 10 ,fontSize:18,color:'rgb(170,170,170)', '-webkit-text-fill-color': 'rgb(170,170,170)', opacity: 1}}
                                value={this.state.DateOfBirth}
                            /> 
                        </div>
                        <div style={styles.divContent}>
                            <label style={styles.labelText}>{this.translate('w_TestResult_Type')}</label>
                            <select disabled="disabled" value={this.state.value} style={styles.selectPickers2}
                                onChange={
                                    (item) => {
                                        var value = item.target.options[item.target.selectedIndex].value;
                                        if (value !== this.state.LabType) {
                                            this.setState({ LabType: value });
                                        }
                                 }}>
                                {resultTypeHtml}
                            </select>
                        </div>
                        <div style={styles.divContent}>
                            <label style={styles.labelText}>{this.translate('w_Test_Date')}</label>
                            
                            <input
                                disabled="disabled"
                                className="input1"
                                type='text'
                                style={{ marginTop: 2,width:'63%',marginLeft:10,textAlign:'start',paddingLeft: 10 ,fontSize:18,color:'rgb(170,170,170)', '-webkit-text-fill-color': 'rgb(170,170,170)', opacity: 1}}
                                value={moment(labResult.dateResults).format('MM/DD/YYYY')}
                            />
                            {/*<div key='t6' className="date_picker_container" style={{minHeight: 48,justifyContent: 'start',marginLeft: 5}}><DatePicker
                                disabled="disabled"
                                showPopperArrow={false}
                                dateFormat="MM/dd/yyyy"
                                value={moment(labResult.dateResults).format('MM/DD/YYYY')}
                                customInput={<CustomDatePickerInput />}
                            /></div>*/}

                            
                        </div>
                        <div style={styles.divContent}>
                            <label style={styles.labelText}>{this.translate('w_location_of_the_test')}</label>
                            <input
                                disabled="disabled"
                                className="input1"
                                type='text'
                                style={{ marginTop: 2,width:'88%',marginLeft:10,textAlign:'start',
                                    paddingLeft: 10,fontSize:18,color:'rgb(170,170,170)', '-webkit-text-fill-color': 'rgb(170,170,170)', opacity: 1}}
                                value={labResult.resourceOther}
                                onChange={(e) => this.setState({ Location: e.target.value })}
                            />
                        </div>
                        <div style={styles.divContent}>
                            <label style={styles.labelText}>{this.translate('w_what_were_your_results')}</label>
                            <select disabled="disabled" value={this.state.value}  style={styles.selectPickers}
                                onChange={
                                    (item) => {
                                        var value = item.target.options[item.target.selectedIndex].value;
                                        if (value !== this.state.result) {
                                            this.setState({ result: value });
                                        }
                                }}>
                                <option value="" disabled selected>{labResult.value}</option> 
                            </select>
                        </div>

                        {!labResult.selfReported && <div style={styles.divContent}>
                            <label style={styles.labelText}>{this.translate('w_Entered_By')}</label>
                            <input
                                disabled="disabled"
                                className="input1"
                                type='text'
                                style={{fontSize: 18,
                                    marginTop: 2,
                                    width:'63%',
                                    marginLeft:10,textAlign:'start',
                                    paddingLeft: 10 ,
                                    color:'rgb(170,170,170)', '-webkit-text-fill-color': 'rgb(170,170,170)', 
                                    opacity: 1}}
                                value={labResult.enteredBy}
                            />
                        </div>}

                        <div style={styles.divContent}>
                            <label style={styles.labelText}>{this.translate('w_picture_of_your_results')}</label>
                            <AddPhotoAndImage image={this.state.photo} labResultAttachments={this.state.labResultAttachments} updateLabResultAttachments={this._updateLabResultAttachments.bind(this)}></AddPhotoAndImage>
                        </div>
                        {!labResult.selfReported ?
                            <div style={{margin:10, width:'100%'}}>
                            <textarea
                            disabled="disabled"
                            className="textarea1"                        
                            style={{ margin: 10, width: '88%', minHeight: '300px', textAlign:'start',paddingLeft: 10 ,fontSize:18,color:'rgb(170,170,170)', '-webkit-text-fill-color': 'rgb(170,170,170)', opacity: 1}}
                            value={labResult.qualitativeText}/> 
                            </div>:<div></div>}
                            
                        <div style={styles.divContent}>
                            <button
                                className="button" 
                                style={ { marginTop: 20, marginBottom: 20, backgroundColor:"#123b6d",width:'90%',cursor:'pointer'}}
                                onClick={() => {
                                    this.props.history.push("/home/more/ShareResults",{ labResultID: this.props.location.state.labResultID });
                                }}
                            >
                            {this.translate("w_share")}
                            </button>
                        </div>
                        <div style={styles.divContent}>
                            <button
                                className="button"
                                key='selectDate'
                                style={ { marginTop: 20, marginBottom: 20, backgroundColor:"#123b6d",width:'90%',cursor:'pointer'}}
                                onClick={() => {
                                    this.props.history.push("/home/more/testresults");
                                }}
                            >
                            {this.translate("w_back")}
                            </button>
                        </div>
                    </div>
                {
                    showIndicator && <Loading />
                }
            </div>

        );
    }
}    

var styles = {
  container: {
    flex: 1,
    alignItems: 'center'
  },

  divContent:{
    width: '100%',
    display: 'block'
  },

  divContent2:{
    width: '100%',
    display: 'flex'
  },

  input1: {
    fontSize: 18,
    marginTop: 2,
    width:'88%',
    marginLeft:10,textAlign:'start',
    paddingLeft: 10 ,
    color:'rgb(170,170,170)', '-webkit-text-fill-color': 'rgb(170,170,170)', 
    opacity: 1
  },
  selectPickers: {
    fontSize: 18,
    height: 40,
    width: '91%',
    padding: 10,
    borderWidth: '0px 0px 2px',
    borderColor: '#ddd',
    background: '#eeeeee',
    marginLeft:10,
  },

  selectPickers2: {
    fontSize: 18,
    height: 40,
    width: '91%',
    padding: 10,
    borderWidth: '0px 0px 2px',
    borderColor: '#ddd',
    background: '#eeeeee',
    marginLeft:10,
  },

  datePicker: {
    marginTop: 0,
  },

  datePickerButton: {
    textAlign: 'start',
    padding: 10,
    borderColor: '#999',
    borderWidth: 0,
    borderStyle: 'solid',
    minWidth: 250,
    borderRadius: 0,
    height: 48
  },

  datePickerText: {
    color: '#000000',
    textAlign: 'left',
  },

  picker: {
    justifyContent: 'space-around',
    flex: 1,
    width: "85%",
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: 'rgb(238,238,238)'
  },

  labelText: {
    width: '100',
    display: 'block',
    margin: 10,
    fontSize:18
  },

  labelText_1: {
    width: '30%', 
    margin: 10,
    fontSize:18
  },

  textInputview: {
    marginTop: 10,
    width: "85%",
    height: 45,
    marginLeft: 15,
    marginRight: 15,
    textAlign: 'left',
  }
}
