import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import TabBarContainer from '../../components/TabBarContainer';
import httpUtil from '../../utils/HttpUtil';
import ReactDOM from 'react-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';
import journay_logo from '../../content/images/track-journay.png';
import star_logo from '../../content/images/star.png';
import Loading from '../../components/Loading';
import CacheUtil from '../../utils/CacheUtil';
import ModalContainer from '../../components/ModalContainer';
import TopicSvr from '../../service/TopicSvr';
export default class Home extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            suveryCount: localStorage.getItem('survey_count'),
            need_alert_survey: localStorage.getItem("need_alert_survey"),
            loading: false,
            swipe_index: 0
        };
        this.swipeCallback = this.swipeCallback.bind(this);
    }

    componentWillMount() {
        moment.locale(localStorage.getItem('cultureName'), {
            months: [this.translate('w_month1'), this.translate('w_month2'), this.translate('w_month3'), this.translate('w_month4'), this.translate('w_month5'), this.translate('w_month6'),
            this.translate('w_month7'), this.translate('w_month8'), this.translate('w_month9'), this.translate('w_month10'), this.translate('w_month11'), this.translate('w_month12')]
        }
        )
        httpUtil.get('/api/Profile/GetAppStatus',
            (appStatus) => {
                if (appStatus && appStatus.hasOwnProperty("numNewMsg")) {
                    localStorage.setItem('msg_count', JSON.stringify(appStatus.numNewMsg));
                    localStorage.setItem('survey_count', JSON.stringify(appStatus.numNewSurvey));
                    localStorage.setItem('wiki_count', JSON.stringify(appStatus.numNewWiki));
                    this.setState({ suveryCount: localStorage.getItem('survey_count') });
                    if (localStorage.getItem('survey_count') == 0) {
                        localStorage.setItem('need_alert_survey', 0);
                        this.setState({ need_alert_survey: 0 });
                    }
                }
            });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({ loading: true })
        CacheUtil.GetFeaturedArticles((responseData) => {
            this.setState({ loading: false, articles: responseData });
        });
    }

    swipeCallback(index, elem) {
        this.setState({ swipe_index: index });
    }

    render() {
        let reactSwipeEl;
        let { articles, swipe_index } = this.state;
        let items = [];
        if (articles && articles.length > 0) {
            articles.map((item, idx) => {
                items.push(
                    <div
                        className="item"
                        key={idx}
                        onClick={() => {
                            TopicSvr.openTopic(item);
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {item.subject.replace(/(<\/?a.*?>)|(<\/?span.*?>)/g, '').replace('&nbsp;', ' ')}
                            <img
                                className="image"
                                src={'data:image/png;base64,' + item.topicImageBase64}
                                resizemode={'contain'}
                                alt=""
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {item.content.replace(/<\/?[^>]*>/g, '').replace(/[|]*\n/, '').replace(/&nbsp;/ig, ' ')}
                        </div>
                    </div>
                );
            });
        }
        return (
            <TabBarContainer tab={'home'} history={this.props.history}>
                <div className="conatiner">
                    <div className="conatiner" style={{ flex: 3 }}>
                        <div style={{ fontSize: 21, marginTop: 20 }}>
                            {this.translate('w_Welcome_to_IMPACC_STUDY')}
                        </div>
                        <div style={{ fontSize: 16, color: '#666666', marginTop: 6 }}>
                            {moment().format('DD MMMM YYYY')}
                        </div>
                        <div className="conatiner" onClick={() => this.props.history.push("/survey")}>
                            <div className="home-item-container">
                                {
                                    this.state.suveryCount && <div className="count">{this.state.suveryCount}</div>
                                }
                                <img
                                    className="logo"
                                    src={journay_logo}
                                    resizemode={'contain'}
                                    alt=""
                                />
                                <div className="title">
                                    {this.translate('w_Survey')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conatiner" style={{ flex: 4, justifyContent: 'flex-start', backgroundColor: '#eeeeee' }}>
                        <div className="carousel-title-container">
                            <img
                                src={star_logo}
                                resizemode={'cover'}
                                style={{ width: 20, height: 20, marginRight: 3 }}
                                alt=""
                            />
                            {this.translate('w_IMPORTANT_INFORMATION')}
                        </div>
                        <div className="carousel-container" >
                            {
                                items.length > 0 &&
                                <div>
                                    <ReactSwipe
                                        key={items.length}
                                        className="carousel"
                                        swipeOptions={{
                                            startSlide: swipe_index,
                                            speed: 400,
                                            auto: 3000,
                                            continuous: false,
                                            //noSwiping: true,
                                            disableScroll: false,
                                            stopPropagation: false,
                                            callback: this.swipeCallback,
                                            transitionEnd: function (index, elem) {
                                            }
                                        }}
                                        ref={el => (reactSwipeEl = el)}>
                                        {
                                            items.map((item, idx) => {
                                                return (
                                                    <div className="swipe" >
                                                        {item}
                                                    </div>)
                                            })
                                        }
                                    </ReactSwipe>
                                    {/*<button onClick={() => reactSwipeEl.prev()}>Previous</button>     
                                        <button onClick={() => reactSwipeEl.next()}>Next</button> */}
                                </div>
                            }
                        </div>
                        <div className="carousel-circle-container">
                            {
                                items.map((item, idx) => {
                                    if (idx == swipe_index) {
                                        return <div className="circle-item" style={{ backgroundColor: '#999999' }}></div>
                                    } else {
                                        return <div className="circle-item"></div>
                                    }
                                })
                            }
                        </div>
                    </div>
                    {this.state.loading && <Loading />}
                    {
                        this.state.need_alert_survey == "1" &&
                        <ModalContainer>
                            <div className="alert_title">
                                {this.translate('w_Complete_your_survey')}
                                {/* {this.state.suveryCount > 1 ? this.translate('w_Complete_your_surveys') : this.translate('w_Complete_your_survey')} */}
                            </div>
                            <div className="alert_content">
                                {this.translate('w_Please_complete_your_visit_surveys')}
                                {/* {this.state.suveryCount > 1 ? this.translate('w_Please_complete_your_visit_surveys') : this.translate('w_Please_complete_your_visit_survey')} */}
                            </div>
                            <div
                                className="alert_button"
                                onClick={() => {
                                    localStorage.removeItem("need_alert_survey");
                                    this.props.history.push("/survey");
                                }}
                            >
                                {this.translate('OK')}
                            </div>
                        </ModalContainer>
                    }
                </div>
            </TabBarContainer>
        );
    }
}
