export default {
  form: {},
  alignRight: {},
  iconRight: {
    alignSelf: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  multiline: {
    fontSize: 18,
    height: 100,
  },
  separatorContainer: {
    paddingTop: 35,
    borderBottomColor: '#C8C7CC',
    borderBottomWidth: 1,
  },
  pickerCombo: {
    height: 100,
    width: 100,
    overflow: 'hidden',
    justifyContent: 'space-around',
  },
  picker: {
    height: 150,
    overflow: 'hidden',
    justifyContent: 'space-around',
  },
  fieldsWrapper: {
    // borderTopColor: '#afafaf',
    // borderTopWidth: 1,
  },
  inputRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  horizontalContainer: {
    flexDirection: 'row',
  },
  verticalContainer: {
    alignItems: 'flex-start',
  },
  fieldContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#C8C7CC',
    justifyContent: 'space-between',
    // paddingLeft: 10,
    // paddingRight: 10,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  fieldContent: {
    height: 44,
  },
  fieldText: {
    fontSize: 18,
    //paddingRight: 10,
    paddingRight: 5,
    alignSelf: 'center',
    color: '#666666',
  },
  fieldValue: {
    fontSize: 18,
    //paddingRight: 10,
    paddingRight: 5,
    alignSelf: 'center',
  },
  input: {
    paddingRight: 10,
    flex: 1,
  },
  helpTextContainer: {
    marginTop: 9,
    marginBottom: 25,
    paddingLeft: 20,
    paddingRight: 20,
  },
  helpText: {
    color: '#7a7a7a',
  },
};
