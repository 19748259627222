import React, { Component } from 'react';
import BaseComponent from '../../../components/BaseComponent';
import NavBar from '../../../components/NavBar.js'
import arrow_right from '../../../content/images/arrow_right.png';
import addTestResultIcon from '../../../content/images/icon_AddPlusCircle.png';
import i18n from 'i18next';
import Loading from '../../../components/Loading';
import common from '../../../Common';
import httpUtil from '../../../utils/HttpUtil';
import moment from 'moment';
export default class TestResult extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            resultHtmls: [],
            penddingHtmls: [],
            showIndicator: false,
        };
    }

    componentDidMount() {
        this._reload();
    }

    _reload() {
        this.setState({ showIndicator: true});
        httpUtil.get('/api/TestResults/GetLabResults',
            (responseData) => {
                if (responseData) {
                    this.setState({ showIndicator: false},()=>{
                        this._setResultHtmlsAndPenddingHtmls(responseData);
                    });
                }
            }
        );
    }

    _setResultHtmlsAndPenddingHtmls(labResults){
        let penddingHtmls = [];
        let resultHtmls = [];

        penddingHtmls.push(
            <div key='P1' style={{
                    display: 'flex',
                    alignSelf: 'stretch',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width:'100%',
                    cursor:'pointer',
                    height:50
                }}
                onClick={() => this.props.history.push("/home/more/addtestresult")}
            >
                <img src={addTestResultIcon} style={{ height: 40,paddingTop:6 }} alt="" />
                <p style={{color: '#2f5597', fontSize:20 }}>{this.translate("w_add_Test_Results")}</p>
            </div>
        );

        labResults.map((labResult, index) => {
                resultHtmls.push(
                    <div style={{ alignSelf: 'stretch',border: '1px solid #ddd' }} key={index}
                         onClick={()=>{
                            this.props.history.push("/home/more/testresultdetail",{ labResultID: labResult.labResultID })
                         }}>
                        <div style={styles.RowNavi}>
                            <p >{labResult.labTypeName}</p>
                            <p style={styles.RowNaviDate}>{moment(labResult.dateResults).format('MM/DD/YYYY')}</p>
                            <img src={arrow_right}
                                style={styles.right_img}
                                alt="" />
                        </div>
                    </div>);
        });
        
        this.setState({penddingHtmls:penddingHtmls,resultHtmls:resultHtmls});
    }
 
    render() {
        let { showIndicator,penddingHtmls,resultHtmls} = this.state;
        return (
            <div className="conatiner" style={{ justifyContent: 'flex-start' }}>
                <NavBar title={this.translate("w_Lab_Result")} backurl='/home/more' history={this.props.history} />
                <div className="conatiner" style={{ justifyContent: 'flex-start' }}>
                    {penddingHtmls}
                    <div style={{overflow:'auto',width:'100%',maxHeight:684}}>
                        {resultHtmls}
                    </div>
                </div>
                {
                    showIndicator && <Loading />
                }
            </div>

        );
    }
}    

const styles = {
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',

    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5,
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    txtErr: {
        color: '#f00',
        marginTop: 3,
        fontSize: 14
    },
    txtOK: {
        color: '#00b359',
        marginTop: 3,
        fontSize: 14
    },
    ptext: {
        color: '#fff',
        textAlign: 'end'
    },
    RowNavi: {
        cursor:'pointer',
        height:55,
        paddingLeft: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    RowNaviDate:{
        flex: 1,
        textAlign: 'right',
        marginRight: 20
    },
    right_img: {
        marginRight: 10
    },
    button_ok: {
        width: 18,
        height: 18,
        borderWidth: 6,
        borderColor: '#123B6D',
        borderRadius: 9,
        borderStyle: 'solid'
    },
    button_no: {
        width: 18,
        height: 18,
        borderWidth: 1,
        borderColor: '#aaa',
        borderRadius: 9,
        borderStyle: 'solid'
    }
}
