import React from 'react';

import BaseComponent from '../../components/BaseComponent.js';
import Container from '../../components/Container.js';
import NavBar from './../../components/NavBar.js';
import httpUtil from '../../utils/HttpUtil';

const globalStyles = require('../../utils/GlobalStyles');

export default class ShareCal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      recipientEmail: '',
      requestPending: false,
    };

    this._sendCal = this._sendCal.bind(this);
  }

  _sendCal() {
    if (!this.state.recipientEmail || this.state.recipientEmail.length < 4) {
      alert(this.translate('w_emailRequired'));
      return false;
    }

    if (!/^.+@.+\..+$/.test(this.state.recipientEmail)) {
      alert(this.translate('w_noValidEmail'));
      return false;
    }

    this.setState({ requestPending: true });

    httpUtil.post(
      '/api/Appointment/SendICalendar',
      {
        appointmentId: this.props.appointmentId,
        email: this.state.recipientEmail,
      },
      (response) => {
        this.setState({ requestPending: false });

        if (response && response.status == 'Success') {
          alert(this.translate('w_calendarSentSuccess'));
          this.props.closePopup();
        } else {
          alert(this.translate('w_calendarSentFailure'));
        }
      }
    );
  }

  render() {
    return (
      <Container>
      <div style={styles.backContainer}>
        <div className='pointer' style={styles.backImg} onClick={()=>{
          this.props.closePopup();
        }}>
          <span style={{ width: 9, height: 14,fontSize:18 }} >{this.translate('w_back')}</span>
        </div>
        <div style={styles.backTxt}>{this.translate('w_appointment')}</div>
        <div style={{ width: 50, height: 40 }}></div>
      </div>

        <div>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#fff',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
          >
            <div
              style={{
                flex: 1,
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <input
                style={{ ...globalStyles.normalFont, ...styles.emailInput }}
                placeholder={this.translate('w_recipientEmail')}
                value={this.state.recipientEmail}
                onChange={(e) => {
                  this.setState({ recipientEmail: e.target.value });
                }}
              />

              <button
                style={{
                  ...globalStyles.normalFont,
                  ...globalStyles.mButton,
                  ...globalStyles.button2,
                  marginTop: 23,
                  backgroundColor: '#123b6d',
                  width: '90%',
                  color: 'white',
                }}
                onClick={this._sendCal}
              >
                {this.translate('w_shareAppointment')}
                {/* <span
                    style={{
                    }}
                  >
                  </span> */}
              </button>
            </div>
          </div>
        </div>
        {/* {this.state.requestPending ? <BusyIndicator /> : null} */}
      </Container>
    );
  }
}

var styles = {
  emailInput: {
    marginTop: 40,
    textAlign: 'center',
    height: 46,
    borderRadius: 4,
    fontSize: 18,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: 'rgb(238,238,238)',
    width: 'calc(90% - 5px)',
    display: 'block',
  },
  backContainer: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    color: '#fff',
    display: 'flex',
    backgroundColor: "#123b6d"
  },
  backImg: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: 21,
    padding: 18,
  },
  backTxt: {
    textDecoration: 'none',
    fontSize: 18,
    display: 'flex',
    alignItems: "center",
  },
};
