import React, { useCallback } from 'react';
import BaseComponent from '../../components/BaseComponent.js';
import Container from '../../components/Container.js';
import TabBarContainer from '../../components/TabBarContainer.js';
import HttpUtil from './../../utils/HttpUtil.js';
import moment from 'moment-timezone';
import Calendar from 'react-calendar';
import '../../content/style/main.css';
import CalendarList from './CalendarList';

const globalStyles = require('../../utils/GlobalStyles');
export default class CalendarView extends BaseComponent {
  constructor(props) {
    super(props);

    let startOfDay = moment().startOf('day').toDate();

    this.state = {
      currentMonth: new Date(),
      selectedDate: startOfDay,
      eventDates: [],
      appointments: [],
      allow2RequestHasAvailable: true,
      allow2Schedule: false,
      loading: true,
    };

    this._onDateSelected = this._onDateSelected.bind(this);
    this._showSurvey = this._showSurvey.bind(this);
    this._scheduleAppointment = this._scheduleAppointment.bind(this);
  }

  componentDidMount() {
    console.log('Calendar mount');
    this._refreshData();
  }

  _refreshData() {
    this.setState({
      //eventDates: [],
      //appointments: [],
      loading: true,
    });

    HttpUtil.post(
      '/api/Appointment/QueryDates',
      {},
      this._loadDates.bind(this)
    );

    this._refreshAppointments(this.state.selectedDate);
  }

  _onDateSelected(dateString) {
    let date = new Date(dateString);
    let currentDate = moment().startOf('day').toDate();
    let allow2ScheduleTemp = currentDate.getTime() <= date.getTime();

    this.setState({
      allow2RequestHasAvailable: allow2ScheduleTemp,
      appointments: [],
      selectedDate: date,
    });

    this._refreshAppointments(date);
  }

  _showSurvey() {
    HttpUtil.get(
      '/api/Quiz/GetQuizForNewAppointment?ticksUTC=' +
        moment(this.state.selectedDate).unix(),
      (survey) => {
        if (survey) {
          if (survey.percentage == 1.0) {
            this._scheduleAppointment(survey.quizzParticipantId);
          } else {
            this.props.history.push('/surveyDetail', { 
              readonly: false, 
              survey: survey,
              selectedDate: this.state.selectedDate,
              continueToScheduleAppt: true });
          }
        }
      }
    );
  }

  _scheduleAppointment(quizParticipantID) {
    this.props.history.push('/home/calendar/schedule', {
      selectedDate: this.state.selectedDate,
      quizParticipantID: quizParticipantID,
    });
  }

  _refreshAppointments(selectedDay) {
    let start = new Date(selectedDay.getTime());
    let end = new Date(selectedDay.getTime());

    HttpUtil.post(
      '/api/Appointment/QueryEntries',
      {
        dateFrom: start.toISOString(),
        dateTo: end.toISOString(),
      },
      this._loadAppointments.bind(this)
    );
  }

  _loadDates(responseData) {
    if (responseData && responseData.length > 0) {
      let eventDates = responseData.map((value) => {
        return moment
          .utc(value.startDateTime)
          .tz('America/New_York')
          .format('YYYY-MM-DD');
      });
      this.setState({ eventDates: eventDates, loading: false });
    } else {
      this.setState({ eventDates: [], loading: false });
    }
  }

  _loadAppointments(responseData) {
    if (responseData && responseData.length > 0) {
      this.setState({
        appointments: responseData,
        allow2Schedule: false,
        loading: false,
      });
    } else {
      if (this.state.allow2RequestHasAvailable) {
        this.setState({
          loading: true,
        });
        HttpUtil.post(
          '/api/AppointmentSlots/HasAvailable?ticksUTC=' +
            moment(this.state.selectedDate).unix(),
          {},
          (responseData) => {
            if (responseData) {
              if (responseData.code && responseData.code === 8) {
                this.setState({
                  allow2Schedule: true,
                  appointments: [],
                  loading: false,
                });
              } else {
                this.setState({
                  allow2Schedule: false,
                  appointments: [],
                  loading: false,
                });
              }
            } else {
              this.setState({
                allow2Schedule: false,
                appointments: [],
                loading: false,
              });
            }
          }
        );
      }
    }
  }

  _viewAppointment(appointment) {
    this.props.history.push('/home/calendar/appointment', appointment);
  }

  _tileClassName(dates) {
    return ({ date }) => {
      const d = moment(date).tz('America/New_York').format('YYYY-MM-DD');
      if (dates.includes(d)) {
        return 'has-event';
      }
    };
  }

  render() {
    let { selectedDate, appointments, loading, allow2Schedule } = this.state;
    const tileName = this._tileClassName(this.state.eventDates);
    return (
      <TabBarContainer history={this.props.history} tab={'calendar'}>
        <div className="navbar-container">{this.translate('Calendar')}</div>
        <Container>
          <Calendar
            className="app-calendar"
            calendarType="US"
            onChange={this._onDateSelected}
            next2Label={null}
            prev2Label={null}
            value={selectedDate}
            tileClassName={tileName}
          />
          <div
            style={{
              flex: 1,
              background: '#f2f2f2',
              padding: 12,
              paddingLeft: 20,
              fontWeight: 'bold',
              color: '#203864',
            }}
          >
            {moment
              .utc(selectedDate)
              .tz('America/New_York')
              .format('ddd MMM DD')}
          </div>
          {!loading && allow2Schedule && (
            <div
              style={{
                alignSelf: 'stretch',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 20,
                display: 'flex',
              }}
            >
              <span style={globalStyles.normalFont}>
                {'No appointments \r\nscheduled on this day'}
              </span>
              <button
                onClick={() => this._showSurvey()}
                style={{
                  padding: 3,
                  backgroundColor: mainColor,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 4,
                }}
              >
                <span
                  style={{
                    ...globalStyles.normalFont,
                    color: '#fff',
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                >
                  {'Schedule'}
                </span>
              </button>
            </div>
          )}
          {!loading &&
            !(appointments && appointments.length > 0) &&
            !allow2Schedule && (
              <div
                style={{
                  alignSelf: 'stretch',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 20,
                }}
              >
                <span style={globalStyles.normalFont}>
                  {this.translate('w_NoAptAvailableonthisday')}
                </span>
              </div>
            )}
          {!loading && appointments && appointments.length > 0 && (
            <CalendarList
              dataItems={appointments}
              pressRow={this._viewAppointment.bind(this)}
            />
          )}
        </Container>
      </TabBarContainer>
    );
  }
}

const mainColor = '#123B6D';
