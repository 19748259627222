import React from 'react';
import BaseComponent from '../../../components/BaseComponent';
import TabBarContainer from '../../../components/TabBarContainer';
import httpUtil from '../../../utils/HttpUtil';
import ReactDOM from 'react-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';
import journay_logo from '../../../content/images/track-journay.png';
import star_logo from '../../../content/images/star.png';

import Loading from '../../../components/Loading';
import CacheUtil from '../../../utils/CacheUtil';
import ModalContainer from '../../../components/ModalContainer';
import ScrollView from '../../../components/ScrollView';
import CommonUtil from './../../../utils/CommonUtil';
import Common from '../../../Common';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePickerInput = ({ value, onClick }) => (
    <button className="custom_date_picker_input" onClick={onClick}>
        {value}
    </button>
);
export default class Complete extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: -1,
            loading: false,
            showOnset: true,
            eligible: false,
            phoneNumber: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
            locationName: '',
            principalInvestigator: '',
            locationAddress: '',
            locationAddress2: '',
            locationCity: '',
            locationState: '',
            locationZipCode: '',
            date: new Date(),
            minDate: new Date()
        };
    }

    componentWillMount() {
        this.setMinDate();
    }

    componentDidMount() {

    }

    setMinDate() {
        var before14daysDate = new Date();
        before14daysDate.setDate(before14daysDate.getDate() - 14);
        this.setState({ minDate: before14daysDate })
    }

    // async showPicker(options) {
    //     try {
    //         var newState = {};
    //         const { action, year, month, day } = await DatePickerAndroid.open(options);
    //         if (action === DatePickerAndroid.dateSetAction) {
    //             var date = new Date(year, month, day);
    //             this._handleValueChange(date);
    //         }
    //     } catch ({ code, message }) {
    //         alert(message);
    //     }
    // }

    _handleValueChange(date) {
        this.setState({ date: date });
    }

    _onDateChange(date) {
        this.setState({ date: date });
    }

    _printTime() {
        return moment(this.state.date).format('YYYY-MM-DD');
    }

    // call() {
    //     if (this.state.phoneNumber && this.state.phoneNumber.length > 9) {
    //         Communications.phonecall(this.state.phoneNumber, true);
    //     }
    // }

    itemClick(index) {
        let profile = JSON.parse(localStorage.getItem('profile'));
        this.setState({ loading: true });
        httpUtil.post('/api/TrkRSV/SaveSymptomOnset', {
            profileId: profile.id,
            symptomOnset: index + 1,
            eligible: this.props.location.state.troubleBreath,
            whenFirstNoticed: CommonUtil.toTicks(this.state.date),
        },
            (responseData) => {
                if (responseData && responseData.status == 'Success') {
                    this.setState({ loading: false });
                    // let profileType = Profile.profileType();
                    // if (profileType == 1 && !this.props.shortnessOfBreath) {
                    //     Alert.alert(
                    //         Translation.lang().w_symptoms,
                    //         Translation.lang().As_Healthcare_Provider_pursue_testing,
                    //         [
                    //             {
                    //                 text: Translation.lang().w_ok, onPress: () => {
                    //                     let showSummary = true;
                    //                     //MomEnv.saveStep('home');
                    //                     this.props.onShowSummary && this.props.onShowSummary(true);
                    //                     MomEnv.saveStep('home', null, { viewSummary: true });
                    //                 }
                    //             }
                    //         ]
                    //     );
                    // }
                    // else 
                    if (this.props.location.state.symptomPath && this.props.location.state.symptomPath != '') {
                        this.props.history.push('/tracktodaycomplete', { onShowSummary: this.props.location.state.onShowSummary, symptomPath: this.props.location.state.symptomPath, riskLevel: this.props.location.state.riskLevel });
                        // MomEnv.pushPage(TrkTodayComplete, { onShowSummary: this.props.onShowSummary, symptomPath: this.props.symptomPath, riskLevel: this.props.riskLevel });
                    }
                    else {
                        console.log(this.props);
                        this.props.onShowSummary && this.props.onShowSummary(true);
                        // MomEnv.saveStep('home', null, { viewSummary: true });
                    }
                }
                else {
                    alert("Warning");
                    //Alert.alert(Translation.lang().Warning, Translation.lang().Service_error_please);
                }
            }
        );

        // this.setState({ value: index });
        // if (index > 3 || !this.props.eligible) {
        //     // Alert.alert('Continue to Track Flu-like Symptoms', `Based on your responses, the CROCuS study is not suitable for your child now.\n\nPlease contact your healthcare provider if medical advice is needed.`, [
        //     //     { text: 'OK', onPress: () => { MomEnv.saveStep('home') } },
        //     // ]);  
        // } else {
        //     this.setState({ showIndicator: true });
        //     this.setState({ showOnset: false });
        //     MomEnv.callApi('api/Profile/GetFacility', null,
        //         (responseData) => {
        //             this.setState({ showIndicator: false });
        //             this.setState({ showOnset: false });
        //             if (responseData) {
        //                 this.setState({
        //                     phoneNumber: responseData.phoneNumber,
        //                     monday: responseData.mon,
        //                     tuesday: responseData.tue,
        //                     wednesday: responseData.wed,
        //                     thursday: responseData.thu,
        //                     friday: responseData.fri,
        //                     saturday: responseData.sat,
        //                     sunday: responseData.sun,
        //                     locationName: responseData.name,
        //                     locationAddress: responseData.streetAddr1 != "" ? responseData.streetAddr1 + "," : "",
        //                     locationAddress2: responseData.streetAddr2 == "" ? "" : responseData.streetAddr2 + ",",
        //                     locationCity: responseData.city != "" ? responseData.city + "," : "",
        //                     locationState: responseData.state != "" ? responseData.state + "," : "",
        //                     locationZipCode: responseData.zipCode,
        //                     principalInvestigator: responseData.principalInvestigator,
        //                 });
        //             }
        //         },
        //         "GET"
        //     );
        // }
    }

    render() {
        let {
            value, phoneNumber, monday, tuesday, wednesday, thursday, friday, saturday, sunday,
            locationName, locationAddress, locationAddress2, locationCity, locationState, locationZipCode,
            showOnset, principalInvestigator
        } = this.state;
        let html = [];

        if (showOnset) {
            html.push(
                <div key='title' style={{ alignSelf: 'stretch', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                    <p style={{ marginTop: 10, marginBottom: 20 }}>
                        {this.translate("_first_notice_symptom")}
                    </p>
                </div>
            );
            html.push(
                <div key='t6' className="date_picker_container">
                    <DatePicker
                        showPopperArrow={false}
                        dateFormat="yyyy-MM-dd"
                        maxDate={new Date()}
                        selected={moment(this.state.date, 'yyyy-MM-dd').toDate()}
                        onChange={(date) => {
                            this._onDateChange(date)
                        }}
                        customInput={<CustomDatePickerInput />}
                    />
                </div>
            );

            html.push(
                <button
                    className="button"
                    key='selectDate'
                    style={ { marginTop: 20, marginBottom: 20, }}
                    onClick={() => { this.itemClick(0) }}
                >
                    {this.translate("selectDate")}
                </button>
            );

            // common.SymptomOnsets.map((item, index) => {
            //     html.push(
            //         <TouchableOpacity key={'1_' + index} onPress={this.itemClick.bind(this, index)}>
            //             <View style={value === index ? globalStyles.selectedoption : globalStyles.option}>
            //                 <Text style={[value === index ? globalStyles.selectedoptiontext : globalStyles.optiontext, { margin: 3 }]}>
            //                     {item}
            //                 </Text>
            //             </View>
            //         </TouchableOpacity>
            //     );
            // }); 

        }


        return (
            <div className="conatiner">

                <div style={styles.content}>
                    {html}
                </div>

                {this.state.loading && <Loading />}
            </div>
        );
    }
}

const styles = {
    container: {
        flex: 1,
        alignSelf: 'stretch',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    content: {
        flex: 1,
        alignSelf: 'center',
        flexDirection: 'column',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 5,
        justifyContent: 'flex-start',
        display:'flex'
    },
    datePickerButton: {
        borderBottomWidth: 1,
        alignSelf: 'center',
        borderColor: 'rgb(51, 181, 229)',
        marginBottom: 50,
        marginTop: 50,
        width: 250,
    },
    datePickerText: {
        paddingBottom: 5,
        textAlign: 'center',
    }
};