// import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { spring } from 'react-router-transition';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './langs/en.json';
import es from './langs/es.json';
import zh from './langs/zh.json';
import './content/style/main.css';
import Login from './pages/account/Login';
import SSOWeb from './pages/account/SSOWeb';
import Consent from './pages/account/Consent';
import Questionnaire from './pages/account/Questionnaire';
import Email from './pages/account/Email.js';
import NameAndMobileNumber from './pages/account/NameAndMobileNumber.js';
import AuthenticationEmail from './pages/account/AuthenticationEmail.js';

import Home from './pages/home/Home';
import Calendar from './pages/calendar/Calendar';
import Resources from './pages/resources/Resources';
import Topic from './pages/resources/Topic';
import More from './pages/more/More';
import Register from './pages/account/Register';
import Pin from './pages/account/pin';
import ProblemsSigningIn from './pages/account/ProblemsSigningIn';
import ResetPin from './pages/account/ResetPin';
import Survey from './pages/survey/main';
import ACSurvey from './pages/survey/Survey2';
import SurveyDetail from './pages/survey/SurveyDetail';
import Setting from './pages/more/settings/setting';
import TestResults from './pages/more/testresult/TestResult';
import AddTestResults from './pages/more/testresult/AddTestResult';
import ShareResults from './pages/more/testresult/ShareResult';
import TestResultDetail from './pages/more/testresult/TestResultDetail';
import SettingTermOfUse from './pages/more/SettingTermOfUse';
import SettingPrivacy from './pages/more/SettingPrivacy';
import UpdateUserAccount from './pages/more/UpdateUserAccount';
import UpdatePIN from './pages/more/UpdatePIN';
import Help from './pages/more/Help';
import HelpDetail from './pages/more/HelpDetail';
import SettingLanguage from './pages/more/SettingLanguage';
import CommonUtil from './utils/CommonUtil';
import TrackMain from './pages/track/main';
import Complete from './pages/track/trackcompment/Complete';
import TrkHowIsToday from './pages/track/trackcompment/TrkHowIsToday';
import Message from './pages/message/Message';

// import TrkCovidExposure from './pages/track/trackcompment/TrkCovidExposure';
import TrkTodayComplete from './pages/track/trackcompment/TrkTodayComplete';
import History from './pages/track/trackcompment/History';
import FeverHistory from './pages/track/trackcompment/FeverHistory';
import AddVaccineRecord from './pages/more/vaccinerecord/AddVaccineRecord';
import VaccineRecord from './pages/more/vaccinerecord/VaccineRecord';
import AppointmentDetails from './pages/calendar/AppointmentDetails';
import ShareCal from './pages/calendar/ShareCal';
import ScheduleAppointment from './pages/calendar/ScheduleAppointment';

i18n.use(initReactI18next).init({
  resources: {
    en: en,
    es: es,
    zh: zh,
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
        }}
      >
        <div style={styles.container}>
          <div
            style={CommonUtil.isPC() ? styles.pcContent : styles.mobileContent}
          >
            <Router>
              <Switch
                atEnter={{ opacity: 0 }}
                atLeave={{
                  opacity: spring(0, { stiffness: 330, damping: 22 }),
                }}
                atActive={{
                  opacity: spring(1, { stiffness: 330, damping: 22 }),
                  scale: spring(2, { stiffness: 330, damping: 22 }),
                }}
                mapStyles={(mStyles) => {
                  return { opacity: mStyles.opacity };
                }}
              >
                <Route exact path="/" component={Login} />
                <Route exact path="/SSOWeb" component={SSOWeb} />
                <Route exact path="/Consent" component={Consent} />
                <Route exact path="/Questionnaire" component={Questionnaire} />
                <Route exact path="/register" component={Register} />
                <Route
                  exact
                  path="/problemssigningin"
                  component={ProblemsSigningIn}
                />
                <Route exact path="/email" component={Email}/>
                <Route exact path="/nameAndMobileNumber" component={NameAndMobileNumber}/>
                <Route exact path="/authenticationEmail" component={AuthenticationEmail}/>                
                <Route exact path="/pin" component={Pin} />
                <Route exact path="/resetpin" component={ResetPin} />
                <Route exact path="/survey" component={Survey} />
                <Route exact path="/acsurvey" component={ACSurvey} />
                <Route exact path="/surveyDetail" component={SurveyDetail} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/home/resources" component={Resources} />
                <Route exact path="/home/calendar" component={Calendar} />
                <Route
                  exact
                  path="/home/calendar/appointment"
                  component={AppointmentDetails}
                />
                <Route
                  exact
                  path="/home/calendar/sharecal"
                  component={ShareCal}
                />
                <Route
                  exact
                  path="/home/calendar/schedule"
                  component={ScheduleAppointment}
                />
                <Route exact path="/home/topic" component={Topic} />
                <Route exact path="/home/more" component={More} />
                <Route exact path="/home/more/settings" component={Setting} />
                <Route exact path="/home/more/surveys" component={Survey} />
                <Route
                  exact
                  path="/home/more/testresults"
                  component={TestResults}
                />
                <Route
                  exact
                  path="/home/more/shareresults"
                  component={ShareResults}
                />
                <Route
                  exact
                  path="/home/more/addtestresult"
                  component={AddTestResults}
                />
                <Route
                  exact
                  path="/home/more/testresultdetail"
                  component={TestResultDetail}
                />
                <Route
                  exact
                  path="/home/more/vaccinerecords"
                  component={VaccineRecord}
                />
                <Route
                  exact
                  path="/home/more/addvaccinerecord"
                  component={AddVaccineRecord}
                />
                <Route
                  exact
                  path="/home/more/settings/termsofUse"
                  component={SettingTermOfUse}
                />
                <Route
                  exact
                  path="/home/more/settings/privacy"
                  component={SettingPrivacy}
                />
                <Route
                  exact
                  path="/home/more/settings/updateuseraccount"
                  component={UpdateUserAccount}
                />
                <Route
                  exact
                  path="/home/more/settings/updatepin"
                  component={UpdatePIN}
                />
                <Route exact path="/home/more/help" component={Help} />
                <Route
                  exact
                  path="/home/more/help/detail/:type"
                  component={HelpDetail}
                />
                <Route
                  exact
                  path="/home/more/settings/language"
                  component={SettingLanguage}
                />
                <Route exact path="/trackmain" component={TrackMain} />
                <Route exact path="/trkhowisToday" component={TrkHowIsToday} />
                <Route exact path="/trackcomplete" component={Complete} />
                <Route
                  exact
                  path="/tracktodaycomplete"
                  component={TrkTodayComplete}
                />
                <Route exact path="/trackhistory" component={History} />
                <Route exact path="/home/messages" component={Message} />
                <Route exact path="/feverhistory" component={FeverHistory} />
              </Switch>
            </Router>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 0,
    margin: 0,
    backgroundColor: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pcContent: {
    display: 'flex',
    width: CommonUtil.clientWidth(),
    height: CommonUtil.clientHeight(),
    zoom: CommonUtil.clientZoom(),
    borderRadius: 10,
    padding: 0,
    margin: 0,
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
  mobileContent: {
    display: 'flex',
    alignSelf: 'stretch',
    flex: 1,
    backgroundColor: '#fff',
  },
};
