import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import httpUtil from '../../utils/HttpUtil';
import NavBar from '../../components/NavBar.js'
import '../../content/style/form.css';

export default class UpdatePin extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            pin: '',
            pinconfirm: '',
            have4digits: true,
            samePin: true,
            showIndicator: false,
            regSuccess: false,
        };
        this._saveAndContinue = this._saveAndContinue.bind(this);
        this._validateValues = this._validateValues.bind(this);
        this._goBack = this._goBack.bind(this);
    }
    componentDidMount() {

    }

    _showError() {
    
    }
    
    _goBack() {
        this.props.navigator.pop();
        if (this.props.afterChange) {
          this.props.afterChange();
        }
    }
    
    _validateValues() {
        return true;
    }

    //TODO:需要继续修改alert
    _saveAndContinue() {
        if (this.state.pin.length === 0) {
            alert(
                //Translation.lang().Missing,
                this.translate("w_Please_enter_digit_PIN_")
            )
        }
        else if (this.state.pin.length < 4) {
            alert(
                //Translation.lang().w_setPin,
                this.translate("w_must_contain_4_digits")
            )
        }
        else if (this.state.pin !== this.state.pinconfirm) {
            alert(
                //Translation.lang().w_setPin,
                this.translate("w_pin_must_match")
            )
        }
        else {
            this.setState({ showIndicator: true });
            httpUtil.post('/api/Participant/UpdatePin', { pin: this.state.pin },
                (responseData) => {
                    this.setState({ showIndicator: false });
                    if (responseData && responseData.status === 'Success') {
                        if (responseData.data === '1') {
                            localStorage.setItem("pin",this.state.pin);
                            alert(this.translate("w_You_have_successfully_reset_your_PIN"));
                            /*Alert.alert(
                              Translation.lang().w_PIN_Reset,
                              Translation.lang().w_changePinSuccess,
                              [
                                {
                                  text: Translation.lang().w_ok, onPress: () => {
                                    MomEnv.back();
                                  }
                                },
                              ]
                            )*/
                            window.history.back();
                          }
                          else {
                            alert(this.translate("w_Reset_Failed"));
                            //Alert.alert(Translation.lang().w_Warning, Translation.lang().w_Reset_Failed);
                          }
                    } else {
                        alert(
                            //Translation.lang().w_reset_pin,
                            this.translate("w_Reset_PIN_Failed__try_again_later")
                        )
                    }
                }
            );
        }
        //}
    }

    _validPin(pin) {
        this.setState({
            have4digits: pin.length >= 4,
            samePin: pin === this.state.pinconfirm
        });
    }

    _validPinconfirm(pinconfirm) {
        this.setState({ samePin: pinconfirm === this.state.pin });
    }

    _scrollToEnd() {
        this._scroll && this._scroll.scrollTo({ x: 0, y: 160, animated: true });
    }

    getProfile() {
        httpUtil.get('/api/Profile/GetMine',
            (profileData) => {
                if (profileData.id && profileData.status === 1) {
                    localStorage.setItem('profile', JSON.stringify(profileData));
                    localStorage.setItem("need_alert_survey","1");
                    this.props.history.push('/trackmain');
                }
            }
        );
    }

    _signIn(param) {
        this.setState({ showIndicator: true });
        httpUtil.post('/Token', `grant_type=password&username=${param.email}&password=${param.pin}`,
            (result) => {
                this.setState({ showIndicator: false });
                if (result && result.access_token) {
                    let token = result.access_token;
                    localStorage.setItem('token', token);
                    this.getProfile();

                }
            }
        );
    }

    render() {
        let { pin, pinconfirm } = this.state;
        return (
            <div className="conatiner" style={{ justifyContent: 'flex-start' }}>
                <NavBar title={this.translate("w_updatePin")} history={this.props.history} />
                <div style={{margin:20}}>
                    <input
                        className="pin_input"
                        type='password'
                        maxLength={4}
                        placeholder={this.translate("w_new_4_digit_pin")}
                        value={pin}
                        onChange={(e) => {
                            this.setState({ pin: e.target.value });
                            this._validPin(e.target.value.replace(/\D/g, ''))
                        }}
                    />
                    <input
                        className="pin_input"
                        type='password'
                        style={{ marginTop: 20, }}
                        maxLength={4}
                        placeholder={this.translate("w_confirm_4_digit_pin")}
                        value={pinconfirm}
                        onChange={(e) => {
                            this.setState({ pinconfirm: e.target.value });
                            this._validPinconfirm(e.target.value.replace(/\D/g, ''));
                        }}
                    />
                    {
                        pin !== '' &&
                        <div style={{ alignSelf: 'stretch', marginBottom: 10 }}>
                            <p style={this.state.have4digits ? styles.txtOK : styles.txtErr}>
                                {this.state.have4digits ? '√' : 'x'} {this.translate("w_must_contain_4_digits")}
                            </p>
                            <p style={this.state.samePin ? styles.txtOK : styles.txtErr}>
                                {this.state.samePin ? '√' : 'x'} {this.translate("w_pin_must_match")}
                            </p>
                        </div>
                    }
                    <button onClick={this._saveAndContinue.bind(this)} className="save_button">
                        {this.translate('w_save')}
                    </button>
                </div>

            </div>
        );
    }

}
const styles = {
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',

    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5,
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    txtErr: {
        color: '#f00',
        marginTop: 3,
        fontSize: 14
    },
    txtOK: {
        color: '#00b359',
        marginTop: 3,
        fontSize: 14
    }
};