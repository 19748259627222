import React, { Component } from 'react';
import TabBarContainer from './../../components/TabBarContainer.js';
import moment from 'moment'; 
import dashboardNoData from './../../content/images/dashboardNoData.png';
import HttpUtil from './../../utils/HttpUtil.js';
import Container from '../../components/Container.js' 
//import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import './../../content/style/confirm.css';  
import BaseComponent from '../../components/BaseComponent.js'; 
import ScrollView from '../../components/ScrollView.js';
import CommonUtil from '../../utils/CommonUtil';

export default class Message extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: null,
      numOfNew: 0,
      loaded: false,
      editing: false,
      delUids: [],
    };
  }

  componentDidMount() {   
     //document.title = 'Message|MACH15';    
     this._reload(); 
  }

  _reload() {
    HttpUtil.post('/api/Message/queryEntries', {},
      (responseData) => {
        if (responseData) {
          let messages = responseData.map((data) => {
            let message = {
              type: data.type == 1 ? 'photo' : 'text',
              image: null,
              position :'left',
              date: data.emailDate,
              name: data.fromUserName,
              attachmentName: data.attachmentName,
              attachmentType: data.attachmentType,
              attachmentBase64Content: data.attachmentBase64Content,
              attachmentId: data.attachmentId 
            };
            if (data.type == 1) {
              message.data = data.content;
            }
            else {
              message.text = data.content;
            }
            return message;
          });
          this.setState({ dataSource: messages, loaded: true });
        }else{
          this.setState({ loaded: true });
        }
      }
    );
  }

  _formatDateTime(datetime){
     if(moment.utc(datetime).local().format('l') ==  moment.utc(new Date()).local().format('l')){
         return moment.utc(datetime).local().format('h:mm A');
     }else{
         return moment.utc(datetime).local().format('lll')
     }
  }

  _renderNoMessages(){
    return (
      <div className='fill' style={{direction:'ltr'}}>
         <div style={{backgroundColor:'#ffffff',margin:20,marginTop:150, marginBottom:60,borderRadius:10,padding:35}}>
            <img src={dashboardNoData} style={{margin:15,marginLeft:140}} />
            <div style={{marginTop:10,marginLeft:128}}>
               {this.translate('w_noMessage')}
            </div>            
         </div>
      </div>
    );
  }

  _clickLinkOrEmail(e) {
    let el = e.target;
    while (el && el !== e.currentTarget && el.tagName !== "A") {
        el = el.parentNode;
    }
    //如果点击点到了a标签
    if (el && el.tagName === "A") {
      //获取data-href的链接，打开新的窗口
      window.open(e.target.dataset.href);
    }
  }

  _renderMessages(dataSource){
    let datas = [];
    dataSource.map((item,index)=>{
      //通过正则替换链接为javascript:;，将原链接存入data-href里面
      var reg = /<a[^>]*href[=\"\'\s]+([^\"\']*)[\"\']?[^>]*>/gi;
      var text = item.text;
      while(reg.exec(text)){
        text = text.replace('<a href="' + RegExp.$1 +'"','<a href="javascript:;" data-href="' + RegExp.$1 +'"');
      }

      datas.push(
         <div key={index} style = {{marginTop:'10px',marginBottom:'5px'}} 
              className={`${item.position} date`}
         >
            { this._formatDateTime(item.date) }
         </div>
      );

      datas.push(
        <div style={{backgroundColor: '#EBE6E6',borderRadius:'10px',width:'80%',height:'80%'}}> 
          <div key={index} style = {{ margin:'10px',textAlign:'left'}}
            onClick={this._clickLinkOrEmail} 
            className={item.position} dangerouslySetInnerHTML={{__html: text}}>
 
          </div>
        </div>
       );
    });
    return (
      <div className='fill chat' style={{justifyContent:'flex-start',backgroundColor:'#fff',paddingTop:5,alignItems: 'flex-end' }}>
          { datas }
      </div>
    );
 }

  render() {  
    let {loaded, numOfNew, dataSource,editing,delUids} = this.state;

    let delColor = delUids.length>0 ? '#f00' : '#aaa';
    return (
       <TabBarContainer history={this.props.history} tab={'message'} msgCount={numOfNew}>         
         <div className="navbar-container">
                    {this.translate('Messages')}
         </div>
         <Container>
         <div className='fill overflow-hidden' style={{backgroundColor:'#fff'}}>
            <div className='fill auto-left-scrollbar' style={{paddingRight:0}}>
              <div className='row auto-left-scrollbar' style={{maxHeight: CommonUtil.clientHeight() - 150,alignSelf:'stretch',justifyContent:'space-between',padding:15,paddingTop:20,paddingBottom:10,paddingRight:0}}> 
                { dataSource && dataSource.length > 0 ? this._renderMessages(dataSource) : this._renderNoMessages()} 
              </div> 
            </div>
          </div>
         </Container>  
       </TabBarContainer>
    );
  }
}