import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import NavBar from '../../components/NavBar';
import common from '../../Common';
import TopicSvr from '../../service/TopicSvr';
import { getAllByTestId } from '@testing-library/dom';
export default class Topic extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let topic = this.props.location.state.topic;
        //console.log(topic);   
        let title = ''
        let uri = '';
       
        if(topic.subject){
            let href_startindex = topic.subject.indexOf('href="');
            if (href_startindex > 0) {
                let href_endindex = topic.subject.indexOf('"', href_startindex + 6);
                if (href_endindex > href_startindex + 6) {
                    uri = JSON.stringify(topic.subject.substring(href_startindex + 6, href_endindex));
                    uri = uri.replace('"', '').replace('"', '');
                }
            }
            //console.log(uri);
            title = topic.subject.replace(/(<\/?a.*?>)|(<\/?span.*?>)/g, '').replace('&nbsp;', ' ');
        }
        if (uri === '' || uri == null) {
            uri = common.BASE_URL + 'WebApi/Media/WikiWithFont2/' + topic.id
        }

        //embed
        return (
            <div className='conatiner'>
              <NavBar title={title} history={this.props.history} />
                <div className='fill' style={{ justifyContent: 'flex-start', backgroundColor: '#ffffff', fontSize: '12px'  }}>               
                   {
                       <iframe 
                       src={uri}
                       className='conatiner'
                       
                       />
                   }              
                </div>
            </div>
        );
    }
}
