import React, { Component } from 'react';
import BaseComponent from '../../../components/BaseComponent';
import NavBar from '../../../components/NavBar.js'
import arrow_right from '../../../content/images/arrow_right.png';

export default class Settings extends BaseComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    _itemClick(item) {
        if (item === 'updateuseraccount') {
            this.props.history.push("/home/more/settings/updateuseraccount");
        } else if (item === 'updatepin') {
            this.props.history.push("/home/more/settings/updatepin");
        } else if (item === 'terms') {
            this.props.history.push("/home/more/settings/termsofUse");
        } else if (item === 'privacy') {
            this.props.history.push("/home/more/settings/privacy");
        } else if (item === 'picture') {
            this.props.history.push("/home/more/settings/picture");
        } else if (item === 'language') {
            this.props.history.push("/home/more/settings/language");
        } else if (item === 'datarequests') {
            this.props.history.push("/home/more/settings/datarequests");
        }
    }

    render() {
        return (
            <div className="conatiner">
                <NavBar title={this.translate("w_settings")} backurl='/home/more' history={this.props.history} />
                <div className='fill' style={{ justifyContent: 'flex-start', backgroundColor: '#ffffff' }}>
                    <div style={{ alignSelf: 'stretch', padding: 12, alignItems: 'flex-start', backgroundColor: '#ddd', fontSize: 20 }}>
                        {this.translate("w_DLANGUAGE")}
                    </div>
                    <div
                        className='row pointer bottomBorder'
                        style={{ alignSelf: 'stretch', padding: 8, display: 'flex', alignItems: 'center' }}
                        onClick={this._itemClick.bind(this, 'language')}
                    >
                        <div style={{ flex: 1, alignItems: 'flex-start', margin: 3, fontSize: 18 }}>{this.translate("w_language")}</div>
                        <img src={arrow_right} style={{ width: 8, height: 16, marginRight: 3 }} />
                    </div>
                    <div style={{ alignSelf: 'stretch', padding: 12, alignItems: 'flex-start', backgroundColor: '#ddd', fontSize: 20 }}>
                        {this.translate("w_setting_security")}
                    </div>
                    {/*<div
                        className='row pointer bottomBorder'
                        style={{ alignSelf: 'stretch', padding: 8, display: 'flex', alignItems: 'center' }}
                        onClick={this._itemClick.bind(this, 'updatepin')}
                    >
                    <div style={{ flex: 1, alignItems: 'flex-start', margin: 3, fontSize: 18 }}>{this.translate("w_updatePin")}</div>
                        <img src={arrow_right} style={{ width: 8, height: 16, marginRight: 3 }} />
                    </div>*/}
                    <div
                        className='row pointer bottomBorder'
                        style={{ alignSelf: 'stretch', padding: 8, display: 'flex', alignItems: 'center' }}
                        onClick={this._itemClick.bind(this, 'updateuseraccount')}
                    >
                    <div style={{ flex: 1, alignItems: 'flex-start', margin: 3, fontSize: 18 }}>{this.translate("w_Update_User_Account")}</div>
                        <img src={arrow_right} style={{ width: 8, height: 16, marginRight: 3 }} />
                    </div>
                    <div style={{ alignSelf: 'stretch', padding: 12, alignItems: 'flex-start', backgroundColor: '#ddd', fontSize: 20 }}>
                        {this.translate("w_setting_legal")}
                    </div>
                    <div
                        className='row pointer bottomBorder'
                        style={{ alignSelf: 'stretch', padding: 8, display: 'flex', alignItems: 'center' }}
                        onClick={this._itemClick.bind(this, 'terms')}
                    >
                        <div style={{ flex: 1, alignItems: 'flex-start', margin: 3, fontSize: 18 }}>{this.translate("w_termOfUse")}</div>
                        <img src={arrow_right} style={{ width: 8, height: 16, marginRight: 3 }} />
                    </div>
                    <div
                        className='row pointer bottomBorder'
                        style={{ alignSelf: 'stretch', padding: 8, display: 'flex', alignItems: 'center' }}
                        onClick={this._itemClick.bind(this, 'privacy')}
                    >
                        <div style={{ flex: 1, alignItems: 'flex-start', margin: 3, fontSize: 18 }}>{this.translate("w_privacyPolicy")}</div>
                        <img src={arrow_right} style={{ width: 8, height: 16, marginRight: 3 }} />
                    </div>
                </div>
            </div>

        );
    }
}