import React from "react";
import BaseComponent from "../../../components/BaseComponent";
import TabBarContainer from "../../../components/TabBarContainer";
import httpUtil from "../../../utils/HttpUtil";
import ReactDOM from "react-dom";
import ReactSwipe from "react-swipe";
import moment from "moment";
import journay_logo from "../../../content/images/track-journay.png";
import star_logo from "../../../content/images/star.png";
import icon_Self_Certification from "../../../content/images/icon_Self_Certification.png";

import Loading from "../../../components/Loading";
import CacheUtil from "../../../utils/CacheUtil";
import ModalContainer from "../../../components/ModalContainer";
import ScrollView from "../../../components/ScrollView";
import CommonUtil from "./../../../utils/CommonUtil";
import Common from "../../../Common";
import TopicSvr from "../../../service/TopicSvr";
let width = CommonUtil.clientWidth();
let height = CommonUtil.clientHeight();
let Items = [
  {
    title: "Start Symptoms Tracker",
    tip: "Select one or more symptoms",
    value: 3,
  },
  { title: "All good!", tip: "No symptoms", value: 1 },
];

export default class TrkHowIsToday extends BaseComponent {
  constructor(props) {
    super(props);
    let temperature = "";
    Common.TrackItems().map((item) => {
      if (item.key == "fever" && item.selected) {
        temperature = item.feverValue.toString();
      }
    });
    this.state = {
      canStart: false,
      temperature: temperature,
      showFever: false,
      eligible: false,
      noSymptoms: false,
      baseQRImg: "",
      base64img: null,
      loaded: false,
      articles: [],
      swipe_index: 0,
      TrackItems: Common.TrackItems(),
    };
    this.swipeCallback = this.swipeCallback.bind(this);
  }

  componentWillMount() {
    //this.getQRCode();
    this.loadData();
  }

  loadData() {
    this.setState({ loading: true });
    CacheUtil.GetFeaturedArticles((responseData) => {
      this.setState({ loading: false, articles: responseData });
    });
  }
  swipeCallback(index, elem) {
    this.setState({ swipe_index: index });
  }
  itemClick(index) {
    if (this.props.onSelectHowToday) {
      if (index == 0) {
        this.props.onSelectHowToday(3, true);
      } else {
        this.props.onSelectHowToday(1, false);
        // MomEnv.pushPage(TrkTodayAllGood);
      }
    }
  }

  PickEditSymptoms(useLastTrk) {
    this.props.onSelectHowToday &&
      this.props.onSelectHowToday(3, true, useLastTrk);
  }

  showSummary() {
    this.props.onShowSummary && this.props.onShowSummary(true);
  }

  pickSymptoms(index) {
    let { eligible, TrackItems } = this.state;

    TrackItems[index].selected = !TrackItems[index].selected;

    let canStart = false;
    TrackItems.map((item) => {
      canStart = canStart || item.selected;
    });
    eligible = false;
    TrackItems.map((item) => {
      if (item.key == "troubleBreadthing" && item.selected) {
        eligible = true;
      }
    });
    if (TrackItems[index].key == "fever") {
      this.setState({ showFever: TrackItems[index].selected });
    }
    this.setState({
      canStart: canStart,
      noSymptoms: false,
      TrackItems: TrackItems,
    });
  }

  start() {
    //let profileType= Profile.profileType();
    let profileType = 1;
    if (this.props.onFinishTracking) {
      if (this.state.noSymptoms) {
        if (profileType == 1) {
          // Alert.alert(
          //     Translation.lang().No_Symptoms,
          //     Translation.lang().Many_authorities_recommend,
          //     [
          //         {
          //             text: Translation.lang().w_ok, onPress: () => {
          this.props.onSelectHowToday && this.props.onSelectHowToday(1, false);
          this.props.onShowSummary && this.props.onShowSummary(true);
          //                 //MomEnv.pushPage(TrkTodayAllGood);
          //             }
          //         }
          //     ]
          // );
        } else {
          // Alert.alert(
          //     Translation.lang().No_Symptoms,
          //     Translation.lang().Please_continue_symptoms,
          //     [
          //         {
          //             text: Translation.lang().w_ok, onPress: () => {
          this.props.onSelectHowToday && this.props.onSelectHowToday(1, false);
          this.props.onShowSummary && this.props.onShowSummary(true);
          //                 //MomEnv.pushPage(TrkTodayAllGood);
          //             }
          //         }
          //     ]
          // );
        }
      } else {
        let { temperature, TrackItems } = this.state;
        let { showFever } = this.state;
        let validTemperature = true;
        if (temperature != null && temperature.length > 0) {
          TrackItems.map((item) => {
            if (item.key == "fever" && item.selected) {
              item.feverValue = temperature;
              validTemperature =
                temperature != "" &&
                parseFloat(temperature) <= 106 &&
                parseFloat(temperature) >= 90;
            }
          });
        }
        if (validTemperature) {
          this.props.onFinishTracking(TrackItems);
        } else {
          //Alert.alert(Translation.lang().Warning, Translation.lang().Temperature_should_greater);
        }
      }
    }
  }
  viewHistory() {
    this.props.viewHistory && this.props.viewHistory();
  }

  toTopic(item) {
    TopicSvr.openTopic(item);
  }

  render() {
    //   let name = Profile.ItemValue("firstName");
    let reactSwipeEl;
    let { lastTrk, value, howIsToday } = this.props;
    let { temperature, base64img, loaded } = this.state;
    let profile = JSON.parse(localStorage.getItem("profile"));
    let name = profile.firstName;
    let title = this.translate("Hello") + name;
    let htmls = [];
    let trkHtmls = [];
    let { canStart, TrackItems, articles, swipe_index } = this.state;
    let items = [];

    if (articles && articles.length > 0) {
      articles.map((item, idx) => {
        items.push(
          <div
            className="item"
            key={idx}
            onClick={this.toTopic.bind(this, item)}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {item.subject
                .replace(/(<\/?a.*?>)|(<\/?span.*?>)/g, "")
                .replace("&nbsp;", " ")}
              <img
                className="image"
                src={"data:image/png;base64," + item.topicImageBase64}
                resizemode={"contain"}
                alt=""
              />
            </div>
            <div style={{ marginTop: 10 }}>
              {item.content
                .replace(/<\/?[^>]*>/g, "")
                .replace(/[|]*\n/, "")
                .replace(/&nbsp;/gi, " ")}
            </div>
          </div>
        );
      });
    }
    if (this.props.showSummary) {
      title = name + this.translate("s_symptoms");
      trkHtmls.push(
        <div
          key={"v_00"}
          style={{
            alignSelf: "stretch",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 0,
            backgroundColor: "#FFF",
            marginTop: 4,
            marginBottom: 4,
            borderRadius: 5,
          }}
        >
          {lastTrk && lastTrk.selfCertification && (
            <div
              onClick={() => {
                this.props.onshowSelfCertification &&
                  this.props.onshowSelfCertification(true, false, false);
              }}
            >
              <img
                style={{ width: 70, height: 70 }}
                src={icon_Self_Certification}
                resizeMode={"contain"}
              />
            </div>
          )}
          <div style={{ flex: 1 }} />
        </div>
      );
      TrackItems.map((item, index) => {
        if (lastTrk && lastTrk[item.key]) {
          trkHtmls.push(
            <div
              key={"v_" + index}
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 0,
                backgroundColor: "#FFF",
                marginTop: 4,
                marginBottom: 4,
                borderRadius: 5,
              }}
            >
              <div style={{ width: 50, textAlign: "center" }}>
                <img
                  style={{ maxWidth: 50, maxHeight: 50 }}
                  src={item.logo}
                  resizeMode={"contain"}
                />
              </div>
              <p style={{ marginLeft: 5, backgroundColor: "#FFF" }}>
                {item.name}
              </p>
              {/* {
                                item.instructiona &&
                                <TouchableOpacity
                                    style={{ padding: 12 }}
                                    onPress={() => {
                                        item.show = !item.show;
                                        this.setState({ update: item.show });
                                    }}
                                >
                                    <Image
                                        style={{ height: 12, width: 12, marginLeft: 20 }}
                                        source={item.show ? require('../../../../assets/crocus/up.png') : require('../../../../assets/crocus/down.png')}
                                        resizeMode={'contain'} />
                                </TouchableOpacity>
                            } */}
              <div style={{ flex: 1 }} />
            </div>
          );
          if (
            item.key == "fever" &&
            lastTrk.feverValue != null &&
            lastTrk.feverValue > 0
          ) {
            trkHtmls.push(
              <p
                key="f_v"
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 48,
                  marginTop: -8,
                }}
              >
                {`${lastTrk.feverValue.toString()} °F`}
              </p>
            );
          }
          // if (item.instructiona && item.show) {
          //     trkHtmls.push(
          //         <View key={'in_' + index} style={{ alignSelf: 'stretch', backgroundColor: '#f6f6f6', padding: 8, borderRadius: 15 }}>
          //             <Text style={[GlobalStyles.normalFont, GlobalStyles.fontSize14]}>
          //                 <Text style={[GlobalStyles.boldFont, GlobalStyles.fontSize14, { color: '#888' }]}>{item.name + ' '}</Text>{item.instructiona}
          //             </Text>
          //         </View>
          //     );
          // }
        }
      });
      if (howIsToday && lastTrk && lastTrk.contactwithCOVID != null) {
        if (lastTrk.contactwithCOVID == false) {
          trkHtmls.push(
            <div
              key="v_no"
              style={{
                alignSelf: "stretch",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFF",
                textAlign: "center",
              }}
            >
              {/* <TouchableOpacity
                                onPress={() => {
                                    this.props.onshowSelfCertification && this.props.onshowSelfCertification(true, false, false);
                                }}
                            >
                                <Image
                                    style={{ width: 70, height: 70 }}
                                    source={require('../../../../assets/icon_Self_Certification.png')}
                                    resizeMode={'contain'} />
                            </TouchableOpacity> */}

              {this.translate("No_Symptoms")}
            </div>
          );
        } else {
          trkHtmls.push(
            <div key="NoSymptoms2">{this.translate("No_Symptoms")}</div>
          );
        }
        // trkHtmls.push(
        //     <View key='NoSymptoms1'
        //         style={[{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 5, backgroundColor: '#FFF' }]}>
        //         <Image
        //             style={{ width: 70, height: 70 }}
        //             source={require('../../../../assets/icon_Self_Certification.png')}
        //             resizeMode={'contain'} />
        //         <Text style={[GlobalStyles.normalFont, GlobalStyles.fontSize18, { color: GlobalColor.textColor, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 30, marginLeft: 40, backgroundColor: 'transparent' }]}>
        //             {Translation.lang().No_Symptoms}
        //         </Text>

        //     </View>
        // );
      }
    } else {
      let { TrackItems } = this.state;
      for (var i = 0, l = TrackItems.length; i < l; i += 3) {
        let item1 = TrackItems[i];
        let item2 = TrackItems[i + 1];
        let item3 = TrackItems[i + 2];
        // let item = common.TrackItems[i];
        htmls.push(
          <div
            key={i}
            style={styles.trackContainer1}
            // style={{
            //     alignSelf: 'stretch',
            //     flexDirection: 'row',
            //     justifyContent: 'space-around',
            //     marginLeft: 30,
            //     marginRight: 30,
            //     alignItems: 'center',
            //     display: 'flex'

            // }, item1.show ? { borderBottomWidth: 0 } : {}}
          >
            <div
              key={i + 1}
              style={{
                marginBottom: 8,
                borderRadius: 10,
                borderWidth: 2,
                marginLeft: 5,
                marginRight: 5,
                borderStyle: "solid",
                borderColor: item1.selected ? "#35B502" : "#eeeeee",
              }}
              onClick={() => {
                this.pickSymptoms(item1.type);
              }}
            >
              <div style={styles.imageContent}>
                <img
                  resizemode={"center"}
                  style={{
                    marginTop: 4,
                    height: 50,
                  }}
                  src={item1.logo}
                />
                {item1.selected && (
                  <img
                    style={styles.selectedSymptom}
                    src={require("../../../content/images/overallIconStatusWell.png")}
                  />
                )}
                <p
                  style={{
                    marginTop: 1,
                    alignSelf: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: width === 320 ? 12 : 14,
                    color: "#717171",
                  }}
                >
                  {item1.name}
                </p>
              </div>
            </div>
            {item2 && (
              <div
                key={i + 2}
                style={{
                  marginBottom: 8,
                  borderRadius: 10,
                  borderWidth: 2,
                  borderStyle: "solid",
                  marginLeft: 5,
                  marginRight: 5,
                  borderColor: item2.selected ? "#35B502" : "#eeeeee",
                }}
                onClick={() => {
                  this.pickSymptoms(item2.type);
                }}
              >
                <div style={styles.imageContent}>
                  <img
                    resizemode={"center"}
                    style={{
                      marginTop: 4,
                      height: 50,
                    }}
                    src={item2.logo}
                  />
                  {item2.selected && (
                    <img
                      style={styles.selectedSymptom}
                      src={require("../../../content/images/overallIconStatusWell.png")}
                    />
                  )}
                  <p
                    style={{
                      marginTop: 1,
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: width === 320 ? 12 : 14,
                      color: "#717171",
                    }}
                  >
                    {item2.name}
                  </p>
                </div>
              </div>
            )}
            {item3 && (
              <div
                key={i + 3}
                style={{
                  marginBottom: 8,
                  marginLeft: 5,
                  marginRight: 5,
                  borderRadius: 10,
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: item3.selected ? "#35B502" : "#eeeeee",
                }}
                onClick={() => {
                  this.pickSymptoms(item3.type);
                }}
              >
                <div style={styles.imageContent}>
                  <img
                    resizemode={"center"}
                    style={{
                      marginTop: 4,
                      height: 50,
                    }}
                    src={item3.logo}
                  />
                  {item3.selected && (
                    <img
                      style={styles.selectedSymptom}
                      src={require("../../../content/images/overallIconStatusWell.png")}
                    />
                  )}
                  <p
                    style={{
                      marginTop: 1,
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: width === 320 ? 12 : 14,
                      color: "#717171",
                    }}
                  >
                    {item3.name}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      }
    }
    //console.log(trkHtmls);
    return (
      // <KeyboardHandler ref='kh' style={[styles.content, { marginTop: 0 }]} offset={150}>
      //, this.props.style
      <div className="conatiner">
        <ScrollView style={styles.content}>
          <div
            style={{
              alignSelf: "stretch",
              flex: 1,
              alignItems: "center",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                alignItems: "center",
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    alignSelf: "flex-start",
                    marginTop: 5,
                    fontSize: 20,
                  }}
                >
                  {title}
                </p>
                <p
                  style={{
                    marginTop: 6,
                    marginBottom: 10,
                    alignSelf: "flex-start",
                  }}
                >
                  {moment().format("D MMMM YYYY")}
                </p>
              </div>

              {
                // lastTrk && lastTrk.isToday &&
                this.props.showSummary && (
                  <div
                    style={{
                      alignSelf: "stretch",
                      justifyContent: "space-around",
                      display: "grid",
                    }}
                  >
                    <button
                      className="button"
                      style={
                        //GlobalStyles.button2,
                        {
                          fontSize: 14,
                          width: width > 400 ? 150 : 120,
                          height: width > 400 ? 40 : 36,
                          marginTop: 5,
                        }
                      }
                      onClick={() => {
                        this.viewHistory();
                      }}
                    >
                      <p>{this.translate("View_History")}</p>
                    </button>

                    <button
                      className="button"
                      style={{
                        fontSize: 14,
                        width: width > 400 ? 150 : 120,
                        height: width > 400 ? 40 : 36,
                        marginTop: 5,
                      }}
                      //onPress={this.PickEditSymptoms.bind(this, false)}
                      onClick={() => {
                        this.props.onShowSummary(false);
                        this.props.onSelectHowToday(3, false, true);
                      }}
                    >
                      {this.translate("Update_Symptoms")}
                    </button>
                  </div>
                )
              }
              {
                // this.props.haveTracked && !(lastTrk && lastTrk.isToday) &&
                howIsToday > 0 && !this.props.showSummary && (
                  <button
                    className="button"
                    style={{
                      fontSize: 14,
                      width: width > 400 ? 150 : 120,
                      height: width > 400 ? 40 : 36,
                      marginTop: 10,
                    }}
                    // underlayColor={GlobalColor.buttoUunderlayColor}
                    onClick={() => {
                      this.props.onShowSummary(true);
                    }}
                  >
                    {this.translate("View_Summary")}
                  </button>
                )
              }
            </div>
            {!this.props.showSummary && (
              <div
                style={{
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    padding: 5,
                    //width: 90,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 5,
                    textAlign: "center",
                    color: "#000",
                  }}
                >
                  {this.translate("do_you_have_symptoms")}
                </p>
              </div>
            )}
            {!howIsToday && !this.props.showSummary && !canStart && (
              <div
                style={
                  this.state.noSymptoms
                    ? styles.button
                    : styles.noSymptomsButton
                }
                onClick={() => {
                  //this.itemClick(1);
                  this.setState({ noSymptoms: !this.state.noSymptoms });
                  this.props.onshowNoSymptoms &&
                    this.props.onshowNoSymptoms(true);
                }}
              >
                <p
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {this.translate("w_Do_have_No_symptoms1")}
                </p>
              </div>
            )}
            <div
              style={{
                alignSelf: "stretch",
                alignItems: "center",
                marginTop: 5,
              }}
            >
              {htmls}
            </div>
            {!this.props.showSummary && (
              <div
                style={{
                  alignSelf: "flex-start",
                  flexDirection: "row",
                  paddingLeft: 5,
                  marginTop: 1,
                  display: "flex",
                  marginLeft: 35,
                }}
              >
                {this.state.showFever && (
                  <input
                    key={"e"}
                    // secureTextEntry={false}
                    // autoFocus={false}
                    // keyboardType={'numeric'}
                    // maxLength={5}
                    // autoCorrect={false}
                    // autoCapitalize="none"
                    // clearButtonMode="while-editing"
                    style={
                      // { flex: 1, height: 36, alignSelf: 'center', color: '#666' },
                      { alignSelf: "flex-start", color: "#666", width: 50 }
                      // width < 360 && { flex: 5 }
                    }
                    type="text"
                    // placeholderTextColor="rgb(158,158,158)"
                    value={this.state.temperature}
                    onChange={(e) => {
                      // text = text.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
                      if (
                        e.target.value == "" ||
                        parseFloat(e.target.value) < 1000
                      ) {
                        this.setState({ temperature: e.target.value });
                        // this.setState({ temperature: text });
                      }
                    }}
                    // ref="fever"
                    onFocus={() => {
                      //this.refs.kh && this.refs.kh.inputFocused(this, 'fever')
                    }}
                  />
                )}
                <div style={{ alignSelf: "center" }}>
                  {this.state.showFever && "  °F"}
                </div>
              </div>
            )}

            {!this.props.showSummary && (canStart || this.state.noSymptoms) && (
              <button
                className="button"
                style={
                  { marginTop: 10, marginBottom: 20, width: width - 70 }
                  // this.state.noSymptoms && { marginTop: -5 }
                }
                onClick={() => {
                  this.start();
                }}
              >
                {this.translate("Next")}
              </button>
            )}

            <ScrollView
              style={{
                alignSelf: "stretch",
                width: CommonUtil.clientWidth() - 20,
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                {trkHtmls}
                <div style={{ height: 20 }} />
              </div>
            </ScrollView>
            {
              // this.props.featuredArticles && this.props.featuredArticles.length > 0 &&
              // !(lastTrk && lastTrk.isToday) &&
              this.props.showSummary && (
                <div
                  className="conatiner"
                  style={{
                    flex: 4,
                    justifyContent: "flex-start",
                    backgroundColor: "#eeeeee",
                  }}
                >
                  <div className="carousel-title-container">
                    <img
                      src={star_logo}
                      resizemode={"cover"}
                      style={{ width: 20, height: 20, marginRight: 3 }}
                      alt=""
                    />
                    {this.translate("w_IMPORTANT_INFORMATION")}
                  </div>
                  <div className="carousel-container">
                    {items.length > 0 && (
                      <div>
                        <ReactSwipe
                          key={items.length}
                          className="carousel"
                          swipeOptions={{
                            startSlide: swipe_index,
                            speed: 400,
                            auto: 3000,
                            continuous: false,
                            //noSwiping: true,
                            disableScroll: false,
                            stopPropagation: false,
                            callback: this.swipeCallback,
                            transitionEnd: function (index, elem) {},
                          }}
                          ref={(el) => (reactSwipeEl = el)}
                        >
                          {items.map((item, idx) => {
                            return <div className="swipe">{item}</div>;
                          })}
                        </ReactSwipe>
                        {/*<button onClick={() => reactSwipeEl.prev()}>Previous</button>     
                                        <button onClick={() => reactSwipeEl.next()}>Next</button> */}
                      </div>
                    )}
                  </div>
                  <div className="carousel-circle-container">
                    {items.map((item, idx) => {
                      if (idx == swipe_index) {
                        return (
                          <div
                            className="circle-item"
                            style={{ backgroundColor: "#999999" }}
                          ></div>
                        );
                      } else {
                        return <div className="circle-item"></div>;
                      }
                    })}
                  </div>
                </div>
              )
            }
            <div style={{ flex: 1 }} />
          </div>
          {this.state.loading && <Loading />}
        </ScrollView>
      </div>
    );
  }
}

const styles = {
  trkItemContainer: {
    alignSelf: "stretch",
    padding: 5,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: "#e0e0e0",
    marginTop: 5,
    backgroundColor: "#fafafa",
  },
  trkItemTitleContainer: {
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  trkItemLogo: {
    height: 30,
    width: 30,
  },
  trkItemArrow: {
    height: 12,
    width: 12,
    transform: { rotateY: "180deg" },
  },
  unSelectContainer: {
    width: 24,
    height: 24,
    backgroundColor: "#fcfcfc",
    borderRadius: 12,
    borderColor: "#e0e0e0",
    borderWidth: 1,
  },
  selectedContainer: {
    width: 24,
    height: 24,
    //backgroundColor: GlobalColor.greenColor,
    borderRadius: 12,

    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  selectedSymptom: {
    flexDirection: "row",
    justifyContent: "center",
    top: 2,
    left: 70,
    position: "absolute",
    width: 30,
    height: 30,
    borderRadius: 8,
    borderWidth: 0,
    borderColor: "transparent",
    //borderColor: '#ffffff',
  },
  input2: {
    alignSelf: "flex-start",
    textAlign: "center",
    height: 30,
    width: 100,
    borderRadius: 4,
    fontSize: 13,
    padding: 0,
    backgroundColor: "rgb(238,238,238)",
  },
  button: {
    //backgroundColor: '#123b6d',
    backgroundColor: "#f2f2f2",
    alignSelf: "center",
    height: width > 400 ? 52 : 46,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#eeeeee",
    borderWidth: 3,
    borderRadius: 6,
    marginTop: 10,
    marginBottom: 20,
    display: "flex",
  },
  noSymptomsButton: {
    //backgroundColor: '#123b6d',
    backgroundColor: "#f2f2f2",
    alignSelf: "center",
    height: width > 400 ? 52 : 46,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#eeeeee",
    borderWidth: 3,
    borderRadius: 6,
    marginTop: 10,
    marginBottom: 20,
    borderWidth: 3,
    borderColor: "#35B502",
    display: "flex",
  },
  buttonText: {
    fontFamily: "AtlasGrotesk-Regular",
    color: "#000",
    alignSelf: "center",
    justifyContent: "center",
    // ...Platform.OS == 'ios' ? {
    //     marginTop: 6,
    //     lineHeight: 26,
    // } : {
    //         marginTop: 6,
    //         lineHeight: 26,
    //     },
    //height: 30,
  },
  trackContainer: {
    marginTop: 1,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: width === 320 ? 12 : 14,
    color: "#717171",
  },
  imageContent: {
    width: 100,
    height: 100,
    padding: 0,
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    textAlign: "center",
    position: "relative",
  },
  trackContainer1: {
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "space-around",
    marginLeft: 25,
    marginRight: 25,
    alignItems: "center",
    display: "flex",
  },
};
