import React from 'react';
import BaseComponent from '../../../components/BaseComponent';
import TabBarContainer from '../../../components/TabBarContainer';
import httpUtil from '../../../utils/HttpUtil';
import ReactDOM from 'react-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';
import journay_logo from '../../../content/images/track-journay.png';
import star_logo from '../../../content/images/star.png';

import Loading from '../../../components/Loading';
import CacheUtil from '../../../utils/CacheUtil';
import ModalContainer from '../../../components/ModalContainer';
import ScrollView from '../../../components/ScrollView';
import CommonUtil from './../../../utils/CommonUtil';
import Common from '../../../Common';
let width = CommonUtil.clientWidth();
let height = CommonUtil.clientHeight();
export default class TrkCovidExposure extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      internationalTravelinPast14D: null,
      contactwithCOVID: null,
      WhereTravelled: '',
      need_alert_survey: '',
    };
  }

  itemClick() {
    this.props.onSelectHowToday(1, false);
  }
  _AlertUnable_Self_certificationMessage() {
    let {
      internationalTravelinPast14D,
      contactwithCOVID,
      WhereTravelled,
    } = this.state;
    this.setState({ need_alert_survey: 1 });
    // Alert.alert(Translation.lang().w_Unable_self_certification_title, Translation.lang().w_Unable_to_checkin_content, [
    //     {
    //         text: Translation.lang().w_ok, onPress: () => {
    //             this.props.onSetNoSymptomsQuestion && this.props.onSetNoSymptomsQuestion(internationalTravelinPast14D, contactwithCOVID, WhereTravelled);
    //             this.props.onSelectHowToday && this.props.onSelectHowToday(1, false);
    //             this.props.onShowSummary && this.props.onShowSummary(true, false, false);
    //         }
    //     }
    // ])
  }

  _callBackOnSelectHowToday() {
    this.props.onSelectHowToday(1, false);
  }

  render() {
    let profile = JSON.parse(localStorage.getItem('profile'));
    let name = profile.name;
    let {
      internationalTravelinPast14D,
      contactwithCOVID,
      WhereTravelled,
    } = this.state;
    return (
      <div className="conatiner" style={{justifyContent:'flex-start'}}>
        <div
          style={{
            alignSelf: 'stretch',
            alignItems: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              alignSelf: 'stretch',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={styles.titleFont}>
              {this.translate('w_No_symptoms_question1')}
            </div>
          </div>
          <div
            style={
              styles.button
              // contactwithCOVID && { borderWidth: 3, borderColor: '#0070c0' }
            }
            onClick={() => {
              this.setState({ contactwithCOVID: true });
            }}
          >
            <div style={styles.buttonText}>
              <div
                style={{
                  alignSelf: 'flex-start',
                  alignItems: 'stretch',
                  justifyContent: 'center',
                }}
              >
                {this.translate('w_yes')}
              </div>
              <div
                style={{
                  borderRadius: 9,
                  borderWidth: 9,
                  alignSelf: 'flex-end',
                  alignItems: 'stretch',
                  justifyContent: 'center',
                  marginRight: 20,
                  borderColor: contactwithCOVID ? '#0070c0' : '#fff',
                  borderStyle: 'solid',
                }}
              ></div>
            </div>
          </div>

          <div
            style={
              styles.button
              // contactwithCOVID != null && contactwithCOVID == false && { borderWidth: 3, borderColor: '#0070c0' }
            }
            onClick={() => {
              //this.itemClick(1);
              this.setState({ contactwithCOVID: false });
            }}
          >
            <div style={styles.buttonText}>
              <div
                style={{
                  alignSelf: 'flex-start',
                  alignItems: 'stretch',
                  justifyContent: 'center',
                }}
              >
                {this.translate('w_no')}
              </div>
              <div
                style={{
                  borderRadius: 9,
                  borderWidth: 9,
                  alignSelf: 'flex-end',
                  alignItems: 'stretch',
                  justifyContent: 'center',
                  marginRight: 20,
                  borderStyle: 'solid',
                  borderColor:
                    contactwithCOVID != null && contactwithCOVID == false
                      ? '#0070c0'
                      : '#fff',
                }}
              ></div>
            </div>
          </div>

          <button
            className="button"
            style={{
              width: width - 60,
              marginBottom: 20,
              marginTop: 40,
              alignSelf: 'center',
            }}
            onClick={() => {
              let profile = JSON.parse(localStorage.getItem('profile'));
              if (contactwithCOVID == false) {
                this.props.onshowSelfCertification && this.props.onshowSelfCertification(true, false, true);
                this.props.onSetNoSymptomsQuestion && this.props.onSetNoSymptomsQuestion(internationalTravelinPast14D, contactwithCOVID);
                this.props.onSelectHowToday && this.props.onSelectHowToday(1, false);
            } else if ((contactwithCOVID != null) && (contactwithCOVID == true)) {
                this._AlertUnable_Self_certificationMessage();

            }
              
              /*if (contactwithCOVID != null && contactwithCOVID == false) {
                httpUtil.get(
                  '/api/Profile/CanGenereateSelfCertificate?profileId=' +
                    profile.id,
                  (responseData) => {
                    if (responseData && responseData.data == '1') {
                      this.props.onshowSelfCertification &&
                        this.props.onshowSelfCertification(true, false, true);
                      this.props.onSetNoSymptomsQuestion &&
                        this.props.onSetNoSymptomsQuestion(
                          internationalTravelinPast14D,
                          contactwithCOVID,
                          WhereTravelled
                        );
                      this.props.onSelectHowToday &&
                        this.props.onSelectHowToday(1, false);
                    } else {
                      this._AlertUnable_Self_certificationMessage();
                    }
                  }
                );
              } else if (contactwithCOVID != null && contactwithCOVID == true) {
                this._AlertUnable_Self_certificationMessage();
              }*/
            }}
          >
            {this.translate('w_next')}
          </button>
        </div>
        {this.state.need_alert_survey == '1' && (
          <ModalContainer>
            <div className="alert_title">
              {this.translate('w_Unable_self_certification_title')}
            </div>
            <div className="alert_content">
              {this.translate('w_Unable_to_checkin_content')}
            </div>
            <div
              className="alert_button"
              onClick={() => {
                this.setState({ need_alert_survey: 0 });
                this.props.onSetNoSymptomsQuestion &&
                  !this.props.onSelectHowToday &&
                  this.props.onSetNoSymptomsQuestion(
                    internationalTravelinPast14D,
                    contactwithCOVID,
                    WhereTravelled
                  );

                !this.props.onSetNoSymptomsQuestion &&
                  this.props.onSelectHowToday &&
                  this.props.onSelectHowToday(1, false);

                this.props.onSetNoSymptomsQuestion &&
                  this.props.onSelectHowToday &&
                  this.props.onSetNoSymptomsQuestion(
                    internationalTravelinPast14D,
                    contactwithCOVID,
                    WhereTravelled,
                    this._callBackOnSelectHowToday.bind(this)
                  );

                this.props.onShowSummary &&
                  this.props.onShowSummary(true, false, false);
              }}
            >
              {this.translate('w_ok')}
            </div>
          </ModalContainer>
        )}
      </div>
    );
  }
}
const styles = {
  titleFont: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 30,
    fontWeight: 600,
    fontSize: 22,
  },
  trkItemContainer: {
    alignSelf: 'stretch',
    padding: 5,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: '#e0e0e0',
    marginTop: 5,
    backgroundColor: '#fafafa',
  },
  trkItemTitleContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  trkItemLogo: {
    height: 30,
    width: 30,
  },
  trkItemArrow: {
    height: 12,
    width: 12,
    transform: [{ rotateY: '180deg' }],
  },
  unSelectContainer: {
    width: 24,
    height: 24,
    backgroundColor: '#fcfcfc',
    borderRadius: 12,
    borderColor: '#e0e0e0',
    borderWidth: 1,
  },
  selectedContainer: {
    width: 24,
    height: 24,
    backgroundColor: '#00b359',
    borderRadius: 12,
    borderColor: '#00b359',
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedSymptom: {
    flexDirection: 'row',
    justifyContent: 'center',
    top: 2,
    left: width > 320 ? 80 : 56,
    position: 'absolute',
    width: 30,
    height: 30,
    borderRadius: 8,
    borderWidth: 0,
    borderColor: 'transparent',
    //borderColor: '#ffffff',
  },
  input2: {
    alignSelf: 'flex-start',
    textAlign: 'center',
    height: 30,
    width: 100,
    borderRadius: 4,
    fontSize: 13,
    padding: 0,
    backgroundColor: 'rgb(238,238,238)',
  },
  button: {
    //backgroundColor: '#123b6d',
    backgroundColor: '#f2f2f2',
    // width: width - 60,
    height: width > 400 ? 52 : 46,
    alignSelf: 'stretch',
    alignItems: 'stretch',
    justifyContent: 'center',
    borderColor: '#eeeeee',
    borderWidth: 3,
    borderRadius: 6,
    marginTop: 5,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  buttonText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
  },
};
