import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import common from '../../Common';
import NavBar from '../../components/NavBar';
import i18n from 'i18next';
export default class HelpDetail extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let uri = '';
        let title = '';
        let language = i18n.language  == 'es' ? 'es' : 'en';
        let url_lan = language == 'es' ? "faq/nl":"faq/en";
        if (this.props.match.params.type == 1) {
            uri = common.BASE_URL + url_lan +"/FAQLogin.html";
            title = this.translate('w_Account_Access');
        }
        else if (this.props.match.params.type == 2) {
            uri = common.BASE_URL + url_lan + "/FAQTracking.html";
            title = this.translate('Resources');
        }
        else if (this.props.match.params.type == 3) {
            uri = common.BASE_URL + url_lan + "/FAQPrivacy.html";
            title = this.translate('Privacy');
        }
        return (
            <div className="fill">
                <NavBar title={title} history={this.props.history} />
                <iframe
                    src={uri}
                    className='fill'
                />
            </div>
        );
    }
}
