import React from 'react';

import moment from 'moment';
import NavBar from './../../components/NavBar';
import HttpUtil from '../../utils/HttpUtil';
import CommonUtil from '../../utils/CommonUtil';
import BaseComponent from '../../components/BaseComponent';
import Loading from '../../components/Loading';

const globalStyles = require('../../utils/GlobalStyles');
var globalColor = require('../../utils/Color');

const width = CommonUtil.clientWidth();
const height = CommonUtil.clientHeight();
const weeks = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export default class ScheduleAppointment extends BaseComponent {
  constructor(props) {
    super(props);

    let day = moment(props.location.state.selectedDate).format('D');
    let days = [];

    for (var i = 1; i <= 31; i++) {
      if (day > i) {
        days.push(
          moment(props.location.state.selectedDate).subtract(day - i, 'days')
        );
      } else if (day < i) {
        days.push(
          moment(props.location.state.selectedDate).add(i - day, 'days')
        );
      } else {
        days.push(props.location.state.selectedDate);
      }
    }

    this.state = {
      timeSlots: [],
      existingAppointments: [],
      //searchSchedules:[],
      //FilterText:[],
      selectedDate: props.location.state.selectedDate,
      date: props.location.state.selectedDate,
      days: days,
      showSearchSchedule: false,
      search: '',
      xScorll: (day - 1) * (width / 7),
      loading: false,
    };
    this.scrollToTop = this.scrollToTop.bind(this);
    //this._showSearchSchedule = this._showSearchSchedule.bind(this);
  }

  componentDidMount() {
    this.loadTimeSolts(this.state.selectedDate);
    this.scrollToTop();
  }

  goBack() {
    this.props.history.goBack();
  }

  scrollToTop() {
    this._scroll && this._scroll.scrollIntoView({ behavior: 'smooth' });
  }

  loadTimeSolts(date) {
    this.setState({ loading: true });
    var search = this.state.search;
    //moment(date).unix()
    HttpUtil.post(
      '/api/AppointmentSlots/RetrieveAvailable?ticksUTC=' +
        moment(date).unix() +
        '&search=' +
        search,
      null,
      (responseData) => {
        //console.warn(responseData);

        if (responseData) {
          if (responseData.code) {
            if (responseData.code == 1) {
              alert(
                'Please select a date greater than or equal to current day !'
              );
              this.setState({
                timeSlots: [],
                loading: false,
                existingAppointments: [],
              });
            } else if (responseData.code == 2) {
              this.setState(
                {
                  timeSlots: [],
                  loading: false,
                  existingAppointments: responseData.existingAppointments || [],
                },
                () => {
                  this.scrollToTop();
                }
              );
            }
          } else {
            this.setState(
              {
                timeSlots: responseData || [],
                loading: false,
                existingAppointments: [],
              },
              () => {
                this.scrollToTop();
              }
            );
          }
        } else {
          this.setState(
            {
              timeSlots: responseData || [],
              loading: false,
              existingAppointments: [],
            },
            () => {
              this.scrollToTop();
            }
          );
        }
      }
    );
  }

  _confirmCancel(id) {
    this.setState({ loading: true });
    HttpUtil.get(
      '/api/Appointment/cancel',
      {
        id: id,
        reasons: ' ',
      },
      () => {
        this.setState({ loading: false });
        this.props.history.goBack();
      }
    );
  }

  saveAppointment(id) {
    this.setState({ loading: true });

    HttpUtil.post(
      '/api/Appointment/SaveEntry',
      {
        appointmentSlotId: id,
        quizParticipantID: this.props.location.state.quizParticipantID,
        scheduledOnWeb: true,
      },
      (responseData) => {
        this.setState({ loading: false });
        if (responseData) {
          if (responseData.code && responseData.code == 1) {
            alert('Failed! This appointment slot is up to capacity!');
          }
        }

        this.props.history.goBack();
      }
    );
  }

  render() {
    let {
      date,
      selectedDate,
      days,
      timeSlots,
      existingAppointments,
      showSearchSchedule,
    } = this.state;
    //alert(JSON.stringify(timeSlots));
    let dayWidth = width / 7;
    return (
      <div
        className="conatiner"
        style={{ justifyContent: 'flex-start', width: '100%' }}
      >
        <NavBar
          title={this.translate('w_schedule_appointment')}
          history={this.props.history}
        />

        <div
          className="conatiner"
          style={{ justifyContent: 'flex-start', flex: 2, overflow: 'hidden' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 15,
              paddingBottom: 15,
              borderBottomWidth: 1,
              borderColor: globalColor.splitlineColor,
            }}
          >
            <span style={globalStyles.normalFont}>
              {moment(date).format('MMMM YYYY')}
            </span>
            <div
              // ref={(scroll) => (this._scroll = scroll)}
              style={{
                flex: 1,
                display: 'flex',
                alignSelf: 'stretch',
                overflowX: 'auto',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {days.map((day, idx) => {
                  let _isSelectDate =
                    moment(day).format('MMDD') ==
                    moment(selectedDate).format('MMDD');
                  return (
                    <div
                      ref={(scroll) => {
                        if (_isSelectDate) this._scroll = scroll;
                      }}
                      key={`d_${idx}`}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: dayWidth,
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: 18,
                        paddingBottom: 5,
                      }}
                    >
                      <span>{moment(day).format('DD')}</span>
                      <div
                        onClick={() => {
                          this.setState({ selectedDate: day });
                          this.loadTimeSolts(day);
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 8,
                            height: 36,
                            width: 36,
                            borderRadius: 18,
                            alignItems: 'center',
                            justifyContent: 'center',
                            ...(_isSelectDate
                              ? { backgroundColor: '#4472C4' }
                              : {}),
                          }}
                        >
                          <span style={_isSelectDate ? { color: '#fff' } : {}}>
                            {weeks[moment(day).format('d')]}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div
            style={{
              flex: 2,
              display: 'flex',
              overflow: 'hidden',
              flexDirection: 'column',
            }}
          >
            <div>
              {
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'stretch',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderBottomWidth: 1,
                    borderColor: globalColor.splitlineColor,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderWidth: 2,
                      borderColor: globalColor.splitlineColor,
                      borderRadius: 15,
                      paddingLeft: 10,
                      width: width - 115,
                      height: 38,
                    }}
                  >
                    <input
                      style={styles.searchInput}
                      // autoCapitalize="none"
                      placeholder={this.translate('w_typeInYourFilterValue')}
                      // placeholderTextColor="rgb(158,158,158)"
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ search: e.target.value });
                      }}
                    ></input>
                    <button
                      style={{
                        width: 20,
                        backgroundColor: 'transparent',
                        border: 'none',
                        marginLeft: '-30px',
                      }}
                      onClick={() => {
                        this.setState({ search: '' }, () => {
                          this.loadTimeSolts(this.state.selectedDate);
                        });
                      }}
                    >
                      {this.translate('w_X')}
                    </button>
                  </div>

                  <button
                    style={{
                      flexDirection: 'row',
                      alignSelf: 'stretch',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 15,
                      width: 75,
                      height: 40,
                      //padding: 3,
                      paddingLeft: 10,
                      paddingRight: 10,
                      backgroundColor: globalColor.mainColor,
                      color: 'white',
                    }}
                    onClick={() => {
                      this.loadTimeSolts(this.state.selectedDate);
                      //this._showSearchSchedule();
                    }}
                  >
                    {this.translate('w_addFilter')}
                  </button>
                </div>
              }
            </div>
            {
              <div
                //ref={(scroll) => this._scroll = scroll}
                style={{
                  overflowY: 'auto',
                }}
              >
                {timeSlots &&
                  timeSlots.map((item, idx) => {
                    return (
                      <div
                        key={`ts_${idx}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: 15,
                          paddingBottom: 15,
                          paddingLeft: 5,
                          paddingRight: 5,
                          marginLeft: 10,
                          marginRight: 10,
                          borderBottomWidth: 1,
                          borderColor: globalColor.splitlineColor,
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span style={globalStyles.normalFont}>
                            {item.name || ''}
                          </span>
                          <span>{item.location || ''}</span>
                          <span style={globalStyles.normalFont}>
                            {item.startTime || ''}
                          </span>
                        </div>

                        <button
                          style={{
                            padding: '5px 3px',
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5,
                            backgroundColor: globalColor.mainColor,
                            color: '#fff',
                          }}
                          onClick={() => {
                            if (
                              window.confirm(
                                this.translate(
                                  'Please_Confirm_You_Would_Like_To'
                                )
                              )
                            ) {
                              this.saveAppointment(item.id);
                            }
                          }}
                        >
                          {this.translate('w_schedule')}
                        </button>
                      </div>
                    );
                  })}
                {existingAppointments &&
                  existingAppointments.length > 0 &&
                  existingAppointments.map((item, idx) => {
                    return (
                      <div
                        key={`ts_${idx}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flex: 1,
                          alignSelf: 'stretch',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          margin: 20,
                          marginBottom: 0,
                          paddingBottom: 10,
                          borderBottomWidth: 1,
                          borderColor: globalColor.splitlineColor,
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span style={globalStyles.normalFont}>
                            {item.appointmentTypeName || ''}
                          </span>
                          <span>{item.location || ''}</span>
                          <span style={globalStyles.normalFont}>
                            {item.startTime || ''}
                          </span>
                        </div>
                        <div
                          onClick={() => {
                            if (
                              window.confirm(
                                this.translate('w_cancelApt_content')
                              )
                            ) {
                              this._confirmCancel(item.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              padding: 3,
                              paddingLeft: 10,
                              paddingRight: 10,
                              borderRadius: 5,
                              backgroundColor: globalColor.greenColor,
                            }}
                          >
                            <span style={{ color: '#fff' }}>
                              {this.translate('w_appointStatus10')}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {(!timeSlots || timeSlots.length == 0) &&
                  (!existingAppointments ||
                    existingAppointments.length == 0) && (
                    <div
                      style={{
                        alignSelf: 'stretch',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 20,
                      }}
                    >
                      <span style={globalStyles.normalFont}>
                        {this.translate('w_NoAptAvailableonthisday')}
                      </span>
                    </div>
                  )}
              </div>
            }
          </div>
        </div>
        {this.state.loading && <Loading />}
      </div>
    );
  }
}
var styles = {
  searchInput: {
    width: width - 155,
    borderRadius: 15,
    fontSize: 12,
    height: 35,
  },
  closeOpacityStyle: {
    color: globalColor.textColor,
    alignSelf: 'stretch',
    textAlign: 'center',
  },
};
