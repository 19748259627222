import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import httpUtil from '../../utils/HttpUtil';
import logo_myownmed from '../../content/images/logo_myownmed.png';
import arrow_left from '../../content/images/arrow_left.png';
import arrow_right from '../../content/images/arrow_right.png';
import moment from 'moment';
import NavBar from '../../components/NavBar';
import Loading from '../../components/Loading';
import common from '../../Common';
import i18n from 'i18next';
export default class Survey extends BaseComponent {
    constructor(props) {
        let profile = JSON.parse(localStorage.getItem('profile'));
        var age = profile.age;
        var gender = profile.gender;
        super(props);
        this.state = {
            loading: false,
            surveys: [],
            age: age,
            gender: gender,
            assessmentOID: '',
            surveyName: '',
            type: ''
        };
    }
    componentDidMount() {
        this._reload();
    }

    _reload() {
        this.setState({ loading: true, surveys: [] });
        httpUtil.get('/api/Quiz/GetQuizs',
            (responseData) => {
                if (responseData) {
                    console.log(responseData);
                    this.setState({ loading: false, surveys: responseData });
                    let surveyCount = 0;
                    responseData.map((item) => {
                        if (item.lastAnswerDate == null) {
                            surveyCount = surveyCount + 1;
                        }
                    });
                    //MomEnv.setNotification(200, surveyCount);
                }
            }
        );
    }

    render() {
        let { loading, surveys, age, gender } = this.state;
        let penddingHtmls = [];
        let resultHtmls = [];
        let isENLocal = i18n.language  == 'en';
        surveys.map((survey, index) => {
            if (survey.lastAnswerDate == null) {
                penddingHtmls.push(
                    <div style={{ alignSelf: 'stretch', borderWidth: 1, borderColor: '#ddd' }} key={index}>
                        <div style={{ alignSelf: 'stretch', backgroundColor: '#123b6d', alignItems: 'flex-end', paddingRight: 10, marginTop: 10 }}>
                            <p style={styles.ptext}>{moment(survey.dateTimeCreated).format('MM-DD-YYYY')}</p>
                        </div>
                        <div style={styles.RowNavi} onClick={() => this.props.history.push('/surveyDetail', { readonly: false, survey: survey, onSaveAnswer: null })}>
                            {isENLocal ? survey.title : survey.titleES}
                            <p style={{ marginLeft: 200 }}>{survey.progress !== "0%" ? survey.progress : null} </p>
                            <img src={arrow_right}
                                style={styles.right_img}
                                alt="" />
                        </div>
                    </div>);
            } else {
                resultHtmls.push(
                    <div style={{ alignSelf: 'stretch', borderWidth: 1, borderColor: '#ddd' }} key={index}>
                        <div style={{ alignSelf: 'stretch', backgroundColor: '#123b6d', alignItems: 'flex-end', paddingRight: 10, marginTop: 10 }}>
                            <p style={styles.ptext}>{moment(survey.lastAnswerDate).format('MM-DD-YYYY')}</p>
                        </div>
                        <div style={styles.RowNavi}>{isENLocal ? survey.title : survey.titleES}
                            <p style={{ marginLeft: 200 }}>{survey.progress !== "0%" ? survey.progress : null} </p>
                            <img src={arrow_right}
                                style={styles.right_img}
                                alt="" />
                        </div>
                    </div>);
            }
        });

        if (penddingHtmls.length === 0 && !loading) {
            penddingHtmls.push(
                <div key='P1' style={{
                    display: 'flex',
                    alignSelf: 'stretch',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 80,
                    padding: 20
                }}>
                    <p style={{ color: '#333' }}>{this.translate("w_No_pending_surveys")}</p>
                </div>
            );
        }
        return (
            <div className="conatiner" style={{ justifyContent: 'flex-start' }}>
                <NavBar title={this.translate("w_Surveys")} backurl='/home/more' history={this.props.history} />
                {
                        <div className="conatiner" style={{ justifyContent: 'flex-start' }}>
                            {penddingHtmls}
                        </div>
                }
                {
                    loading && <Loading />
                }
            </div>

        );
    }

}
const styles = {
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',

    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        marginLeft: 5,
        marginRight: 5,
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    txtErr: {
        color: '#f00',
        marginTop: 3,
        fontSize: 14
    },
    txtOK: {
        color: '#00b359',
        marginTop: 3,
        fontSize: 14
    },
    ptext: {
        color: '#fff',
        textAlign: 'end'
    },
    RowNavi: {
        marginTop: 10,
        paddingLeft: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    right_img: {
        //position: 'absolute',
        //right: 10
        marginRight: 10
    },
    button_ok: {
        width: 18,
        height: 18,
        borderWidth: 6,
        borderColor: '#123B6D',
        borderRadius: 9,
        borderStyle: 'solid'
    },
    button_no: {
        width: 18,
        height: 18,
        borderWidth: 1,
        borderColor: '#aaa',
        borderRadius: 9,
        borderStyle: 'solid'
    }
};