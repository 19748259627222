import React, { Component } from 'react';

export default class ScrollView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {     
  }

  render() {
    return (
       <div className='fill overflow-hidden' style={this.props.style}>
         <div className='fill auto-hide-scrollbar'>
           {this.props.children}
         </div>  
       </div>
    );
  }
}