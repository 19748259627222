import React from 'react';
import BaseComponent from '../../../components/BaseComponent';
import NavBar from '../../../components/NavBar';
import 'react-calendar/dist/Calendar.css';
import httpUtil from '../../../utils/HttpUtil';
import Loading from '../../../components/Loading';
import moment from 'moment';

import ReactEcharts from 'echarts-for-react';
//下面是按需加载
import echarts from 'echarts/lib/echarts'
//导入折线图
import 'echarts/lib/chart/line';  //折线图是line,饼图改为pie,柱形图改为bar
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markPoint';

const default_option = {
    title: {
        text: '',
        x: 'center'
    },
    tooltip: {
        trigger: 'axis',
    },
    xAxis: {
        data: [],
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisPointer: {
            show: false
        },
        axisLabel: {
            show: true,
            fontSize: 11,
            margin: 10,
            padding: 3,
            color: '#666'
        }
    },
    yAxis: {
        type: 'value',
        min: 95,
        max: 105,
        splitNumber: 5,
        minInterval: 2.5,
        nameGap: 1,
        offset: 3,
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisPointer: {
            show: false
        },
        axisLabel: {
            show: true,
            fontSize: 10,
            margin: 3,
            padding: 3,
            color: '#666'
        }
    },
    series: [
        {
            name: '',
            type: 'line',
            symbolSize: 8,
            data: [],
            lineStyle: {
                color: '#123b6d'
            },
            itemStyle: {
                color: '#123b6d',
                borderWidth: 2
            },
        }
    ]
}

export default class FerveHistory extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            historys: [],
            option: default_option
        };
    }

    componentDidMount() {
        this._refreshData();
    }

    componentWillUnmount() {
    }

    _refreshData() {
        let profile = JSON.parse(localStorage.getItem('profile'));
        this.setState({ loading: true });
        httpUtil.post('api/TrkRSV/FerveHistory', { profileId: profile.id },
            (responseData) => {
                console.log(responseData);
                let xDatas = [];
                let sDatas = [];
                if (responseData && responseData.length > 0) {
                    responseData.map((item, idx) => {
                        xDatas.push(moment(item.day).format('DD MMM'));
                        if (item.ferve < 95) {
                            item.ferve = 95;
                        }
                        sDatas.push(item.ferve);
                    });
                }
                let option = {
                    title: {
                        text: '',
                        x: 'center'
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    xAxis: {
                        data: xDatas,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisPointer: {
                            show: false
                        },
                        axisLabel: {
                            show: true,
                            fontSize: 11,
                            margin: 10,
                            padding: 3,
                            color: '#666'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: 95,
                        max: 105,
                        splitNumber: 5,
                        minInterval: 2.5,
                        nameGap: 1,
                        offset: 3,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisPointer: {
                            show: false
                        },
                        axisLabel: {
                            show: true,
                            fontSize: 10,
                            margin: 3,
                            padding: 3,
                            color: '#666'
                        }
                    },
                    series: [
                        {
                            name: '',
                            type: 'line',
                            symbolSize: 8,
                            data: sDatas,
                            lineStyle: {
                                color: '#123b6d'
                            },
                            itemStyle: {
                                color: '#123b6d',
                                borderWidth: 2
                            },
                        }
                    ]
                };

                this.setState({ historys: responseData, loading: false, option: option });
            }
        );
    }

    render() {
        let { historys, option } = this.state;
        let details = [];
        historys.map((item, idx) => {
            details.splice(0, 0,
                <div style={{
                    alignSelf: 'stretch',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    margin: 10,
                    paddingBottom: 10,
                    borderBottomColor: '#eee',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    color: '#666'
                }}>
                    <div>{moment(item.day).format('DD MMMM YYYY hh:mm A')}</div>
                    <div>{item.ferve}</div>
                </div>
            );
        });
        return (
            <div className="conatiner">
                <NavBar title={this.translate("Fever")} backurl='/trackhistory' history={this.props.history} />
                <ReactEcharts option={option} style={{ height: '300px', width: '100%', marginTop: '-10px' }} />
                <div style={{ flex: 1, alignSelf: 'stretch' }}>
                    {details}
                </div>
                {this.state.loading && <Loading />}
            </div>
        );
    }
}
