import React, { Component } from 'react';

export default class Radio extends Component {
  constructor(props) {
    super(props);
    this.state = {token: null};
  }

  componentDidMount() {     
  }

  render() {
    var styleType = 1;
    var checked = styles.checked;
    var unecked = styles.uncheck;
    if(this.props.styleType == 2){
      checked = styles.checked2;
      unecked = styles.uncheck2;
    }
    return (
       <div className='row' style={{color:'#444',cursor:'pointer'}} onClick={this.props.onClick}>
          <div style={this.props.checked?checked:unecked}>
            {
              this.props.checked
              ?
              '√'
              :
              ''
            }
          </div>
          {this.props.title}
       </div>
    );
  }
}

const styles = {
  checked:{    
      width:22,
      height:22,
      borderStyle: 'none',
      borderRadius: 11,
      borderWidth: 0,
      borderColor:'#ff6319',
      backgroundColor:'#ff6319',
      color: '#fff',
      fontSize:13,
      marginRight:10,
      alignItems:'center',
      justifyContent:'center',
  },
  uncheck:{    
      width:20,
      height:20,
      borderStyle: 'solid',
      borderRadius: 10,
      borderWidth: 1,
      borderColor:'#bbb',
      color: 'transparent',
      marginRight:10,
  },
  checked2:{
    width:22,
    height:22,
    textAlign:'center',
    borderStyle: 'none',
    borderWidth: 0,
    borderColor:'#123B6D',
    backgroundColor:'#123B6D',
    color: '#fff',
    fontSize:13,
    marginRight:10,
    alignItems:'center',
    justifyContent:'center',
  },
  uncheck2:{    
    width:20,
    height:20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor:'#bbb',
    color: 'transparent',
    marginRight:10,
},
};