//'use strict';
import React, { Component } from "react";
import moment from "moment";
import common from "../../Common";
import httpUtil from "../../utils/HttpUtil";
import Consent from "./Consent";
import i18n from "i18next";
export default class SSOWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //language: 'Dutch',
    };
    this._saveAndContinue = this._saveAndContinue.bind(this);
    this._validateValues = this._validateValues.bind(this);
    this._goBack = this._goBack.bind(this);
    this._handleMessage = this._handleMessage.bind(this);
    this._handleWhenAccountExists = this._handleWhenAccountExists.bind(this);
    this._handleWhenAccountNotExist =
      this._handleWhenAccountNotExist.bind(this);
  }

  translate(key) {
    return i18n.t(key);
  }

  _saveAndContinue() {
    if (this._validateValues()) {
      this._goBack();
    } else {
      this._showError();
    }
  }
  _showError() {}

  _goBack() {
    this.props.navigator.pop();
  }

  _validateValues() {
    return true;
  }

  componentDidMount() {
    //let token = localStorage.getItem('token');
    //console.log('get token from localStorage');
    //console.log(token);
    //TODO
    // if(token){
    // console.log('has token from localStorage');
    // this.getAppStatus(() => {
    // this.getProfile();
    // });
    // }
    // else{
    // console.log('has not token from localStorage');
    // this._checkQueryString(new URLSearchParams(this.props.location.search));
    // }
    this._checkQueryString(new URLSearchParams(this.props.location.search));
  }

  _checkQueryString(query) {
    //console.log("query");
    //console.log(query);
    const data2Pass = {
      email: query.get("email"),
      firstName: query.get("firstname") || "",
      lastName: query.get("lastname") || "",
      pin: query.get("pin") || "",
      phoneNumber: query.get("phonenumber") || "",
      employeeID: query.get("employeeId") || "",
      token: query.get("token") || "",
      hasRegistered: query.get("hasRegistered") || 0,
    };
    if (!data2Pass.email) {
      window.location.href = common.getSSOUrl();
    } else {
      if (data2Pass.hasRegistered == "1" || data2Pass.hasRegistered == 1) {
        console.log("hasRegistered");
        //console.log(data2Pass);
        this._handleWhenAccountExists(data2Pass);
      } else {
        console.log("has not Registered");
        //console.log(data2Pass);
        this._handleWhenAccountNotExist(data2Pass);
      }
    }
  }

  _handleMessage(e) {
    let data = JSON.parse(e.data);
    if (data) {
      if (data.hasRegistered === "1") {
        this._handleWhenAccountExists(data);
      } else {
        this._handleWhenAccountNotExist(data);
      }
    }
  }

  _handleWhenAccountExists(data) {
    var profile = JSON.parse(localStorage.getItem("profile"));
    if (!profile) {
      profile = {};
    }
    var email = data.email;
    profile.email = email;
    //console.log('set token in localStorate')
    //console.log(data.token);
    if (data.token) {
      localStorage.setItem("token", data.token);
      //localStorage.setItem('pin', data.pin);
      localStorage.setItem("profile", JSON.stringify(profile));
      this.getAppStatus(() => {
        this.getProfile();
      });
    }
    // if (email && email.length > 0) {
    // if (pin && pin.length > 0) {
    // httpUtil.post('/Token', `grant_type=password&username=${email}&password=${pin}`,
    // (result) => {
    // if (result && result.access_token) {
    // let token = result.access_token;
    // localStorage.setItem('token', token);
    // this.getAppStatus(() => {
    // this.getProfile();
    // console.log(result);
    //this.props.history.push('/trackmain');
    // });
    // } else {
    // this.setState({ loading: false });
    // this._handleWhenAccountNotExist(data);
    // }
    // });
    // }
    // else {
    // alert(this.translate('Please_enter_your_PIN'));
    // }
    // } else {
    // alert(this.translate('Please enter the email for your account'));
    // }
  }

  getProfile() {
    httpUtil.get("/api/Profile/GetMine", (result) => {
      if (result && result.id) {
        httpUtil.get(
          "/api/TrkRSV/GetLatestTrkRSV3?profileId=" + result.id,
          (responseData) => {
            //result.password =  localStorage.getItem("pin");
            var latestTrkRSV3 = {};
            var viewSummary = false;
            if (responseData) {
              if (responseData.status == 1) {
                latestTrkRSV3.latestTrkRSV3Status = 1;
                latestTrkRSV3.responseData = responseData;
                viewSummary = true;
                localStorage.setItem("viewSummary", true);
              }
            }
            result.latestTrkRSV3 = latestTrkRSV3;
            localStorage.setItem("profile", JSON.stringify(result));
            if (result.cultureName) {
              if (result.cultureName == "en" || result.cultureName == "en-US") {
                i18n.changeLanguage("en");
                localStorage.setItem("cultureName", "en");
              } else if (
                result.cultureName == "nl" ||
                result.cultureName == "nl-NL"
              ) {
                i18n.changeLanguage("es");
                localStorage.setItem("cultureName", "es");
              }
            }
            if (result.isTempPassword == false) {
              localStorage.setItem("need_alert_survey", "1");
              this.props.history.push("/trackmain", {
                viewSummary: viewSummary,
              });
            } else {
              this.props.history.push("/trackmain", {
                viewSummary: viewSummary,
              });
            }
          }
        );
      } else {
        localStorage.removeItem("token");
        this.setState({ loading: false });
      }
    });
  }
  getAppStatus(callback) {
    httpUtil.get(
      "/api/Profile/GetAppStatus",
      (appStatus) => {
        if (appStatus && appStatus.hasOwnProperty("numNewMsg")) {
          localStorage.setItem(
            "msg_count",
            JSON.stringify(appStatus.numNewMsg)
          );
          localStorage.setItem(
            "survey_count",
            JSON.stringify(appStatus.numNewSurvey)
          );
          localStorage.setItem(
            "wiki_count",
            JSON.stringify(appStatus.numNewWiki)
          );
        }
        callback && callback();
      },
      (responseData) => {
        if (responseData && responseData.status == 401) {
          window.location.href = common.getSSOUrl();
        }
      }
    );
  }

  _handleWhenAccountNotExist(data) {
    localStorage.setItem("pageWithoutToken", true);
    if (data.token) {
      localStorage.setItem("token", data.token);
    }
    this.props.history.push("/Consent", { data: data });
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span>loading...</span>
      </div>
    );
  }
}

var styles = {
  termTitle: {
    fontSize: 20,
    textAlign: "center",
    color: "black",
  },
  termContent: {
    color: "black",
    marginLeft: 25,
    marginRight: 25,
  },
  termView: {},
  scrollView: {
    //paddingTop: 15,
    //paddingBottom: 15,
    //paddingVertical: 10,
    backgroundColor: "rgb(238,238,238)",
  },
};
