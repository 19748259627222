import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import TabBarContainer from '../../components/TabBarContainer';
import ScrollView from '../../components/ScrollView';
import NavBar from '../../components/NavBar';
import common from '../../Common';
import CommonUtil from '../../utils/CommonUtil';
import i18n from 'i18next';
export default class SettingTermOfUse extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: 'Dutch',
    };
    this._saveAndContinue = this._saveAndContinue.bind(this);
    this._validateValues = this._validateValues.bind(this);
    this._goBack = this._goBack.bind(this);
  }

  componentDidMount(){
    localStorage.setItem('pageWithoutToken',true);
  }
  
  _saveAndContinue() {
    if (this._validateValues()) {
      this._goBack();
    }
    else {
      this._showError();
    }
  }
  
  _showError() {

  }

  _goBack() {
    this.props.navigator.pop();
    if (this.props.afterChange) {
      this.props.afterChange();
    }
  }

  _validateValues() {
    return true;
  }

  render() {


    let language = i18n.language  == 'es' ? 'es' : 'en';
    let url_lan = language == 'es' ? "Privacy/nl":"Privacy/en";
    let uri = common.BASE_URL + url_lan +"/TermsOfUse.html";
    //let uri = common.BASE_URL+ "Privacy/en/TermsOfUse.html";
    return (
      <div className="conatiner" style={{display:"block"}}>        
        <div style={{width: '100%',overflow:'auto',justifyContent: 'flex-start',border: 0}}>
          <NavBar title={this.translate("w_termOfUse")}  history={this.props.history} />
          <iframe
          title="resg"
          src={uri}    
          style={{height: CommonUtil.clientHeight() - 60,width: '100%'}}   
          //srcDoc={common}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          scrolling="auto"
          />
        </div>
      </div>
    );
  }
}

