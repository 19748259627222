import React from 'react';
import BaseComponent from '../../../components/BaseComponent';
import TabBarContainer from '../../../components/TabBarContainer';
import httpUtil from '../../../utils/HttpUtil';
import ReactDOM from 'react-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';
import journay_logo from '../../../content/images/track-journay.png';
import icon_checkmark256 from '../../../content/images/icon_checkmark256.png';
import star_logo from '../../../content/images/star.png';
import icon_Self_Certification from '../../../content/images/icon_Self_Certification.png';

import Loading from '../../../components/Loading';
import CacheUtil from '../../../utils/CacheUtil';
import ModalContainer from '../../../components/ModalContainer';
import ScrollView from '../../../components/ScrollView';
import CommonUtil from './../../../utils/CommonUtil';
import Common from '../../../Common';
let width = CommonUtil.clientWidth();
let height = CommonUtil.clientHeight();

export default class TrkSelfCertification extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            internationalTravelinPast14D: null,
            contactwithCOVID: null,
            base64img: null,
            showDone: null,
            qrDateTimeStart: null,
            qrDateTimeExpired: null,
            qrDateTimeExpiring: null,
            loaded: false,
        };
    }
    componentDidMount() {
        //this.GetBase64img();       
        let showDone = this.props.showDone;
        if (showDone == false) {
            this.GetBase64img();
        } else {
            this.CreateBase64img();
        }
    }
    itemClick() {
        this.props.onSelectHowToday(1, false)
    }
    GetBase64img() {
        let profile = JSON.parse(localStorage.getItem('profile'));
        httpUtil.post('api/QRCode/GetQRCode?ProfileId=' + profile.id, null,
            (responseData) => {
                if (responseData) {
                    //console.log(responseData);
                    if (responseData.status == "Success") {
                        let QRCode = JSON.parse(responseData.data);
                        this.setState({
                            loaded: true, base64img: QRCode.QRCodeBase64String, qrDateTimeStart: QRCode.DateTimeStart,
                            qrDateTimeExpired: QRCode.DateTimeExpired, qrDateTimeExpiring: QRCode.DateTimeExpiring
                        });
                    }
                    else {
                        this.setState({ loaded: true });
                    }
                }
            }
        );
    }

    CreateBase64img() {
        let profile = JSON.parse(localStorage.getItem('profile'));
        httpUtil.post('api/QRCode/CreateQRCode', {
            profileId: profile.id,
        },
            (responseData) => {
                if (responseData) {
                    if (responseData.status == "Success") {
                        this.setState({ loaded: true, base64img: responseData.data });
                    }
                    else {
                        this.setState({ loaded: true });
                    }
                }
            }
        );
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }
        let profile = JSON.parse(localStorage.getItem('profile'));
        let name = profile.name;
        let { internationalTravelinPast14D, contactwithCOVID, base64img, qrDateTimeExpired, qrDateTimeStart, qrDateTimeExpiring } = this.state;
        let DateNow = new Date();
        let showDone = this.props.showDone;
        let base64imgUri = `data:image/jpg;base64,${base64img}`;
        let ExpiresDate = DateNow.setDate(DateNow.getDate() + 1);
        //let qrDateTimeStartNew = qrDateTimeStart?qrDateTimeStart.setDate(qrDateTimeStart.getDate() + 1):null;
        return (
            <div className="conatiner">
                <div style={{ alignSelf: 'stretch', flex: 1, alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                    <div style={{ alignSelf: 'stretch', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',display: 'flex', }}>
                        <div style={{ alignSelf: 'stretch', alignItems: 'center', justifyContent: 'center', marginTop: height * 0.05, display: 'flex', fontSize: 18 }}>

                            {this.translate("w_Self_Certification")}
                            {/* <Image
                            style={{ width: 25, height: 25, marginLeft: width > 320 ? 80 : 60 }}
                            source={require('../../../../assets/iconShare.png')}
                            resizeMode={'contain'} /> */}
                        </div>
                        {
                            base64img ?
                                <img
                                    style={{ width: 150, height: 150, marginTop: 10 }}
                                    src={base64imgUri}
                                    resizeMode={'contain'} />
                                :
                                <img
                                    style={{ width: 150, height: 150, marginTop: 10 }}
                                    src={icon_Self_Certification}
                                    resizeMode={'contain'} />
                        }
                        <img
                            style={{ width: 100, height: 100, marginTop: 10 }}
                            src={icon_checkmark256}
                            resizeMode={'contain'} />
                        <div style={{ flexDirection: 'row', marginTop: 10,display:'flex' }}>
                            {qrDateTimeExpired ?
                                <div style={{ color: 'red' }}>

                                    {this.translate("w_Expiresed")}
                                </div>
                                :
                                <div>

                                    {this.translate("w_Expires_on")}
                                </div>
                            }
                            {qrDateTimeExpired ?
                                null :
                                qrDateTimeStart && qrDateTimeExpiring ?
                                    <div style={{ marginLeft: 10 }}>
                                        {moment(qrDateTimeExpiring).format('D MMMM YYYY')}
                                    </div>
                                    :
                                    <div style={{ marginLeft: 10 }}>
                                        {moment(ExpiresDate).format('D MMMM YYYY')}
                                    </div>
                            }
                        </div>
                        {this.translate("w_Disclaimer1")}

                    </div>
                    {showDone ?
                        <button className="button" style={{ width: width - 60, marginBottom: 20, marginTop: 30, alignSelf: 'center' }}
                            onClick={() => {
                                //this.props.onshowSelfCertification && this.props.onshowSelfCertification(false,false);
                                this.props.onShowSummary && this.props.onShowSummary(true, false, false,true,true);
                            }} >
                            {this.translate("w_done")}

                        </button>
                        :
                        <button className="button" style={{ width: width - 60, marginBottom: 20, marginTop: 30, alignSelf: 'center' }}
                            onClick={() => {

                                //this.props.onshowSelfCertification && this.props.onshowSelfCertification(false,false);
                                this.props.onShowSummary && this.props.onShowSummary(true, false, false,false);
                            }} >

                            {this.translate("w_back")}

                        </button>}
                </div>
            </div>
        );
    }
}
const styles = {
    trkItemContainer: {
        alignSelf: 'stretch',
        padding: 5,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: '#e0e0e0',
        marginTop: 5,
        backgroundColor: '#fafafa',
    },
    trkItemTitleContainer: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    trkItemLogo: {
        height: 30,
        width: 30
    },
    trkItemArrow: {
        height: 12, width: 12, transform: [{ rotateY: '180deg' }]
    },
    unSelectContainer: {
        width: 24,
        height: 24,
        backgroundColor: '#fcfcfc',
        borderRadius: 12,
        borderColor: '#e0e0e0',
        borderWidth: 1,
    },
    selectedContainer: {
        width: 24,
        height: 24,
        backgroundColor: '#00b359',
        borderRadius: 12,
        borderColor: '#00b359',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectedSymptom: {
        flexDirection: 'row',
        justifyContent: 'center',
        top: 2,
        left: width > 320 ? 80 : 56,
        position: 'absolute',
        width: 30,
        height: 30,
        borderRadius: 8,
        borderWidth: 0,
        borderColor: 'transparent',
        //borderColor: '#ffffff',
    },
    input2: {
        alignSelf: 'flex-start',
        textAlign: 'center',
        height: 30,
        width: 100,
        borderRadius: 4,
        fontSize: 13,
        padding: 0,
        backgroundColor: 'rgb(238,238,238)',
    },
    button: {
        //backgroundColor: '#123b6d',
        backgroundColor: '#f2f2f2',
        width: width - 60,
        height: width > 400 ? 52 : 46,
        alignSelf: 'stretch',
        alignItems: 'stretch',
        justifyContent: 'center',
        borderColor: "#eeeeee",
        borderWidth: 3,
        borderRadius: 6,
        marginTop: 5,
        marginBottom: 5,
    },
    buttonText: {
        fontFamily: 'AtlasGrotesk-Regular',
        color: '#000',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        marginLeft: 10

    },
};