import React from 'react';
import BaseComponent from '../../../components/BaseComponent';
import TabBarContainer from '../../../components/TabBarContainer';
import httpUtil from '../../../utils/HttpUtil';
import ReactDOM from 'react-dom';
import ReactSwipe from 'react-swipe';
import moment from 'moment';
import journay_logo from '../../../content/images/track-journay.png';
import star_logo from '../../../content/images/star.png';

import Loading from '../../../components/Loading';
import CacheUtil from '../../../utils/CacheUtil';
import ModalContainer from '../../../components/ModalContainer';
import ScrollView from '../../../components/ScrollView';
import CommonUtil from './../../../utils/CommonUtil';
import Common from '../../../Common';

let width = CommonUtil.clientWidth();
let height = CommonUtil.clientHeight();
export default class TrkTodayComplete extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            Symptompaths: [],
            loading: false,
        };
    }

    componentDidMount() {
        this.getDescription();
    }

    getDescription() {
        let path = this.props.location.state.symptomPath;
        httpUtil.post('api/TrkRSV/GetSymptomInstructionByPath',
            { path: path },
            (responseData) => {
                if (responseData) {
                    this.setState({ Symptompaths: responseData, loading: true });
                }
            });
    }



    render() {
        if (!this.state.loading) {
            return <Loading />
        }
        let { loading, Symptompaths } = this.state;
        let htmls = [];
        let htmlsV2 = [];
        if (loading) {
            Symptompaths.map((item, index) => {
                var reg = /<a[^>]*href[=\"\'\s]+([^\"\']*)[\"\']?[^>]*>/gi;
                var text = item.description;
                while(reg.exec(text)){
                    text = text.replace('<a href="' + RegExp.$1 +'"','<a href="' + RegExp.$1 +'" target="_blank"');
                }                   
                htmlsV2.push(
                    <div style={{ alignSelf: 'stretch' }} key={index}>
                        <div style={styles.borderView}>
                            <iframe
                                title="resg"
                                srcDoc={text}                               
                                //srcDoc={common}
                                style={{ width: '105%',height:'120%'}}
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                scrolling="auto"
                            />
                            {/* <HTMLView
                                stylesheet={styles}
                                value={item.description}
                            // style={styles.borderText}
                            /> */}
                        </div>
                    </div>

                );
                
            });
        }
        // if (this.props.symptomPath == 'E') {
        //     htmlsV2.push(
        //         <View style={{ alignSelf: 'stretch' }}>
        //             <TouchableHighlight style={[globalStyles.button2, { width: width / 3, marginTop: 30, alignSelf: 'center' }]}
        //                 underlayColor={globalColor.buttoUunderlayColor}
        //                 onPress={() => { Linking.openURL('tel:203-582-8742') }}

        //             >
        //                 <Text style={[globalStyles.normalFont, globalStyles.buttonTextBold]}>{Translation.lang().w_Call_now}</Text>
        //             </TouchableHighlight>
        //         </View>
        //     );
        // }

        return (
            <div className="fill" style={{ alignSelf: 'stretch', height: height, width:'100%',backgroundColor: 'rgb(237,237,237)' }}>
                <div className='fill overflow-hidden' style={this.props.style}>
                <div className='fill auto-left-scrollbar'>
                    <div style={
                        {
                            alignSelf: 'stretch',
                            alignItems: 'center',
                            paddingLeft: '5%',
                            width:CommonUtil.isPC()?'95%':'90%',
                            flex: 1
                        }
                    }>
                        <div style={{
                            alignSelf: 'stretch',
                            justifyContent: 'space-between',
                            marginTop: 10,
                            flex: 1
                        }}>
                            <div style={{ alignSelf: 'stretch', justifyContent: 'space-between' }}>
                                {/* <Image
                                style={{
                                    width: 44,
                                    height: 44,
                                    alignSelf: 'center',
                                    marginTop: 10
                                }}
                                source={require('../../../../assets/crocus/intro/Drexel_Vertical_stacked_Lockup_HEX.png')}
                                resizeMode={'contain'}
                            /> */}
                                {htmlsV2}
                            </div>
                            {/* <View style={{
                            alignSelf: 'stretch',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 30,
                        }}>
                            <Text style={[globalStyles.normalFont, globalStyles.fontSize16]}>
                                {'Go to '}
                            </Text>
                            <TouchableOpacity onPress={() => {
                                MomEnv.saveStep('autologintoresources');
                            }}>
                                <Text style={[globalStyles.normalFont, { color: globalColor.navyColor }]}>
                                    {'Resources '}
                                </Text>
                            </TouchableOpacity>
                            <Text style={[globalStyles.normalFont, globalStyles.fontSize16]}>
                                {'to get the latest news'}
                            </Text>
                        </View>
                        <View style={{
                            alignSelf: 'stretch',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Text style={[globalStyles.normalFont, globalStyles.fontSize16, { alignSelf: 'stretch' }]}>
                                {'and information.'}
                            </Text>
                        </View> */}
                            <button className="button" style={
                                {
                                    width: '90%',
                                    marginBottom: 30,
                                    marginTop: 30,
                                    alignSelf: 'center'
                                }}
                                onClick={() => { this.props.history.push('/trackmain', { viewSummary: true }); }}
                            //onPress={() => { MomEnv.pushPage(TrackView, {showSummary:true})}}
                            //onPress={() => { MomEnv.pushPage(TrackView); this.props.onShowSummary && this.props.onShowSummary(true)}}
                            >
                                {this.translate("w_done")}
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}
const styles = {
    trkItemContainer: {
        alignSelf: 'stretch',
        padding: 5,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: '#e0e0e0',
        marginTop: 5,
        backgroundColor: '#fafafa',
    },
    content: {
        flex: 1,
        alignSelf: 'stretch',
    },
    borderView: {
        height:200,
        marginTop: 20,
        alignSelf: 'stretch',
        justifyContent: 'center',
        alignItems: 'stretch',
        backgroundColor: 'white',
        borderWidth: 2.5,
        borderRadius: 20,
        paddingTop: 10,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 55,
        borderColor: '#d2d2d2',
        overflow: "hidden"
    },
    borderViewIOS: {
        marginTop: 10,
        alignSelf: 'stretch',
        justifyContent: 'center',
        alignItems: 'stretch',
        backgroundColor: 'white',
        borderWidth: 2.5,
        borderRadius: 20,
        padding: 12,
        borderColor: '#d2d2d2',
        overflow: "hidden"
    },
    borderText: {
        alignSelf: 'stretch',
        marginLeft: 10,
        marginRight: 10,
        backgroundColor: 'transparent',
        fontSize: 18
    },
    p: {
        //color: '#333333',
        fontSize: 18,
        lineHeight: 20,
        fontFamily: 'AtlasGrotesk-Regular',
        backgroundColor: 'transparent',
    },
    a: {
        fontSize: 18,
        lineHeight: 20,
        color: '#0472c1',
        textDecorationLine: 'underline',
        textDecorationColor: '#0472c1'
    }
};