import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import Drexel_Vertical_stacked_Lockup_HEX from '../../content/images/Drexel_Vertical_stacked_Lockup_HEX.png';
import httpUtil from '../../utils/HttpUtil';
import logo_myownmed from '../../content/images/logo_myownmed.png';
import study from '../../content/images/icon-study.png';
import track from '../../content/images/icon-track.png';
import phone from '../../content/images/icon-phone.png';
import news from '../../content/images/icon-news.png';
import ScrollView from '../../components/ScrollView';
import Loading from '../../components/Loading';
import i18n from 'i18next';
import common from '../../Common';
import LabResultSvr from '../../service/LabResultSvr';
import Profile from '../../store/ProfileStorage';
const globalColor = require('./../../utils/Color');
const VIEW_TYPES = {
    PIN: 'PIN',
    LOGIN: 'LOGIN',
};
export default class Login extends BaseComponent {
    constructor(props) {
        let storepin = null;
        let email = '';
        let viewtype = VIEW_TYPES.LOGIN;
        let firstLogin = true;
        let profile = JSON.parse(localStorage.getItem('profile'));

        if (profile) {
            firstLogin = false;
            viewtype = VIEW_TYPES.PIN;
            email = profile.email;
            storepin = profile.pin;
        }
        super(props);
        if(props.location.state && props.location.state.email){
            email = props.location.state.email;
        }
        this.state = {
            email: email,
            password: '',
            storepin: storepin,
            firstLogin: firstLogin,
            firstLanuch: false,
            showNotice: true,
            loading: false,
            pin1: '',
            pin2: '',
            pin3: '',
            pin4: '',
            viewtype: viewtype,
            loading: false,
            loginWithJustPin: (email && email.length > 0) ? true : false
        };
    }
    componentDidMount() {
        //1.Email页存在账号跳转到登录页，设置MOM_APP_FIRSTLANUCH为1
        //2.存在token时，设置MOM_APP_FIRSTLANUCH为1
        if((this.props.location.state && this.props.location.state.email) || localStorage.getItem('token')){
            localStorage.setItem('MOM_APP_FIRSTLANUCH', 1);
        }

        let MOM_APP_FIRSTLANUCH = localStorage.getItem('MOM_APP_FIRSTLANUCH');
        this.setState({ firstLanuch: MOM_APP_FIRSTLANUCH !== '1' }, ()=> {
            if(!this.state.firstLanuch){
                this._showNotice();
            }
        });

        if(localStorage.getItem("cultureName") == "es"){
            i18n.changeLanguage('es');
        }else{
            i18n.changeLanguage('en');
        }
    }

    getProfile() {
        //{"$id":"1","id":2173,"name":"chliam z","nickname":null,"firstName":"chliam","lastName":"z","gender":"M","dateOfBirth":null,"homePhone":null,"mobilePhone":null,"email":"chliam06@sina.com","address1":null,"address2":null,"city":null,"state":null,"zipCode":null,"status":1,"consented":true,"stage":4,"avatarBase64":null,"isTempPassword":false,"studyArm":0,"trkItems":[{"$id":"2","id":1,"enable":false},{"$id":"3","id":2,"enable":false},{"$id":"4","id":3,"enable":false},{"$id":"5","id":4,"enable":false},{"$id":"6","id":5,"enable":false},{"$id":"7","id":6,"enable":false},{"$id":"8","id":7,"enable":false},{"$id":"9","id":8,"enable":false},{"$id":"10","id":9,"enable":false},{"$id":"11","id":10,"enable":false},{"$id":"12","id":11,"enable":true}]}
        let { storepin } = this.state;
        httpUtil.get('/api/Profile/GetMine',
            (result) => {
                if (result && result.id) {
                    //Lang.saveLanguageId(result.language);
                    result.email = this.state.email;
                    result.password = this.state.password;
                    LabResultSvr.getLabResultNotes(Profile.saveLabResultNotes);
                    localStorage.setItem('profile', JSON.stringify(result));
                    if (result.cultureName) {
                        if (result.cultureName == 'en' || result.cultureName == 'en-US') {
                            i18n.changeLanguage('en');
                            localStorage.setItem("cultureName", "en");
                        }
                        else if (result.cultureName == 'nl' || result.cultureName == 'nl-NL') {
                            i18n.changeLanguage('es');
                            localStorage.setItem("cultureName", "es");

                        }
                    }
                    if (result.isTempPassword == false) {
                        localStorage.setItem("need_alert_survey", "1");
                        this.props.history.push('/trackmain');
                    }
                    else {
                        this.setState({
                            pin1: '',
                            pin2: '',
                            pin3: '',
                            pin4: '',
                        });
                        this.props.history.push('/resetpin', { email: this.state.email });
                    }
                    //this.setState({ loading: false, pin: '', password: '', isTempPassword: result.isTempPassword, stage: result.stage });
                } else {
                    localStorage.removeItem('token');
                    this.setState({ loading: false });
                }
            });
    }
    getAppStatus(callback) {
        httpUtil.get('/api/Profile/GetAppStatus',
            (appStatus) => {
                if (appStatus && appStatus.hasOwnProperty("numNewMsg")) {
                    localStorage.setItem('msg_count', JSON.stringify(appStatus.numNewMsg));
                    localStorage.setItem('survey_count', JSON.stringify(appStatus.numNewSurvey));
                    localStorage.setItem('wiki_count', JSON.stringify(appStatus.numNewWiki));
                }
                callback();
            });
    }
    loginClick() {
        let { email, password, pin1, pin2, pin3, pin4 } = this.state;
        if (email && email.length > 0) {
            if (pin1 && pin1.length > 0
                && pin2 && pin2.length > 0
                && pin3 && pin3.length > 0
                && pin4 && pin4.length > 0) {
                this.setState({ loading: true, password: `${pin1}${pin2}${pin3}${pin4}` });
                httpUtil.post('/Token', `grant_type=password&username=${email}&password=${pin1}${pin2}${pin3}${pin4}`,
                    (result) => {
                        console.log(result);
                        if (result && result.access_token) {
                            let token = result.access_token;
                            localStorage.setItem('token', token);
                            //TODO: Load Profile                          
                            this.getAppStatus(() => {
                                //console.log(result);
                                //this.props.history.push('/home');
                                this.getProfile();
                            });
                        } else {
                            this.setState({ loading: false });
                            alert(this.translate('Your PIN is incorrect'));
                        }
                    });
            }
            else {
                alert(this.translate('Please_enter_your_PIN'));
            }
        } else {
            alert(this.translate('Please enter the email for your account'));
        }
    }
    _onPinTextChange(text, index) {
        //Alert.alert(text.toString());
        text = text.replace(/\D/g, '');
        if (index === 1) {
            if (text !== '') {
                this.input2.focus();
                //this.refs.pin2.focus();
            }
            this.setState({ pin1: text.toString() });
        } else if (index === 2) {
            if (text !== '') {
                this.input3.focus();
                //this.refs.pin3.focus();
            }
            this.setState({ pin2: text.toString() });
        } else if (index === 3) {
            if (text !== '') {
                this.input4.focus();
                //this.refs.pin4.focus();
            }
            this.setState({ pin3: text.toString() });
        } else if (index === 4) {
            if (text !== '') {
                this.input4.blur();
                //this.refs.pin4.blur();
            }
            this.setState({ pin4: text.toString() });
        }
    }

    _onPinKeyPress(e, index) {
        if (e.keyCode === 8) {
            if (index === 4) {
                this.input3.focus();
            } else if (index === 3) {
                this.input2.focus();
            } else if (index === 2) {
                this.input1.focus();
            }
        }
    }

    _changeLanguage(culture){
        i18n.changeLanguage(culture);       
        this.forceUpdate();
        let language = culture == 'en' ? 'English' : 'Spanish';
        httpUtil.post('/api/Profile/UpdateCulture', { culture: language },
        (responseData) => {
            if (responseData.status == 'Success') {
                localStorage.setItem("cultureName", culture);
            }
        });
    }

    _renderLogin(){
        if(common.isTurnToSSOLogin()){
            this._renderSSOLogin();
            return;
        }
           
        let {email,password,pin1, pin2, pin3, pin4,loginWithJustPin} = this.state;
        return (
            <div className="conatiner" >
            <div style={styles.borderContainer}>
               <p style={{color:globalColor.mainColor,fontSize:15,fontWeight:800,margin:0}}>{loginWithJustPin ? this.translate("w_welcome_back") : this.translate("w_welcome")}</p>
              {loginWithJustPin? <p>{this.translate("w_sign_in_to_your_account")}</p>:
               <div style={{width:'100%'}}>
                 <p style={{fontWeight:800}}>{this.translate("w_sign_enter_email")}</p>
                 <input type="text" 
                   className="input1"
                   style={{margin:0,marginBottom:5,width:'86%'}} 
                   placeholder={this.translate('Email')}   
                   value={email}
                   onChange={(e)=>{
                     this.setState({email:e.target.value});
                   }}
                 />
               </div>
               }
               <p style={{fontWeight:800}}>{this.translate("w_sign_enter_pin")}</p>
               <div style={{ marginTop: 8, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', display: "flex", alignSelf: 'stretch' }}>
                       <input
                           type={/mobi/i.test(window.navigator.userAgent.toLowerCase()) ? "number" : "password"}
                           style={styles.pin}
                           value={pin1}
                           maxLength={1}
                           onChange={(e) => {
                               this._onPinTextChange(e.target.value, 1);
                               //this.setState({ pin1: e.target.value });
                           }}
                           onFocus={() => { this.setState({ pin1: '' }); }}
                           onKeyDown={(e) => this._onPinKeyPress(e, 1)}
                           ref={(input1) => this.input1 = input1}
                           className="input"
                       />
                       <input
                           type={/mobi/i.test(window.navigator.userAgent.toLowerCase()) ? "number" : "password"}
                           style={styles.pin}
                           value={pin2}
                           maxLength={1}
                           onChange={(e) => {
                               this._onPinTextChange(e.target.value, 2);
                               //this.setState({ pin2: e.target.value });
                           }}
                           onFocus={() => { this.setState({ pin2: '' }); }}
                           onKeyDown={(e) => this._onPinKeyPress(e, 2)}
                           ref={(input2) => this.input2 = input2}
                           className="input"
                       />
                       <input
                           type={/mobi/i.test(window.navigator.userAgent.toLowerCase()) ? "number" : "password"}
                           style={styles.pin}
                           maxLength={1}
                           value={pin3}
                           onChange={(e) => {
                               this._onPinTextChange(e.target.value, 3);
                               //this.setState({ pin3: e.target.value });
                           }}
                           onFocus={() => { this.setState({ pin3: '' }); }}
                           onKeyDown={(e) => this._onPinKeyPress(e, 3)}
                           ref={(input3) => this.input3 = input3}
                           className="input"
                       />
                       <input
                           type={/mobi/i.test(window.navigator.userAgent.toLowerCase()) ? "number" : "password"}
                           style={styles.pin}
                           maxLength={1}
                           value={pin4}
                           onChange={(e) => {
                               this._onPinTextChange(e.target.value, 4);
                               //this.setState({ pin4: e.target.value });
                           }}
                           onFocus={() => { this.setState({ pin4: '' }); }}
                           onKeyDown={(e) => this._onPinKeyPress(e, 4)}
                           ref={(input4) => this.input4 = input4}
                           className="input"
                       />
                    </div>
                    <button style={{marginLeft:0,marginRight:0,width:'80%'}} onClick={this.loginClick.bind(this)} className="button">{this.translate('w_signin')}</button>
               </div>
               <span style={{width:'80%',textAlign:'center'}}>
                 <a className='link' style={{marginBottom:15,color:globalColor.greenColor,float:loginWithJustPin?'left':'none'}} onClick={()=>{ this.props.history.push('/problemssigningin') }}>{this.translate('w_forgotYourPin')}</a>
                 <a className='link' style={{marginBottom:15,color:globalColor.greenColor,float:'right',display:loginWithJustPin?'block':'none'}} 
                     onClick={()=>{ this.setState({loginWithJustPin:false,email:''}); }}>{this.translate('w_Login_with_Email')}</a>
               </span>
               <div style={{flex:1}}>
                    <img style={{ margin: 20 }} src={logo_myownmed} alt="" />
            </div>
          </div>
        );
    }

    _renderSSOLogin(){
        let MOM_APP_FIRSTLANUCH = localStorage.getItem('MOM_APP_FIRSTLANUCH');
        if(MOM_APP_FIRSTLANUCH === '1' || MOM_APP_FIRSTLANUCH === 1){
            this.props.history.push('/SSOWeb');
            return;
        }
    }

    _showNotice() {
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(
            () => {
                this.setState({showNotice: false});
            },
            30 * 1000
        );
    }

    renderNotice(){
        return(
            <div style={styles.WordSection1}>
                <p style={styles.xxxmsonormal}>
                    <span style={styles.xxcontentpasted0}>
                        <b><span style={{color:'black'}}>{this.translate("w_NOTICE_OF_CHANGE_IN_PLATFORM")}<p></p></span></b>
                    </span>
                </p>
                <p style={styles.xxxmsonormal}>
                    <span style={{fontSize: 10}}><p>&nbsp;</p></span>
                </p>
                <p style={styles.xxxmsonormal}>
                    <span style={styles.xxcontentpasted0}>
                        <span style={{color:'black'}}>
                            {this.translate("w_NOTICE_OF_CHANGE_IN_PLATFORM_content1")}
                            <b><a href="https://apps.med.drexel.edu/covidresource">{this.translate("w_NOTICE_OF_CHANGE_IN_PLATFORM_content2")}</a></b>.
                        </span>
                    </span>
                    <span style={{fontSize: 10}}><p></p></span>
                </p>
                <p style={styles.xxxmsonormal}>
                    <span style={styles.xxcontentpasted0}><b>
                        <span style={{color:'black'}}>
                            <a href="https://apps.med.drexel.edu/covidresource">{this.translate("w_NOTICE_OF_CHANGE_IN_PLATFORM_content3")}</a>
                        </span></b>
                        <span style={{color:'black'}}>
                        </span>
                    </span>
                    <span style={{fontSize: 10}}><p></p></span>
                </p>
                <p style={styles.xxxmsonormal}><span style={styles.xxcontentpasted0}><span style={{color:'black'}}>&nbsp;</span></span><span style={{fontSize: 10}}><p></p></span></p>
                <p style={styles.xxxmsonormal}>
                    <span style={styles.xxcontentpasted0}>
                        <span style={{color:'black'}}>
                            {this.translate("w_NOTICE_OF_CHANGE_IN_PLATFORM_content4")}
                        </span>
                    </span>
                </p>
                <p style={styles.xxxmsonormal}><span style={styles.xxcontentpasted0}><span style={{color:'black'}}>&nbsp;</span></span><span style={{fontSize: 10}}><p></p></span></p>
                <p style={styles.xxxmsonormal}>
                    <span style={styles.xxcontentpasted0}>
                        <span style={{color:'black'}}>
                            {this.translate("w_NOTICE_OF_CHANGE_IN_PLATFORM_content5")}
                            <b><a href="https://drexel.edu/coronavirus/health-safety/drexel-health-checker/"> 
                            {this.translate("w_NOTICE_OF_CHANGE_IN_PLATFORM_content6")}</a>
                            </b>.
                        </span>
                    </span>
                </p>               
            </div>
        )
    }

    renderStart() {
        return (
            <div style={{ alignSelf: 'center',display: 'flex', flexDirection: "column", justifyContent: 'space-around' }}>
                <div style={styles.slide}>
                    <div style={styles.altLogoText}>
                        {this.translate("w_with_this_app")}
                    </div>
                    <div style={styles.row}>
                        <img
                            src={study}
                            style={styles.rowLogo}
                            resizemode={'contain'}
                            alt=""
                        />
                        <div style={styles.bodyText}>
                            {this.translate("Track_your_Health")}
                        </div>
                    </div>
                    <div style={styles.row}>
                        <img
                            src={track}
                            style={styles.rowLogo}
                            resizemode={'contain'}
                            alt=""
                        />
                        <div style={styles.bodyText}>
                            {this.translate("Link_to_Frequently_Asked_Questions")}
                        </div>
                    </div>
                    <div style={styles.row}>
                        <img
                            src={phone}
                            style={styles.rowLogo}
                            resizemode={'contain'}
                            alt=""
                        />
                        <div style={styles.bodyText}>
                            {this.translate("Connect_with_Drexel_Health_Resources")}
                        </div>
                    </div>
                    <div style={styles.row}>
                        <img
                            src={news}
                            style={styles.rowLogo}
                            resizemode={'contain'}
                            alt=""
                        />
                        <div style={styles.bodyText}>
                            {this.translate("Get_the_latest_news_and_updates")}
                        </div>
                    </div>
                    <div style={styles.row}>
                        <button
                            onClick={this.start.bind(this)}
                            style={{ margin: 5, marginTop: 20, flex: 1 }}
                            className="button1"
                        >
                            {this.translate("w_getStarted")}
                        </button>
                    </div>

                </div>
            </div>
        )
    }
    start() {
        localStorage.setItem('MOM_APP_FIRSTLANUCH', 1);
        this.setState({ firstLanuch: false },()=>{
            this._showNotice();
        });
        // if(common.isTurnToSSOLogin()){
            // this._renderSSOLogin();
        // }
        // else{
            // this.props.history.push("/email");    
        // }  
    }
    render() {
        let { email, password, viewtype, firstLogin, showNotice } = this.state;
        return (
            <div className="conatiner" style={{position: showNotice ? "relative" : "relative"}}>
                {showNotice ? <div></div> : <div>
                    <img style={{ margin: 25,height:120 }} src={require('../../content/images/Drexel_Vertical_stacked_Lockup_HEX.png')} alt="" />
                    <p style={{ fontSize:17,fontWeight:800,color:globalColor.mainColor,marginTop:0,textAlign:'center' }}>{this.translate("w_Health_Checker")}</p>
                </div>}
                {
                    this.state.firstLanuch
                        ?
                        (this.renderStart())
                        :
                        (showNotice ? this.renderNotice() : this._renderLogin())
                }
                

                
                {
                    this.state.loading && <Loading />
                }
            </div>
        );
    }

}
const styles = {
    MsoListParagraph:{
        marginLeft: 0,
    },
    WordSection1: {
        marginLeft:10,
        marginRight: 10,
    },
    xxxmsonormal:{
        marginLeft: 5,
        backgroundColor: '#fff',
    },
    MsoNormal:{

    },
    backContainer: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        color: '#fff',

    },
    borderContainer: {
        borderWidth: 2,
        borderColor: "#c2c2c2",
        borderStyle: "solid",
        borderRadius: 3,
        alignSelf: 'center',
        textAlign:'center',
        width:'94%',
    },
    backImg: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: 21,
        padding: 18,
    },
    pin: {
        margin: 0,
        padding: 2,
        marginBottom: 5,
        marginTop: 5,
        alignSelf: 'center',
        textAlign: 'center',
        width: 46,
        height: 46,
        // webkitTextSecurity: 'disc'
    },
    backTxt: {
        textDecoration: 'none',
        fontSize: 18,
    },
    forgotPassword: {
        backgroundColor: 'transparent',
        marginTop: 31,
    },
    forgotPasswordText: {
        fontSize: 17,
        color: 'rgb(0,115,178)',
    },
    row: {
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: "row",
        alignItems: 'center',
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "center",
        justifyContent: "center",
        padding: 0
    },
    slide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 30,
        paddingTop: 0,

    },
    rowLogo: {

        width: 44,
        margin: 10,
        tintColor: '#123B6D',

    },
    rowText: {
        flex: 1,
        textAlign: 'left'
    },
    bodyText: {
        fontSize: 17,
        textAlign: 'left',
        color: '#4A4A4A',
        flex: 1,
    },
    altLogoText: {
        fontSize: 20,
        textAlign: 'center',
        color: '#4A4A4A',
        marginBottom: 10
    },
};