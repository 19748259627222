import React from 'react';

import BaseComponent from '../../../components/BaseComponent.js';
import Container from '../../../components/Container.js';
import NavBar from '../../../components/NavBar.js';
import httpUtil from '../../../utils/HttpUtil';

const globalStyles = require('../../../utils/GlobalStyles');

export default class ShareResult extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      recipientEmail: '',
      requestPending: false,
    };

    this._send = this._send.bind(this);
  }

  _send() {
    if (!this.state.recipientEmail || this.state.recipientEmail.length < 4) {
      alert(
        this.translate('w_confirmation'),
        this.translate('w_emailRequired')
      )
      return false;
    }

    if (!/^.+@.+\..+$/.test(this.state.recipientEmail)) {
      alert(
        this.translate('w_confirmation'),
        this.translate('w_noValidEmail')
      )
      return false;
    }

    this.setState({requestPending: true});

    httpUtil.post('/api/TestResults/Send',
      {
        labResultID: this.props.location.state.labResultID,
        email: this.state.recipientEmail,
      },
      (response) => {
        this.setState({requestPending: false});

        if (response && response.status == "Success") {
          alert(this.translate('w_result_sent_success'), [
            {
                text: this.translate('w_ok'), onPress: () => {
              }
            }
          ]); 
          this.props.history.goBack();
        }
        else {
            alert(this.translate('w_result_sent_failure'));
           
        }
      }
    );

  } 

  render() {
    return (
      <Container>
        <NavBar
          title={this.translate('w_share_Test_Results')}
        //   backurl={'/home/calendar'}
          history={this.props.history}
        />
        <div>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#fff',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
          >
            <div
              style={{
                flex: 1,
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <input
                style={{ ...globalStyles.normalFont, ...styles.emailInput }}
                placeholder={this.translate('w_recipientEmail')}
                value={this.state.recipientEmail}
                onChange={(e) => {
                  this.setState({ recipientEmail: e.target.value });
                }}
              />

              <button
                style={{
                  ...globalStyles.normalFont,
                  ...globalStyles.mButton,
                  ...globalStyles.button2,
                  marginTop: 23,
                  width: '90%',
                  color: 'white',
                }}
                className="button"
                onClick={this._send}
              >
                {this.translate('w_Send')}
              </button>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

var styles = {
  emailInput: {
    marginTop: 40,
    textAlign: 'center',
    height: 46,
    borderRadius: 4,
    fontSize: 18,
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: 'rgb(238,238,238)',
    width: 'calc(90% - 5px)',
    display: 'block',
  },
};
