import React from 'react';
import BaseComponent from '../../components/BaseComponent';
import faq_login from '../../content/images/more/faq_login.png';
import faq_privacy from '../../content/images/more/faq_privacy.png';
import faq_track from '../../content/images/more/faq_track.png';
import NavBar from '../../components/NavBar';

export default class Help extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="fill">
                <NavBar title={this.translate('w_Help')} history={this.props.history} />
                <div
                    className="row_container"
                    onClick={() => {
                        this.props.history.push(`/home/more/help/detail/1`)
                    }}
                >
                    <img className="row_logo" src={faq_login} resizemode={'contain'} />
                    <div className="row_title">
                        {this.translate('w_Account_Access')}
                    </div>
                </div>
                <div
                    className="row_container"
                    onClick={() => {
                        this.props.history.push(`/home/more/help/detail/2`)
                    }}
                >
                    <img className="row_logo" src={faq_track} resizemode={'contain'} />
                    <div className="row_title">
                        {this.translate('Resources')}
                    </div>
                </div>
                <div
                    className="row_container"
                    onClick={() => {
                        this.props.history.push(`/home/more/help/detail/3`)
                    }}
                >
                    <img className="row_logo" src={faq_privacy} resizemode={'contain'} />
                    <div className="row_title">
                        {this.translate('Privacy')}
                    </div>
                </div>
            </div>
        );
    }
}
